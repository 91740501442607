import { FC, memo, ReactElement, CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { setLayerType } from '../../../../../store/vehicle/vehicle.slice';
import satelliteSrc from '../../../../assets/satellite.png';
import terrainSrc from '../../../../assets/terrain.png';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { LayersEnum } from '../../common/config';
import classes from '../../styles/index.module.css';

interface ILayer {
  id: string;
  src: string;
  name: string;
}

const LayersSettings: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const mapLayerType = useAppSelector((state) => state.vehicles.mapLayerType);
  const { t } = useTranslation();

  const layers: ILayer[] = useMemo(
    (): ILayer[] => [
      {
        id: LayersEnum.SATELLITE,
        src: satelliteSrc,
        name: t('vehiclesList.mapLayerSatelliteLabel'),
      },
      {
        id: LayersEnum.TERRAIN,
        src: terrainSrc,
        name: t('vehiclesList.mapLayerTerrainLabel'),
      },
    ],
    []
  );

  const setActiveBorderColor = (isActive: boolean): CSSProperties => ({
    borderColor: isActive ? '#4778F5' : '#fff',
  });

  const setActiveTextColor = (isActive: boolean): CSSProperties => ({
    color: isActive ? '#4778F5' : '#14151A',
  });

  return (
    <Box className={classes.layerImagesContainer}>
      {layers.map(
        ({ id, src, name }: ILayer): ReactElement => (
          <Box
            key={id}
            className={classes.layerImageContainer}
            onClick={() => dispatch(setLayerType(id))}
          >
            <img
              srcSet={src}
              src={src}
              alt={id}
              style={setActiveBorderColor(id === mapLayerType)}
              className={classes.layerImage}
            />
            <Typography
              variant='subtitle2'
              style={setActiveTextColor(id === mapLayerType)}
            >
              {name}
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
});

export default LayersSettings;

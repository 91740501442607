import { FC, ReactElement, memo } from 'react';

const NoRecord: FC = memo(
  (): ReactElement => (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='1'
        width='34'
        height='34'
        rx='17'
        stroke='#F7F7F8'
        strokeWidth='2'
      />
      <circle cx='18' cy='18' r='10' fill='#F7F7F8' />
    </svg>
  )
);

export default NoRecord;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserError } from '../../domain/user';
import { connectToSocket } from '../socket/socket.thunks';
import { SUCCESS_STATUS } from '../../infra/common/httpService/constants';
import { IArchive } from '../../domain/vehicle';

export const getVehicleList = createAsyncThunk(
  'vehicle/getVehicleList',
  async (vehicleListOptions: string, { extra: container }) => {
    try {
      return await (container as any)
        // @ts-ignore
        .getVehicleList(vehicleListOptions);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);
export const getVehicleListMetrics = createAsyncThunk(
  'vehicle/getVehicleListMetrics',
  async (options: string, { extra: container }) => {
    try {
      // @ts-ignore
      return await container.getVehicleListMetrics(options);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getVehicleStatus = createAsyncThunk(
  'vehicle/getVehicleStatus',
  async (
    { vehicleUuid, viewType }: { vehicleUuid: string; viewType: string },
    { extra: container, dispatch }
  ) => {
    try {
      // @ts-ignore
      const vehicleResponse = await container.getVehicleStatus(vehicleUuid);
      if (vehicleResponse) {
        dispatch(connectToSocket({ vehicleUuid, viewType }));
      }
      return vehicleResponse;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const connectToVehicle = createAsyncThunk(
  'vehicle/connectToVehicle',
  async (vehicleUuid: string, { extra: container }) => {
    try {
      // @ts-ignore
      await container.connectToVehicle(vehicleUuid);
      return true;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const vehicleIsCanConnect = createAsyncThunk(
  'vehicle/vehicleIsCanConnect',
  async (vehicleUuid: string, { extra: container }) => {
    try {
      // @ts-ignore
      const repsonse = await container.vehicleIsCanConnect(vehicleUuid);
      return repsonse;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const disconnectFromVehicle = createAsyncThunk(
  'vehicle/disconnectFromVehicle',
  async (vehicleUuid: string, { extra: container }) => {
    try {
      // @ts-ignore
      await container.disconnectFromVehicle(vehicleUuid);
      return true;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const startStreamingRecord = createAsyncThunk(
  'vehicle/startStreamingRecord',
  async (
    {
      uuid,
      camera,
      onSuccess,
    }: { uuid: string; camera: number; onSuccess: () => void },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      const response = await container.startStreamingRecord(uuid, camera);
      if (response.status === SUCCESS_STATUS) {
        return onSuccess();
      }
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const stopStreamingRecord = createAsyncThunk(
  'vehicle/stopStreamingRecord',
  async (
    {
      uuid,
      camera,
      onSuccess,
    }: { uuid: string; camera: number; onSuccess: () => void },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      const response = await container.stopStreamingRecord(uuid, camera);
      if (response.status === SUCCESS_STATUS) {
        return onSuccess();
      }
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const listStreamingRecordings = createAsyncThunk(
  'vehicle/listStreamingRecordings',
  async (
    { uuid, limit, page }: { uuid: string; limit: string; page: string },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      const response = await container.listStreamingRecordings(
        uuid,
        limit,
        page
      );
      if (response.status === SUCCESS_STATUS) {
        return response.data;
      }

      return null;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const deleteStreamingRecording = createAsyncThunk(
  'vehicle/deleteStreamingRecording',
  async (
    {
      uuid,
      names,
      onSuccess,
    }: { uuid: string; names: string; onSuccess: () => void },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      const response = await container.deleteStreamingRecording(uuid, names);
      if (response.status === SUCCESS_STATUS) {
        onSuccess();
        return names;
      }

      return null;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const listStreamingPhotos = createAsyncThunk(
  'vehicle/listStreamingPhotos',
  async (
    {
      uuid,
      from,
      to,
      limit,
      page,
    }: { uuid: string; from: string; to: string; limit: string; page: string },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      return await container.listStreamingPhotos(uuid, from, to, limit, page);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const deleteStreamingPhotos = createAsyncThunk(
  'vehicle/deleteStreamingPhotos',
  async (
    {
      uuid,
      list_ids,
      photo_type,
      onSuccess,
    }: {
      uuid: string;
      list_ids: number[];
      photo_type: 'photo' | 'slide';
      onSuccess: () => void;
    },
    { extra: container, dispatch }
  ) => {
    try {
      // @ts-ignore
      const response = await container.deleteStreamingPhotos(
        uuid,
        list_ids,
        photo_type
      );

      if (response.status === SUCCESS_STATUS) {
        onSuccess();
        return { list_ids, photo_type };
      }

      return null;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const listStreamingSlides = createAsyncThunk(
  'vehicle/listStreamingSlides',
  async (
    {
      uuid,
      from,
      to,
      limit,
      page,
    }: { uuid: string; from: string; to: string; limit: string; page: string },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      return await container.listStreamingSlides(uuid, from, to, limit, page);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getArchive = createAsyncThunk(
  'vehicle/getArchive',
  async (uuid: string, { extra: container }) => {
    try {
      // @ts-ignore
      return await container.getArchive(uuid);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getArchiveList = createAsyncThunk(
  'vehicle/getArchiveList',
  async (
    { uuid, limit, page }: { uuid: string; limit: number; page: number },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      return await container.getArchiveList(uuid, limit, page);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const createArchive = createAsyncThunk(
  'vehicle/createArchive',
  async (
    {
      archive_type,
      list_ids,
    }: {
      archive_type: 'photo' | 'slide';
      list_ids: number[];
    },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      return await container.createArchive(archive_type, list_ids);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

import { FC, ReactElement, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { UnknownAction } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from '../assets/ArrowLeft';
import { createPasswordRecovery } from '../../store/user/user.thunks';
import { useAppDispatch, useAppSelector } from '../../store';
import ROUTES from '../common/router/constants';
import Checked from '../assets/Checked';
import classes from '../styles/auth.module.css';
import PasswordRequestTimer from './PasswordRequestTime';
import { clearPasswordRecoveryError } from '../../store/user/user.slice';

const ResetPasswordRequestPage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const createPasswordRecoveryLoading = useAppSelector(
    (state) => state.user.createPasswordRecoveryLoading
  );

  const createPasswordRecoverySuccess = useAppSelector(
    (state) => state.user.createPasswordRecoverySuccess
  );

  const createPasswordRecoveryError = useAppSelector(
    (state) => state.user.createPasswordRecoveryError
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToLogin = (): void => navigate(ROUTES.AUTH);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    const formData: FormData = new FormData(event.currentTarget);
    const email: string = formData.get('email') as string;
    await dispatch(createPasswordRecovery(email) as unknown as UnknownAction);
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setEmail(event.target.value);
    if (createPasswordRecoveryError !== null) {
      dispatch(clearPasswordRecoveryError(null));
    }
  };

  if (createPasswordRecoverySuccess) {
    return (
      <Box className={classes.loginHeaders}>
        <Checked />
        <Typography variant='body1' sx={{ mt: 3 }}>
          {t('resetPasswordRequest.congratulationTitle')}
        </Typography>
        <Typography variant='caption'>
          {t('resetPasswordRequest.congratulationSubTitle')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.backToLogin} onClick={navigateToLogin}>
        <ArrowLeft />
      </Box>
      <Box className={classes.loginHeaders}>
        <Typography variant='body1'>
          {t('resetPasswordRequest.title')}
        </Typography>
        <Typography variant='caption'>
          {t('resetPasswordRequest.subTitle')}
        </Typography>
      </Box>
      <Box
        component='form'
        onSubmit={handleSubmit}
        className={classes.inputsContainer}
      >
        <TextField
          fullWidth
          placeholder={t('resetPasswordRequest.emailPlaceholder')}
          name='email'
          value={email}
          onChange={handleEmailChange}
          error={!!createPasswordRecoveryError}
          helperText={
            /\d+/.test(createPasswordRecoveryError)
              ? null
              : createPasswordRecoveryError
          }
        />
        {/\d+/.test(createPasswordRecoveryError) ? (
          <PasswordRequestTimer
            createPasswordRecoveryError={createPasswordRecoveryError}
          />
        ) : null}

        <Button
          disabled={
            createPasswordRecoveryLoading ||
            /\d+/.test(createPasswordRecoveryError)
          }
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: '2.5rem' }}
        >
          {t('resetPasswordRequest.resetPasswordRequestButtonText')}
        </Button>
      </Box>
    </>
  );
};

export default ResetPasswordRequestPage;

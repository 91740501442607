import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import fromDegreesToDMS from '../../helpers/fromDegreesToDMS';
import { useAppSelector } from '../../../../store';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import classes from '../styles/index.module.css';

interface IProps {
  containerClassName: string;
}
const MiniMapCoordinates: FC<IProps> = ({
  containerClassName,
}): ReactElement => {
  const latitude = useAppSelector(
    (state) => state.socket.vehicleMetrics?.position?.latitude
  );

  const longitude = useAppSelector(
    (state) => state.socket.vehicleMetrics?.position?.longitude
  );

  const [copiedText, copy] = useCopyToClipboard();

  const { t } = useTranslation();

  return (
    <Tooltip
      arrow
      title={copiedText ? t('vehicle.copiedLabel') : t('vehicle.copyLabel')}
    >
      <Box
        className={containerClassName}
        onClick={() =>
          copy(
            `${fromDegreesToDMS(latitude || 0, 'lat')} ${fromDegreesToDMS(longitude || 0, 'long')}`
          )
        }
      >
        <Typography
          variant='caption'
          noWrap
          className={classes.miniMapCoordinatesValue}
        >
          {fromDegreesToDMS(latitude || 0, 'lat')}
        </Typography>
        <Typography
          variant='caption'
          noWrap
          className={classes.miniMapCoordinatesValue}
        >
          {fromDegreesToDMS(longitude || 0, 'long')}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default MiniMapCoordinates;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserError } from '../../domain/user';
import { setTimeProcessing } from './app.slice';

export const getServerTime = createAsyncThunk(
  'app/getServerTime',
  async (_, { extra: container, dispatch }) => {
    try {
      const differences: number[] = [];
      let startTime: number | undefined;
      let endTime: number | undefined;
      let timeForRequest: number | undefined;
      let differenceDateTime: number | undefined;
      let difference: number | undefined;

      const checkServerTime = async () => {
        if (differences.length < 10) {
          startTime = new Date().getTime() / 1000;
          const serverTime = await (container as any).getServerTime();
          endTime = new Date().getTime() / 1000;
          timeForRequest = (endTime - startTime) / 2;
          differenceDateTime = endTime - timeForRequest;
          difference = differenceDateTime - serverTime;
          differences.push(Math.abs(difference));
          setTimeout(checkServerTime, 0);
        } else {
          const sumDifferences = differences.reduce((acc, num) => acc + num);
          dispatch(setTimeProcessing(sumDifferences / differences.length));
        }
      };

      await checkServerTime();
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getPing = createAsyncThunk(
  'app/getPing',
  async (_, { extra: container }) => {
    try {
      // @ts-ignore
      return await container.getPing();
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

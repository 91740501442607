import { ChangeEvent, FC, MouseEvent, ReactElement, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import {
  URLSearchParamsInit,
  useLocation,
  useSearchParams,
  useBlocker,
} from 'react-router-dom';
import { UnknownAction } from '@reduxjs/toolkit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { getOnsHistory } from '../../store/ons/ons.thunks';
import {
  clearHistoryData,
  setNonDeletedUuids,
  setSelectedUuids,
  setTitleInProcessing,
  setSelectedAllUuids,
  setDeselectedAllUuids,
} from '../../store/ons/ons.slice';
import { IOnsHistoryItem } from '../../domain/ons';
import EnhancedTableToolbar from '../components/OnsHistory/Toolbar';
import EnhancedTableHead from '../components/OnsHistory/Header';
import ActionsCell from '../components/OnsHistory/ActionsCell';
import StatusCell from '../components/OnsHistory/StatusCell';
import SizeCell from '../components/OnsHistory/SizeCell';
import { ONS_HISTORY_INTERVAL } from '../components/OnsHistory/helpers';
import CreatedAtCell from '../components/OnsHistory/CreatedAtCell';
import Pagination from '../components/OnsHistory/Pagination';
import classes from '../styles/ons.module.css';

let onsHistoryInterval: NodeJS.Timeout;
const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const OnsHistoryPage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const getHistoryLoading = useAppSelector(
    (state) => state.ons.getHistoryLoading
  );
  const titleInProcessing = useAppSelector(
    (state) => state.ons.titleInProcessing
  );
  const nonDeletedUuids = useAppSelector((state) => state.ons.nonDeletedUuids);
  const selectedUuids = useAppSelector((state) => state.ons.selectedUuids);
  const history = useAppSelector((state) => state.ons.history);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams: any = Object.fromEntries([...searchParams]);

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!location.search.length) {
      const updatedParams: URLSearchParamsInit = {
        ...currentParams,
        limit: 10,
        page: 1,
      };

      setSearchParams(updatedParams);
    }

    return () => {
      clearInterval(onsHistoryInterval);
      dispatch(clearHistoryData());
    };
  }, []);

  useEffect(() => {
    if (location.search) {
      onsHistoryInterval = setInterval(
        (function getOnsHistoryHandlerInterval() {
          getOnsHistoryHandler();
          return getOnsHistoryHandlerInterval;
        })(),
        ONS_HISTORY_INTERVAL
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (titleInProcessing) {
      setTimeout(() => {
        dispatch(setTitleInProcessing(''));
      }, 4000);
    }
  }, [titleInProcessing]);

  useEffect(() => {
    if (nonDeletedUuids.length) {
      setTimeout(() => {
        dispatch(setNonDeletedUuids([]));
      }, 4000);
    }
  }, [nonDeletedUuids]);

  const getOnsHistoryHandler = (): void => {
    dispatch(
      getOnsHistory(
        decodeURIComponent(location.search)
      ) as unknown as UnknownAction
    );
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(setSelectedAllUuids());
      return;
    }
    dispatch(setDeselectedAllUuids());
  };

  const handleClick = (event: MouseEvent<unknown>, uuid: string) => {
    dispatch(setSelectedUuids(uuid));
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname
  );

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.onsHistoryContainer}>
        <EnhancedTableToolbar
          numSelected={selectedUuids}
          refreshIcon={
            <Tooltip
              title={t('ons.refreshListTooltipText')}
              arrow
              placement='top'
            >
              <IconButton
                color='default'
                size='medium'
                onClick={getOnsHistoryHandler}
              >
                <CachedIcon className={classes.clearAllButton} />
              </IconButton>
            </Tooltip>
          }
        />
        {blocker.state === 'blocked' ? (
          <Dialog
            open
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {t('vehicle.leavePageTitle')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {t('ons.onsLeavePageMessage')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => blocker.reset()} color='primary'>
                {t('vehicle.stayOnPage')}
              </Button>
              <Button
                onClick={() => blocker.proceed()}
                color='primary'
                autoFocus
              >
                {t('vehicle.leavePage')}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
        {history && history.items.length ? (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
              <EnhancedTableHead
                numSelected={selectedUuids.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={history.items.length}
              />
              <TableBody
                className={getHistoryLoading ? classes.tableBodyOverlay : ''}
              >
                {history.items.map(
                  (row: IOnsHistoryItem, index: number): ReactElement => {
                    const isItemSelected =
                      selectedUuids.indexOf(row.uuid) !== -1;
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.uuid)}
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.uuid}
                        selected={isItemSelected}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: nonDeletedUuids.includes(row.uuid)
                            ? 'dimgrey'
                            : 'initial',
                        }}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            sx={{
                              color: 'rgba(255, 255, 255, 0.7)',
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          width={350}
                        >
                          <Tooltip arrow placement='top' title={row.title}>
                            <Typography
                              variant='body2'
                              color='#fff'
                              noWrap
                              className={classes.title}
                            >
                              {row.title}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell align='left' width={350}>
                          <CreatedAtCell created_at={row.created_at} />
                        </TableCell>
                        <TableCell align='left' width={350}>
                          <SizeCell archive_size={row.archive_size} />
                        </TableCell>
                        <TableCell align='left' width={350}>
                          <StatusCell
                            uuid={row.uuid}
                            status={row.status}
                            percent_processed={row.percent_processed}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <ActionsCell
                            status={row.status}
                            title={row.title}
                            uuid={row.uuid}
                            archive={row.archive}
                            index={index}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box className={classes.noRoutesContainer}>
            <Typography
              variant='body2'
              color='#fff'
              noWrap
              className={classes.noRoutes}
            >
              {t('ons.noRoutesText')}
            </Typography>
          </Box>
        )}
        {history && history.items.length ? (
          <Pagination count={history.count} />
        ) : null}
      </Box>
      {titleInProcessing ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open
          autoHideDuration={4000}
          key='topright'
        >
          <Alert variant='filled' severity='success'>
            <Typography
              variant='body1'
              className={classes.titleInNotification}
              noWrap
            >
              {t('ons.acceptForProcessingText', { titleInProcessing })}
            </Typography>
          </Alert>
        </Snackbar>
      ) : null}
    </ThemeProvider>
  );
};

export default OnsHistoryPage;

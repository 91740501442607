import { FC, memo, ReactElement, useEffect, useMemo, useRef } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import L, { LatLngBounds } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { fromRadiansToDegrees, listMapConfig } from '../common/config';
import { useAppSelector } from '../../../../store';
import { VEHICLE_TYPES } from '../../../../domain/vehicle';

const {
  bounds: { padding, duration },
  marker: { rotationOrigin },
} = listMapConfig;

interface IProps {
  vehicleID: string;
  vehicleType: VEHICLE_TYPES | string;
  latitude: number;
  longitude: number;
}

const RotatedSelectedMarker: FC<IProps> = memo(
  ({ vehicleID, vehicleType, latitude, longitude }): ReactElement => {
    const listMapDragged = useAppSelector(
      (state) => state.vehicles.listMapDragged
    );

    const markerRef: any = useRef();
    const map = useMap();
    const rotationAngle: number = useMemo(
      (): number => fromRadiansToDegrees(180),
      [180]
    );

    const { t } = useTranslation();

    useEffect(() => {
      map.flyToBounds(
        L.latLngBounds([L.latLng(latitude, longitude)]) as LatLngBounds,
        {
          padding,
          maxZoom: 18,
          duration,
        }
      );
    }, [vehicleID, latitude, longitude]);

    useEffect(() => {
      if (!listMapDragged) {
        if (markerRef.current && !markerRef.current.isPopupOpen()) {
          markerRef.current.openPopup();
        }
      } else if (markerRef.current && markerRef.current.isPopupOpen()) {
        markerRef.current.closePopup();
      }
    }, [vehicleID, listMapDragged]);

    const displayVehicleLabel = (vehicleType: VEHICLE_TYPES): string => {
      switch (vehicleType) {
        case VEHICLE_TYPES.TRACTOR:
          return t('vehiclesList.listDeviceTractorLabel');
        case VEHICLE_TYPES.CAR:
          return t('vehiclesList.listDeviceCarLabel');
        case VEHICLE_TYPES.BOAT:
          return t('vehiclesList.listDeviceBoatLabel');
        case VEHICLE_TYPES.DRONE:
          return t('vehiclesList.listDeviceDroneLabel');
        case VEHICLE_TYPES.PLANE:
          return t('vehiclesList.listDevicePlaneLabel');
        case VEHICLE_TYPES.TRACTOR_GEMINI:
          return t('vehiclesList.listDeviceTractorGeminiLabel');
        case VEHICLE_TYPES.TRACTOR_KHTI:
          return t('vehiclesList.listDeviceTractorHTILabel');
        default:
          return '';
      }
    };

    return (
      <Marker
        key={vehicleID}
        ref={markerRef}
        position={[latitude, longitude]}
        rotationOrigin={rotationOrigin}
        rotationAngle={rotationAngle}
      >
        <Popup>
          <div>
            {t('vehiclesList.latitude')}: {latitude}
          </div>
          <div>
            {t('vehiclesList.longitude')}: {longitude}
          </div>
          <div>
            {t('vehiclesList.vehicleTypeLabel')}:{' '}
            {displayVehicleLabel(vehicleType as VEHICLE_TYPES)}
          </div>
        </Popup>
      </Marker>
    );
  }
);

export default RotatedSelectedMarker;

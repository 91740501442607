export type User = {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  nickname: string;
  phone: null;
  is_signed: boolean;
  permissions: {
    add_customers: boolean;
    use_remote_control: boolean;
    use_visual_navigation: boolean;
  };
};

export type UserError = {
  code: number;
  title: string;
  detail: string;
};

export type ConfirmPasswordUserData = {
  uidb64: string | null;
  token: string | null;
  newPassword: string;
  confirmedPassword: string;
};

export type UserRepository = {
  createLoginRequest: (
    email: string,
    password: string
  ) => Promise<User | unknown>;
  createPasswordRecoveryRequest: (email: string) => Promise<User | unknown>;
  createPasswordConfirmRequest: (
    userData: ConfirmPasswordUserData
  ) => Promise<User | unknown>;
  getLicenseRequest: () => Promise<string | unknown>;
  acceptLicenseRequest: () => Promise<boolean | unknown>;
  getAcceptLicenseStatusRequest: () => Promise<boolean | unknown>;
  getUserProfileRequest: () => Promise<User | unknown>;
};

export const validateUserEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }

  if (email.length > 100) {
    return false;
  }

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validateUserEmailForSpaces = (email: string): boolean => {
  if (email.includes(' ')) {
    return false;
  }

  return true;
};

export const validateUserPassword = (password: string): boolean => {
  return !(password.length < 8 || password.length > 40);
};

export const validateUserPasswordForSpaces = (password: string) => {
  if (password.includes(' ')) {
    return false;
  }
  return true;
};

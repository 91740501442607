export type VideoStreamingCommand = {
  type_task: 'command';
  task_uuid: null;
  step: null;
  is_check: boolean;
  command: {
    command_to_drone:
      | 'make_frame'
      | 'start_video_streaming'
      | 'stop_video_streaming'
      | 'start_slide_streaming'
      | 'change_camera_resolution'
      | 'stop_slide_streaming';
    is_online: boolean;
    move_to: null;
    take_off: null;
    ned_velocity: null;
    mission: null;
    camera:
      | { stream: number; save: number[] }
      | { frame: number[] }
      | { resolution: number[] };
  };
  telemetry: null;
  is_online: boolean;
  is_force: boolean;
  is_controller: boolean;
  time_processing: {
    frontend_sent: number;
  };
};

export const startVideoStreamingCommand = (
  numberOfCamera: number
): VideoStreamingCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'start_video_streaming',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
    camera: { stream: numberOfCamera, save: [0, 1, 2] },
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const stopVideoStreamingCommand = (
  numberOfCamera: number
): VideoStreamingCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'stop_video_streaming',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
    camera: { stream: numberOfCamera, save: [0, 1, 2] },
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const takePhotoCommand = (
  numberOfCamera: number
): VideoStreamingCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'make_frame',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
    camera: { frame: [numberOfCamera] },
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});
export const changeCameraResolutionCommand = ({
  width,
  height,
  camera,
}: {
  width: number;
  height: number;
  camera: number;
}) => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'change_camera_resolution',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
    camera: { resolution: [{ width, height, camera }] },
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

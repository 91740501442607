import { FC, memo, ReactElement, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';
import { Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { polyLineStyle, previewMapConfig } from '../../OnsCreate/mapConfig';
import GoogleMapMarker from '../../OnsCreate/GoogleMapMarker';
import { useAppSelector } from '../../../../store';
import downloadImage from '../../../common/helpers/downloadImage';
import useBytesToSize from '../../../common/hooks/useBytesToSize';
import classes from '../../../styles/ons.module.css';

interface IProps {
  index: number;
}

let flightPath: google.maps.Polyline;

const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxWidth: '37.5rem',
    width: '37.5rem',
    height: '34.5rem',
    borderRadius: '0.25rem',
  },
}));

const {
  gestureHandling,
  style,
  mapTypeControlOptions,
  mapTypeId,
  streetViewControl,
  fullscreenControl,
  defaultCenter,
  defaultZoom,
} = previewMapConfig;

const Preview: FC<IProps> = memo(({ index }): ReactElement => {
  const { list_waypoints, title, archive_size, archive } = useAppSelector(
    (state) => state.ons.history?.items[index]
  );

  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);
  const { t } = useTranslation();
  const map = useMap();
  const maps = useMapsLibrary('maps');
  const [getSize] = useBytesToSize();

  useEffect(() => {
    if (!maps || !list_waypoints.length) {
      return;
    }

    const wayPoints: { lat: number; lng: number }[] = list_waypoints.map(
      (point: {
        latitude: number;
        longitude: number;
      }): { lat: number; lng: number } => ({
        lat: point.latitude,
        lng: point.longitude,
      })
    );

    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (bounds) {
        wayPoints.forEach(({ lat, lng }: { lat: number; lng: number }) =>
          bounds.extend({ lat, lng })
        );
        map.fitBounds(bounds);
      }
      flightPath = new maps.Polyline(polyLineStyle(wayPoints));
      flightPath.setPath(wayPoints);
      flightPath.setMap(map);
    }
  }, [list_waypoints.length, map]);

  const handleClose = (event: any) => {
    event.stopPropagation();
    setVisiblePreview(false);
  };

  const downloadHandler = async () => {
    if (archive) {
      await downloadImage(archive);
    }
  };

  return (
    <>
      <Tooltip title={t('ons.previewTitle')} arrow placement='top'>
        <IconButton
          color='default'
          size='medium'
          onClick={(event) => {
            event.stopPropagation();
            setVisiblePreview(true);
          }}
        >
          <PreviewIcon className={classes.tableIcon} />
        </IconButton>
      </Tooltip>
      <DialogWrapper
        onClick={(event) => event.stopPropagation()}
        open={visiblePreview}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Tooltip arrow placement='top' title={title}>
          <DialogTitle
            style={{ padding: '1rem 1.5rem' }}
            id='alert-dialog-title'
            className={classes.titleInNotification}
            noWrap
          >
            {title}
          </DialogTitle>
        </Tooltip>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ padding: '0 0.5rem 0.5rem' }}>
          <Map
            className={classes.previewMap}
            style={style}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
            mapId={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
            gestureHandling={gestureHandling}
            mapTypeControlOptions={mapTypeControlOptions}
            mapTypeId={mapTypeId}
            zoomControl
            fullscreenControl={fullscreenControl}
            streetViewControl={streetViewControl}
          >
            {list_waypoints.map(
              (
                marker: {
                  latitude: number;
                  longitude: number;
                },
                index: number
              ): ReactElement => (
                <GoogleMapMarker
                  key={index}
                  index={index}
                  lng={marker.longitude}
                  lat={marker.latitude}
                  hover={false}
                  draggable={false}
                />
              )
            )}
          </Map>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.clearAllButton}>
            {t('ons.cancel')}
          </Button>
          <Button
            onClick={downloadHandler}
            autoFocus
            variant='contained'
            color='primary'
            disabled={!archive}
          >
            {t('ons.downloadLabel')}{' '}
            {getSize(archive_size) ? `(${getSize(archive_size)})` : null}
          </Button>
        </DialogActions>
      </DialogWrapper>
    </>
  );
});

export default Preview;

import { FC, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton/IconButton';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../store';

const GamepadArrowButtons: FC = (): ReactElement => {
  const [arrowUpStyle, setArrowUpStyle] = useState<string>('#000');
  const [arrowDownStyle, setArrowDownStyle] = useState<string>('#000');
  const [arrowLeftStyle, setArrowLeftStyle] = useState<string>('#000');
  const [arrowRightStyle, setArrowRightStyle] = useState<string>('#000');
  const [shareButtonStyle, setShareButtonStyle] = useState<string>('#000');
  const [snackbarOpen, setSneckbarOpen] = useState(false);
  const gamepadArrowUp = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[12].pressed
  );
  const gamepadArrowDown = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[13].pressed
  );

  const gamepadSquare = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[2].pressed
  );

  const gamepadShare = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[8].pressed
  );
  const gamepadOptions = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[9].pressed
  );
  const l2 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[6].pressed
  );
  const r2 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[7].pressed
  );
  // const manualControlRunning = useAppSelector(
  //   (state) => state.vehicles.manualControlRunning
  // );
  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );

  const { t } = useTranslation();

  useEffect(() => {
    gamepadArrowUpStyle();
    gamepadShareStyle();
    handleSnackbarOpen();
  }, [gamepadArrowUp, l2, r2, gamepadArrowDown, gamepadShare, gamepadSquare]);

  useEffect(() => {
    window.addEventListener('blur', resetStyle);
    window.addEventListener('offline', resetStyle);
  }, []);

  useEffect(() => {
    if (!manualControlMetric) {
      resetStyle();
    }
  }, [manualControlMetric]);

  const gamepadArrowUpStyle = () => {
    if (gamepadArrowUp && manualControlMetric && arrowDownStyle === '#000') {
      setArrowUpStyle('#FF0000');
      setArrowDownStyle('#000');
    }
    if (gamepadArrowDown && manualControlMetric && arrowUpStyle === '#000') {
      setArrowDownStyle('#FF0000');
      setArrowUpStyle('#000');
    }
    if (l2 || r2 || gamepadSquare) {
      resetStyle();
      setSneckbarOpen(false);
    }
  };

  const gamepadShareStyle = () => {
    if (gamepadShare && shareButtonStyle === '#000' && manualControlMetric) {
      setShareButtonStyle('#FF0000');
    }
    if (gamepadShare && shareButtonStyle === '#FF0000' && manualControlMetric) {
      setShareButtonStyle('#000');
    }
  };

  const resetStyle = () => {
    setArrowUpStyle('#000');
    setArrowDownStyle('#000');
    setShareButtonStyle('#000');
  };

  const handleSnackbarOpen = () => {
    if (arrowUpStyle === '#FF0000' && gamepadArrowDown) {
      setSneckbarOpen(true);
    }
    if (arrowDownStyle === '#FF0000' && gamepadArrowUp) {
      setSneckbarOpen(true);
    }
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSneckbarOpen(false);
  };

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    <Snackbar
      sx={{
        top: '68px',
        bottom: 'auto !important',
        left: 'auto !important',
        right: '50% !important',
      }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      action={action}
    >
      <Alert variant='filled' severity='warning' onClose={handleClose}>
        {t('vehicle.gpUnableNotificationText')}
      </Alert>
    </Snackbar>
  );
};

export default GamepadArrowButtons;

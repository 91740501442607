import { AxiosInstance, AxiosResponse } from 'axios';
import {
  ACCEPT_LICENSE,
  CREATE_PASSWORD_CONFIRM,
  CREATE_PASSWORD_RECOVERY,
  GET_LICENSE,
  LOCAL_STORAGE_AUTH_KEY,
  ACCESS_TOKEN_HEADER,
  REFRESH_TOKEN_HEADER,
  USER_PROFILE,
  LOGIN,
} from '../common/httpService/constants';
import { ConfirmPasswordUserData, UserRepository } from '../../domain/user';
import { transformBrowserLanguage } from '../../view/common/helpers/utils';
import { setDataToLocalStorage } from '../../view/common/router/helpers';

type Dependencies = {
  http: AxiosInstance;
};

export default ({ http }: Dependencies): UserRepository => ({
  async createLoginRequest(email: string, password: string) {
    try {
      const response: AxiosResponse = await http.post(LOGIN, {
        email,
        password,
      });

      setDataToLocalStorage(LOCAL_STORAGE_AUTH_KEY, {
        accessToken: response.headers[ACCESS_TOKEN_HEADER],
        refreshToken: response.headers[REFRESH_TOKEN_HEADER],
      });
      return response.data.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async createPasswordRecoveryRequest(email: string) {
    try {
      const response: AxiosResponse = await http.post(
        CREATE_PASSWORD_RECOVERY,
        {
          email,
        }
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async createPasswordConfirmRequest(userData: ConfirmPasswordUserData) {
    try {
      const response: AxiosResponse = await http.post(
        `${CREATE_PASSWORD_CONFIRM}${userData.uidb64}/${userData.token}/`,
        {
          new_password: userData.newPassword,
        }
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getLicenseRequest() {
    try {
      const response: AxiosResponse = await http.get(
        `${GET_LICENSE}${transformBrowserLanguage()}/license`
      );
      return response.data.data.file_url;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async acceptLicenseRequest() {
    try {
      const response: AxiosResponse = await http.post(ACCEPT_LICENSE);

      setDataToLocalStorage(LOCAL_STORAGE_AUTH_KEY, {
        accessToken: response.headers[ACCESS_TOKEN_HEADER],
        refreshToken: response.headers[REFRESH_TOKEN_HEADER],
      });
      return true;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getAcceptLicenseStatusRequest() {
    try {
      const response: AxiosResponse = await http.get(ACCEPT_LICENSE);
      return response.data.data.is_signed;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getUserProfileRequest() {
    try {
      const response: AxiosResponse = await http.get(USER_PROFILE);
      return response.data.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
});

import { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Ukraine from '../../assets/UkraineIcon';
import USA from '../../assets/USAIcon';
import classes from '../LoginLayout/index.module.css';

const SelectLanguage: FC = memo((): ReactElement => {
  const { i18n, t } = useTranslation();

  const handleChange = (event: SelectChangeEvent): void => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Box className={classes.language} sx={{ minWidth: 120 }}>
      <FormControl variant='filled' fullWidth>
        <InputLabel id='demo-simple-select-label'>
          {t('login.language')}
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={i18n.language}
          label='lang'
          onChange={handleChange}
        >
          <MenuItem value='en'>
            <Typography className={classes.menuItemTypography}>
              <USA />
              English
            </Typography>
          </MenuItem>
          <MenuItem value='uk'>
            <Typography className={classes.menuItemTypography}>
              <Ukraine />
              Українська
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
});
export default SelectLanguage;

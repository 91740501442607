import { ChangeEvent, FC, memo, ReactElement, useMemo } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface IProps {
  numSelected: number;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
}

const EnhancedTableHead: FC<IProps> = memo(
  ({ onSelectAllClick, numSelected, rowCount }): ReactElement => {
    const { t, i18n } = useTranslation();

    const headCells: readonly HeadCell[] = useMemo(
      () => [
        {
          id: 'name',
          disablePadding: true,
          label: t('ons.historyPageNameColumnLabel'),
        },
        {
          id: 'date',
          disablePadding: false,
          label: t('ons.historyPageDateColumnLabel'),
        },
        {
          id: 'size',
          disablePadding: false,
          label: t('ons.historyPageSizeColumnLabel'),
        },
        {
          id: 'status',
          disablePadding: false,
          label: t('ons.historyPageStatusColumnLabel'),
        },
        {
          id: 'actions',
          disablePadding: false,
          label: '',
        },
      ],
      [i18n.language]
    );

    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              <Typography variant='body2' color='#fff'>
                {headCell.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
);

export default EnhancedTableHead;

import {
  FC,
  ReactElement,
  useEffect,
  useState,
  FormEvent,
  MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { UnknownAction } from '@reduxjs/toolkit';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { acceptLicense, getLicense } from '../../store/user/user.thunks';
import classes from '../styles/license.module.css';

const License: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getLicenseFileURL = useAppSelector(
    (state) => state.user.getLicenseFileURL
  );

  const [isAgreementDisabled, setIsAgreementDisabled] = useState<boolean>(true);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getLicense() as unknown as UnknownAction);
  }, [i18n.language]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const onSuccess = () => {
      navigate('/vehicles/list');
    };

    if (!isAgreementDisabled) {
      dispatch(acceptLicense(onSuccess) as unknown as UnknownAction);
    }
  };

  const handleExit: MouseEventHandler<HTMLButtonElement> = (event): void => {
    event.preventDefault();
    navigate('/auth');
  };
  const pdfUrl = `${getLicenseFileURL || ''}#toolbar=0&navpanes=0&page=1&view=fitH`;

  return (
    <Box className={classes.iframeContainer}>
      <Typography className={classes.licenseText} variant='h5'>
        {t('license.title')}
      </Typography>
      <iframe className={classes.iframe} src={pdfUrl} title='license' />
      <form className={classes.licenseForm} onSubmit={handleSubmit}>
        <label style={{ color: 'black' }} htmlFor='checkbox'>
          <Typography className={classes.agreementText} variant='body1'>
            <input
              id='checkbox'
              type='checkbox'
              onChange={() => setIsAgreementDisabled(!isAgreementDisabled)}
            />
            {t('license.checkboxAgreeText')}
          </Typography>
        </label>
        <Box className={classes.buttonsContainer}>
          <Button
            onClick={handleExit}
            sx={{
              '&.MuiButton-root': {
                padding: '6px 8px 6px 0',
              },
            }}
          >
            <KeyboardArrowLeftIcon fontSize='small' /> {t('license.back')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            type='submit'
            disabled={isAgreementDisabled}
            className={classes.buttonContinue}
          >
            {t('license.licenseButtonText')}{' '}
            <KeyboardArrowRightIcon fontSize='small' />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default License;

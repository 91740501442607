// LOCALSTORAGE CONSTANTS
export const ACCESS_TOKEN_HEADER = 'access-token';
export const REFRESH_TOKEN_HEADER = 'refresh-token';
export const LOCAL_STORAGE_AUTH_KEY = 'auth';
export const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';

// OTHER CONSTANTS
export const PING_INTERVAL = 3000;
export const SUCCESS_STATUS = 'success';
export const WARNING_STATUS = 'warning';
// ENDPOINTS CONSTANTS
export const LOGIN = '/auth/login';
export const REFRESH_TOKEN = '/auth/refresh';
export const CREATE_PASSWORD_RECOVERY = '/auth/password/recovery/request';
export const CREATE_PASSWORD_CONFIRM = '/auth/password/recovery/complete/';
export const VEHICLES_LIST = '/employee/vehicles_list';
export const VEHICLES_LIST_METRICS = '/vehicle/vehicles_with_metrics';
export const CONNECT_TO_VEHICLE = '/employee/connect?vehicle_uuid=';
export const VEHICLE_IS_CONNECT = '/employee/is_can_connect?vehicle_uuid=';
export const VEHICLE_STATUS = 'employee/vehicle_status?vehicle_uuid=';
export const DISCONNECT_FROM_VEHICLE = '/employee/disconnect?vehicle_uuid=';
export const SERVER_TIME = 'utils/server_time';
export const GET_LICENSE = 'utils/about/';
export const ACCEPT_LICENSE = 'employee_profile/signature';
export const USER_PROFILE = 'employee_profile/me';
export const PING_SERVER = '/employee_utils/ping';
export const GET_MAP_WAYPOINT = 'gps_no_gps/get_map_waypoint';
export const ONS_HISTORY = 'gps_no_gps/history';
export const PERCENTAGE = 'gps_no_gps/percentage';
export const GENERATE_ARCHIVE = 'gps_no_gps/generate';
export const REQUEST = 'gps_no_gps/request';

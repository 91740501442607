import { KeyboardEnum } from '../../../infra/common/socketService/commands/tractorControlCommands';
import { VEHICLE_TYPES } from '../../../domain/vehicle';

export const keyboardButtons: {
  buttonText: string;
  keyActive: string;
}[] = [
  {
    buttonText: 'Forward Left',
    keyActive: 'KeyW',
  },
  {
    buttonText: 'Forward Right',
    keyActive: 'KeyE',
  },
  {
    buttonText: 'Back Left',
    keyActive: 'KeyS',
  },
  {
    buttonText: 'Back right',
    keyActive: 'KeyD',
  },
  {
    buttonText: 'Trawl Left',
    keyActive: 'KeyK',
  },
  {
    buttonText: 'Trawl Right',
    keyActive: 'KeyL',
  },
  {
    buttonText: 'Acceleration',
    keyActive: 'Space',
  },
];

export const keyboardControlsState = {
  KeyE: false,
  KeyD: false,
  KeyW: false,
  KeyS: false,
  KeyK: false,
  KeyL: false,
  Space: false,
  lastLeftCaterpillar: 0,
  lastRightCaterpillar: 0,
  lastTrawl: 0,
};

export const resetTractorControls = () => {
  keyboardControlsState.KeyE = false;
  keyboardControlsState.KeyD = false;
  keyboardControlsState.KeyW = false;
  keyboardControlsState.KeyS = false;
  keyboardControlsState.KeyK = false;
  keyboardControlsState.KeyL = false;
  keyboardControlsState.Space = false;
  keyboardControlsState.lastLeftCaterpillar = 0;
  keyboardControlsState.lastRightCaterpillar = 0;
  keyboardControlsState.lastTrawl = 0;
};

export const setVehicleDirection = (
  forward: boolean,
  back: boolean,
  last: number
): number => {
  if (forward && back) {
    return last;
  }

  if (forward) {
    return 1;
  }

  if (back) {
    return -1;
  }

  return 0;
};

export const updateKeyboardControlsState = (
  code: KeyboardEnum,
  status: 'down' | 'up'
) => {
  if (status === 'down') {
    if (code === KeyboardEnum.LEFT_CATERPILLAR_FORWARD) {
      if (keyboardControlsState[KeyboardEnum.LEFT_CATERPILLAR_BACK]) {
        keyboardControlsState.lastLeftCaterpillar = 1;
      }
    }

    if (code === KeyboardEnum.LEFT_CATERPILLAR_BACK) {
      if (keyboardControlsState[KeyboardEnum.LEFT_CATERPILLAR_FORWARD]) {
        keyboardControlsState.lastLeftCaterpillar = -1;
      }
    }

    if (code === KeyboardEnum.RIGHT_CATERPILLAR_FORWARD) {
      if (keyboardControlsState[KeyboardEnum.RIGHT_CATERPILLAR_BACK]) {
        keyboardControlsState.lastRightCaterpillar = 1;
      }
    }

    if (code === KeyboardEnum.RIGHT_CATERPILLAR_BACK) {
      if (keyboardControlsState[KeyboardEnum.RIGHT_CATERPILLAR_FORWARD]) {
        keyboardControlsState.lastRightCaterpillar = -1;
      }
    }

    if (code === KeyboardEnum.TRAWL_RIGHT) {
      if (keyboardControlsState[KeyboardEnum.TRAWL_LEFT]) {
        keyboardControlsState.lastTrawl = 1;
      }
    }

    if (code === KeyboardEnum.TRAWL_LEFT) {
      if (keyboardControlsState[KeyboardEnum.TRAWL_RIGHT]) {
        keyboardControlsState.lastTrawl = -1;
      }
    }

    keyboardControlsState[code] = true;
  }

  if (status === 'up') {
    keyboardControlsState[code] = false;
  }
};

export const setDataCommandByKeyboard = (vehicleType: VEHICLE_TYPES) => {
  const {
    KeyW,
    KeyS,
    lastLeftCaterpillar,
    KeyE,
    KeyD,
    lastRightCaterpillar,
    KeyL,
    KeyK,
    lastTrawl,
    Space,
  } = keyboardControlsState;
  if (vehicleType === VEHICLE_TYPES.TRACTOR) {
    return {
      left_caterpillar: setVehicleDirection(KeyW, KeyS, lastLeftCaterpillar),
      right_caterpillar: setVehicleDirection(KeyE, KeyD, lastRightCaterpillar),
      trawl: setVehicleDirection(KeyL, KeyK, lastTrawl),
      acceleration: Space ? 1 : 0,
    };
  }

  return null;
};

import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const TakeOffIcon: FC = memo(
  (): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          d='M20.5 18.9998H3.49998C2.94998 18.9998 2.49998 19.4498 2.49998 19.9998C2.49998 20.5498 2.94998 20.9998 3.49998 20.9998H20.5C21.05 20.9998 21.5 20.5498 21.5 19.9998C21.5 19.4498 21.05 18.9998 20.5 18.9998ZM22.07 9.63979C21.85 8.83979 21.03 8.36979 20.23 8.57979L14.92 9.99979L8.45998 3.97979C8.18998 3.71979 7.79998 3.62979 7.43998 3.72979C6.75998 3.91979 6.43998 4.69979 6.78998 5.30979L10.23 11.2698L5.25998 12.5998L3.68998 11.3598C3.43998 11.1698 3.11998 11.0998 2.80998 11.1798L2.47998 11.2698C2.15998 11.3498 2.00998 11.7198 2.17998 11.9998L4.05998 15.2498C4.28998 15.6398 4.74998 15.8298 5.17998 15.7198L21 11.4798C21.8 11.2598 22.28 10.4398 22.07 9.63979Z'
          fill='white'
        />
      </svg>
    </Box>
  )
);

export default TakeOffIcon;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  socketConnect,
  socketDisconnect,
} from '../../infra/common/socketService';

export const connectToSocket = createAsyncThunk(
  'socket/connectToSocket',
  async (
    { vehicleUuid, viewType }: { vehicleUuid: string; viewType: string },
    { dispatch }
  ) => {
    await socketConnect(vehicleUuid, viewType, dispatch);
  }
);

export const disconnectFromSocket = createAsyncThunk(
  'socket/disconnectFromSocket',
  async () => {
    await socketDisconnect();
  }
);

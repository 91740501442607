import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UnknownAction } from '@reduxjs/toolkit';
import ROUTES from './constants';
import { removeDataFromLocalStorage } from './helpers';
import { LOCAL_STORAGE_AUTH_KEY } from '../../../infra/common/httpService/constants';
import { IAuthData } from './types';
import { useAppDispatch } from '../../../store';
import { loginUser } from '../../../store/user/user.thunks';
import { clearUserData } from '../../../store/user/user.slice';

const AuthContext = createContext({});

export const AuthProvider: FC<{
  children: ReactNode;
  authData: IAuthData | null;
}> = ({ children, authData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTES.EMPTY) {
      navigate(
        authData
          ? `${ROUTES.VEHICLES.MAIN}/${ROUTES.VEHICLES.LIST}`
          : ROUTES.AUTH
      );
    }
  }, [authData]);

  const login = async (email: string, password: string) => {
    dispatch(loginUser({ email, password }) as unknown as UnknownAction);
  };

  const logout = () => {
    dispatch(clearUserData());
    removeDataFromLocalStorage(LOCAL_STORAGE_AUTH_KEY);
    navigate(ROUTES.AUTH, { replace: true });
  };

  const value = useMemo(
    () => ({
      authData,
      login,
      logout,
    }),
    []
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

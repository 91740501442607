import { FC, memo, ReactElement, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../store';
import useBytesToSize from '../../../common/hooks/useBytesToSize';
import classes from '../../../styles/ons.module.css';
import { IOnsHistoryItem } from '../../../../domain/ons';
import downloadImage from '../../../common/helpers/downloadImage';

interface IProps {
  numSelected: string[];
}

const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxWidth: '24rem',
  },
}));

const Download: FC<IProps> = memo(({ numSelected }): ReactElement => {
  const totalSize = useAppSelector((state) => state.ons.totalSize);
  const items = useAppSelector((state) => state.ons.history?.items);

  const [visibleDownload, setVisibleDownload] = useState<boolean>(false);

  const { t } = useTranslation();
  const [getSize] = useBytesToSize();

  const handleCloseDownload = (event: any) => {
    event.stopPropagation();
    setVisibleDownload(false);
  };

  const downloadAllFilesHandler = async (): Promise<void> => {
    const filteredItems: IOnsHistoryItem[] = items.filter(
      (item: IOnsHistoryItem): boolean =>
        numSelected.includes(item.uuid) && !!item.archive
    );

    filteredItems.forEach(({ archive }) => downloadImage(archive as string));
  };

  return (
    <>
      <Button
        variant='text'
        size='medium'
        disabled={!totalSize}
        className={classes.toolbarRightButtons}
        onClick={() => setVisibleDownload(true)}
      >
        {t('ons.downloadLabel')}
      </Button>
      <DialogWrapper
        open={visibleDownload}
        onClose={handleCloseDownload}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {t('ons.bulkDownloadLabel')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('ons.downloadSubTitle', { totalSize: getSize(totalSize) })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDownload}
            className={classes.clearAllButton}
          >
            {t('ons.cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={downloadAllFilesHandler}
            autoFocus
            className={classes.downloadBtn}
          >
            {t('ons.downloadLabel')} ({numSelected.length})
          </Button>
        </DialogActions>
      </DialogWrapper>
    </>
  );
});

export default Download;

import { FC, memo, ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { useTranslation } from 'react-i18next';
import { ONS_ARCHIVE_STATUS } from '../../../../domain/ons';
import { setVisibility } from '../helpers';
import downloadImage from '../../../common/helpers/downloadImage';
import classes from '../../../styles/ons.module.css';

interface IProps {
  status: ONS_ARCHIVE_STATUS;
  archive: string | null;
}

const Download: FC<IProps> = memo(({ status, archive }): ReactElement => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        archive ? t('ons.downloadArchiveTitle') : t('ons.archiveUnavailable')
      }
      arrow
      placement='top'
    >
      <IconButton
        color='default'
        size='medium'
        style={{
          visibility: setVisibility(status === ONS_ARCHIVE_STATUS.COMPLETED),
        }}
        onClick={async (event) => {
          event.stopPropagation();

          if (archive) {
            await downloadImage(archive);
          }
        }}
      >
        <DownloadRoundedIcon className={classes.tableIcon} />
      </IconButton>
    </Tooltip>
  );
});

export default Download;

import { AxiosInstance, AxiosResponse } from 'axios';
import { PING_SERVER, SERVER_TIME } from '../common/httpService/constants';
import { AppRepository } from '../../domain/app';

type Dependencies = {
  http: AxiosInstance;
};

export default ({ http }: Dependencies): AppRepository => ({
  async getServerTimeRequest() {
    try {
      const response: AxiosResponse = await http.get(SERVER_TIME);
      return response.data.data.server_time;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getPingRequest() {
    try {
      const abortController = new AbortController();
      const timeout = setTimeout(() => {
        abortController.abort();
      }, 2000);
      const response: AxiosResponse = await http.get(PING_SERVER, {
        signal: abortController.signal,
      });
      clearTimeout(timeout);
      return response.data;
    } catch (error) {
      throw (error as any).response.data.data;
    }
  },
});

import { FC, ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { VEHICLE_TYPES } from '../../../../domain/vehicle';
import SlidesIcon from '../../../assets/SlidesIcon';
import StopCrop from '../../../assets/StopCrop';

interface IProps {
  videoRestarted: boolean;
  vehicleType: VEHICLE_TYPES;
  startStopSlideStreaming: (image: string) => void;
  image: string;
  reduceButton: boolean;
  slidesLoading: boolean;
}

const SlideStreamingControls: FC<IProps> = memo(
  ({
    videoRestarted,
    vehicleType,
    startStopSlideStreaming,
    image,
    reduceButton,
    slidesLoading,
  }): ReactElement | null => {
    const { t } = useTranslation();

    if (videoRestarted) {
      return null;
    }

    if (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI) {
      return null;
    }

    const setTitle = (image: string, slidesLoading: boolean): string => {
      if (slidesLoading) {
        return t('vehicle.slidesLoadingLabel');
      }

      return image ? t('vehicle.stopCropLabel') : t('vehicle.startCropLabel');
    };

    return (
      <Tooltip
        key='crop'
        placement='top'
        title={setTitle(image, slidesLoading)}
        arrow
      >
        <span
          style={{
            cursor: slidesLoading ? 'not-allowed' : 'pointer',
          }}
        >
          <IconButton
            disabled={slidesLoading}
            color='default'
            size={reduceButton ? 'small' : 'large'}
            onClick={() => startStopSlideStreaming(image)}
          >
            {image ? <StopCrop /> : <SlidesIcon />}
          </IconButton>
        </span>
      </Tooltip>
    );
  }
);

export default SlideStreamingControls;

import { OnsRepository } from '../../domain/ons';

type Dependencies = {
  onsRepository: OnsRepository;
};

export default ({ onsRepository }: Dependencies) =>
  async (request_uuid: string) => {
    try {
      return await onsRepository.getProcessingStatusRequest(request_uuid);
    } catch (error) {
      throw error;
    }
  };

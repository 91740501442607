import { FC, memo, ReactElement } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { VEHICLE_TYPES } from '../../../../domain/vehicle';
import SelectedListItemInfo from './SelectedListItemInfo';
import classes from '../../../styles/vehicles.module.css';

interface IProps {
  uuid: string;
  vehicleType: VEHICLE_TYPES;
  name: string;
  isOnline: boolean;
  latitude: number;
  longitude: number;
}

const SelectedVehicleListItem: FC<IProps> = memo((props): ReactElement => {
  const { uuid, vehicleType, name, isOnline, latitude, longitude } = props;

  return (
    <ListItem
      sx={{ boxShadow: 'none !important' }}
      key={uuid}
      className={classes.vehiclesListItem}
    >
      <ListItemText
        sx={{
          '&.MuiListItemText-root': { margin: 0 },
        }}
        className={classes.vehiclesListTextColor}
        primary={<Typography variant='h6'>{name}</Typography>}
      />
      <SelectedListItemInfo
        isOnline={isOnline}
        uuid={uuid}
        vehicleType={vehicleType}
        longitude={longitude}
        latitude={latitude}
      />
    </ListItem>
  );
});
export default SelectedVehicleListItem;

import { IWayPointRequest, OnsRepository } from '../../domain/ons';

type Dependencies = {
  onsRepository: OnsRepository;
};

export default ({ onsRepository }: Dependencies) =>
  async (wayPoints: { title: string; list_waypoints: IWayPointRequest[] }) => {
    try {
      return await onsRepository.getMapWaypointRequest(wayPoints);
    } catch (error) {
      throw error;
    }
  };

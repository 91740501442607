import { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import classes from '../../styles/vehicles.module.css';
import ListItemInfo from './ListItemInfo';
import { VEHICLE_TYPES } from '../../../domain/vehicle';
import { useAppSelector } from '../../../store';

interface IProps {
  uuid: string;
  vehicleType: VEHICLE_TYPES;
  name: string;
  isOnline: boolean;
  isRealOnline: boolean;
  q: string;
  changeOnlineStatusAt: string;
  handleVehicleItemClick?: (vehicle: any) => void;
}

const VehicleListItem: FC<IProps> = memo((props): ReactElement => {
  const {
    uuid,
    vehicleType,
    name,
    isOnline,
    q,
    changeOnlineStatusAt,
    handleVehicleItemClick,
    isRealOnline,
  } = props;
  const { t } = useTranslation();

  const selectedUuidDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.uuid
  );

  const getHighlightedText = (
    text: string,
    highlightText: string
  ): ReactElement | string => {
    if (highlightText) {
      const parts: string[] = text.split(
        new RegExp(`(${highlightText})`, 'gi')
      );
      return (
        <span>
          {parts.map(
            (part: string, index: number): ReactElement => (
              <span
                key={index}
                style={
                  part.toLowerCase() === highlightText.toLowerCase()
                    ? { fontWeight: 'bold', background: '#0c8ce999' }
                    : {}
                }
              >
                {part}
              </span>
            )
          )}{' '}
        </span>
      );
    }

    return text;
  };

  return (
    <Tooltip
      title={
        !isRealOnline
          ? `${t('vehiclesList.offlineFrom')} ${changeOnlineStatusAt}`
          : ''
      }
      placement='right'
      arrow
    >
      <ListItem
        key={uuid}
        className={
          selectedUuidDevice === uuid
            ? classes.vehiclesListItemActive
            : classes.vehiclesListItem
        }
        onClick={() =>
          handleVehicleItemClick && handleVehicleItemClick(ListItem)
        }
      >
        <ListItemText
          sx={{
            '&.MuiListItemText-root': { margin: 0 },
          }}
          className={classes.vehiclesListTextColor}
          primary={
            <Typography variant='h6'>{getHighlightedText(name, q)}</Typography>
          }
        />
        <ListItemInfo
          isOnline={isOnline}
          isRealOnline={isRealOnline}
          uuid={uuid}
          vehicleType={vehicleType}
        />
      </ListItem>
    </Tooltip>
  );
});
export default VehicleListItem;

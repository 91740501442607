import { FC, ReactElement, memo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import PinIcon from '../../../assets/PinIcon';
import UnpinIcon from '../../../assets/UnpinIcon';
import FullScreenIcon from '../../../assets/FullScreenIcon';
import ExitFullScreenIcon from '../../../assets/ExitFullScreenIcon';
import { setLayoutOptionsStyles } from '../../../common/helpers/vehicleStyles';
import { useAppDispatch } from '../../../../store';
import { setPinnedMap } from '../../../../store/vehicle/vehicle.slice';
import classes from '../../../styles/stream.module.css';

interface IProps {
  camera: number;
  pinnedCamera: number | null;
  setPinnedCamera: (camera: number | null) => void;
  videoFullScreenCamera: number | null;
  setVideoFullScreenMode: (status: boolean) => void;
  setVideoFullScreenCamera: (camera: number | null) => void;
  setVideoHovered: (hovered: boolean) => void;
  pinnedMap: boolean;
}

const LayoutControls: FC<IProps> = memo(
  ({
    camera,
    pinnedCamera,
    setPinnedCamera,
    videoFullScreenCamera,
    setVideoFullScreenCamera,
    setVideoFullScreenMode,
    setVideoHovered,
    pinnedMap,
  }): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const setPinnedState = (pinned: boolean): void => {
      setVideoFullScreenMode(false);
      setVideoFullScreenCamera(null);
      setPinnedCamera(pinned ? camera : null);
      setVideoHovered(false);

      if (pinnedMap) {
        dispatch(setPinnedMap(false));
      }
    };

    const setFullScreenState = (full: boolean): void => {
      setVideoFullScreenMode(full);
      setVideoFullScreenCamera(full ? camera : null);
      setVideoHovered(false);

      if (pinnedMap) {
        dispatch(setPinnedMap(false));
      }
    };

    return (
      <Box
        className={classes.iconOptions}
        style={setLayoutOptionsStyles(
          pinnedMap || (pinnedCamera !== null && pinnedCamera !== camera),
          videoFullScreenCamera === camera
        )}
      >
        <Tooltip
          key='pin'
          placement='top'
          title={
            camera === pinnedCamera
              ? t('vehicle.unPinCameraLabel')
              : t('vehicle.pinCameraLabel')
          }
          arrow
        >
          <Box
            className={classes.iconOption}
            onClick={() => setPinnedState(pinnedCamera !== camera)}
          >
            {camera !== pinnedCamera ? <PinIcon /> : <UnpinIcon />}
          </Box>
        </Tooltip>
        <Tooltip
          key='full'
          placement='top'
          title={
            videoFullScreenCamera === camera
              ? t('vehicle.exitLabel')
              : t('vehicle.fullScreenLabel')
          }
          arrow
        >
          <Box
            className={classes.iconOption}
            onClick={() => setFullScreenState(videoFullScreenCamera !== camera)}
          >
            {videoFullScreenCamera !== camera ? (
              <FullScreenIcon />
            ) : (
              <ExitFullScreenIcon />
            )}
          </Box>
        </Tooltip>
      </Box>
    );
  }
);

export default LayoutControls;

import { useEffect, useState } from 'react';
import {
  setEngineRunning,
  setGamepadData,
  setManualControlRunning,
} from '../../../store/vehicle/vehicle.slice';
import {
  detectNulls,
  setDataCommandByGamepad,
  setDataCommandBySteeringWheel,
  setGeminiTractorBrake,
} from '../helpers/gamepadControls';
import { VEHICLE_TYPES, vehicleActionsByType } from '../../../domain/vehicle';
import { socketSend } from '../../../infra/common/socketService';
import {
  disarmCommand,
  geminiTractorCommands,
  manualControlCommand,
  setControlCommand,
  startEngineCommand,
  stopEngineCommand,
} from '../../../infra/common/socketService/commands/commonCommands';
import { useAppDispatch, useAppSelector } from '../../../store';

let isLightsCommandSent = false;
let isAutoStopCommandSent = false;
// let isBrakeActive = false;
let isSharePressed = false;
let gamepadInterval: NodeJS.Timeout;
let isArmPressed = false;
let forcedArm = false;
let isDisarmPressed = false;
let armTimeOut: NodeJS.Timeout;

const useVehicleGeminiControls = (): void => {
  const dispatch = useAppDispatch();
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const isDeviceOnline = useAppSelector(
    (state) => state.vehicles.activeVehicle?.is_online
  );
  const gamepadRunning = useAppSelector(
    (state) => state.vehicles.gamepadRunning
  );
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const vehicleLightsMetrics = useAppSelector(
    (state) => state.socket.vehicleMetrics?.lights_is_on
  );
  const vehicleAutoStopMetrics = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_on_auto_stop
  );
  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );
  const isUserOnline = useAppSelector((state) => state.app.isUserOnline);
  const getPingSuccess = useAppSelector((state) => state.app.getPingSuccess);
  const engineRunning = useAppSelector((state) => state.vehicles.engineRunning);
  const controller = useAppSelector((state) => state.vehicles.controller);
  let isLightsOn = vehicleLightsMetrics;
  // let isAutoStopOn = vehicleAutoStopMetrics;

  const [onceNullable, setOnceNullable] = useState<boolean>(false);
  const [commands, setCommands] = useState<any>(null);

  const isOperatorOnline = getPingSuccess || isUserOnline;

  useEffect(() => {
    if (!vehicleType) {
      return;
    }

    if (vehicleType !== VEHICLE_TYPES.TRACTOR_GEMINI) {
      return;
    }

    if (!isDeviceOnline || !isOperatorOnline) {
      dispatch(setManualControlRunning(false));
      clearInterval(gamepadInterval);
    } else {
      gamepadInterval = setInterval(async () => {
        if (gamepadRunning && controller === 'gamepad') {
          const gamepad: Gamepad | null = navigator.getGamepads()[0];
          if (gamepad?.buttons[8].pressed && !isArmPressed && !forcedArm) {
            isArmPressed = true;
            await dispatch(setEngineRunning(true));
            if (!engineRunning) {
              await socketSend(
                startEngineCommand(
                  vehicleActionsByType[vehicleType as VEHICLE_TYPES].armCommand
                ),
                timeProcessing
              );
              armTimeOut = setTimeout(async () => {
                await socketSend(
                  stopEngineCommand(
                    vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                      ?.stopArmCommand
                  ),
                  timeProcessing
                );
                forcedArm = true;
                isArmPressed = false;
                await dispatch(setEngineRunning(false));
              }, 7000);
            }
          }

          if (!gamepad?.buttons[8].pressed && isArmPressed && !forcedArm) {
            clearTimeout(armTimeOut);
            await socketSend(
              stopEngineCommand(
                vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                  ?.stopArmCommand
              ),
              timeProcessing
            );
            isArmPressed = false;
            await dispatch(setEngineRunning(false));
          }
          if (!gamepad?.buttons[8].pressed) {
            forcedArm = false;
          }

          // start manual control command, share button
          if (
            gamepad?.buttons[9].pressed &&
            !isSharePressed &&
            !manualControlMetric
          ) {
            await socketSend(
              manualControlCommand('start_manual'),
              timeProcessing
            );
            // await dispatch(setManualControlRunning(!manualControlRunning));
            isSharePressed = true;
          }

          if (
            gamepad?.buttons[9].pressed &&
            !isSharePressed &&
            manualControlMetric
          ) {
            await socketSend(
              manualControlCommand('stop_manual'),
              timeProcessing
            );
            // await dispatch(setManualControlRunning(!manualControlRunning));
            isSharePressed = true;
          }
          if (!gamepad?.buttons[9].pressed) {
            isSharePressed = false;
          }

          if (gamepad && manualControlMetric) {
            const commands: any = await setDataCommandByGamepad(
              vehicleType as VEHICLE_TYPES,
              gamepad.buttons,
              gamepad.axes
            );
            // if (gamepad.buttons[9].pressed && !isBrakeActive) {
            //   await socketSend(
            //     setControlCommand(
            //       'tractor_gemini_brake_no_clutch',
            //       setGeminiTractorBrake(gamepad.buttons[9].pressed)
            //     ),
            //     timeProcessing
            //   );
            //   isBrakeActive = true;
            // }
            // if (!gamepad.buttons[9].pressed) {
            //   isBrakeActive = false;
            // }

            if (gamepad.buttons[1].pressed) {
              if (
                vehicleAutoStopMetrics &&
                !isAutoStopCommandSent
                // isAutoStopOn
              ) {
                await socketSend(
                  geminiTractorCommands('tractor_gemini_remove_auto_stop'),
                  timeProcessing
                );
                isAutoStopCommandSent = true;
                // isAutoStopOn = false;
              }
              if (
                !vehicleAutoStopMetrics &&
                !isAutoStopCommandSent
                // !isAutoStopOn
              ) {
                await socketSend(
                  geminiTractorCommands('tractor_gemini_auto_stop'),
                  timeProcessing
                );
                isAutoStopCommandSent = true;
                // isAutoStopOn = true;
              }
            } else {
              isAutoStopCommandSent = false;
            }

            if (gamepad.buttons[2].pressed) {
              if (vehicleLightsMetrics && !isLightsCommandSent && isLightsOn) {
                await socketSend(
                  geminiTractorCommands('tractor_gemini_lights_off'),
                  timeProcessing
                );
                isLightsCommandSent = true;
                isLightsOn = false;
              }
              if (
                !vehicleLightsMetrics &&
                !isLightsCommandSent &&
                !isLightsOn
              ) {
                await socketSend(
                  geminiTractorCommands('tractor_gemini_lights_on'),
                  timeProcessing
                );
                isLightsCommandSent = true;
                isLightsOn = true;
              }
            } else {
              isLightsCommandSent = false;
            }
            if (gamepad?.buttons[3].pressed && !isDisarmPressed) {
              await socketSend(
                disarmCommand('tractor_gemini_disarm'),
                timeProcessing
              );
              await socketSend(
                manualControlCommand('stop_manual'),
                timeProcessing
              );
              await dispatch(setManualControlRunning(false));
              isDisarmPressed = true;
            }
            if (!gamepad?.buttons[3].pressed && isDisarmPressed) {
              isDisarmPressed = false;
            }

            await dispatch(setGamepadData(gamepad));

            await setCommands(commands);
            if (detectNulls(commands, vehicleType)) {
              return;
            }

            await setOnceNullable(false);

            await socketSend(
              setControlCommand(
                vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                  .controlCommand,
                commands
              ),
              timeProcessing
            );
          }
        } else if (controller === 'wheel') {
          if (gamepadRunning) {
            const gamepad: Gamepad | null = navigator.getGamepads()[0];
            if (gamepad?.buttons[8].pressed && !isArmPressed && !forcedArm) {
              isArmPressed = true;
              await dispatch(setEngineRunning(true));
              if (!engineRunning) {
                await socketSend(
                  startEngineCommand(
                    vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                      .armCommand
                  ),
                  timeProcessing
                );
                armTimeOut = setTimeout(async () => {
                  await socketSend(
                    stopEngineCommand(
                      vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                        ?.stopArmCommand
                    ),
                    timeProcessing
                  );
                  forcedArm = true;
                  isArmPressed = false;
                  await dispatch(setEngineRunning(false));
                }, 7000);
              }
            }

            if (!gamepad?.buttons[8].pressed && isArmPressed && !forcedArm) {
              clearTimeout(armTimeOut);
              await socketSend(
                stopEngineCommand(
                  vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                    ?.stopArmCommand
                ),
                timeProcessing
              );
              isArmPressed = false;
              await dispatch(setEngineRunning(false));
            }
            if (!gamepad?.buttons[8].pressed) {
              forcedArm = false;
            }

            // start manual control command, share button
            if (
              gamepad?.buttons[9].pressed &&
              !isSharePressed &&
              !manualControlMetric
            ) {
              await socketSend(
                manualControlCommand('start_manual'),
                timeProcessing
              );
              // await dispatch(setManualControlRunning(!manualControlRunning));
              isSharePressed = true;
            }

            if (
              gamepad?.buttons[9].pressed &&
              !isSharePressed &&
              manualControlMetric
            ) {
              await socketSend(
                manualControlCommand('stop_manual'),
                timeProcessing
              );
              // await dispatch(setManualControlRunning(!manualControlRunning));
              isSharePressed = true;
            }
            if (!gamepad?.buttons[9].pressed) {
              isSharePressed = false;
            }
            if (gamepad && manualControlMetric) {
              const commands: any = await setDataCommandBySteeringWheel(
                vehicleType as VEHICLE_TYPES,
                gamepad.buttons,
                gamepad.axes
              );

              if (gamepad.buttons[2].pressed) {
                if (
                  vehicleAutoStopMetrics &&
                  !isAutoStopCommandSent
                  // isAutoStopOn
                ) {
                  await socketSend(
                    geminiTractorCommands('tractor_gemini_remove_auto_stop'),
                    timeProcessing
                  );
                  isAutoStopCommandSent = true;
                  // isAutoStopOn = false;
                }
                if (
                  !vehicleAutoStopMetrics &&
                  !isAutoStopCommandSent
                  // !isAutoStopOn
                ) {
                  await socketSend(
                    geminiTractorCommands('tractor_gemini_auto_stop'),
                    timeProcessing
                  );
                  isAutoStopCommandSent = true;
                  // isAutoStopOn = true;
                }
              } else {
                isAutoStopCommandSent = false;
              }

              if (gamepad.buttons[1].pressed) {
                if (
                  vehicleLightsMetrics &&
                  !isLightsCommandSent &&
                  isLightsOn
                ) {
                  await socketSend(
                    geminiTractorCommands('tractor_gemini_lights_off'),
                    timeProcessing
                  );
                  isLightsCommandSent = true;
                  isLightsOn = false;
                }
                if (
                  !vehicleLightsMetrics &&
                  !isLightsCommandSent &&
                  !isLightsOn
                ) {
                  await socketSend(
                    geminiTractorCommands('tractor_gemini_lights_on'),
                    timeProcessing
                  );
                  isLightsCommandSent = true;
                  isLightsOn = true;
                }
              } else {
                isLightsCommandSent = false;
              }
              if (gamepad?.buttons[3].pressed && !isDisarmPressed) {
                await socketSend(
                  disarmCommand('tractor_gemini_disarm'),
                  timeProcessing
                );
                await socketSend(
                  manualControlCommand('stop_manual'),
                  timeProcessing
                );
                await dispatch(setManualControlRunning(false));
                isDisarmPressed = true;
              }
              if (!gamepad?.buttons[3].pressed && isDisarmPressed) {
                isDisarmPressed = false;
              }

              await dispatch(setGamepadData(gamepad));

              await setCommands(commands);
              if (detectNulls(commands, vehicleType)) {
                return;
              }

              await setOnceNullable(false);

              await socketSend(
                setControlCommand(
                  vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                    .controlCommand,
                  commands
                ),
                timeProcessing
              );
            }
          }
        }
      }, 100);
    }
    // eslint-disable-next-line consistent-return
    return () => clearInterval(gamepadInterval);
  }, [
    manualControlMetric,
    gamepadRunning,
    isDeviceOnline,
    isOperatorOnline,
    vehicleLightsMetrics,
    vehicleAutoStopMetrics,
  ]);

  useEffect(() => {
    if (!vehicleType) {
      return;
    }

    if (vehicleType !== VEHICLE_TYPES.TRACTOR_GEMINI) {
      return;
    }
    const setResetCommand = async () => {
      if (commands && detectNulls(commands, vehicleType) && !onceNullable) {
        await setOnceNullable(true);
        await socketSend(
          setControlCommand(
            vehicleActionsByType[vehicleType as VEHICLE_TYPES].resetCommand,
            commands
          ),
          timeProcessing
        );

        if (gamepadRunning) {
          await dispatch(setGamepadData(navigator.getGamepads()[0]));
        }
      }
    };

    setResetCommand();
  }, [commands, onceNullable, vehicleType]);

  const blurStopControl = async () => {
    if (
      manualControlMetric &&
      (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI ||
        vehicleType === VEHICLE_TYPES.CAR ||
        vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI)
    ) {
      await socketSend(manualControlCommand('stop_manual'), timeProcessing);
    }
  };

  useEffect(() => {
    window.addEventListener('blur', blurStopControl);
    return () => {
      window.removeEventListener('blur', blurStopControl);
    };
  }, [manualControlMetric, vehicleType]);
};

export default useVehicleGeminiControls;

import { FC, memo, ReactElement, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { UnknownAction } from '@reduxjs/toolkit';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { useTranslation } from 'react-i18next';
import { listStreamingPhotos } from '../../../../store/vehicle/vehicle.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store';
import SinglePhoto from './SinglePhoto';
import { ISelectedPhoto, IStreamingPhoto } from '../../../../domain/vehicle';
import getLocalISO from '../../helpers/getLocalISO';
import classes from '../index.module.css';

const Photos: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
  const listPhotosLoading = useAppSelector(
    (state) => state.vehicles.listPhotosLoading
  );
  const listPhotos = useAppSelector((state) => state.vehicles.listPhotos);
  const photosStartDate = useAppSelector(
    (state) => state.vehicles.mediaList.photosStartDate
  );
  const photosEndDate = useAppSelector(
    (state) => state.vehicles.mediaList.photosEndDate
  );
  const photosLimit = useAppSelector(
    (state) => state.vehicles.mediaList.photosLimit
  );
  const photosPage = useAppSelector(
    (state) => state.vehicles.mediaList.photosPage
  );
  const selectedPhotos = useAppSelector(
    (state) => state.vehicles.mediaList.selectedPhotos
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (uuid) {
      dispatch(
        listStreamingPhotos({
          uuid,
          from: photosStartDate || '2011-04-11T11:51:00',
          to: photosEndDate || getLocalISO(),
          limit: photosLimit,
          page: photosPage,
        }) as unknown as UnknownAction
      );
    }
  }, [uuid, photosStartDate, photosEndDate, photosLimit, photosPage]);

  if (listPhotosLoading) {
    return (
      <Box className={classes.mediaListLoader}>
        <CircularProgress color='inherit' />
        <Typography variant='caption'>
          {t('vehicle.loadingPhotosLabel')}
        </Typography>
      </Box>
    );
  }

  if (!listPhotos.length) {
    return (
      <Box className={classes.mediaListNoData}>
        <Typography variant='caption'>{t('vehicle.noPhotosLabel')}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.videoListContainer}>
      {listPhotos.map(
        (photo: IStreamingPhoto, index: number): ReactElement => (
          <SinglePhoto
            key={photo.id}
            index={index}
            imageID={photo.id}
            imageURL={photo.image}
            createdAt={photo.created_at}
            selected={
              !!selectedPhotos.find(
                (selectedPhoto: ISelectedPhoto): boolean =>
                  selectedPhoto.id === photo.id
              )
            }
          />
        )
      )}
    </Box>
  );
});

export default Photos;

import { FC, ReactElement, memo } from 'react';

const Record: FC = memo(
  (): ReactElement => (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='1'
        width='34'
        height='34'
        rx='17'
        stroke='white'
        strokeWidth='2'
      />
      <rect x='12' y='12' width='12' height='12' rx='2' fill='#F44336' />
    </svg>
  )
);

export default Record;

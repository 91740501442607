import { FC, memo, ReactElement, useState } from 'react';
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import Box from '@mui/material/Box';
import {
  removeWayPoints,
  setRowHoverIndex,
  updateWayPoints,
} from '../../../store/ons/ons.slice';
import { setElevation } from './mapConfig';
import { useAppDispatch, useAppSelector } from '../../../store';
import classes from '../../styles/ons.module.css';

interface IProps {
  index: number;
  lat: number;
  lng: number;
  setDragging?: (dragging: boolean) => void;
  elevation?: any;
  hover?: boolean;
  draggable?: boolean;
}

const GoogleMapMarker: FC<IProps> = memo(
  ({ setDragging, lng, lat, index, elevation, draggable }): ReactElement => {
    const dispatch = useAppDispatch();

    const rowHoverIndex = useAppSelector((state) => state.ons.rowHoverIndex);

    const [markerRef, marker] = useAdvancedMarkerRef();
    const [visible, setVisible] = useState<boolean>(false);

    return (
      <>
        <AdvancedMarker
          ref={markerRef}
          className={classes.advancedMarker}
          draggable={draggable}
          position={{ lat, lng }}
          onClick={() => {
            dispatch(setRowHoverIndex(index + 1));
            setVisible(true);
          }}
          onDragStart={() => {
            if (setDragging) {
              setDragging(true);
            }
          }}
          onDrag={async (event: any) => {
            dispatch(
              updateWayPoints({
                index,
                position: {
                  lat: event.latLng.lat(),
                  lng: event.latLng.lng(),
                  elevation: await setElevation(elevation, event.latLng),
                },
              })
            );
          }}
          onDragEnd={() => {
            if (setDragging) {
              setTimeout(() => {
                setDragging(false);
              }, 200);
            }
          }}
        >
          <Box
            className={
              rowHoverIndex === index + 1 ? classes.activePin : classes.pin
            }
          />
        </AdvancedMarker>
        {visible && rowHoverIndex === index + 1 ? (
          <InfoWindow headerDisabled anchor={marker} pixelOffset={[0, 5]}>
            <Box className={classes.infoWindowWrapper}>
              <span>{index + 1}</span>
              <svg
                width='14'
                height='18'
                viewBox='0 0 14 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => dispatch(removeWayPoints(index))}
              >
                <path
                  d='M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z'
                  fill='#000'
                  fillOpacity='0.56'
                />
              </svg>
            </Box>
          </InfoWindow>
        ) : null}
      </>
    );
  }
);

export default GoogleMapMarker;

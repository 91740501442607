import { FC, ReactElement, memo } from 'react';

const UnpinIcon: FC = memo((): ReactElement => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.8197 14H18C18.55 14 19 13.55 19 13V12.87C19 12.4 18.66 12.02 18.2 11.89C16.93 11.54 16 10.38 16 9V4H17C17.55 4 18 3.55 18 3C18 2.45 17.55 2 17 2H7C6.45 2 6 2.45 6 3C6 3.55 6.45 4 7 4H8V5.18033L16.8197 14Z'
        fill='white'
      />
      <path
        d='M3.51012 3.51027L20.4901 20.4903C20.8801 20.8803 20.8701 21.5103 20.4801 21.9003C20.0901 22.2903 19.4601 22.2903 19.0701 21.9003C17.1064 19.9366 15.2859 18.131 13.4966 16.3563C13.3259 16.187 13.1555 16.018 12.9853 15.8492L13 20.9996C13 21.5496 12.55 21.9996 12 21.9996C11.45 21.9996 11 21.5496 11 20.9996L10.98 13.9996H6C5.45 13.9996 5 13.5496 5 12.9996V12.8696C5 12.3996 5.34 12.0196 5.8 11.8896C6.57014 11.6773 7.21526 11.1672 7.60377 10.4908C5.87819 8.76154 4.07372 6.93884 2.10012 4.92027C1.71012 4.53027 1.71012 3.90027 2.10012 3.51027C2.49012 3.12027 3.12012 3.12027 3.51012 3.51027Z'
        fill='white'
      />
    </svg>
  );
});

export default UnpinIcon;

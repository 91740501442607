import { ChangeEvent, useState, DragEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { t } from 'i18next';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SquareIcon from '@mui/icons-material/Square';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import LoopIcon from '@mui/icons-material/Loop';
import RouteIcon from '@mui/icons-material/Route';
import { socketSend } from '../../../../infra/common/socketService';
import {
  droneCommand,
  sendMissionCommand,
} from '../../../../infra/common/socketService/commands/droneCommands';
import { useAppSelector } from '../../../../store';
import classes from '../../../styles/mission.module.css';
import {
  setIsMissionStarted,
  setMissionText,
} from '../../../../store/vehicle/vehicle.slice';

const Mission = () => {
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const isMissionStarted = useAppSelector(
    (state) => state.vehicles.isMissionStarted
  );
  const missionText = useAppSelector((state) => state.vehicles.missionText);
  const dispatch = useDispatch();
  const flightModeMetrics = useAppSelector(
    (state) => state.socket.vehicleMetrics?.flight_mode
  );
  const [isResumeButtonPressed, setIsResumeButtonPressed] =
    useState<boolean>(false);
  // const isArduPilotMissionFinished = useAppSelector(
  //   (state) => state.socket.vehicleMetrics?.is_ardupilot_mission_finished
  // );
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isStartMissionPressed, setIsStartMissionPressed] =
    useState<boolean>(false);
  const [text, setText] = useState<string>('');

  const startMission = async (): Promise<void> => {
    await socketSend(droneCommand('arm_auto'), timeProcessing);
    dispatch(setIsMissionStarted(true));
  };

  // const resumeMission = async (): Promise<void> => {
  //   await socketSend(droneCommand('set_mode_auto'), timeProcessing);
  //   dispatch(setIsMissionStarted(true));
  // };

  // const pauseMission = async (): Promise<void> => {
  //   await socketSend(droneCommand('set_mode_guided'), timeProcessing);
  //   dispatch(setIsMissionStarted(false));
  // };

  const stopMission = async () => {
    // setText('');
    await socketSend(droneCommand('set_mode_guided'), timeProcessing);
    dispatch(setIsMissionStarted(false));
  };

  const restartMission = async (): Promise<void> => {
    await socketSend(droneCommand('ardupilot_mission_reset'), timeProcessing);
  };

  const sendMission = async (): Promise<void> => {
    await socketSend(sendMissionCommand(missionText), timeProcessing);
    setModalIsOpen(false);
  };
  const handleFileUpload = (file: File): void => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target?.result as string;
      dispatch(setMissionText(result));
    };

    reader.readAsText(file);
  };

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDrop = (event: DragEvent<HTMLTextAreaElement>): void => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLTextAreaElement>): void => {
    event.preventDefault();
  };

  const handleDisableUploadButton = () => {
    if (!missionText) {
      return true;
    }

    if (isMissionStarted) {
      return true;
    }

    return false;
  };

  const startButtonStyle = () => {
    if (isMissionStarted) {
      return classes.activeMissionStartButton;
    }
    if (missionText) {
      return classes.missionStartButton;
    }
    return classes.disabledMissionStartButton;
  };

  return (
    <>
      <Box className={classes.controlItemContainer}>
        <IconButton
          size='large'
          className={startButtonStyle()}
          onClick={isMissionStarted ? stopMission : startMission}
          disabled={!missionText}
        >
          {isMissionStarted ? (
            <SquareIcon className={classes.stopIcon} />
          ) : (
            <PlayArrowIcon className={classes.startIcon} />
          )}
        </IconButton>
        <Typography variant='caption' noWrap className={classes.commandText}>
          {isMissionStarted
            ? t('mission.stopMission')
            : t('mission.startMission')}
        </Typography>
      </Box>
      {/* <Box className={classes.controlItemContainer}>
        <IconButton
          onClick={isMissionStarted ? pauseMission : resumeMission}
          size='large'
          // disabled={!isStartMissionPressed}
        >
          {isMissionStarted ? (
            <PauseIcon className={classes.pauseIcon} />
          ) : (
            <PlayArrowIcon className={classes.resumeIcon} />
          )}
        </IconButton>
        <Typography variant='caption' noWrap className={classes.commandText}>
          {isMissionStarted
            ? t('mission.pauseMission')
            : t('mission.resumeMission')}
        </Typography>
      </Box> */}
      <Box className={classes.controlItemContainer}>
        <IconButton
          onClick={restartMission}
          size='large'
          // disabled={!isStartMissionPressed}
        >
          <LoopIcon className={classes.restartIcon} />
        </IconButton>
        <Typography variant='caption' noWrap className={classes.commandText}>
          {isMissionStarted
            ? t('mission.restartMission')
            : t('mission.resetMission')}
        </Typography>
      </Box>
      <Box className={classes.controlItemContainer}>
        <IconButton onClick={() => setModalIsOpen(true)} size='large'>
          <RouteIcon className={classes.routIcon} />
        </IconButton>
        <Typography variant='caption' noWrap className={classes.commandText}>
          {t('mission.routDataMission')}
        </Typography>
      </Box>

      <Modal
        disableEscapeKeyDown
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby='media-list-title'
        aria-describedby='media-list-description'
      >
        <Box className={classes.modalContainer}>
          <IconButton
            onClick={() => setModalIsOpen(false)}
            className={classes.modalCloseIcon}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <textarea
              value={missionText}
              readOnly
              rows={10}
              cols={100}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className={classes.missionTextarea}
            />
            <Box className={classes.missionContainer}>
              <Box className={classes.missionInputWrapper}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<UploadIcon />}
                >
                  {t('mission.uploadTXT')}
                </Button>
                <input
                  className={classes.missionInput}
                  type='file'
                  accept='.txt'
                  onChange={handleFileInput}
                />
              </Box>
              <Box className={classes.missionSendAndStartContainer}>
                <Button
                  variant='text'
                  color='inherit'
                  onClick={() => dispatch(setMissionText(''))}
                >
                  {t('mission.clearMission')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  endIcon={<ArrowRightIcon />}
                  onClick={sendMission}
                  disabled={handleDisableUploadButton()}
                >
                  {t('mission.uploadMission')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Mission;

import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const LightsIcon: FC<{ on: boolean }> = memo(
  ({ on }): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11 4C9.94942 4 8.90914 4.20693 7.93853 4.60896C6.96793 5.011 6.08601 5.60028 5.34315 6.34315C4.60028 7.08601 4.011 7.96793 3.60896 8.93853C3.20693 9.90914 3 10.9494 3 12C3 13.0506 3.20693 14.0909 3.60896 15.0615C4.011 16.0321 4.60028 16.914 5.34315 17.6569C6.08601 18.3997 6.96793 18.989 7.93853 19.391C8.90914 19.7931 9.94942 20 11 20C11.5523 20 12 19.5523 12 19L12 5C12 4.44772 11.5523 4 11 4ZM8.7039 6.45672C9.12153 6.28373 9.55637 6.15891 10 6.08392V17.9161C9.55637 17.8411 9.12154 17.7163 8.7039 17.5433C7.97595 17.2417 7.31451 16.7998 6.75736 16.2426C6.20021 15.6855 5.75825 15.0241 5.45672 14.2961C5.15519 13.5681 5 12.7879 5 12C5 11.2121 5.15519 10.4319 5.45672 9.7039C5.75825 8.97595 6.20021 8.31451 6.75736 7.75736C7.31451 7.20021 7.97595 6.75825 8.7039 6.45672Z'
          fill={on ? '#66BB6A' : '#fff'}
        />
        <path d='M21 6H13V8H21V6Z' fill={on ? '#66BB6A' : '#fff'} />
        <path d='M13 11H21V13H13V11Z' fill={on ? '#66BB6A' : '#fff'} />
        <path d='M21 16H13V18H21V16Z' fill={on ? '#66BB6A' : '#fff'} />
      </svg>
    </Box>
  )
);

export default LightsIcon;

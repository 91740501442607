import { FC, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../../../store';
import { socketSend } from '../../../../../infra/common/socketService';
import { carChangeGearCommand } from '../../../../../infra/common/socketService/commands/carControlCommands';
import {
  gearsCar,
  gearsBoat,
  gearsTractorKHTI,
  formatPedals,
} from '../../../../common/helpers/gamepadControls';
import { VEHICLE_TYPES } from '../../../../../domain/vehicle';
import classes from './index.module.css';
import boatChangeGearCommand from '../../../../../infra/common/socketService/commands/boatControlCommands';

interface IProps {
  vehicleType: VEHICLE_TYPES;
}

const GearsControls: FC<IProps> = memo(({ vehicleType }): ReactElement => {
  const [gearIndex, setGearIndex] = useState<number | null>(null);
  const [changeGearAvailable, setChangeGearAvailable] = useState(true);
  const [mountGearCheck, setMountGearCheck] = useState<boolean>(true);
  // const vehicleMetricsCarGear = useAppSelector(
  //   (state) => state.socket.vehicleMetrics?.car_gear
  // );
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const gamepadButtonL1 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[4].pressed
  );
  const gamepadButtonL2 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[6].pressed
  );
  const gamepadButtonR1 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[5].pressed
  );
  const gamepadArrowDown = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[13].pressed
  );
  const gamepadArrowUp = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[12].pressed
  );
  const gamepadArrowRight = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[15].pressed
  );
  const gamepadArrowLeft = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[14].pressed
  );
  const l2 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[6].pressed
  );
  const r2 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[7].pressed
  );
  const gamepadLeftPetal = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[5].pressed
  );
  const gamepadRightPetal = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[4].pressed
  );
  const gamepadBrakeAxes = useAppSelector(
    (state) => state.vehicles.gamepad?.axes[5]
  );

  const metrics = useAppSelector((state) => state.socket.vehicleMetrics);

  const controller = useAppSelector((state) => state.vehicles.controller);

  const gears: string[] = useMemo((): string[] => {
    if (vehicleType === VEHICLE_TYPES.CAR) {
      return gearsCar;
    }
    if (vehicleType === VEHICLE_TYPES.BOAT) {
      return gearsBoat;
    }
    return gearsTractorKHTI;
  }, [vehicleType]);

  const maxGearIndex: number = useMemo((): number => {
    if (vehicleType === VEHICLE_TYPES.CAR) {
      return 3;
    }
    return 2;
  }, [vehicleType]);

  useEffect(() => {
    if (
      gamepadButtonL2 &&
      vehicleType !== VEHICLE_TYPES.TRACTOR_KHTI &&
      controller === 'gamepad'
    ) {
      if (gearIndex === null) {
        setGearIndex(0);
        return;
      }

      if (gamepadButtonL1) {
        setGearIndex(gearIndex > 0 ? gearIndex - 1 : gearIndex);
      }

      if (gamepadButtonR1) {
        setGearIndex(gearIndex < maxGearIndex ? gearIndex + 1 : gearIndex);
      }
    }

    if (metrics?.car_gear === 'park' && mountGearCheck) {
      setGearIndex(0);
      setMountGearCheck(false);
    }

    if (
      vehicleType !== VEHICLE_TYPES.TRACTOR_KHTI &&
      controller === 'wheel' &&
      formatPedals(gamepadBrakeAxes) > 0.1
    ) {
      if (gearIndex === null) {
        setGearIndex(0);
        return;
      }

      if (gamepadLeftPetal) {
        setGearIndex(gearIndex > 0 ? gearIndex - 1 : gearIndex);
      }

      if (gamepadRightPetal) {
        setGearIndex(gearIndex < maxGearIndex ? gearIndex + 1 : gearIndex);
      }
    }
  }, [
    gamepadButtonL2,
    gamepadButtonL1,
    gamepadButtonR1,
    metrics?.car_gear,
    mountGearCheck,
  ]);

  useEffect(() => {
    return () => {
      setMountGearCheck(true);
    };
  }, []);

  useEffect(() => {
    if (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI) {
      if (gamepadArrowUp && changeGearAvailable) {
        setGearIndex(0);
        setChangeGearAvailable(false);
      }
      if ((l2 || r2) && !gamepadArrowDown && !gamepadArrowUp) {
        setGearIndex(1);
        setChangeGearAvailable(true);
      }
      if (gamepadArrowDown && changeGearAvailable) {
        setChangeGearAvailable(false);
        setGearIndex(2);
      }
    }
  }, [gamepadArrowUp, gamepadArrowDown, l2, r2]);

  useEffect(() => {
    const sendGearCommand = async () => {
      if (gearIndex !== null) {
        if (vehicleType === VEHICLE_TYPES.CAR) {
          await socketSend(
            carChangeGearCommand(gears[gearIndex].toLowerCase()),
            timeProcessing
          );
        }
        if (vehicleType === VEHICLE_TYPES.BOAT) {
          await socketSend(
            boatChangeGearCommand(gears[gearIndex].toLowerCase()),
            timeProcessing
          );
        }
      }
    };

    sendGearCommand();
  }, [gearIndex]);

  return (
    <Box className={classes.gearControls}>
      {gears.map(
        (gear: string, index: number): ReactElement => (
          <Typography
            key={gear}
            variant='caption'
            style={{
              fontSize: '13px',
              fontWeight: 500,
              color:
                gearIndex === index
                  ? 'rgba(255, 255, 255, 1)'
                  : 'rgba(255, 255, 255, 0.32)',
            }}
          >
            {gear.at(0)}
          </Typography>
        )
      )}
    </Box>
  );
});
export default GearsControls;

import { FC, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  MapContainer,
  ScaleControl,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { setStylesForVehiclePageMap } from '../../helpers/vehicleStyles';
import { setMapPosition } from '../../../../store/vehicle/vehicle.slice';
import MiniMapExitIcon from '../../../assets/MiniMapExitIcon';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { miniMapConfig } from '../common/config';
import PinMapWidget from '../Widgets/PinMapWidget';
import MapLabelWidget from '../Widgets/MapLabelWidget';
import MiniMapCoordinates from './MiniMapCoordinates';
import RotatedMarker from '../SlidesMap/RotatedMarker';
import classes from '../styles/index.module.css';

const {
  zoom: {
    initialZoom,
    zoomControl,
    zoomControlPosition,
    zoomSnap,
    minZoom,
    maxZoom,
    maxNativeZoom,
    minZoomTile,
    maxZoomTile,
  },
  bounds: { maxBoundsViscosity },
  layerControls: { satelliteTileLayerUrl, subdomainsForSatelliteTile },
  scale,
  dragging,
} = miniMapConfig;

const MiniMap: FC = memo((): ReactElement | null => {
  const dispatch = useAppDispatch();
  const [mapHovered, setMapHovered] = useState<boolean>(false);
  const [miniMapHovered, setMiniMapHovered] = useState<boolean>(false);
  const [miniMapMode, setMiniMapMode] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const collapseAdditionalInfoSection = useAppSelector(
    (state) => state.app.collapseAdditionalInfoSection
  );
  const pinnedMap = useAppSelector((state) => state.vehicles.pinnedMap);
  const vehicleMetrics = useAppSelector((state) => state.socket.vehicleMetrics);
  const mapPositionY = useAppSelector((state) => state.vehicles.mapYPosition);
  const yaw = useAppSelector(
    (state) => state.socket.vehicleMetrics?.attitude?.yaw
  );

  const coordinates: LatLngExpression = useMemo((): LatLngExpression => {
    if (!vehicleMetrics) {
      return [0, 0];
    }

    return [
      vehicleMetrics.position ? vehicleMetrics.position.latitude : 0,
      vehicleMetrics.position ? vehicleMetrics.position.longitude : 0,
    ];
  }, [vehicleMetrics]);

  useEffect(() => {
    if (!collapseAdditionalInfoSection) {
      setMiniMapMode(false);
    }
  }, [collapseAdditionalInfoSection]);
  const onDrag = (): void => setIsDragging(true);

  const onDragStop = (event: any, data: any): void => {
    setIsDragging(false);
    dispatch(setMapPosition(data.y));
  };

  if (pinnedMap && collapseAdditionalInfoSection) {
    return null;
  }

  if (miniMapMode) {
    return (
      <Draggable
        bounds='#parent'
        axis='y'
        position={{ x: 0, y: mapPositionY }}
        onDrag={onDrag}
        onStop={onDragStop}
      >
        <Box
          className={classes.miniMap}
          onMouseEnter={() => (!isDragging ? setMiniMapHovered(true) : null)}
          onMouseLeave={() => (!isDragging ? setMiniMapHovered(false) : null)}
        >
          <Box className={classes.miniMapChild}>
            <IconButton
              size='medium'
              className={classes.miniMapExitIconContainer}
              style={{ visibility: miniMapHovered ? 'visible' : 'hidden' }}
              onClick={() => {
                if (!isDragging) {
                  setMiniMapMode(false);
                  setMapHovered(false);
                }
              }}
            >
              <MiniMapExitIcon />
            </IconButton>
          </Box>
        </Box>
      </Draggable>
    );
  }

  return (
    <Draggable
      key='miniMap'
      bounds='#parent'
      disabled={!collapseAdditionalInfoSection}
      axis='y'
      position={
        !collapseAdditionalInfoSection
          ? { x: 0, y: 0 }
          : { x: -211, y: mapPositionY }
      }
      onDrag={onDrag}
      onStop={onDragStop}
    >
      <section
        className={classes.wrapperForVehiclePage}
        style={setStylesForVehiclePageMap(
          mapHovered,
          !!collapseAdditionalInfoSection
        )}
        onMouseEnter={() => setMapHovered(true)}
        onMouseLeave={() => setMapHovered(false)}
      >
        <MapContainer
          className='miniMapContainer'
          minZoom={minZoom}
          zoom={initialZoom}
          maxZoom={maxZoom}
          zoomSnap={zoomSnap}
          maxBoundsViscosity={maxBoundsViscosity}
          zoomControl={zoomControl}
          center={coordinates}
          dragging={dragging}
        >
          <TileLayer
            url={satelliteTileLayerUrl}
            subdomains={subdomainsForSatelliteTile}
            minZoom={minZoomTile}
            maxZoom={maxZoomTile}
            maxNativeZoom={maxNativeZoom}
            detectRetina
          />
          {mapHovered ? (
            <>
              <ScaleControl position={scale.position} />
              <ZoomControl position={zoomControlPosition} />
              <PinMapWidget
                mapHovered={mapHovered}
                isDragging={isDragging}
                pinnedMap={pinnedMap}
              />
              <MapLabelWidget
                mapHovered={mapHovered}
                isDragging={isDragging}
                collapseAdditionalInfoSection={collapseAdditionalInfoSection}
                setMiniMapMode={setMiniMapMode}
                setMiniMapHovered={setMiniMapHovered}
              />
            </>
          ) : null}
          <RotatedMarker position={coordinates} yaw={yaw} reducePin centered />
        </MapContainer>
        {!collapseAdditionalInfoSection ? (
          <MiniMapCoordinates
            containerClassName={classes.miniMapCoordinatesContainer}
          />
        ) : null}
      </section>
    </Draggable>
  );
});

export default MiniMap;

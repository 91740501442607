import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfirmPasswordUserData, UserError } from '../../domain/user';

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (
    { email, password }: { email: string; password: string },
    { extra: container }
  ) => {
    try {
      // @ts-ignore
      return await container.createLogin(email, password);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const createPasswordRecovery = createAsyncThunk(
  'user/createPasswordRecovery',
  async (email: string, { extra: container }) => {
    try {
      // @ts-ignore
      return await container.createPasswordRecovery(email);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const confirmPasswordRecovery = createAsyncThunk(
  'user/confirmPasswordRecovery',
  async (userData: ConfirmPasswordUserData, { extra: container }) => {
    try {
      // @ts-ignore
      return await container.createPasswordConfirm(userData);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getLicense = createAsyncThunk(
  'user/getLicense',
  async (_, { extra: container }) => {
    try {
      // @ts-ignore
      return await container.createGetLicense();
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const acceptLicense = createAsyncThunk(
  'user/acceptLicense',
  async (onSuccess: () => void, { extra: container, dispatch }) => {
    try {
      // @ts-ignore
      const acceptedLicenseStatus = await container.createAcceptLicense();

      if (acceptedLicenseStatus) {
        dispatch(acceptLicenseStatus(onSuccess));
      }
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const acceptLicenseStatus = createAsyncThunk(
  'user/acceptLicenseStatus',
  async (onSuccess: () => void, { extra: container }) => {
    try {
      // @ts-ignore
      const acceptLicenseStatus = await container.getAcceptLicenseStatus();
      if (acceptLicenseStatus) {
        onSuccess();
      }
      return acceptLicenseStatus;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (_, { extra: container }) => {
    try {
      // @ts-ignore
      return await container.getUserProfile();
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import MiniMapIcon from '../../../assets/MiniMapIcon';
import classes from '../styles/index.module.css';

interface IProps {
  mapHovered: boolean;
  isDragging: boolean;
  collapseAdditionalInfoSection: boolean;
  setMiniMapMode: (status: boolean) => void;
  setMiniMapHovered: (status: boolean) => void;
}

const MapLabelWidget: FC<IProps> = ({
  mapHovered,
  isDragging,
  collapseAdditionalInfoSection,
  setMiniMapMode,
  setMiniMapHovered,
}): ReactElement | null => {
  if (!mapHovered || !collapseAdditionalInfoSection) {
    return null;
  }

  return (
    <Box
      className={classes.miniMapContainer}
      onClick={() => {
        if (!isDragging) {
          setMiniMapMode(true);
          setMiniMapHovered(false);
        }
      }}
    >
      <MiniMapIcon />
    </Box>
  );
};

export default MapLabelWidget;

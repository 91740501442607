import {
  FC,
  ReactElement,
  useEffect,
  useState,
  memo,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '../../assets/Search';
import useDebounce from '../../common/hooks/useDebounce';
import classes from '../../styles/vehicle.module.css';

interface IProps {
  q: string;
  handleQueryParams: (key: string, value: string) => void;
  removeVehiclesQueryParam: (key: string) => void;
}

const Search: FC<IProps> = memo(
  ({ q, handleQueryParams, removeVehiclesQueryParam }): ReactElement => {
    const [search, setSearch] = useState<string>(q || '');
    const debouncedValue = useDebounce(search, 1000);
    const { t } = useTranslation();

    useEffect(() => {
      if (debouncedValue) {
        handleQueryParams('q', debouncedValue);
      }
    }, [debouncedValue]);

    const searchVehicle = (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;

      if (value.length) {
        setSearch(value);
      } else {
        removeVehiclesQueryParam('q');
        setSearch(value);
      }
    };

    return (
      <TextField
        size='small'
        fullWidth
        className={classes.searchInput}
        placeholder={t('vehiclesList.listSearchPlaceholder')}
        variant='outlined'
        onChange={searchVehicle}
        value={search}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover': {
              '& fieldset': {
                borderColor: 'transparent',
              },
            },
          },
        }}
        InputProps={{
          style: { color: '#fafafa', fontSize: '14px' },
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default Search;

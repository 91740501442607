import { FC, ReactElement, memo } from 'react';

const Logo: FC<{
  orientation: 'vertical' | 'horizontal';
}> = memo(
  ({ orientation }): ReactElement =>
    orientation !== 'horizontal' ? (
      <svg
        width='94'
        height='100'
        viewBox='0 0 94 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M59.6258 89.8321C60.5435 89.8321 61.3755 90.0462 62.1219 90.4745C62.8805 90.8905 63.4739 91.4839 63.9021 92.2548C64.3304 93.0134 64.5445 93.9004 64.5445 94.916C64.5445 95.9315 64.3304 96.8247 63.9021 97.5956C63.4739 98.3542 62.8805 98.9476 62.1219 99.3759C61.3755 99.7919 60.5435 99.9999 59.6258 99.9999C58.2676 99.9999 57.2337 99.5716 56.5241 98.7151V99.853H53.7894V86.2349H56.6526V91.0251C57.3745 90.2298 58.3655 89.8321 59.6258 89.8321ZM59.1303 97.6506C59.8644 97.6506 60.4639 97.4059 60.9289 96.9165C61.4061 96.4149 61.6447 95.748 61.6447 94.916C61.6447 94.084 61.4061 93.4233 60.9289 92.9338C60.4639 92.4322 59.8644 92.1813 59.1303 92.1813C58.3961 92.1813 57.7905 92.4322 57.3133 92.9338C56.8483 93.4233 56.6158 94.084 56.6158 94.916C56.6158 95.748 56.8483 96.4149 57.3133 96.9165C57.7905 97.4059 58.3961 97.6506 59.1303 97.6506Z'
          fill='#0B0C0E'
        />
        <path
          d='M51.1387 89.9791V99.8532H48.404V98.7153C47.6944 99.5718 46.6666 100 45.3207 100C44.3907 100 43.5465 99.792 42.7879 99.376C42.0415 98.96 41.4542 98.3666 41.026 97.5957C40.5977 96.8249 40.3836 95.9317 40.3836 94.9162C40.3836 93.9006 40.5977 93.0074 41.026 92.2366C41.4542 91.4657 42.0415 90.8723 42.7879 90.4563C43.5465 90.0403 44.3907 89.8323 45.3207 89.8323C46.5809 89.8323 47.5659 90.2299 48.2755 91.0252V89.9791H51.1387ZM45.8162 97.6508C46.5381 97.6508 47.1376 97.4061 47.6148 96.9167C48.092 96.415 48.3306 95.7482 48.3306 94.9162C48.3306 94.0841 48.092 93.4234 47.6148 92.934C47.1376 92.4323 46.5381 92.1815 45.8162 92.1815C45.0821 92.1815 44.4764 92.4323 43.9992 92.934C43.522 93.4234 43.2834 94.0841 43.2834 94.9162C43.2834 95.7482 43.522 96.415 43.9992 96.9167C44.4764 97.4061 45.0821 97.6508 45.8162 97.6508Z'
          fill='#0B0C0E'
        />
        <path
          d='M30.3148 87.0059H33.288V97.4306H39.73V99.8532H30.3148V87.0059Z'
          fill='#0B0C0E'
        />
        <path
          d='M88.7369 81.7642C87.9171 81.7642 87.1156 81.6663 86.3326 81.4705C85.5495 81.2625 84.9255 81.0056 84.4605 80.6997L85.4149 78.6441C85.8554 78.9255 86.3876 79.158 87.0116 79.3415C87.6357 79.5128 88.2474 79.5985 88.847 79.5985C90.0583 79.5985 90.664 79.2987 90.664 78.6992C90.664 78.4178 90.4988 78.2159 90.1684 78.0935C89.8381 77.9712 89.3303 77.8672 88.6451 77.7815C87.8375 77.6592 87.1707 77.5184 86.6446 77.3594C86.1184 77.2003 85.6596 76.9189 85.2681 76.5151C84.8888 76.1114 84.6991 75.5363 84.6991 74.7899C84.6991 74.1659 84.8765 73.6153 85.2314 73.1381C85.5984 72.6487 86.1246 72.2694 86.8098 72.0002C87.5072 71.731 88.327 71.5964 89.2691 71.5964C89.9665 71.5964 90.6578 71.676 91.343 71.835C92.0405 71.9819 92.6155 72.1899 93.0682 72.459L92.1139 74.4963C91.2451 74.0068 90.2969 73.7621 89.2691 73.7621C88.6573 73.7621 88.1985 73.8478 87.8926 74.0191C87.5867 74.1904 87.4338 74.4106 87.4338 74.6798C87.4338 74.9857 87.5989 75.1998 87.9293 75.3222C88.2597 75.4445 88.7858 75.5608 89.5077 75.6709C90.3152 75.8055 90.976 75.9523 91.4899 76.1114C92.0038 76.2582 92.4503 76.5335 92.8297 76.9373C93.209 77.341 93.3986 77.9039 93.3986 78.6258C93.3986 79.2375 93.2151 79.782 92.848 80.2592C92.4809 80.7364 91.9426 81.1096 91.2329 81.3788C90.5355 81.6357 89.7035 81.7642 88.7369 81.7642Z'
          fill='#0B0C0E'
        />
        <path
          d='M79.3433 81.7642C78.291 81.7642 77.3427 81.5501 76.4985 81.1218C75.6665 80.6813 75.0119 80.0757 74.5347 79.3048C74.0697 78.534 73.8372 77.6592 73.8372 76.6803C73.8372 75.7015 74.0697 74.8266 74.5347 74.0558C75.0119 73.2849 75.6665 72.6854 76.4985 72.2572C77.3427 71.8167 78.291 71.5964 79.3433 71.5964C80.3833 71.5964 81.2887 71.8167 82.0596 72.2572C82.8426 72.6854 83.4116 73.3033 83.7664 74.1108L81.5457 75.3038C81.0318 74.3984 80.2915 73.9457 79.3249 73.9457C78.5785 73.9457 77.9606 74.1904 77.4712 74.6798C76.9818 75.1692 76.7371 75.8361 76.7371 76.6803C76.7371 77.5246 76.9818 78.1914 77.4712 78.6808C77.9606 79.1703 78.5785 79.415 79.3249 79.415C80.3037 79.415 81.044 78.9622 81.5457 78.0568L83.7664 79.2681C83.4116 80.0512 82.8426 80.663 82.0596 81.1035C81.2887 81.544 80.3833 81.7642 79.3433 81.7642Z'
          fill='#0B0C0E'
        />
        <path
          d='M69.051 71.7433H71.9142V81.6174H69.051V71.7433ZM70.4826 70.3668C69.9565 70.3668 69.5282 70.2139 69.1979 69.908C68.8675 69.6021 68.7023 69.2228 68.7023 68.7701C68.7023 68.3174 68.8675 67.9381 69.1979 67.6322C69.5282 67.3263 69.9565 67.1733 70.4826 67.1733C71.0087 67.1733 71.437 67.3202 71.7673 67.6138C72.0977 67.9075 72.2629 68.2745 72.2629 68.715C72.2629 69.1922 72.0977 69.5899 71.7673 69.908C71.437 70.2139 71.0087 70.3668 70.4826 70.3668Z'
          fill='#0B0C0E'
        />
        <path
          d='M67.4339 81.1403C67.1525 81.3483 66.8038 81.5074 66.3878 81.6175C65.984 81.7154 65.5619 81.7643 65.1214 81.7643C63.9346 81.7643 63.023 81.4645 62.3868 80.865C61.7505 80.2654 61.4324 79.3845 61.4324 78.2221V69.5593H64.2955V71.9636H66.7365V74.166H64.2955V78.1854C64.2955 78.6014 64.3995 78.9257 64.6075 79.1581C64.8155 79.3784 65.1153 79.4885 65.5068 79.4885C65.9473 79.4885 66.3388 79.3661 66.6814 79.1214L67.4339 81.1403Z'
          fill='#0B0C0E'
        />
        <path
          d='M54.1594 81.7642C53.1194 81.7642 52.1834 81.5501 51.3514 81.1218C50.5316 80.6813 49.8892 80.0757 49.4243 79.3048C48.9593 78.534 48.7268 77.6592 48.7268 76.6803C48.7268 75.7015 48.9593 74.8266 49.4243 74.0558C49.8892 73.2849 50.5316 72.6854 51.3514 72.2572C52.1834 71.8167 53.1194 71.5964 54.1594 71.5964C55.1995 71.5964 56.1294 71.8167 56.9491 72.2572C57.7689 72.6854 58.4113 73.2849 58.8762 74.0558C59.3412 74.8266 59.5737 75.7015 59.5737 76.6803C59.5737 77.6592 59.3412 78.534 58.8762 79.3048C58.4113 80.0757 57.7689 80.6813 56.9491 81.1218C56.1294 81.5501 55.1995 81.7642 54.1594 81.7642ZM54.1594 79.415C54.8936 79.415 55.4931 79.1703 55.9581 78.6808C56.4352 78.1792 56.6738 77.5123 56.6738 76.6803C56.6738 75.8483 56.4352 75.1876 55.9581 74.6982C55.4931 74.1965 54.8936 73.9457 54.1594 73.9457C53.4253 73.9457 52.8196 74.1965 52.3425 74.6982C51.8653 75.1876 51.6267 75.8483 51.6267 76.6803C51.6267 77.5123 51.8653 78.1792 52.3425 78.6808C52.8196 79.1703 53.4253 79.415 54.1594 79.415Z'
          fill='#0B0C0E'
        />
        <path
          d='M43.9406 71.7433H46.8038V81.6174H43.9406V71.7433ZM45.3722 70.3668C44.8461 70.3668 44.4178 70.2139 44.0875 69.908C43.7571 69.6021 43.5919 69.2228 43.5919 68.7701C43.5919 68.3174 43.7571 67.9381 44.0875 67.6322C44.4178 67.3263 44.8461 67.1733 45.3722 67.1733C45.8983 67.1733 46.3266 67.3202 46.6569 67.6138C46.9873 67.9075 47.1525 68.2745 47.1525 68.715C47.1525 69.1922 46.9873 69.5899 46.6569 69.908C46.3266 70.2139 45.8983 70.3668 45.3722 70.3668Z'
          fill='#0B0C0E'
        />
        <path
          d='M37.3366 71.5964C38.5724 71.5964 39.5512 71.9635 40.2731 72.6976C41.0073 73.4195 41.3743 74.5085 41.3743 75.9645V81.6174H38.5112V76.405C38.5112 75.6219 38.346 75.0407 38.0157 74.6614C37.6975 74.2699 37.2387 74.0741 36.6392 74.0741C35.9662 74.0741 35.434 74.2944 35.0424 74.7349C34.6509 75.1631 34.4551 75.8055 34.4551 76.662V81.6174H31.592V76.405C31.592 74.8511 30.968 74.0741 29.7199 74.0741C29.0592 74.0741 28.5331 74.2944 28.1416 74.7349C27.75 75.1631 27.5543 75.8055 27.5543 76.662V81.6174H24.6911V71.7433H27.4258V72.8812C27.7928 72.4652 28.2394 72.147 28.7656 71.9268C29.3039 71.7066 29.8912 71.5964 30.5275 71.5964C31.2249 71.5964 31.8551 71.7371 32.4179 72.0186C32.9807 72.2877 33.4334 72.6854 33.776 73.2115C34.1798 72.6976 34.6876 72.3 35.2994 72.0186C35.9234 71.7371 36.6025 71.5964 37.3366 71.5964Z'
          fill='#0B0C0E'
        />
        <path
          d='M18.0865 79.47C18.6004 79.47 19.0531 79.3966 19.4446 79.2498C19.8484 79.0907 20.2216 78.846 20.5642 78.5156L22.0875 80.1674C21.1576 81.2319 19.7995 81.7642 18.0131 81.7642C16.8996 81.7642 15.9147 81.5501 15.0582 81.1218C14.2017 80.6813 13.541 80.0757 13.076 79.3048C12.6111 78.534 12.3786 77.6592 12.3786 76.6803C12.3786 75.7137 12.605 74.845 13.0577 74.0741C13.5226 73.2911 14.1528 72.6854 14.9481 72.2572C15.7556 71.8167 16.6611 71.5964 17.6644 71.5964C18.6065 71.5964 19.463 71.7983 20.2338 72.2021C21.0047 72.5936 21.6165 73.1687 22.0692 73.9273C22.5341 74.6737 22.7666 75.5608 22.7666 76.5885L15.462 78.0018C15.67 78.4912 15.9942 78.8582 16.4347 79.103C16.8874 79.3477 17.438 79.47 18.0865 79.47ZM17.6644 73.7621C16.9425 73.7621 16.3552 73.9946 15.9024 74.4596C15.4497 74.9245 15.2111 75.5669 15.1867 76.3867L19.9952 75.4506C19.8607 74.9367 19.5854 74.5269 19.1693 74.221C18.7533 73.9151 18.2517 73.7621 17.6644 73.7621Z'
          fill='#0B0C0E'
        />
        <path
          d='M5.83205 81.8376C4.8165 81.8376 3.83153 81.703 2.87716 81.4339C1.93502 81.1524 1.17642 80.7915 0.601345 80.351L1.61078 78.1119C2.16138 78.5157 2.81598 78.8399 3.57459 79.0846C4.33319 79.3293 5.0918 79.4517 5.8504 79.4517C6.69466 79.4517 7.31867 79.3293 7.72245 79.0846C8.12622 78.8277 8.32811 78.4912 8.32811 78.0752C8.32811 77.7693 8.20575 77.5185 7.96104 77.3227C7.72856 77.1147 7.42267 76.9495 7.04337 76.8272C6.6763 76.7048 6.17465 76.5702 5.5384 76.4234C4.55955 76.1909 3.75812 75.9584 3.13411 75.726C2.51009 75.4935 1.97173 75.1203 1.51901 74.6064C1.07853 74.0925 0.858292 73.4073 0.858292 72.5508C0.858292 71.8045 1.06018 71.1315 1.46395 70.532C1.86773 69.9202 2.47339 69.4369 3.28093 69.0821C4.10072 68.7272 5.09792 68.5498 6.27253 68.5498C7.09231 68.5498 7.89374 68.6477 8.67682 68.8435C9.4599 69.0392 10.1451 69.3206 10.7324 69.6877L9.81473 71.9452C8.62788 71.2722 7.44103 70.9357 6.25418 70.9357C5.42216 70.9357 4.80426 71.0703 4.40049 71.3395C4.00895 71.6087 3.81318 71.9635 3.81318 72.404C3.81318 72.8445 4.03954 73.1748 4.49226 73.3951C4.95721 73.6031 5.66075 73.8111 6.60289 74.0191C7.58174 74.2516 8.38317 74.4841 9.00718 74.7165C9.63119 74.949 10.1634 75.3161 10.6039 75.8177C11.0566 76.3194 11.283 76.9985 11.283 77.855C11.283 78.5891 11.075 79.262 10.659 79.8738C10.2552 80.4734 9.64343 80.9506 8.82365 81.3054C8.00386 81.6602 7.00666 81.8376 5.83205 81.8376Z'
          fill='#0B0C0E'
        />
        <path
          d='M77.6383 46.3445C77.0523 45.5775 76.3193 44.935 75.4821 44.4545C74.645 43.9741 73.7204 43.6654 72.7626 43.5463C69.0931 43.0404 65.7732 41.1025 63.5281 38.1563C61.2831 35.21 60.2956 31.4948 60.7813 27.8227C60.9674 26.4036 60.73 24.9611 60.0989 23.6764C59.4678 22.3917 58.4713 21.3221 57.2344 20.602C55.9974 19.8819 54.5752 19.5434 53.1464 19.6289C51.7177 19.7144 50.3461 20.2201 49.2039 21.0826C48.0616 21.9451 47.1997 23.1258 46.7263 24.4766C46.253 25.8274 46.1892 27.2878 46.5432 28.6747C46.8971 30.0615 47.6531 31.3129 48.7159 32.2715C49.7786 33.2302 51.1011 33.8535 52.5169 34.063C54.3458 34.3027 56.1093 34.9011 57.7064 35.8238C59.3035 36.7463 60.7029 37.9752 61.8242 39.4396C62.9455 40.9041 63.7667 42.5756 64.2408 44.358C64.715 46.1405 64.8326 47.9991 64.5871 49.8271C64.4503 51.0036 64.6033 52.1957 65.0328 53.2995C65.4623 54.4032 66.1553 55.3852 67.0513 56.1599C67.9472 56.9345 69.019 57.4782 70.1733 57.7437C71.3276 58.0092 72.5292 57.9882 73.6735 57.6829C74.818 57.3776 75.8702 56.7969 76.7388 55.9917C77.6074 55.1864 78.2658 54.1811 78.6568 53.0631C79.0476 51.945 79.1593 50.7483 78.9818 49.5773C78.8043 48.4063 78.3431 47.2964 77.6383 46.3445Z'
          fill='#FF7438'
        />
        <path
          d='M32.4344 42.3964C33.763 42.9708 35.2328 43.1351 36.6553 42.8683C38.0779 42.6015 39.3882 41.9157 40.4184 40.899C41.4485 39.8822 42.1513 38.5809 42.4366 37.162C42.722 35.7431 42.5769 34.2712 42.0199 32.9354C41.313 31.2316 40.9493 29.4051 40.9496 27.5605C40.95 25.716 41.3143 23.8896 42.022 22.1862C42.7296 20.4828 43.7664 18.9357 45.0731 17.6337C46.3797 16.3318 47.9305 15.3006 49.6365 14.5992C50.9589 14.0478 52.0886 13.1175 52.8836 11.9256C53.6785 10.7336 54.1032 9.3331 54.104 7.90031C54.1049 6.46751 53.682 5.06645 52.8885 3.87356C52.0949 2.68058 50.9663 1.74897 49.6446 1.19599C48.3228 0.643012 46.867 0.493265 45.4605 0.765754C44.0538 1.03816 42.7592 1.72053 41.7395 2.72707C40.7197 3.73352 40.0205 5.01916 39.7297 6.4221C39.439 7.82504 39.5697 9.28265 40.1055 10.6115C41.5063 14.0404 41.4917 17.8847 40.0649 21.3029C38.638 24.7211 35.9151 27.4348 32.4922 28.8501C31.1384 29.381 29.9751 30.3061 29.1529 31.5056C28.3308 32.7052 27.8877 34.1239 27.8809 35.578C27.8743 37.0323 28.3043 38.455 29.1154 39.662C29.9265 40.8691 31.0811 41.8048 32.43 42.3482L32.4344 42.3964Z'
          fill='#FF7438'
        />
        <path
          d='M50.9374 39.5073C49.9789 39.6293 49.0544 39.9413 48.218 40.4251C47.3815 40.9089 46.6501 41.5546 46.0663 42.3246C43.7957 45.2484 40.4599 47.1543 36.7881 47.6256C33.1163 48.0969 29.4072 47.0951 26.4718 44.8395C25.7125 44.2477 24.8432 43.8127 23.9144 43.5599C22.9856 43.307 22.0158 43.2414 21.0613 43.3667C19.8874 43.5305 18.7713 43.9792 17.8106 44.6735C16.8499 45.3679 16.0738 46.2868 15.55 47.35C15.0261 48.4133 14.7705 49.5887 14.8053 50.7735C14.8402 51.9583 15.1645 53.1166 15.7501 54.1472C16.3356 55.1778 17.1644 56.0495 18.1643 56.6861C19.1642 57.3228 20.3046 57.7049 21.4862 57.7993C22.6677 57.8937 23.8545 57.6976 24.9428 57.2279C26.0311 56.7582 26.9879 56.0293 27.7296 55.1047C28.8526 53.6403 30.2535 52.4119 31.8519 51.4898C33.4505 50.5677 35.2152 49.9699 37.045 49.7309C38.8749 49.4919 40.734 49.6162 42.5157 50.0968C44.2975 50.5775 45.9668 51.4048 47.4283 52.5316C48.3777 53.2434 49.4871 53.7117 50.6594 53.8954C51.8317 54.0793 53.0311 53.9731 54.1529 53.5862C55.2746 53.1992 56.2844 52.5434 57.094 51.6759C57.9037 50.8084 58.4884 49.7556 58.7971 48.6099C59.1058 47.4641 59.1291 46.2603 58.8649 45.1034C58.6007 43.9466 58.0571 42.8722 57.2815 41.974C56.506 41.0759 55.5223 40.3815 54.4164 39.9514C53.3104 39.5215 52.1159 39.3689 50.9374 39.5073Z'
          fill='#FF7438'
        />
      </svg>
    ) : (
      <svg
        width='36'
        height='32'
        viewBox='0 0 36 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M33.8926 24.7084C33.5802 24.2995 33.1894 23.957 32.743 23.7008C32.2967 23.4447 31.8038 23.2801 31.2931 23.2166C29.3367 22.9469 27.5667 21.9137 26.3698 20.3429C25.1729 18.7721 24.6464 16.7914 24.9053 14.8336C25.0045 14.077 24.878 13.3079 24.5415 12.623C24.205 11.9381 23.6737 11.3678 23.0143 10.9839C22.3548 10.6 21.5966 10.4195 20.8348 10.4651C20.0731 10.5107 19.3418 10.7803 18.7328 11.2401C18.1238 11.7 17.6643 12.3295 17.4119 13.0496C17.1596 13.7698 17.1256 14.5484 17.3143 15.2878C17.503 16.0272 17.906 16.6944 18.4727 17.2055C19.0393 17.7166 19.7443 18.0489 20.4992 18.1606C21.4742 18.2884 22.4144 18.6074 23.2659 19.0994C24.1174 19.5912 24.8635 20.2464 25.4613 21.0271C26.0591 21.808 26.497 22.6991 26.7498 23.6494C27.0026 24.5997 27.0653 25.5906 26.9344 26.5652C26.8614 27.1925 26.943 27.828 27.172 28.4165C27.401 29.005 27.7704 29.5285 28.2481 29.9415C28.7258 30.3545 29.2972 30.6444 29.9126 30.7859C30.528 30.9275 31.1687 30.9163 31.7788 30.7535C32.3889 30.5907 32.95 30.2812 33.413 29.8519C33.8761 29.4225 34.2271 28.8865 34.4356 28.2904C34.644 27.6943 34.7035 27.0564 34.6089 26.432C34.5142 25.8077 34.2684 25.216 33.8926 24.7084Z'
          fill='rgba(255, 255, 255, 0.56)'
        />
        <path
          d='M9.79201 22.6034C10.5003 22.9096 11.2839 22.9973 12.0424 22.855C12.8008 22.7128 13.4994 22.3471 14.0486 21.8051C14.5978 21.263 14.9726 20.5692 15.1247 19.8127C15.2768 19.0562 15.1995 18.2715 14.9025 17.5593C14.5256 16.6509 14.3317 15.6771 14.3319 14.6937C14.3321 13.7103 14.5263 12.7366 14.9036 11.8284C15.2808 10.9202 15.8336 10.0954 16.5303 9.40125C17.2269 8.7071 18.0537 8.15735 18.9632 7.78338C19.6683 7.48941 20.2706 6.99344 20.6944 6.35794C21.1183 5.72244 21.3447 4.97577 21.3451 4.21188C21.3456 3.44799 21.1201 2.70102 20.697 2.06503C20.274 1.429 19.6722 0.93231 18.9676 0.637489C18.2628 0.342672 17.4867 0.262834 16.7368 0.408112C15.9869 0.553346 15.2966 0.917146 14.753 1.45378C14.2093 1.99037 13.8365 2.6758 13.6815 3.42378C13.5265 4.17176 13.5962 4.94888 13.8818 5.65733C14.6287 7.48546 14.6209 9.53506 13.8602 11.3574C13.0994 13.1798 11.6477 14.6266 9.8228 15.3812C9.10102 15.6643 8.4808 16.1575 8.04249 16.797C7.60417 17.4366 7.3679 18.1929 7.36432 18.9682C7.36078 19.7435 7.59004 20.5021 8.02246 21.1456C8.45488 21.7891 9.07051 22.288 9.78965 22.5777L9.79201 22.6034Z'
          fill='rgba(255, 255, 255, 0.56)'
        />
        <path
          d='M19.657 21.0632C19.1459 21.1283 18.6531 21.2946 18.2071 21.5526C17.7612 21.8105 17.3712 22.1547 17.0599 22.5653C15.8494 24.1241 14.0709 25.1402 12.1133 25.3915C10.1557 25.6427 8.17815 25.1087 6.61317 23.9061C6.20836 23.5905 5.7449 23.3586 5.24968 23.2238C4.75451 23.089 4.23745 23.054 3.72858 23.1209C3.1027 23.2082 2.50765 23.4474 1.99548 23.8176C1.48326 24.1878 1.0695 24.6777 0.790223 25.2445C0.510906 25.8114 0.374639 26.4381 0.393213 27.0698C0.411838 27.7015 0.584731 28.319 0.896905 28.8685C1.20907 29.4179 1.65095 29.8827 2.18404 30.2221C2.71712 30.5615 3.32514 30.7652 3.95509 30.8156C4.58503 30.8659 5.21777 30.7614 5.79797 30.5109C6.37823 30.2605 6.88833 29.8719 7.28377 29.379C7.88251 28.5982 8.62936 27.9433 9.48158 27.4517C10.3338 26.96 11.2747 26.6413 12.2503 26.5139C13.2259 26.3865 14.217 26.4528 15.167 26.709C16.1169 26.9653 17.0069 27.4064 17.7861 28.0071C18.2923 28.3866 18.8837 28.6362 19.5087 28.7342C20.1338 28.8323 20.7732 28.7757 21.3713 28.5694C21.9693 28.3631 22.5077 28.0134 22.9393 27.5509C23.371 27.0884 23.6827 26.5271 23.8474 25.9163C24.0119 25.3054 24.0243 24.6636 23.8835 24.0468C23.7426 23.43 23.4528 22.8572 23.0393 22.3783C22.6258 21.8995 22.1014 21.5293 21.5118 21.3C20.9221 21.0708 20.2853 20.9895 19.657 21.0632Z'
          fill='rgba(255, 255, 255, 0.56)'
        />
      </svg>
    )
);

export default Logo;

import { useEffect } from 'react';
import { useOutlet, Navigate, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { UnknownAction } from '@reduxjs/toolkit';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Header from '../Header';
import { useAuth } from './AuthProvider';
import ROUTES from './constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getUserProfile } from '../../../store/user/user.thunks';
import { getServerTime } from '../../../store/app/app.thunks';
import { Vehicle } from '../../../domain/vehicle';
import { setIsUserOnline } from '../../../store/app/app.slice';
import { socketDisconnect } from '../../../infra/common/socketService';
import { cleanUpVehiclePage } from '../../../store/vehicle/vehicle.slice';

const ProtectedLayout = () => {
  const dispatch = useAppDispatch();
  const { authData }: any = useAuth();
  const outlet = useOutlet();
  const navigate = useNavigate();

  const userData = useAppSelector((state) => state.user.userData);
  const activeVehicle = useAppSelector((state) => state.vehicles.activeVehicle);
  const getPingSuccess = useAppSelector((state) => state.app.getPingSuccess);
  const isUserOnline = useAppSelector((state) => state.app.isUserOnline);
  const connectToVehicleSuccess = useAppSelector(
    (state) => state.vehicles.connectToVehicleSuccess
  );
  const collapseAdditionalInfoSection = useAppSelector(
    (state) => state.app.collapseAdditionalInfoSection
  );

  const { t } = useTranslation();

  const connectionOffline = async () => {
    dispatch(setIsUserOnline(false));
  };
  const connectionOnline = async () => {
    dispatch(setIsUserOnline(true));
  };

  useEffect(() => {
    dispatch(getServerTime() as unknown as UnknownAction);
    dispatch(getUserProfile() as unknown as UnknownAction);

    window.addEventListener('offline', connectionOffline);
    window.addEventListener('online', connectionOnline);

    return () => {
      window.removeEventListener('offline', connectionOffline);
      window.removeEventListener('online', connectionOnline);
    };
  }, []);

  useEffect(() => {
    if (!getPingSuccess || !isUserOnline) {
      const reconnectSocket = async () => {
        await socketDisconnect();
        dispatch(cleanUpVehiclePage());
      };

      reconnectSocket();
    }
  }, [getPingSuccess, isUserOnline]);

  useEffect(() => {
    if (userData) {
      if (!userData.is_signed) {
        navigate(ROUTES.LICENSE);
        return;
      }

      if (
        !userData.permissions.use_remote_control &&
        !userData.permissions.use_visual_navigation
      ) {
        navigate('/no-access');
      }
    }
  }, [userData]);

  if (!authData && !userData) {
    return <Navigate to={ROUTES.AUTH} />;
  }

  const setOpenBackdropStatus = (
    isOperatorOffline: boolean,
    activeVehicle: Vehicle | null,
    connectToVehicleSuccess: boolean
  ): boolean => {
    if (isOperatorOffline) {
      return true;
    }

    if (activeVehicle) {
      if (!activeVehicle.is_online) {
        return true;
      }

      if (!connectToVehicleSuccess) {
        return true;
      }
    }

    return false;
  };

  const displayOfflineMessage = (
    isOperatorOffline: boolean,
    activeVehicle: Vehicle | null,
    connectToVehicleSuccess: boolean
  ) => {
    if (isOperatorOffline) {
      return t('authorizedUserMessages.clientNoConnection');
    }

    if (activeVehicle) {
      if (!activeVehicle.is_online) {
        return t('authorizedUserMessages.deviceNoConnection');
      }

      if (!connectToVehicleSuccess) {
        return t('authorizedUserMessages.vehicleConnecting');
      }
    }

    return '';
  };

  return userData && userData.is_signed ? (
    <>
      {!collapseAdditionalInfoSection ? <Header /> : null}
      <Box>
        <Backdrop
          className='backdrop'
          open={setOpenBackdropStatus(
            !getPingSuccess || !isUserOnline,
            activeVehicle,
            connectToVehicleSuccess
          )}
        >
          <Typography variant='body1' className='backdropMessage'>
            {displayOfflineMessage(
              !getPingSuccess || !isUserOnline,
              activeVehicle,
              connectToVehicleSuccess
            )}
          </Typography>
          <CircularProgress color='inherit' />
        </Backdrop>
        {outlet}
      </Box>
    </>
  ) : null;
};

export default ProtectedLayout;

import { ConfirmPasswordUserData, UserRepository } from '../../domain/user';

type Dependencies = {
  userRepository: UserRepository;
  validateUserPassword: (password: string) => boolean;
};

export default ({ userRepository, validateUserPassword }: Dependencies) =>
  async (userData: ConfirmPasswordUserData) => {
    if (!validateUserPassword(userData.newPassword)) {
      throw new Error('Invalid new password format');
    }

    if (!validateUserPassword(userData.confirmedPassword)) {
      throw new Error('Invalid confirmed password format');
    }

    if (userData.newPassword !== userData.confirmedPassword) {
      throw new Error('Passwords do not match');
    }

    try {
      return await userRepository.createPasswordConfirmRequest(userData);
    } catch (error) {
      throw error;
    }
  };

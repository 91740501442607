import { FC, memo, MouseEvent, ReactElement, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { UnknownAction } from '@reduxjs/toolkit';
import {
  deleteStreamingRecording,
  listStreamingRecordings,
} from '../../../../store/vehicle/vehicle.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store';
import displayVideoName from '../../helpers/displayVideoName';
import classes from '../index.module.css';

interface IProps {
  recordingURL: string;
}

const SingleVideoDelete: FC<IProps> = memo(({ recordingURL }): ReactElement => {
  const dispatch = useAppDispatch();
  const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
  const deleteRecordingLoading = useAppSelector(
    (state) => state.vehicles.deleteRecordingLoading
  );
  const recordingsLimit = useAppSelector(
    (state) => state.vehicles.mediaList.recordingsLimit
  );
  const recordingsPage = useAppSelector(
    (state) => state.vehicles.mediaList.recordingsPage
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (): void => {
    setAnchorEl(null);
  };

  const removeRecordingHandler = (recordingURL: string): void => {
    if (deleteRecordingLoading) {
      return;
    }

    dispatch(
      deleteStreamingRecording({
        uuid,
        names: recordingURL,
        onSuccess: () => {
          dispatch(
            listStreamingRecordings({
              uuid,
              limit: recordingsLimit,
              page: recordingsPage,
            }) as unknown as UnknownAction
          );
          closePopover();
        },
      }) as unknown as UnknownAction
    );
  };

  return (
    <>
      <IconButton aria-describedby={recordingURL} onClick={openPopover}>
        <DeleteIcon />
      </IconButton>
      <Popover
        id={recordingURL}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.deletePopover}>
          <Typography sx={{ p: 1 }} variant='caption'>
            Are you sure you want to delete this video?
          </Typography>
          <Box className={classes.deletePopoverButtons}>
            <Button variant='text' onClick={closePopover}>
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                removeRecordingHandler(displayVideoName(recordingURL));
              }}
              color='error'
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
});

export default SingleVideoDelete;

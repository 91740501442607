import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';

interface TimerProps {
  createPasswordRecoveryError: number;
}
let timerId: NodeJS.Timeout;
const PasswordRequestTimer: FC<TimerProps> = ({
  createPasswordRecoveryError,
}) => {
  const timerNumber = Number(createPasswordRecoveryError);
  const [time, setTime] = useState<number>(timerNumber);

  useEffect(() => {
    timerId = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [createPasswordRecoveryError]);

  useEffect(() => {
    if (time <= 0) {
      clearInterval(timerId);
    }
  }, [time]);

  const formatTime = (seconds: number) => {
    if (seconds < 60) {
      return `${seconds} seconds`;
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minutes ${remainingSeconds} seconds`;
  };

  return (
    <Box sx={{ mt: '2rem', textAlign: 'center' }}>
      <Typography variant='body1'>
        {t('resetPasswordRequest.passwordRequestTimer')}
        <Box sx={{ fontWeight: 700 }}>{formatTime(time)}</Box>
      </Typography>
    </Box>
  );
};

export default PasswordRequestTimer;

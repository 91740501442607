import { FC, memo, ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { carElementControlCommand } from '../../../../infra/common/socketService/commands/carControlCommands';
import { socketSend } from '../../../../infra/common/socketService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import LightsIcon from '../../../assets/LightsIcon';
import WipersDryIcon from '../../../assets/WipersDryIcon';
import WipersWetIcon from '../../../assets/WipersWetIcon';
import classes from '../../../styles/vehicle.module.css';

const CarElementsControls: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);

  const [sweepersDryOn, setSweepersDryOn] = useState<boolean>(false);
  const [sweepersWetOn, setSweepersWetOn] = useState<boolean>(false);
  const [lightsOn, setLightsOn] = useState<boolean>(false);
  const [sweepersTypeVisible, setSweepersTypeVisible] =
    useState<boolean>(false);
  const [dry, setDry] = useState<boolean>(false);

  const { t } = useTranslation();

  const toggleDrySweepers = async (sweepersDryOn: boolean): Promise<void> => {
    await setSweepersDryOn(sweepersDryOn);
    await socketSend(
      carElementControlCommand(
        sweepersDryOn ? 'car_sweepers_dry_on' : 'car_sweepers_dry_off'
      ),
      timeProcessing
    );
  };

  const toggleWetSweepers = async (sweepersWetOn: boolean): Promise<void> => {
    await setSweepersWetOn(sweepersWetOn);
    await socketSend(
      carElementControlCommand(
        sweepersWetOn ? 'car_sweepers_wet_on' : 'car_sweepers_wet_off'
      ),
      timeProcessing
    );
  };

  const toggleLights = async (lightsOn: boolean): Promise<void> => {
    await setLightsOn(lightsOn);
    await socketSend(
      carElementControlCommand(lightsOn ? 'car_lights_on' : 'car_lights_off'),
      timeProcessing
    );
  };

  return (
    <>
      <Box className={classes.wipers}>
        {sweepersTypeVisible ? (
          <Box className={classes.sweepersType}>
            <Typography
              variant='caption'
              noWrap
              className={
                !dry ? classes.sweepersTypeItem : classes.sweepersNonTypeItem
              }
              onClick={() => {
                setDry(false);
                if (sweepersDryOn) {
                  setSweepersDryOn(false);
                }
                setSweepersTypeVisible(false);
              }}
            >
              {t('vehicle.wetLabel')}
            </Typography>
            <Typography
              variant='caption'
              noWrap
              className={
                dry ? classes.sweepersTypeItem : classes.sweepersNonTypeItem
              }
              onClick={() => {
                setDry(true);

                if (sweepersWetOn) {
                  setSweepersWetOn(false);
                }

                setSweepersTypeVisible(false);
              }}
            >
              {t('vehicle.dryLabel')}
            </Typography>
          </Box>
        ) : null}
        <IconButton
          size='large'
          className={!sweepersDryOn ? classes.lightsOn : classes.lightsOff}
          onClick={() =>
            dry
              ? toggleDrySweepers(!sweepersDryOn)
              : toggleWetSweepers(!sweepersWetOn)
          }
        >
          {dry ? (
            <WipersDryIcon on={sweepersDryOn} />
          ) : (
            <WipersWetIcon on={sweepersWetOn} />
          )}
        </IconButton>
        <Box className={classes.startEngineIconWrapper}>
          <svg
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ paddingRight: '0.5rem' }}
            onClick={() => setSweepersTypeVisible(!sweepersTypeVisible)}
            className={classes.fullWidthHeight}
          >
            <path
              d='M6.71002 14.71C7.10002 15.1 7.73002 15.1 8.12002 14.71L12 10.83L15.88 14.71C16.27 15.1 16.9 15.1 17.29 14.71C17.68 14.32 17.68 13.69 17.29 13.3L12.7 8.71C12.31 8.32 11.68 8.32 11.29 8.71L6.70002 13.3C6.32002 13.68 6.32002 14.32 6.71002 14.71Z'
              fill='white'
              fillOpacity='0.56'
            />
          </svg>
        </Box>
        <Typography variant='caption' noWrap className={classes.commandText}>
          {!sweepersDryOn
            ? t('vehicle.wipersOnLabel')
            : t('vehicle.wipersOffLabel')}
        </Typography>
      </Box>
      <Box className={classes.controlItemContainer}>
        <IconButton
          size='large'
          className={!lightsOn ? classes.lightsOn : classes.lightsOff}
          onClick={() => toggleLights(!lightsOn)}
        >
          <LightsIcon on={lightsOn} />
        </IconButton>
        <Typography variant='caption' noWrap className={classes.commandText}>
          {!lightsOn ? t('vehicle.lightsOnLabel') : t('vehicle.lightsOffLabel')}
        </Typography>
      </Box>
    </>
  );
});

export default CarElementsControls;

import { FC, memo, ReactElement, SyntheticEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import classes from './index.module.css';

interface IProps {
  activeTab: number;
  changeTab: (_: SyntheticEvent, tab: number) => void;
}

const MediaTabs: FC<IProps> = memo(({ activeTab, changeTab }): ReactElement => {
  const { t } = useTranslation();
  return (
    <Box className={classes.tabsContainer}>
      <Tabs value={activeTab} onChange={changeTab} centered>
        <Tab label={t('vehicle.videosLabel')} />
        <Tab label={t('vehicle.photosLabel')} />
        <Tab label={t('vehicle.slidesLabel')} />
      </Tabs>
    </Box>
  );
});

export default MediaTabs;

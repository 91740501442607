import { UserRepository } from '../../domain/user';

type Dependencies = {
  userRepository: UserRepository;
  validateUserEmail: (email: string) => boolean;
};

export default ({ userRepository, validateUserEmail }: Dependencies) =>
  async (email: string) => {
    if (!validateUserEmail(email)) {
      throw new Error('Invalid email format');
    }

    try {
      return await userRepository.createPasswordRecoveryRequest(email);
    } catch (error) {
      throw error;
    }
  };

import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import GamepadStickLeft from './GamepadStickLeft';
import GamepadStickRight from './GamepadStickRight';
import classes from '../../../../styles/vehicle.module.css';
import {
  VEHICLE_TYPES,
  vehicleActionsByType,
} from '../../../../../domain/vehicle';
import { useAppSelector } from '../../../../../store';

const GamepadControls: FC = memo((): ReactElement | null => {
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const gamepadRunning = useAppSelector(
    (state) => state.vehicles.gamepadRunning
  );
  const controller = useAppSelector((state) => state.vehicles.controller);

  if (
    !vehicleActionsByType[vehicleType as VEHICLE_TYPES]?.gamepadAvailable ||
    !gamepadRunning
  ) {
    return null;
  }

  return (
    <Box className={classes.gamepadContainer}>
      {vehicleType === VEHICLE_TYPES.TRACTOR_KHTI &&
      controller === 'wheel' ? null : (
        <>
          <GamepadStickLeft />
          <GamepadStickRight />
        </>
      )}
    </Box>
  );
});

export default GamepadControls;

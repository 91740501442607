import {
  ReactElement,
  FC,
  memo,
  useState,
  MouseEvent,
  CSSProperties,
} from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { useAuth } from './router/AuthProvider';
import Logo from '../assets/Logo';
import { useAppSelector } from '../../store';
import { VEHICLE_TYPES } from '../../domain/vehicle';
import DroneIcon from '../assets/Drone';
import TractorIcon from '../assets/Tractor';
import CarIcon from '../assets/Car';
import BoatIcon from '../assets/Boat';
import classes from './header/header.module.css';

const StyledBadge = styled(Badge)(({ theme }: any) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    marginTop: 8,
    width: 137,
  },
}));

const Header: FC = memo((): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout }: any = useAuth();
  const { t } = useTranslation();
  const activeVehicleName = useAppSelector(
    (state) => state.vehicles.activeVehicle?.name
  );
  const isOnline = useAppSelector(
    (state) => state.vehicles.activeVehicle?.is_online
  );
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const userData = useAppSelector((state) => state.user.userData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: MouseEvent<HTMLButtonElement>): void =>
    setAnchorEl(event.currentTarget);

  const closeMenu = (): void => setAnchorEl(null);

  const displayVehicleType = (vehicleType: VEHICLE_TYPES) => {
    if (vehicleType === VEHICLE_TYPES.DRONE) {
      return <DroneIcon />;
    }

    if (
      vehicleType === VEHICLE_TYPES.TRACTOR ||
      vehicleType === VEHICLE_TYPES.TRACTOR_KHTI
    ) {
      return <TractorIcon />;
    }

    if (vehicleType === VEHICLE_TYPES.CAR) {
      return <CarIcon />;
    }

    return <BoatIcon />;
  };

  const setActiveTabStyles = (pathname: string): CSSProperties => {
    if (location.pathname.includes(pathname)) {
      return {
        color: 'rgba(255, 255, 255, 1)',
        borderBottom: '1px solid rgba(255, 255, 255, 1)',
      };
    }

    return {};
  };

  const redirectToOns = () => {
    navigate('/ons/history');
  };

  return (
    <AppBar position='relative' enableColorOnDark className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.breadcrumbs}>
          <Logo orientation='horizontal' />
          {location.search.includes('role') ? (
            <Box className={classes.breadcrumbs}>
              <Typography
                variant='h6'
                className='textDecorationNoneForDeviceLink'
                onClick={() => navigate(-1)}
              >
                {t('header.devices')}
              </Typography>
              <svg
                width='8'
                height='10'
                viewBox='0 0 8 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.08748 0L0.912476 1.175L4.72914 5L0.912476 8.825L2.08748 10L7.08748 5L2.08748 0Z'
                  fill='white'
                  fillOpacity='0.56'
                />
              </svg>
              {displayVehicleType(vehicleType)}
              <Typography
                variant='h6'
                sx={{ color: '#fff', marginLeft: '-6px' }}
              >
                {activeVehicleName}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box className={classes.tabs}>
          {userData && userData.permissions.use_remote_control ? (
            <Link
              to='/vehicles/list'
              className='textDecorationNoneForDeviceLink'
            >
              <Typography variant='body1' sx={setActiveTabStyles('vehicles')}>
                Remote Control
              </Typography>
            </Link>
          ) : null}
          {userData && userData.permissions.use_visual_navigation ? (
            <Typography
              variant='body1'
              sx={setActiveTabStyles('ons')}
              className='textDecorationNoneForDeviceLink'
              onClick={redirectToOns}
            >
              ONS
            </Typography>
          ) : null}
          <StyledBadge
            id='badge-control'
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant='dot'
            onClick={openMenu}
          >
            <Avatar alt='Remy Sharp' src='' />
          </StyledBadge>
          <StyledMenu
            id='basic-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'badge-control',
            }}
          >
            <MenuItem onClick={closeMenu} className={classes.menuItem}>
              <PersonOutlineIcon fontSize='small' />
              <Typography variant='caption' sx={{ color: '#14151A' }}>
                {t('header.profile')}
              </Typography>
            </MenuItem>
            <MenuItem onClick={logout} className={classes.menuItem}>
              <LogoutIcon fontSize='small' sx={{ color: '#E6483D' }} />
              <Typography variant='caption' sx={{ color: '#E6483D' }}>
                {t('header.logout')}
              </Typography>
            </MenuItem>
          </StyledMenu>
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default Header;

const ROUTES = {
  EMPTY: '/',
  AUTH: '/auth',
  RECOVERY: '/reset',
  RESTORE: '/restore',
  LICENSE: '/license',
  VEHICLES: {
    MAIN: 'vehicles',
    LIST: 'list',
    ID: ':vehicleUuid',
  },
  ONS: {
    MAIN: 'ons',
    HISTORY: 'history',
    CREATE: 'create',
  },
  NO_ACCESS: 'no-access',
};

export default ROUTES;

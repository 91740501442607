import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import { REACT_APP_STREAM_BASE_URL } from '../../../../infra/common/streamService/constants';
import SingleVideoDelete from './SingleVideoDelete';
import displayVideoName from '../../helpers/displayVideoName';
import downloadImage from '../../helpers/downloadImage';
import classes from '../index.module.css';

interface IProps {
  recordingURL: string;
}

const SingleVideo: FC<IProps> = memo(
  ({ recordingURL }): ReactElement => (
    <Box>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        src={`${REACT_APP_STREAM_BASE_URL.slice(0, -1)}${recordingURL}`}
        width='100%'
        height={250}
        className={classes.mediaListSingleVideo}
        controls
        controlsList='nodownload noremoteplayback noplaybackrate'
        disablePictureInPicture
        preload='metadata'
      />
      <Box className={classes.videoDescription}>
        <Typography variant='caption'>
          {displayVideoName(recordingURL)}
        </Typography>
        <SingleVideoDelete recordingURL={recordingURL} />
        <IconButton
          onClick={() =>
            downloadImage(
              `${REACT_APP_STREAM_BASE_URL.slice(0, -1)}${recordingURL}`
            )
          }
        >
          <DownloadIcon />
        </IconButton>
      </Box>
    </Box>
  )
);

export default SingleVideo;

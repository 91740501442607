import { FC, ReactElement, memo } from 'react';

const Ukraine: FC = memo(
  (): ReactElement => (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_203_6504)'>
        <path
          d='M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24Z'
          fill='#FFDA44'
        />
        <path
          d='M0.5 12C0.5 5.37262 5.87262 0 12.5 0C19.1274 0 24.5 5.37262 24.5 12'
          fill='#338AF3'
        />
      </g>
      <defs>
        <clipPath id='clip0_203_6504'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
);

export default Ukraine;

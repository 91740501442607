// LOCALSTORAGE CONSTANTS
export const ACCESS_TOKEN_STREAM = 'Authorization';
export const LOCAL_STORAGE_AUTH_KEY = 'auth';
export const REFRESH_TOKEN = '/auth/refresh';
export const RECORD_START = 'api/record/start';
export const RECORD_STOP = 'api/record/stop';
export const RECORD_LIST = 'api/record/list';
export const PHOTO_LIST = '/gallery/photos';
export const SLIDE_LIST = '/gallery/slides';
export const ARCHIVE = '/gallery/archive';
export const ARCHIVE_LIST = '/gallery/archive/list';

// TODO: fix prod endpoint
export const REACT_APP_STREAM_BASE_URL = process.env
  .REACT_APP_STREAM_BASE_URL as string;

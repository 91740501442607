import { FC, memo, ReactElement, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  setClearAllPhotos,
  setSelectedAllPhotos,
  setSelectedAllSlides,
  setClearAllSlides,
} from '../../../../store/vehicle/vehicle.slice';
import ImagesActionsModal from './ImagesActionsModal';
import ArchivesModal from './ArchivesModal';
import { MediaListTabsEnum } from '../index';
import classes from '../index.module.css';

interface IProps {
  activeTab: MediaListTabsEnum;
}

const ImagesActions: FC<IProps> = memo(({ activeTab }): ReactElement | null => {
  const dispatch = useAppDispatch();
  const selectedPhotosLength = useAppSelector(
    (state) => state.vehicles.mediaList.selectedPhotos.length
  );
  const selectedSlidesLength = useAppSelector(
    (state) => state.vehicles.mediaList.selectedSlides.length
  );
  const listPhotos = useAppSelector((state) => state.vehicles.listPhotos);
  const listSlides = useAppSelector((state) => state.vehicles.listSlides);
  const { t } = useTranslation();

  const selectedImagesLength: number = useMemo(
    (): number =>
      activeTab === MediaListTabsEnum.PhotosTab
        ? selectedPhotosLength
        : selectedSlidesLength,
    [activeTab, selectedPhotosLength, selectedSlidesLength]
  );

  const imagesLength: number = useMemo(
    (): number =>
      activeTab === MediaListTabsEnum.PhotosTab
        ? listPhotos.length
        : listSlides.length,
    [activeTab, listPhotos.length, listSlides.length]
  );

  const [imagesActionsModalVisible, setImagesActionsModalVisible] =
    useState<boolean>(false);

  const selectAllImagesHandler = (): void => {
    if (activeTab === MediaListTabsEnum.PhotosTab) {
      dispatch(setSelectedAllPhotos());
    } else {
      dispatch(setSelectedAllSlides());
    }
  };

  const deselectAllImagesHandler = (): void => {
    if (activeTab === MediaListTabsEnum.PhotosTab) {
      dispatch(setClearAllPhotos());
    } else {
      dispatch(setClearAllSlides());
    }
  };

  const handleClose = (): void => {
    setImagesActionsModalVisible(false);
  };

  const setLabel = (): string => {
    if (activeTab === MediaListTabsEnum.PhotosTab) {
      return `${selectedPhotosLength}`;
    }

    return `${selectedSlidesLength}`;
  };

  return (
    <Stack direction='row' spacing={1}>
      {selectedImagesLength ? (
        <Chip
          className={classes.chipAction}
          label={setLabel()}
          color='primary'
          onClick={() => setImagesActionsModalVisible(true)}
        />
      ) : null}
      {imagesLength === selectedImagesLength ? (
        <Chip
          className={classes.chipAction}
          label={t('vehicle.clearAllLabel')}
          onClick={deselectAllImagesHandler}
        />
      ) : (
        <Chip
          className={classes.chipAction}
          label={t('vehicle.selectAllLabel')}
          onClick={selectAllImagesHandler}
        />
      )}
      <ArchivesModal />
      {imagesActionsModalVisible ? (
        <ImagesActionsModal
          open={imagesActionsModalVisible}
          handleClose={handleClose}
          activeTab={activeTab}
          type={activeTab === MediaListTabsEnum.PhotosTab ? 'photo' : 'slide'}
        />
      ) : null}
    </Stack>
  );
});

export default ImagesActions;

import {
  IJoinMessage,
  IStartMessage,
  IListParticipantsMessage,
  ILeaveMessage,
  ICreateAnswerSchema,
  IPauseMessage,
} from './types';

export const setListParticipantsMessage = (
  room: number
): { message: IListParticipantsMessage } => ({
  message: {
    request: 'listparticipants',
    room,
  },
});

export const setCreateAnswerSchema = (
  sdp: string | undefined,
  type: string | undefined
): ICreateAnswerSchema | any => ({
  jsep: {
    sdp,
    type,
  },
  body: {
    video: true,
  },
  tracks: [{ type: 'data' }],
});

export const setJoinMessage = (
  room: number,
  feed: number
): { message: IJoinMessage } => ({
  message: {
    display: 'aiortc',
    request: 'join',
    ptype: 'subscriber',
    room,
    streams: [
      {
        feed,
      },
    ],
  },
});

export const startMessage: { message: IStartMessage } = {
  message: {
    request: 'start',
  },
};

export const pauseMessage: { message: IPauseMessage } = {
  message: {
    request: 'pause',
  },
};

export const leaveMessage: { message: ILeaveMessage } = {
  message: {
    body: {
      request: 'leave',
    },
  },
};

export const createOpaqueID = (): string => {
  const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return Array.from(
    { length: 12 },
    () => letters[Math.floor(Math.random() * letters.length)]
  ).join('');
};

import { CSSProperties } from 'react';

export const mapStyles = (
  pinnedMap: boolean,
  collapseAdditionalInfoSection: boolean
): CSSProperties => {
  return {
    margin: pinnedMap ? '2rem 2rem 2rem 0' : 0,
    borderRadius: pinnedMap ? '0.5rem' : 0,
  };
};

export const zoomControlStyles = (
  pinnedMap: boolean,
  collapseAdditionalInfoSection: boolean
): CSSProperties => {
  return {
    bottom: pinnedMap && collapseAdditionalInfoSection ? '6.5rem' : '0.5rem',
  };
};

import { FC, memo, ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useTranslation } from 'react-i18next';
import {
  setFullScreenImage,
  setFullScreenImageIndex,
} from '../../../../store/vehicle/vehicle.slice';
import { useAppDispatch } from '../../../../store';
import classes from '../index.module.css';

interface IProps {
  index: number;
}

const SingleImageFullscreen: FC<IProps> = memo(({ index }): ReactElement => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <Tooltip title={t('vehicle.expandLabel')} arrow placement='top'>
      <IconButton
        className={classes.singlePhotoExpand}
        size='small'
        onClick={() => {
          dispatch(setFullScreenImage());
          dispatch(setFullScreenImageIndex(index));
        }}
      >
        <OpenInFullIcon className={classes.singlePhotoIcon} />
      </IconButton>
    </Tooltip>
  );
});

export default SingleImageFullscreen;

import {
  ChangeEvent,
  FC,
  memo,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import TextField from '@mui/material/TextField';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../common/hooks/useDebounce';

const Search: FC = memo((): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams: any = Object.fromEntries([...searchParams]);

  const [value, setValue] = useState<string>(currentParams.title || '');

  const debouncedValue = useDebounce(value, 1000);

  const { t } = useTranslation();

  useEffect(() => {
    if (debouncedValue) {
      const updatedParams: URLSearchParamsInit = {
        ...currentParams,
        title: debouncedValue,
      };

      setSearchParams(updatedParams);
    }
  }, [debouncedValue]);

  const searchByTitle = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const { value } = event.target;

    setValue(value);

    if (!value) {
      delete currentParams.title;
      await setSearchParams(currentParams);
    }
  };

  return (
    <TextField
      size='medium'
      id='outlined-basic'
      label={t('ons.searchLabel')}
      variant='outlined'
      value={value}
      onChange={searchByTitle}
    />
  );
});

export default Search;

import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import classes from './Fallback.module.css';

const Fallback: FC = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <main className={classes.fallbackContainer}>
      <CircularProgress />
      <Typography variant='subtitle1' className={classes.loadingText}>
        {t('init.loadingText')}
      </Typography>
    </main>
  );
};

export default Fallback;

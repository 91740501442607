import { FC, ReactElement, memo } from 'react';

const SlidesIcon: FC = memo((): ReactElement => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 7C2 6.45 2.45 6 3 6C3.55 6 4 6.45 4 7V19C4 19.55 4.45 20 5 20H17C17.55 20 18 20.45 18 21C18 21.55 17.55 22 17 22H4C2.9 22 2 21.1 2 20V7Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H8C6.9 18 6 17.1 6 16V4C6 2.9 6.9 2 8 2ZM9.00007 15.0001H19.0001C19.4101 15.0001 19.6501 14.5301 19.4001 14.2001L16.6501 10.5301C16.4501 10.2601 16.0501 10.2601 15.8501 10.5301L13.2501 14.0001L11.4001 11.5301C11.2001 11.2601 10.8001 11.2601 10.6001 11.5301L8.60007 14.2001C8.35007 14.5301 8.59007 15.0001 9.00007 15.0001Z'
        fill='white'
      />
    </svg>
  );
});

export default SlidesIcon;

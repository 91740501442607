import { FC, memo, ReactElement, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { setFullScreenImageIndex } from '../../../../../store/vehicle/vehicle.slice';
import { useAppDispatch } from '../../../../../store';
import classes from './index.module.css';

interface IProps {
  fullScreenImageIndex: number;
  listImagesLength: number;
}

const Arrows: FC<IProps> = memo(
  ({ fullScreenImageIndex, listImagesLength }): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const goForward = (): void => {
      dispatch(setFullScreenImageIndex(fullScreenImageIndex + 1));
    };
    const goBack = (): void => {
      dispatch(setFullScreenImageIndex(fullScreenImageIndex - 1));
    };

    const keyPressHandler = (event: KeyboardEvent): void => {
      if (
        event.key === 'ArrowRight' &&
        fullScreenImageIndex !== listImagesLength - 1
      ) {
        goForward();
      }

      if (event.key === 'ArrowLeft' && fullScreenImageIndex > 0) {
        goBack();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', keyPressHandler);

      return () => {
        document.removeEventListener('keydown', keyPressHandler);
      };
    }, [listImagesLength, fullScreenImageIndex]);

    return (
      <>
        {fullScreenImageIndex > 0 ? (
          <Tooltip title={t('vehicle.previousLabel')} arrow placement='top'>
            <IconButton
              className={classes.singlePhotoArrowBack}
              size='small'
              onClick={goBack}
            >
              <ArrowBackIosIcon className={classes.fullScreenMode} />
            </IconButton>
          </Tooltip>
        ) : null}
        {fullScreenImageIndex !== listImagesLength - 1 ? (
          <Tooltip title={t('vehicle.nextLabel')} arrow placement='top'>
            <IconButton
              className={classes.singlePhotoArrowForward}
              size='small'
              onClick={goForward}
            >
              <ArrowForwardIosIcon className={classes.fullScreenMode} />
            </IconButton>
          </Tooltip>
        ) : null}
      </>
    );
  }
);

export default Arrows;

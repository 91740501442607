import { IAuthData } from './types';

export const setDataToLocalStorage = (
  key: string,
  data: IAuthData | string
): void => localStorage.setItem(key, JSON.stringify(data));
export const getDataFromLocalStorage = (key: string): IAuthData | null =>
  JSON.parse(localStorage.getItem(key) as string);
export const removeDataFromLocalStorage = (key: string): void =>
  localStorage.removeItem(key);

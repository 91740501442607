import { FC, ReactElement, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import DroneIcon from '../../../assets/Drone';
import TractorIcon from '../../../assets/Tractor';
import CarIcon from '../../../assets/Car';
import BoatIcon from '../../../assets/Boat';
import { VEHICLE_TYPES } from '../../../../domain/vehicle';
import { useAppSelector } from '../../../../store';
import classes from '../../../styles/vehicles.module.css';
import MiniCoordinates from './MiniCoordinates';

interface IProps {
  isOnline: boolean;
  uuid: string;
  vehicleType: VEHICLE_TYPES;
  latitude: number;
  longitude: number;
}

const SelectedListItemInfo: FC<IProps> = memo(
  ({ isOnline, uuid, vehicleType, latitude, longitude }): ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const selectedRealOnlineDevice = useAppSelector(
      (state) => state.vehicles.selectedUuidDevice?.is_real_online
    );

    const handleClick = (type: string | null, viewType: string) => {
      navigate(`/vehicles/${uuid}?viewType=${viewType}`);
    };
    const displayVehicleType = (vehicleType: VEHICLE_TYPES) => {
      if (vehicleType === VEHICLE_TYPES.DRONE) {
        return <DroneIcon />;
      }

      if (
        vehicleType === VEHICLE_TYPES.TRACTOR ||
        vehicleType === VEHICLE_TYPES.TRACTOR_KHTI
      ) {
        return <TractorIcon />;
      }

      if (vehicleType === VEHICLE_TYPES.CAR) {
        return <CarIcon />;
      }

      return <BoatIcon />;
    };

    const setVehicleStatus = (
      isOnline: boolean,
      isRealOnline: boolean
    ): string => {
      if (isRealOnline || isOnline) {
        if (isOnline && isRealOnline) {
          return t('vehiclesList.listDeviceStatusOnMission');
        }
        return t('vehiclesList.listDeviceStatusOnline');
      }
      return t('vehiclesList.listDeviceStatusOffline');
    };

    const setVehicleStatusClassName = (
      isOnline: boolean,
      isRealOnline: boolean
    ): string => {
      if (isRealOnline || isOnline) {
        if (isOnline && isRealOnline) {
          return classes.vehicleStatusOnMission;
        }
        return classes.vehicleStatusOnline;
      }
      return classes.vehicleStatusOffline;
    };

    const displayVehicleLabel = (vehicleType: VEHICLE_TYPES): string => {
      switch (vehicleType) {
        case VEHICLE_TYPES.TRACTOR:
          return t('vehiclesList.listDeviceTractorLabel');
        case VEHICLE_TYPES.CAR:
          return t('vehiclesList.listDeviceCarLabel');
        case VEHICLE_TYPES.BOAT:
          return t('vehiclesList.listDeviceBoatLabel');
        case VEHICLE_TYPES.DRONE:
          return t('vehiclesList.listDeviceDroneLabel');
        case VEHICLE_TYPES.PLANE:
          return t('vehiclesList.listDevicePlaneLabel');
        case VEHICLE_TYPES.TRACTOR_GEMINI:
          return t('vehiclesList.listDeviceTractorGeminiLabel');
        case VEHICLE_TYPES.TRACTOR_KHTI:
          return t('vehiclesList.listDeviceTractorHTILabel');
        default:
          return '';
      }
    };

    useEffect(() => {
      setVehicleStatus(isOnline, selectedRealOnlineDevice);
      setVehicleStatusClassName(isOnline, selectedRealOnlineDevice);
    }, [selectedRealOnlineDevice, isOnline]);

    return (
      <>
        <Box className={classes.uuidContainer}>
          <Typography variant='caption' className={classes.listUuid}>
            {uuid}
          </Typography>
        </Box>
        <Box className={classes.vehiclesDeviceContainer}>
          <Box className={classes.vehiclesDeviceWrapper}>
            <Box
              className={`${classes.vehicleStatus} ${setVehicleStatusClassName(
                isOnline,
                selectedRealOnlineDevice
              )}`}
            >
              <Typography
                variant='caption'
                className={classes.vehicleStatusColor}
              >
                {setVehicleStatus(isOnline, selectedRealOnlineDevice)}
              </Typography>
            </Box>
            <Box className={classes.vehicleTypeIconContainer}>
              <Box className={classes.vehicleTypeIcon}>
                {displayVehicleType(vehicleType)}
              </Box>
              <Typography className={classes.vehiclesListTextColor}>
                {vehicleType.length > 11
                  ? `${displayVehicleLabel(vehicleType).slice(0, 11)}...`
                  : displayVehicleLabel(vehicleType)}
              </Typography>
            </Box>
            <MiniCoordinates
              containerClassName={classes.miniMapCoordinatesContainer}
              latitude={latitude}
              longitude={longitude}
            />
          </Box>
        </Box>
      </>
    );
  }
);

export default SelectedListItemInfo;

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { connectToSocket, disconnectFromSocket } from './socket.thunks';
import { SOCKET_STATES } from '../../domain/socket';
import { VehicleTelemetry } from '../../domain/vehicle';

export interface SocketState {
  connectionStatus: SOCKET_STATES | string;
  socket: string | null;
  vehicleMetrics: VehicleTelemetry | null;
  vehicleImages: { [key in string]: string };
}

const initialState: SocketState = {
  connectionStatus: '',
  socket: null,
  vehicleMetrics: null,
  vehicleImages: {},
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setSocket: (state: SocketState, { payload }) => {
      state.socket = payload;
    },
    setVehicleMetrics: (state: SocketState, { payload }) => {
      state.vehicleMetrics = payload;
    },
    setVehicleImages: (state, { payload }) => {
      state.vehicleImages[payload.camera] = payload.image;
    },
    clearVehicleImage: (state, { payload }) => {
      // @ts-ignore
      state.vehicleImages[payload] = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<SocketState>) => {
    builder.addCase(connectToSocket.pending, (state: SocketState) => {
      state.connectionStatus = 'Connecting ...';
    });
    builder.addCase(connectToSocket.fulfilled, (state: SocketState) => {
      state.connectionStatus = 'Socket Connected';
    });
    builder.addCase(connectToSocket.rejected, (state: SocketState) => {
      state.connectionStatus = 'Socket Connection Failed';
    });
    builder.addCase(disconnectFromSocket.pending, (state: SocketState) => {
      state.connectionStatus = 'Disconnecting ...';
    });
    builder.addCase(disconnectFromSocket.fulfilled, (state: SocketState) => {
      state.connectionStatus = 'Socket Disconnected';
    });
    builder.addCase(disconnectFromSocket.rejected, (state: SocketState) => {
      state.connectionStatus = 'Socket Disconnection Failed';
    });
  },
});

const socketSliceReducer = socketSlice.reducer;
export const {
  setVehicleMetrics,
  setSocket,
  setVehicleImages,
  clearVehicleImage,
} = socketSlice.actions;

export default socketSliceReducer;

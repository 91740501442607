import { JanusServers } from './types';

export const VIDEO_ROOM_PLUGIN = 'janus.plugin.videoroom';
export const ATTACHED_VIDEO_PLUGIN_STATUS = 'attached';
export const PARTICIPANTS_DATA_STATUS = 'participants';

export const BITRATE_TIMEOUT: number = 10000;
export const RESTART_TIMEOUT: number = 5000;
export const STOP_STREAM_TIMEOUT: number = 3000;

export const max_poll_events = 1;
export const janusConfig = {
  // url: 'http://49.13.74.163:8088/janus',
  url: process.env.REACT_APP_JANUS_BASE_URL,
  apiSecret: process.env.REACT_APP_API_SECRET,
};

export const iceServers: JanusServers[] = [
  {
    urls: process.env.REACT_APP_STUN_ICE_SERVER_URL as string,
    username: process.env.REACT_APP_STUN_ICE_SERVER_USERNAME,
    credential: process.env.REACT_APP_STUN_ICE_SERVER_CREDENTIAL,
  },
  {
    // urls: "turn:49.13.74.163:9911",
    urls: process.env.REACT_APP_TURN_ICE_SERVER_URL as string,
    username: process.env.REACT_APP_TURN_ICE_SERVER_USERNAME,
    credential: process.env.REACT_APP_TURN_ICE_SERVER_CREDENTIAL,
  },
];

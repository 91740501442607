import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classes from './index.module.css';

interface IProps {
  name: string;
}

const Title: FC<IProps> = memo(
  ({ name }): ReactElement => (
    <Box className={classes.titleFullScreen}>
      <Typography variant='caption' className={classes.fullScreenMode}>
        {name}
      </Typography>
    </Box>
  )
);

export default Title;

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IOnsHistoryItem, OnsHistoryT } from '../../domain/ons';
import {
  deleteRequests,
  generateArchive,
  getOnsHistory,
  getProcessingStatus,
  getMapWayPoint,
} from './ons.thunks';

export interface IWayPoint {
  lat: number;
  lng: number;
  elevation: number;
}

export interface OnsState {
  wayPoints: IWayPoint[];
  wayPointsLength: number;
  rowHoverIndex: number | null;
  elevation: any;
  getHistoryLoading: boolean;
  history: OnsHistoryT | null;
  getHistoryError: string | null;
  getProcessingStatusLoading: boolean;
  getProcessingError: string | null;
  generateArchiveLoading: boolean;
  generateArchiveError: string | null;
  deleteRequestsLoading: boolean;
  deleteRequestsError: string | null;
  alertMessage: string;
  severity: 'success' | 'warning' | 'error' | undefined;
  title: string;
  focusOnLastPoint: boolean;
  editable: boolean;
  getMapWayPointLoading: boolean;
  getMapWayPointError: string | null;
  titleInProcessing: string;
  loadingUuids: string[];
  nonDeletedUuids: string[];
  selectedUuids: any[];
  totalSize: number;
}

const initialState: OnsState = {
  wayPoints: [],
  wayPointsLength: 0,
  rowHoverIndex: null,
  elevation: null,
  getHistoryLoading: false,
  history: null,
  getHistoryError: null,
  getProcessingStatusLoading: false,
  getProcessingError: null,
  generateArchiveLoading: false,
  generateArchiveError: null,
  deleteRequestsLoading: false,
  deleteRequestsError: null,
  alertMessage: '',
  severity: undefined,
  title: `Route ${dayjs().format('DD/MM/YY')}`,
  focusOnLastPoint: false,
  editable: false,
  getMapWayPointLoading: false,
  getMapWayPointError: null,
  titleInProcessing: '',
  loadingUuids: [],
  nonDeletedUuids: [],
  selectedUuids: [],
  totalSize: 0,
};

export const onsSlice = createSlice({
  name: 'ons',
  initialState,
  reducers: {
    setSelectedUuids: (state: OnsState, { payload }) => {
      const selectedIndex = state.selectedUuids.indexOf(payload);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(state.selectedUuids, payload);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedUuids.slice(1));
      } else if (selectedIndex === state.selectedUuids.length - 1) {
        newSelected = newSelected.concat(state.selectedUuids.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedUuids.slice(0, selectedIndex),
          state.selectedUuids.slice(selectedIndex + 1)
        );
      }

      state.selectedUuids = newSelected;

      if (state.history) {
        state.totalSize = state.history.items
          .filter((item: IOnsHistoryItem): boolean =>
            newSelected.includes(item.uuid)
          )
          .reduce(
            (acc: number, curr: IOnsHistoryItem): number =>
              acc + (curr.archive_size || 0),
            0
          );
      }
    },
    setSelectedAllUuids: (state: OnsState) => {
      if (state.history) {
        const newSelected = state.history.items.map(
          (item: IOnsHistoryItem): string => item.uuid
        );

        state.selectedUuids = newSelected;

        state.totalSize = state.history.items
          .filter((item: IOnsHistoryItem): boolean =>
            newSelected.includes(item.uuid)
          )
          .reduce(
            (acc: number, curr: IOnsHistoryItem): number =>
              acc + (curr.archive_size || 0),
            0
          );
      }
    },
    setDeselectedAllUuids: (state: OnsState) => {
      state.selectedUuids = [];
      state.totalSize = 0;
    },
    setNonDeletedUuids: (state: OnsState, { payload }) => {
      state.nonDeletedUuids = payload;
    },
    setLoadingUuids: (state: OnsState, { payload }) => {
      if (!state.loadingUuids.includes(payload)) {
        state.loadingUuids.push(payload);
      }
    },
    removeLoadingUuid: (state: OnsState, { payload }) => {
      state.loadingUuids = state.loadingUuids.filter(
        (uuid: string): boolean => uuid !== payload
      );
    },
    setTitleInProcessing: (state: OnsState, { payload }) => {
      state.titleInProcessing = payload;
    },
    setEditable: (state: OnsState, { payload }) => {
      state.editable = payload;
    },
    setTitle: (state: OnsState, { payload }) => {
      state.title = payload;
    },
    cleatNotificationsData: (state: OnsState) => {
      state.alertMessage = '';
      state.severity = undefined;
    },
    setSeverity: (state: OnsState, { payload }) => {
      state.severity = payload;
    },
    setAlertMessage: (state: OnsState, { payload }) => {
      state.alertMessage = payload;
    },
    setElevationService: (state: OnsState, { payload }) => {
      state.elevation = payload;
    },
    setRowHoverIndex: (state: OnsState, { payload }) => {
      state.rowHoverIndex = payload;
    },
    reorderWayPoints: (state: OnsState, { payload }) => {
      state.wayPoints = payload;
      state.focusOnLastPoint = false;
      state.editable = false;
    },
    addWayPoints: (state: OnsState, { payload }) => {
      state.rowHoverIndex = null;
      state.focusOnLastPoint = true;
      state.editable = false;
      state.wayPoints.push(payload);
    },
    updateWayPoints: (state: OnsState, { payload }) => {
      if (state.wayPoints.length) {
        if (!state.wayPoints[payload.index]) {
          state.wayPoints[payload.index] = { lat: 0, lng: 0, elevation: 0 };
        }

        state.wayPoints[payload.index].lat = payload.position.lat;
        state.wayPoints[payload.index].lng = payload.position.lng;
        state.wayPoints[payload.index].elevation = payload.position.elevation;
      } else {
        state.wayPoints[0] = payload.position;
      }
    },
    removeWayPoints: (state: OnsState, { payload }) => {
      state.wayPoints = state.wayPoints.filter(
        (_, innerIndex: number): boolean => innerIndex !== payload
      );
      state.rowHoverIndex = null;
      state.editable = false;
      state.focusOnLastPoint = false;
    },
    setWayPointsLength: (state: OnsState, { payload }) => {
      state.wayPointsLength = payload;
    },
    clearOnsData: (state: OnsState) => {
      state.wayPoints = [];
      state.wayPointsLength = 0;
      state.title = `Route ${dayjs().format('DD/MM/YY')}`;
    },
    clearHistoryData: (state: OnsState) => {
      state.history = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<OnsState>) => {
    builder.addCase(getOnsHistory.pending, (state: OnsState) => {
      state.getHistoryLoading = true;
      state.getHistoryError = null;
    });
    builder.addCase(getOnsHistory.fulfilled, (state: OnsState, { payload }) => {
      state.getHistoryLoading = false;
      state.history = payload;
    });
    builder.addCase(
      getOnsHistory.rejected,
      (state: OnsState, { payload }: { payload: any }) => {
        state.getHistoryLoading = false;
        state.getHistoryError = payload;
      }
    );
    builder.addCase(getMapWayPoint.pending, (state: OnsState) => {
      state.getMapWayPointLoading = true;
      state.getHistoryError = null;
    });
    builder.addCase(getMapWayPoint.fulfilled, (state: OnsState) => {
      state.getMapWayPointLoading = false;
    });
    builder.addCase(
      getMapWayPoint.rejected,
      (state: OnsState, { payload }: { payload: any }) => {
        state.getMapWayPointLoading = false;
        state.getMapWayPointError = payload;
      }
    );
    builder.addCase(getProcessingStatus.pending, (state: OnsState) => {
      state.getProcessingStatusLoading = true;
      state.getProcessingError = null;
    });
    builder.addCase(
      getProcessingStatus.fulfilled,
      (state: OnsState, { payload }) => {
        state.getProcessingStatusLoading = false;

        if (state.history) {
          state.history.items = state.history.items.map(
            (item: IOnsHistoryItem): IOnsHistoryItem => {
              if (item.uuid === payload.data.uuid) {
                return payload.data;
              }

              return item;
            }
          );
        }
      }
    );
    builder.addCase(
      getProcessingStatus.rejected,
      (state: OnsState, { payload }: { payload: any }) => {
        state.getProcessingStatusLoading = false;
        state.getProcessingError = payload;
      }
    );
    builder.addCase(generateArchive.pending, (state: OnsState) => {
      state.generateArchiveLoading = true;
      state.generateArchiveError = null;
    });
    builder.addCase(generateArchive.fulfilled, (state: OnsState) => {
      state.generateArchiveLoading = false;
    });
    builder.addCase(
      generateArchive.rejected,
      (state: OnsState, { payload }: { payload: any }) => {
        state.generateArchiveLoading = false;
        state.generateArchiveError = payload;
      }
    );
    builder.addCase(deleteRequests.pending, (state: OnsState) => {
      state.deleteRequestsLoading = true;
      state.deleteRequestsError = null;
    });
    builder.addCase(deleteRequests.fulfilled, (state: OnsState) => {
      state.deleteRequestsLoading = false;
    });
    builder.addCase(
      deleteRequests.rejected,
      (state: OnsState, { payload }: { payload: any }) => {
        state.deleteRequestsLoading = false;
        state.deleteRequestsError = payload;
      }
    );
  },
});

const onsSliceReducer = onsSlice.reducer;
export const {
  addWayPoints,
  removeWayPoints,
  updateWayPoints,
  setWayPointsLength,
  clearOnsData,
  setRowHoverIndex,
  setElevationService,
  reorderWayPoints,
  clearHistoryData,
  setAlertMessage,
  setSeverity,
  cleatNotificationsData,
  setTitle,
  setEditable,
  setTitleInProcessing,
  setLoadingUuids,
  removeLoadingUuid,
  setNonDeletedUuids,
  setSelectedUuids,
  setSelectedAllUuids,
  setDeselectedAllUuids,
} = onsSlice.actions;

export default onsSliceReducer;

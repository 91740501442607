import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const StartEngine: FC = memo(
  (): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          d='M12 3C11.45 3 11 3.45 11 4V12C11 12.55 11.45 13 12 13C12.55 13 13 12.55 13 12V4C13 3.45 12.55 3 12 3ZM17.14 5.86C16.75 6.25 16.76 6.86 17.13 7.25C18.26 8.45 18.96 10.05 19 11.82C19.09 15.65 15.92 18.95 12.09 18.99C8.17999 19.05 4.99999 15.9 4.99999 12C4.99999 10.16 5.70999 8.49 6.86999 7.24C7.23999 6.85 7.23999 6.24 6.85999 5.86C6.45999 5.46 5.80999 5.47 5.42999 5.88C3.97999 7.42 3.06999 9.47 2.99999 11.74C2.85999 16.62 6.82999 20.84 11.71 20.99C16.81 21.15 21 17.06 21 11.99C21 9.62 20.08 7.48 18.58 5.88C18.2 5.47 17.54 5.46 17.14 5.86Z'
          fill='white'
          fillOpacity='1'
        />
      </svg>
    </Box>
  )
);
export default StartEngine;

import { FC, memo, ReactElement } from 'react';
import {
  Draggable,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';
import {
  removeWayPoints,
  setRowHoverIndex,
} from '../../../store/ons/ons.slice';
import RouteListItemOrder from './RouteListItemOrder';
import RouteListItemLatitude from './RouteListItemLatitude';
import RouteListItemLongitude from './RouteListItemLongitude';
import { useAppDispatch, useAppSelector } from '../../../store';
import classes from '../../styles/ons.module.css';

interface IProps {
  index: number;
  lat: number;
  lng: number;
  elevation: number;
  isVisibleReorderIcon: boolean;
}

const RouteListItem: FC<IProps> = memo(
  ({ index, lat, lng, elevation, isVisibleReorderIcon }): ReactElement => {
    const dispatch = useAppDispatch();
    const rowHoverIndex = useAppSelector((state) => state.ons.rowHoverIndex);
    const { t } = useTranslation();

    return (
      <Draggable draggableId={index.toString()} index={index}>
        {(provided): ReactElement => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={classes.listItem}
            onMouseEnter={() => dispatch(setRowHoverIndex(index + 1))}
            onMouseLeave={() => dispatch(setRowHoverIndex(null))}
            style={{
              ...provided.draggableProps.style,
              backgroundColor:
                rowHoverIndex === index + 1
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'initial',
            }}
          >
            <RouteListItemOrder
              index={index}
              isVisibleReorderIcon={isVisibleReorderIcon}
              isVisibleCounter
              dragHandleProps={
                {
                  ...provided.dragHandleProps,
                } as DraggableProvidedDragHandleProps
              }
            />
            <RouteListItemLatitude index={index} lat={lat} lng={lng} />
            <RouteListItemLongitude index={index} lat={lat} lng={lng} />
            <TextField
              size='small'
              variant='outlined'
              autoComplete='off'
              value={elevation}
              className={classes.elevation}
              InputProps={{
                readOnly: true,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
                className: classes.elevationValue,
                endAdornment: (
                  <InputAdornment position='end' className={classes.elevationM}>
                    <span>{t('ons.meters')}</span>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              aria-label='delete'
              onClick={() => dispatch(removeWayPoints(index))}
            >
              <CloseRoundedIcon className={classes.deleteIcon} />
            </IconButton>
          </li>
        )}
      </Draggable>
    );
  }
);

export default RouteListItem;

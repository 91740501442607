import {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useSearchParams,
  URLSearchParamsInit,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UnknownAction } from '@reduxjs/toolkit';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getVehicleList,
  getVehicleListMetrics,
} from '../../../store/vehicle/vehicle.thunks';
import classes from '../../styles/vehicles.module.css';
import VehicleListItem from './VehicleListItem';
import { Vehicle } from '../../../domain/vehicle';
import SearchVehicle from './Search';
import ChooseVehicleType from './ChooseVehicleType';
import FilterByInternetStatus from './FilterByInternetStatus';
import ListHeader from './ListHeader';
import {
  cleanUpVehiclesListPage,
  selectDevice,
  setListMapDragged,
} from '../../../store/vehicle/vehicle.slice';

let vehicleMetricsInterval: NodeJS.Timeout;

const VehicleList: FC = (): ReactElement | null => {
  const dispatch = useAppDispatch();
  const vehiclesList = useAppSelector((state) => state.vehicles.vehiclesList);
  const vehiclesListLoading = useAppSelector(
    (state) => state.vehicles.vehiclesListLoading
  );
  const vehiclesListQuantity = useAppSelector(
    (state) => state.vehicles.vehiclesListQuantity
  );
  const metricsInterval = useAppSelector(
    (state) => state.vehicles.metricsInterval
  );

  const vehiclesListMetrics = useAppSelector(
    (state) => state.vehicles.vehiclesListMetrics
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [vehiclesWithMetrics, setVehiclesWithMetrics] = useState<Vehicle[]>([]);

  const currentParams: any = Object.fromEntries([...searchParams]);
  const location = useLocation();
  const { t } = useTranslation();

  const handleVehicleItemClick = (vehicle: any) => {
    dispatch(selectDevice(vehicle));
    dispatch(setListMapDragged(false));
  };

  useEffect(() => {
    const updatedVehicles = vehiclesList?.map((vehicle: Vehicle) => {
      const metrics = vehiclesListMetrics?.find(
        (metric: Vehicle) => metric.vehicle_id === vehicle.id
      );
      return {
        ...vehicle,
        is_online: metrics ? metrics.is_online : vehicle.is_online,
        is_real_online: metrics
          ? metrics.is_real_online
          : vehicle.is_real_online,
      };
    });

    let filteredVehicles = updatedVehicles || [];

    if (currentParams.is_online) {
      filteredVehicles = filteredVehicles.filter(
        (vehicle: Vehicle) => vehicle.is_online
      );
    }

    if (currentParams.is_real_online) {
      filteredVehicles = filteredVehicles.filter(
        (vehicle: Vehicle) => vehicle.is_real_online && !vehicle.is_online
      );
    }

    setVehiclesWithMetrics(filteredVehicles);
  }, [
    vehiclesList,
    vehiclesListMetrics,
    currentParams.is_online,
    currentParams.is_real_online,
  ]);

  useEffect(() => {
    return () => {
      dispatch(selectDevice(null));
    };
  }, []);

  useEffect(() => {
    if (!location.search.length) {
      const updatedParams: URLSearchParamsInit = {
        ...currentParams,
        limit: 10,
        page: 1,
      };

      setSearchParams(updatedParams);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getVehicleList(
        decodeURIComponent(location.search)
      ) as unknown as UnknownAction
    );
    return () => {
      clearInterval(vehicleMetricsInterval);
      dispatch(cleanUpVehiclesListPage());
    };
  }, [location.search]);

  useEffect(() => {
    if (vehiclesList && vehiclesList.length) {
      clearInterval(vehicleMetricsInterval);

      const vehiclesUuids: string = vehiclesList
        .map((item: Vehicle): string => item.uuid)
        .join(',');

      vehicleMetricsInterval = setInterval(
        (function getVehicleListMetricsInterval() {
          dispatch(
            getVehicleListMetrics(
              `${location.search}&vehicles_uuids=${vehiclesUuids}`
            ) as unknown as UnknownAction
          );
          return getVehicleListMetricsInterval;
        })(),
        metricsInterval
      );
    } else {
      clearInterval(vehicleMetricsInterval);
    }
  }, [vehiclesList, metricsInterval]);

  const handleVehiclesQueryParams = useCallback(
    (key: string, value: string | boolean | number): void => {
      const updatedParams: URLSearchParamsInit = {
        ...currentParams,
        page: 1,
        [key]: value,
      };

      setSearchParams(updatedParams);
    },
    [currentParams]
  );

  const removeVehiclesQueryParam = async (key: string): Promise<void> => {
    delete currentParams[key];
    await setSearchParams(currentParams);
  };

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number
  ): void => {
    const updatedParams: URLSearchParamsInit = {
      ...currentParams,
      page: page + 1,
    };
    setSearchParams(updatedParams);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updatedParams: URLSearchParamsInit = {
      ...currentParams,
      limit: parseInt(event.target.value, 10),
      page: 1,
    };
    setSearchParams(updatedParams);
  };

  const setPage = (currentParams: any): number => {
    if (currentParams && currentParams.page) {
      const currentPage = Number(currentParams.page);
      return currentPage - 1 === 0 ? 0 : currentPage - 1;
    }

    return 0;
  };

  return (
    <Box className={classes.vehiclesListContainer}>
      <ListHeader
        search={
          <SearchVehicle
            q={currentParams.q}
            handleQueryParams={handleVehiclesQueryParams}
            removeVehiclesQueryParam={removeVehiclesQueryParam}
          />
        }
        filterByDeviceType={
          <ChooseVehicleType
            vehicle_types={currentParams.vehicle_types}
            handleQueryParams={handleVehiclesQueryParams}
            removeVehiclesQueryParam={removeVehiclesQueryParam}
          />
        }
        filterByStatus={
          <FilterByInternetStatus
            internetStatus={currentParams.is_online}
            handleQueryParams={handleVehiclesQueryParams}
            removeVehiclesQueryParam={removeVehiclesQueryParam}
          />
        }
      />
      <List
        className={classes.vehiclesList}
        sx={{
          '&.MuiList-root': {
            padding: 0,
          },
        }}
      >
        {vehiclesListLoading ? (
          <Box className={classes.vehiclesListLoader}>
            <CircularProgress color='inherit' />
          </Box>
        ) : (
          vehiclesWithMetrics?.map((vehicle: Vehicle) => (
            <VehicleListItem
              key={vehicle.id}
              q={currentParams.q}
              uuid={vehicle.uuid}
              name={vehicle.name}
              vehicleType={vehicle.vehicle_type}
              isOnline={vehicle.is_online}
              isRealOnline={vehicle.is_real_online}
              changeOnlineStatusAt={vehicle.change_online_status_at}
              handleVehicleItemClick={() => handleVehicleItemClick(vehicle)}
            />
          ))
        )}
      </List>

      <Box style={{ width: '100%' }}>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            {
              label: t('vehiclesList.paginationAllText'),
              value: vehiclesListQuantity || 100,
            },
          ]}
          count={vehiclesListQuantity}
          page={setPage(currentParams)}
          sx={{
            overflow: 'hidden',
            color: '#fafafa',
            '& .Mui-disabled': {
              color: '#ffffff78 !important',
            },
            '& .MuiSelect-icon': {
              color: '#fafafa',
            },
            '& .MuiToolbar-root': {
              padding: 0,
            },
            '& .MuiInputBase-root': {
              marginRight: '20px',
            },
            '& .MuiToolbar-root .MuiTablePagination-actions': {
              marginLeft: '14px',
            },
            '& .MuiTablePagination-spacer': {
              flex: 'none',
            },
          }}
          rowsPerPage={
            currentParams && currentParams.limit
              ? Number(currentParams.limit)
              : 0
          }
          labelRowsPerPage={t('vehiclesList.labelRowsPerPage')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component='div'
        />
      </Box>
    </Box>
  );
};

export default VehicleList;

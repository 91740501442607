import { useCallback, useState, useRef, useEffect } from 'react';

type CopiedValue = string | null;
type Timeout = ReturnType<typeof setTimeout>;
type CopyFn = (text: string) => Promise<boolean>;

const useCopyToClipboard = (): [CopiedValue, CopyFn] => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const timeout = useRef<Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const copy: CopyFn = useCallback(async (text: string): Promise<boolean> => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (!navigator?.clipboard) {
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      timeout.current = setTimeout(() => {
        setCopiedText(null);
      }, 5000);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  }, []);

  return [copiedText, copy];
};

export default useCopyToClipboard;

import { FC, memo, ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import Header from './Header';
import LayersSettings from './LayersSettings';
import MetricsIntervalSettings from './MetricsIntervalSettings';
import useEscape from '../../../hooks/useEscape';
import classes from '../../styles/index.module.css';

const SettingsWidget: FC = memo((): ReactElement => {
  const [visibleSettings, setVisibleSettings] = useState<boolean>(false);

  useEscape(() => setVisibleSettings(false));

  return (
    <>
      <Box
        className={classes.settingsContainer}
        onClick={() => setVisibleSettings(true)}
      >
        <SettingsIcon />
      </Box>
      <Box
        className={classes.settingsWidget}
        style={{ display: visibleSettings ? 'flex' : 'none' }}
      >
        <Header setVisibleSettings={setVisibleSettings} />
        <Divider />
        <LayersSettings />
        <Divider />
        <MetricsIntervalSettings />
      </Box>
    </>
  );
});

export default SettingsWidget;

import {
  UserRepository,
  validateUserEmailForSpaces,
  validateUserPasswordForSpaces,
} from '../../domain/user';

type Dependencies = {
  userRepository: UserRepository;
  validateUserEmail: (email: string) => boolean;
  validateUserPassword: (password: string) => boolean;
};

export default ({
    userRepository,
    validateUserEmail,
    validateUserPassword,
  }: Dependencies) =>
  async (email: string, password: string) => {
    if (!validateUserEmailForSpaces(email)) {
      throw new Error('Contain spaces in email');
    }

    if (!validateUserEmail(email)) {
      throw new Error('Invalid email format');
    }

    if (!validateUserPasswordForSpaces(password)) {
      throw new Error('Contain spaces in password');
    }

    if (!validateUserPassword(password)) {
      throw new Error('Invalid password format');
    }

    try {
      return await userRepository.createLoginRequest(email, password);
    } catch (error) {
      throw error;
    }
  };

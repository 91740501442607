import displayImageName from './displayImageName';

const downloadImage = async (imageURL: string) => {
  const response: Response = await fetch(imageURL);
  const buffer: ArrayBuffer = await response.arrayBuffer();
  const url: string = window.URL.createObjectURL(new Blob([buffer]));
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  link.setAttribute('download', displayImageName(imageURL));
  document.body.appendChild(link);
  link.click();
};

export default downloadImage;

import { UserRepository } from '../../domain/user';

type Dependencies = {
  userRepository: UserRepository;
};

export default ({ userRepository }: Dependencies) =>
  async () => {
    try {
      return await userRepository.acceptLicenseRequest();
    } catch (error) {
      throw error;
    }
  };

import { AxiosInstance, AxiosResponse } from 'axios/index';
import {
  IWayPointResponse,
  IWayPointRequest,
  OnsRepository,
} from '../../domain/ons';
import {
  GENERATE_ARCHIVE,
  GET_MAP_WAYPOINT,
  ONS_HISTORY,
  PERCENTAGE,
  REQUEST,
} from '../common/httpService/constants';

type Dependencies = {
  http: AxiosInstance;
};

export default ({ http }: Dependencies): OnsRepository => ({
  async getMapWaypointRequest(payload: {
    title: string;
    list_waypoints: IWayPointRequest[];
  }): Promise<IWayPointResponse | unknown> {
    try {
      const response: AxiosResponse = await http.post(
        GET_MAP_WAYPOINT,
        payload
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getOnsHistoryRequest(
    options: string
  ): Promise<IWayPointResponse | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${ONS_HISTORY}${options}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getProcessingStatusRequest(
    request_uuid: string
  ): Promise<IWayPointResponse | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${PERCENTAGE}?request_uuid=${request_uuid}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async generateArchiveRequest(
    request_uuid: string
  ): Promise<IWayPointResponse | unknown> {
    try {
      const response: AxiosResponse = await http.post(
        `${GENERATE_ARCHIVE}?request_uuid=${request_uuid}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async deleteRequestsRequest(
    requests: string[]
  ): Promise<IWayPointResponse | unknown> {
    try {
      const response: AxiosResponse = await http.delete(REQUEST, {
        data: {
          requests,
        },
      });
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
});

import { FC, ReactElement, memo, useMemo } from 'react';
import Box from '@mui/material/Box';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useAppSelector } from '../../../../../store';
import Zoom from './Zoom';
import Arrows from './Arrows';
import CloseFullScreen from './Close';
import Title from './Title';
import SlidesMap from '../../../map/SlidesMap';
import displayImageName from '../../../helpers/displayImageName';
import { ISelectedPhoto, IStreamingSlide } from '../../../../../domain/vehicle';
import { MediaListTabsEnum } from '../../index';
import classes from './index.module.css';
import Timestamp from './Timestamp';

interface IProps {
  activeTab: MediaListTabsEnum;
}

const FullScreenImage: FC<IProps> = memo(
  ({ activeTab }): ReactElement | null => {
    const fullScreenMode = useAppSelector(
      (state) => state.vehicles.mediaList.fullScreenMode
    );
    const fullScreenImageIndex = useAppSelector(
      (state) => state.vehicles.mediaList.fullScreenImageIndex
    );
    const listPhotos = useAppSelector((state) => state.vehicles.listPhotos);
    const listSlides = useAppSelector((state) => state.vehicles.listSlides);

    const listImages: ISelectedPhoto[] | IStreamingSlide[] = useMemo(
      (): ISelectedPhoto[] | IStreamingSlide[] =>
        activeTab === MediaListTabsEnum.PhotosTab ? listPhotos : listSlides,
      [activeTab, listPhotos, listSlides]
    );

    if (!listImages.length) {
      return null;
    }

    return (
      <Box
        className={
          fullScreenMode
            ? classes.fullScreenModeActive
            : classes.fullScreenModeInActive
        }
      >
        <TransformWrapper initialScale={1} centerOnInit disablePadding>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Zoom
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                resetTransform={resetTransform}
              />
              <TransformComponent>
                <img
                  src={listImages[fullScreenImageIndex].image}
                  alt={listImages[fullScreenImageIndex].image}
                  style={{ width: '100vw', height: '100vh' }}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
        {activeTab === MediaListTabsEnum.SlidesTab ? (
          <SlidesMap
            key={fullScreenImageIndex}
            latitude={listSlides[fullScreenImageIndex].location.latitude}
            longitude={listSlides[fullScreenImageIndex].location.longitude}
            yaw={listSlides[fullScreenImageIndex].yaw}
          />
        ) : null}
        <Title
          name={displayImageName(listImages[fullScreenImageIndex].image)}
        />
        <Timestamp created_at={listImages[fullScreenImageIndex].created_at} />
        <Arrows
          fullScreenImageIndex={fullScreenImageIndex}
          listImagesLength={listImages.length}
        />
        <CloseFullScreen />
      </Box>
    );
  }
);

export default FullScreenImage;

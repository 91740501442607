import { FC, memo, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store';
import RouteEditableTitle from './RouteEditableTitle';
import classes from '../../styles/ons.module.css';

const RouteListHeader: FC = memo((): ReactElement => {
  const wayPointsLength = useAppSelector((state) => state.ons.wayPointsLength);

  const { t } = useTranslation();

  return (
    <Box className={classes.listHeader}>
      <RouteEditableTitle />
      {wayPointsLength ? (
        <Typography variant='subtitle2' color='rgba(255, 255, 255, 0.7)'>
          {t('ons.totalLabel')}: {Math.round(wayPointsLength)}
          {t('ons.meters')}
        </Typography>
      ) : null}
    </Box>
  );
});

export default RouteListHeader;

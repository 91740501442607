const radioCommand = (rc_channels_override: number[]) => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'rc_channels_override',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    rc_channels_override,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: false,
  is_controller: true,
  time_processing: { frontend_sent: 0 },
});

export default radioCommand;

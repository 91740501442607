import { FC, ReactElement, memo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import classes from '../../../styles/stream.module.css';
import useOutsideClick from '../../../common/hooks/useOutsideClick';
import useEscape from '../../../common/hooks/useEscape';
import { changeCameraResolutionCommand } from '../../../../infra/common/socketService/commands/streamingCommands';
import { socketSend } from '../../../../infra/common/socketService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setCameraResolution } from '../../../../store/vehicle/vehicle.slice';

interface IQualityOptions {
  text: string;
  value: { width: number; height: number };
}

interface IProps {
  camera: number;
}

const qualityOptions: IQualityOptions[] = [
  { text: '2160p', value: { width: 4096, height: 2160 } },
  { text: '1440p', value: { width: 2550, height: 1440 } },
  { text: '1080p', value: { width: 1920, height: 1080 } },
  { text: '720p', value: { width: 1280, height: 720 } },
  { text: '480p', value: { width: 640, height: 480 } },
];

const StreamSettings: FC<IProps> = memo(({ camera }): ReactElement => {
  const dispatch = useAppDispatch();
  const frameSizeWidth = useAppSelector(
    (state) =>
      state.socket.vehicleMetrics?.cameras_data[camera].frame_size.width
  );
  const frameSizeHeight = useAppSelector(
    (state) =>
      state.socket.vehicleMetrics?.cameras_data[camera].frame_size.height
  );
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const cameraResolution = useAppSelector(
    (state) => state.vehicles.cameraResolution[camera]
  );

  const [settingsVisibility, setSettingsVisibility] = useState<boolean>(false);

  const ref: any = useRef();
  const { t } = useTranslation();
  useOutsideClick(ref, () => setSettingsVisibility(false));
  useEscape(() => setSettingsVisibility(false));

  useEffect(() => {
    dispatch(
      setCameraResolution({
        camera,
        resolution: cameraResolution || '480p',
      })
    );
  }, []);

  const changeCameraResolution = async ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => {
    await socketSend(
      changeCameraResolutionCommand({ width, height, camera }),
      timeProcessing
    );
  };

  return (
    <section className={classes.settingsContainer} ref={ref}>
      <Tooltip
        key='settings'
        placement='top'
        title={t('vehicle.settingsLabel')}
        arrow
      >
        <IconButton
          color='default'
          size='small'
          onClick={() => {
            setSettingsVisibility(!settingsVisibility);
          }}
        >
          <SettingsIcon sx={{ color: '#fff' }} />
        </IconButton>
      </Tooltip>
      {settingsVisibility ? (
        <article className={classes.settingsListContainer}>
          <ul className={classes.settingsList}>
            {qualityOptions
              .filter(
                (qualityOption: IQualityOptions): boolean =>
                  qualityOption.value.width <= frameSizeWidth &&
                  qualityOption.value.height <= frameSizeHeight
              )
              .map(
                (qualityOption: IQualityOptions): ReactElement => (
                  <li
                    key={qualityOption.text}
                    className={classes.settingsListItem}
                    onClick={async () => {
                      await dispatch(
                        setCameraResolution({
                          resolution: qualityOption.text,
                          camera,
                        })
                      );
                      await changeCameraResolution({
                        width: qualityOption.value.width,
                        height: qualityOption.value.height,
                      });
                    }}
                  >
                    <svg
                      width='18'
                      height='13'
                      viewBox='0 0 18 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{
                        visibility:
                          cameraResolution === qualityOption.text
                            ? 'visible'
                            : 'hidden',
                      }}
                    >
                      <path
                        d='M6.00014 10.1701L2.53014 6.70007C2.14014 6.31007 1.51014 6.31007 1.12014 6.70007C0.730137 7.09007 0.730137 7.72007 1.12014 8.11007L5.30014 12.2901C5.69014 12.6801 6.32014 12.6801 6.71014 12.2901L17.2901 1.71007C17.6801 1.32007 17.6801 0.690068 17.2901 0.300068C16.9001 -0.0899316 16.2701 -0.0899316 15.8801 0.300068L6.00014 10.1701Z'
                        fill='white'
                      />
                    </svg>

                    <Typography variant='caption' style={{ color: '#fff' }}>
                      {qualityOption.text}
                    </Typography>
                  </li>
                )
              )}
          </ul>
        </article>
      ) : null}
    </section>
  );
});
export default StreamSettings;

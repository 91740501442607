import { FC, memo, ReactElement, useMemo, useState } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from '../../../styles/vehicles.module.css';

const FilterStatus: FC = memo((): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams: any = Object.fromEntries([...searchParams]);

  const [value, setValue] = useState<string>(currentParams.status || 'all');
  const { t, i18n } = useTranslation();

  const statuses: { label: string; value: string }[] = useMemo(
    (): { label: string; value: string }[] => [
      { label: t('ons.statusAll'), value: 'all' },
      { label: t('ons.statusNew'), value: 'new' },
      { label: t('ons.statusLoading'), value: 'load_map_image' },
      { label: t('ons.statusBuilding'), value: 'build_archive' },
      { label: t('ons.statusCompleted'), value: 'completed' },
      { label: t('ons.statusRebuild'), value: 'need_to_build_archive' },
      { label: t('ons.statusError'), value: 'error' },
    ],
    [i18n.language]
  );

  const handleStatus = async (status: string) => {
    setValue(status);

    if (status === 'all') {
      delete currentParams.status;
      await setSearchParams(currentParams);
      return;
    }

    const updatedParams: URLSearchParamsInit = {
      ...currentParams,
      page: 1,
      status,
    };

    setSearchParams(updatedParams);
  };

  return (
    <FormControl variant='outlined' fullWidth sx={{ width: '11.25rem' }}>
      <InputLabel id='demo-multiple-name-label'>
        {t('ons.statusLabel')}
      </InputLabel>
      <Select
        size='small'
        labelId='demo-multiple-name-label'
        id='demo-multiple-name'
        label={t('ons.statusLabel')}
        value={value}
        onChange={async (event) => {
          await handleStatus(event.target.value);
        }}
      >
        {statuses.map(
          (status: { label: string; value: string }): ReactElement => (
            <MenuItem key={status.label} value={status.value}>
              <ListItemText
                className={classes.vehiclesListTextColor}
                primary={
                  <Typography variant='body1'>{status.label}</Typography>
                }
              />
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
});

export default FilterStatus;

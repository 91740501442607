import { FC, ReactElement, memo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import 'dayjs/locale/uk';
import 'dayjs/locale/en';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ukUA, enUS } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from '../../../styles/ons.module.css';

const isoFormat = 'YYYY-MM-DD';

const FilterRangePicker: FC = memo((): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams: any = Object.fromEntries([...searchParams]);

  const [startDate, setStartDate] = useState<Dayjs | null>(
    currentParams.date_from ? dayjs(currentParams.date_from) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    currentParams.date_to ? dayjs(currentParams.date_to) : null
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!startDate && endDate) {
      delete currentParams.date_from;
      delete currentParams.date_to;
      setSearchParams(currentParams);
      setEndDate(null);
    }
  }, [startDate, endDate]);

  const onChangeStartDate = (value: Dayjs | null): void => {
    if (value) {
      setStartDate(value);
      const updatedParams: URLSearchParamsInit = {
        ...currentParams,
        date_from: value.format(isoFormat),
      };
      setSearchParams(updatedParams);
    } else {
      delete currentParams.date_from;
      setSearchParams(currentParams);
      setStartDate(null);
    }
  };

  const onChangeEndDate = (value: Dayjs | null): void => {
    if (value) {
      setEndDate(value);
      const updatedParams: URLSearchParamsInit = {
        ...currentParams,
        date_to: value.format(isoFormat),
      };
      setSearchParams(updatedParams);
    } else {
      delete currentParams.date_to;
      setSearchParams(currentParams);
      setEndDate(null);
    }
  };

  return (
    <Box className={classes.datePickers}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          i18n.language === 'uk'
            ? ukUA.components.MuiLocalizationProvider.defaultProps.localeText
            : enUS.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale={i18n.language}
      >
        <DatePicker
          label={t('ons.fromLabel')}
          closeOnSelect={false}
          slotProps={{
            textField: {
              size: 'medium',
              fullWidth: true,
              error: false,
            },
            actionBar: { actions: ['clear'] },
          }}
          value={startDate}
          onChange={onChangeStartDate}
        />
      </LocalizationProvider>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          i18n.language === 'uk'
            ? ukUA.components.MuiLocalizationProvider.defaultProps.localeText
            : enUS.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale={i18n.language}
      >
        <DatePicker
          label={t('ons.toLabel')}
          disabled={!startDate}
          closeOnSelect={false}
          value={endDate}
          slotProps={{
            textField: {
              size: 'medium',
              fullWidth: true,
              error: false,
            },
            actionBar: { actions: ['clear'] },
          }}
          onChange={onChangeEndDate}
        />
      </LocalizationProvider>
    </Box>
  );
});

export default FilterRangePicker;

import { FC, ReactElement, useEffect } from 'react';
import Box from '@mui/material/Box';
import 'dayjs/locale/uk';
import 'dayjs/locale/en';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { enUS, ukUA } from '@mui/x-date-pickers/locales';
import { useTranslation } from 'react-i18next';
import {
  setMediaListPhotosEndDate,
  setMediaListPhotosStartDate,
  setMediaListSlidesStartDate,
  setMediaListSlidesEndDate,
} from '../../../../store/vehicle/vehicle.slice';
import { useAppDispatch } from '../../../../store';
import { MediaListTabsEnum } from '../index';
import classes from '../index.module.css';

interface IProps {
  activeTab: MediaListTabsEnum;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

const DateTimePickers: FC<IProps> = ({
  activeTab,
  startDate,
  endDate,
}): ReactElement => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!startDate && endDate) {
      if (activeTab === MediaListTabsEnum.PhotosTab) {
        dispatch(setMediaListPhotosEndDate(null));
      } else {
        dispatch(setMediaListSlidesEndDate(null));
      }
    }
  }, [startDate, endDate]);

  const onChangeStartDate = (value: Dayjs | null): void => {
    if (activeTab === MediaListTabsEnum.PhotosTab) {
      dispatch(
        setMediaListPhotosStartDate(value ? value.format(isoFormat) : null)
      );
    } else {
      dispatch(
        setMediaListSlidesStartDate(value ? value.format(isoFormat) : null)
      );
    }
  };

  const onChangeEndDate = (value: Dayjs | null): void => {
    if (activeTab === MediaListTabsEnum.PhotosTab) {
      dispatch(
        setMediaListPhotosEndDate(value ? value.format(isoFormat) : null)
      );
    } else {
      dispatch(
        setMediaListSlidesEndDate(value ? value.format(isoFormat) : null)
      );
    }
  };

  return (
    <Box className={classes.datePickers}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          i18n.language === 'uk'
            ? ukUA.components.MuiLocalizationProvider.defaultProps.localeText
            : enUS.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale={i18n.language}
      >
        <DateTimePicker
          label={t('ons.fromLabel')}
          closeOnSelect={false}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              error: false,
            },
            actionBar: { actions: ['clear'] },
          }}
          value={startDate ? dayjs(startDate) : null}
          onChange={onChangeStartDate}
        />
      </LocalizationProvider>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          i18n.language === 'uk'
            ? ukUA.components.MuiLocalizationProvider.defaultProps.localeText
            : enUS.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale={i18n.language}
      >
        <DateTimePicker
          label={t('ons.toLabel')}
          disabled={!startDate}
          closeOnSelect={false}
          minDateTime={dayjs(startDate).add(2, 'days')}
          value={endDate ? dayjs(endDate) : null}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              error: false,
            },
            actionBar: { actions: ['clear'] },
          }}
          onChange={onChangeEndDate}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateTimePickers;

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
} from 'react-router-dom';
import LoginPage from '../../pages/Login';
import ResetPasswordConfirmPage from '../../pages/ResetPasswordConfirmPage';
import ResetPasswordRequestPage from '../../pages/ResetPasswordRequestPage';
import VehicleListPage from '../../pages/VehiclesList';
import VehiclePage from '../../pages/Vehicle';
import ErrorPage from '../../pages/Error';
import ProtectedLayout from './ProtectedLayout';
import LoginLayout from '../../components/LoginLayout';
import AuthLayout from './AuthLayout';
import Ons from '../../pages/OnsCreatePage';
import { getDataFromLocalStorage } from './helpers';
import { LOCAL_STORAGE_AUTH_KEY } from '../../../infra/common/httpService/constants';
import ROUTES from './constants';
import LicencePage from '../../pages/License';
import { IAuthData } from './types';
import OnsHistoryPage from '../../pages/OnsHistoryPage';
import NoAccess from '../../pages/NoAccess';

const getAuthData = (): Promise<IAuthData | null> =>
  new Promise((resolve) => {
    setTimeout(() => {
      const authData: IAuthData | null = getDataFromLocalStorage(
        LOCAL_STORAGE_AUTH_KEY
      );
      resolve(authData);
    }, 1000);
  });

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ authDataPromise: getAuthData() })}
    >
      <Route element={<LoginLayout />}>
        <Route path={ROUTES.AUTH} element={<LoginPage />} />
        <Route path={ROUTES.RESTORE} element={<ResetPasswordRequestPage />} />
        <Route path={ROUTES.RECOVERY} element={<ResetPasswordConfirmPage />} />
        <Route path={ROUTES.LICENSE} element={<LicencePage />} />
      </Route>

      <Route path={ROUTES.VEHICLES.MAIN} element={<ProtectedLayout />}>
        <Route path={ROUTES.VEHICLES.LIST} element={<VehicleListPage />} />
        <Route path={ROUTES.VEHICLES.ID} element={<VehiclePage />} />
      </Route>
      <Route path={ROUTES.ONS.MAIN} element={<ProtectedLayout />}>
        <Route path={ROUTES.ONS.HISTORY} element={<OnsHistoryPage />} />
        <Route path={ROUTES.ONS.CREATE} element={<Ons />} />
      </Route>
      <Route path={ROUTES.NO_ACCESS} element={<NoAccess />} />
      <Route path='*' element={<ErrorPage />} />
    </Route>
  )
);

export default router;

import { FC, ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import classes from '../../styles/vehicles.module.css';

interface IProps {
  search: ReactElement;
  filterByDeviceType: ReactElement;
  filterByStatus: ReactElement;
}

const ListHeader: FC<IProps> = memo(
  ({ search, filterByDeviceType, filterByStatus }): ReactElement => {
    const { t } = useTranslation();
    return (
      <Box>
        <Box className={classes.vehiclesListHeader}>
          <Typography variant='h5'>{t('vehiclesList.listTitle')}</Typography>
        </Box>
        <Stack direction='row' spacing={0.5}>
          {filterByDeviceType}
          {filterByStatus}
        </Stack>
        {search}
      </Box>
    );
  }
);

export default ListHeader;

import { FC, memo, ReactElement, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  ScaleControl,
} from 'react-leaflet';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MiniMapExitIcon from '../../../assets/MiniMapExitIcon';
import { slidesMiniMapConfig } from '../common/config';
import MiniMapCoordinates from '../MiniMap/MiniMapCoordinates';
import CenteringWidget from './CenteringWidget';
import ReduceMapWidget from './ReduceMapWidget';
import RotatedMarker from './RotatedMarker';
import classes from '../styles/index.module.css';

const {
  zoom: {
    initialZoom,
    zoomControl,
    zoomControlPosition,
    zoomSnap,
    minZoom,
    maxZoom,
    maxNativeZoom,
    minZoomTile,
    maxZoomTile,
  },
  bounds: { maxBoundsViscosity },
  layerControls: { satelliteTileLayerUrl, subdomainsForSatelliteTile },
  scale,
  dragging,
} = slidesMiniMapConfig;

interface IProps {
  latitude: number;
  longitude: number;
  yaw: number;
}

const SlidesMap: FC<IProps> = memo(
  ({ latitude, longitude, yaw }): ReactElement => {
    const [mapHovered, setMapHovered] = useState<boolean>(false);
    const [miniMapHovered, setMiniMapHovered] = useState<boolean>(false);
    const [miniMapMode, setMiniMapMode] = useState<boolean>(false);

    if (miniMapMode) {
      return (
        <Box
          className={classes.slidesMap}
          onMouseEnter={() => setMiniMapHovered(true)}
          onMouseLeave={() => setMiniMapHovered(false)}
        >
          <Box className={classes.slidesMapChild}>
            <IconButton
              size='medium'
              className={classes.slidesMapExitIconContainer}
              style={{ visibility: miniMapHovered ? 'visible' : 'hidden' }}
              onClick={() => {
                setMiniMapMode(false);
                setMapHovered(false);
              }}
            >
              <MiniMapExitIcon />
            </IconButton>
          </Box>
        </Box>
      );
    }

    return (
      <section
        className={classes.slidesMiniMap}
        onMouseEnter={() => setMapHovered(true)}
        onMouseLeave={() => setMapHovered(false)}
      >
        <MapContainer
          className='slidesMiniMapContainer'
          minZoom={minZoom}
          zoom={initialZoom}
          zoomSnap={zoomSnap}
          maxZoom={maxZoom}
          maxBoundsViscosity={maxBoundsViscosity}
          zoomControl={zoomControl}
          center={[latitude, longitude]}
          dragging={dragging}
        >
          <TileLayer
            url={satelliteTileLayerUrl}
            subdomains={subdomainsForSatelliteTile}
            minZoom={minZoomTile}
            maxZoom={maxZoomTile}
            maxNativeZoom={maxNativeZoom}
            detectRetina
          />
          {mapHovered ? (
            <>
              <ScaleControl position={scale.position} />
              <ZoomControl position={zoomControlPosition} />
              <CenteringWidget coordinates={[latitude, longitude]} />
              <ReduceMapWidget
                setMiniMapHovered={setMiniMapHovered}
                setMiniMapMode={setMiniMapMode}
              />
            </>
          ) : null}
          <RotatedMarker
            position={[latitude, longitude]}
            yaw={yaw}
            reducePin={false}
            centered={false}
          />
        </MapContainer>
        <MiniMapCoordinates
          containerClassName={classes.slidesMiniMapCoordinatesContainer}
        />
      </section>
    );
  }
);

export default SlidesMap;

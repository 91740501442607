import { FC, ReactElement, memo } from 'react';

const ArrowLeft: FC = memo(
  (): ReactElement => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
    >
      <path
        d='M3.52325 6.1664H13.6666V7.83307H3.52325L7.99325 12.3031L6.81492 13.4814L0.333252 6.99973L6.81492 0.518066L7.99325 1.6964L3.52325 6.1664Z'
        fill='#0F1324'
        fillOpacity='0.6'
      />
    </svg>
  )
);

export default ArrowLeft;

const buttons = {
  styleOverrides: {
    // root: ({ ownerState }: any) => ({
    //   ...(ownerState.variant === 'contained' &&
    //     ownerState.color === 'primary' && {
    //       backgroundColor: '#14151A',
    //       color: '#fff',
    //       borderRadius: '12px',
    //       padding: '12px 16px',
    //       '&:hover': {
    //         backgroundColor: '#14151A',
    //       },
    //     }),
    //   ...(ownerState.variant === 'text' &&
    //     ownerState.color === 'primary' && {
    //       backgroundColor: '#FFFFFF00',
    //       color: '#0F132499',
    //       fontSize: '0.875rem',
    //       fontWeight: 500,
    //       padding: '10px 12px',
    //       lineHeight: '20px',
    //       '&:hover': {
    //         backgroundColor: '#FFFFFF00',
    //       },
    //     }),
    // }),
  },
};

export default buttons;

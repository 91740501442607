import { createTheme } from '@mui/material';
import typography from './typography';
import MuiButton from './buttons';
import MuiOutlinedInput from './inputs';

const baseTheme = createTheme({
  typography,
  components: {
    MuiButton,
    MuiOutlinedInput,
  },
});

export default baseTheme;

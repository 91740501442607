import { VehicleRepository } from '../../domain/vehicle';

type Dependencies = {
  vehicleRepository: VehicleRepository;
};

export default ({ vehicleRepository }: Dependencies) =>
  async (uuid: string, limit: number, page: number) => {
    try {
      return await vehicleRepository.getArchivesListRequest(uuid, limit, page);
    } catch (error) {
      throw error;
    }
  };

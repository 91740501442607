import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const WipersWetIcon: FC<{ on: boolean }> = memo(
  ({ on }): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          d='M15.6687 2.84711C15.8838 2.78456 16.1161 2.78456 16.3312 2.84709C16.7555 2.97043 17.1994 2.72648 17.3227 2.30222C17.4461 1.87795 17.2021 1.43404 16.7778 1.3107C16.271 1.16335 15.7289 1.16336 15.222 1.31074C14.7977 1.4341 14.5538 1.87803 14.6772 2.30229C14.8005 2.72655 15.2445 2.97047 15.6687 2.84711Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
        <path
          d='M11.6687 2.8471C11.8838 2.78457 12.1161 2.78457 12.3312 2.84712C12.7554 2.97049 13.1994 2.72656 13.3227 2.3023C13.4461 1.87805 13.2022 1.43411 12.7779 1.31075C12.2711 1.16337 11.729 1.16336 11.2221 1.3107C10.7978 1.43403 10.5539 1.87795 10.6772 2.30221C10.8005 2.72648 11.2444 2.97043 11.6687 2.8471Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
        <path
          d='M10.8366 3.84088C10.9196 3.40692 10.6351 2.98784 10.2011 2.90482C9.76718 2.82181 9.34809 3.10631 9.26508 3.54027C9.22192 3.7659 9.19995 4.0029 9.19995 4.25016V4.3335C9.19995 4.39066 9.20595 4.44641 9.21734 4.50018C9.20595 4.55394 9.19995 4.6097 9.19995 4.66686V4.7502C9.19995 5.19202 9.55812 5.5502 9.99995 5.5502C10.4418 5.5502 10.8 5.19202 10.8 4.7502V4.66686C10.8 4.6097 10.794 4.55394 10.7826 4.50018C10.794 4.44641 10.8 4.39066 10.8 4.3335V4.25016C10.8 4.09774 10.8135 3.96168 10.8366 3.84088Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
        <path
          d='M18.7348 3.5403C18.6518 3.10634 18.2327 2.82184 17.7988 2.90485C17.3648 2.98786 17.0803 3.40694 17.1633 3.8409C17.1864 3.9617 17.2 4.09777 17.2 4.25019V4.33352C17.2 4.39068 17.2059 4.44643 17.2173 4.50019C17.2059 4.55395 17.2 4.60971 17.2 4.66686V4.7502C17.2 5.19202 17.5581 5.5502 18 5.5502C18.4418 5.5502 18.8 5.19202 18.8 4.7502V4.66686C18.8 4.60971 18.794 4.55395 18.7826 4.50019C18.794 4.44643 18.8 4.39068 18.8 4.33352V4.25019C18.8 4.00293 18.778 3.76593 18.7348 3.5403Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
        <path
          d='M14.8366 3.84097C14.9196 3.40701 14.6351 2.98792 14.2011 2.90491C14.1333 2.89194 14.0658 2.88794 14 2.89209C13.9341 2.88794 13.8666 2.89194 13.7988 2.90492C13.3648 2.98793 13.0803 3.40701 13.1633 3.84097C13.1864 3.96177 13.2 4.09784 13.2 4.25025V4.69622C13.2 5.13804 13.5581 5.49622 14 5.49622C14.4418 5.49622 14.8 5.13804 14.8 4.69622V4.25025C14.8 4.09784 14.8135 3.96177 14.8366 3.84097Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
        <path
          d='M14.8 9.17729C14.8 8.73547 14.4418 8.37729 14 8.37729C13.5581 8.37729 13.2 8.73547 13.2 9.17729V10.0733C13.2 10.5152 13.5581 10.8733 14 10.8733C14.4418 10.8733 14.8 10.5152 14.8 10.0733V9.17729Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
        <path
          d='M14.8 11.8662C14.8 11.4244 14.4418 11.0662 14 11.0662C13.5581 11.0662 13.2 11.4244 13.2 11.8662V12.7607C13.2 13.2026 13.5581 13.5607 14 13.5607C14.4418 13.5607 14.8 13.2026 14.8 12.7607V11.8662Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.7938 6.38867C14.7447 5.99395 14.408 5.68848 14 5.68848C13.6459 5.68848 13.3456 5.91847 13.2402 6.2372C12.828 6.21258 12.4144 6.2002 12 6.2002C8.34879 6.2002 4.76195 7.16129 1.59995 8.98687C1.41621 9.09295 1.28213 9.26769 1.22721 9.47263C1.1723 9.67757 1.20105 9.89594 1.30713 10.0797L5.30713 17.0079C5.41322 17.1916 5.58795 17.3257 5.7929 17.3806C5.99784 17.4355 6.2162 17.4068 6.39995 17.3007C7.3712 16.74 8.41688 16.3307 9.50097 16.0825L11.2615 20.3078C11.4314 20.7156 11.8998 20.9085 12.3076 20.7385C12.7155 20.5686 12.9083 20.1002 12.7384 19.6924L11.1307 15.834C11.4193 15.8115 11.7092 15.8002 12 15.8002C13.966 15.8002 15.8973 16.3177 17.5999 17.3007C17.7837 17.4068 18.0021 17.4355 18.207 17.3806C18.4119 17.3257 18.5867 17.1916 18.6928 17.0079L22.6928 10.0797C22.9137 9.69705 22.7826 9.20778 22.3999 8.98687C20.0499 7.63006 17.4652 6.75078 14.7938 6.38867ZM13.3474 7.84753C13.4924 8.05151 13.7306 8.18458 14 8.18458C14.2108 8.18458 14.4026 8.103 14.5455 7.96969C16.7602 8.26594 18.911 8.94719 20.8988 9.98694L17.6946 15.5367C16.7719 15.0783 15.7994 14.7341 14.7987 14.5099C14.7751 14.0893 14.4265 13.7555 14 13.7555C13.6618 13.7555 13.3726 13.9653 13.2555 14.2619C12.8394 14.2209 12.4203 14.2002 12 14.2002C11.4929 14.2002 10.9878 14.2303 10.4874 14.2899L7.96222 8.22956C9.28375 7.94528 10.637 7.8002 12 7.8002C12.4504 7.8002 12.8999 7.81605 13.3474 7.84753ZM3.10111 9.98694C4.15893 9.43364 5.26289 8.98185 6.39807 8.63559L8.87775 14.5868C7.99088 14.8099 7.12828 15.1279 6.30528 15.5367L3.10111 9.98694Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
      </svg>
    </Box>
  )
);

export default WipersWetIcon;

import { FC, ReactElement, memo } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IWayPoint, reorderWayPoints } from '../../../store/ons/ons.slice';
import RouteListHeader from './RouteListHeader';
import RouteListItemOrder from './RouteListItemOrder';
import RouteListItemLatitude from './RouteListItemLatitude';
import RouteListItemLongitude from './RouteListItemLongitude';
import RouteListItem from './RouteListItem';
import RouteActions from './RouteActions';
import classes from '../../styles/ons.module.css';

const RouteList: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const wayPoints = useAppSelector((state) => state.ons.wayPoints);
  const { t } = useTranslation();
  const reorder = (
    list: IWayPoint[],
    startIndex: number,
    endIndex: number
  ): IWayPoint[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      wayPoints,
      result.source.index,
      result.destination.index
    );

    dispatch(reorderWayPoints(reorderedItems));
  };

  return (
    <Box className={classes.listWrapper}>
      <RouteListHeader />
      <Box className={classes.listContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided): ReactElement => {
              const lastIndex: number = wayPoints.length;
              return (
                <div>
                  <ul
                    className={classes.list}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {wayPoints.length
                      ? wayPoints.map(
                          (marker: IWayPoint, index: number): ReactElement => (
                            <RouteListItem
                              key={index}
                              index={index}
                              lat={marker.lat}
                              lng={marker.lng}
                              elevation={marker.elevation}
                              isVisibleReorderIcon={wayPoints.length > 1}
                            />
                          )
                        )
                      : null}
                    {provided.placeholder}
                  </ul>
                  <li className={classes.listItem} key={lastIndex}>
                    <RouteListItemOrder
                      index={lastIndex}
                      isVisibleReorderIcon={false}
                      isVisibleCounter={lastIndex === 0}
                    />
                    <RouteListItemLatitude index={lastIndex} lat={0} lng={0} />
                    <RouteListItemLongitude index={lastIndex} lat={0} lng={0} />
                    <TextField
                      size='small'
                      variant='outlined'
                      autoComplete='off'
                      value='-'
                      className={classes.elevation}
                      InputProps={{
                        readOnly: true,
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        className: classes.elevationNullable,
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            className={classes.elevationM}
                          >
                            <span>{t('ons.meters')}</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <IconButton
                      aria-label='delete'
                      style={{ visibility: 'hidden' }}
                    >
                      <CloseRoundedIcon className={classes.deleteIcon} />
                    </IconButton>
                  </li>
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        <RouteActions
          disabledForClear={wayPoints.length > 0}
          disabledForGenerate={wayPoints.length > 1}
          wayPoints={wayPoints}
        />
      </Box>
    </Box>
  );
});

export default RouteList;

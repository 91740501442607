import { FC, memo, ReactElement, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import RestoreIcon from '@mui/icons-material/Restore';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { UnknownAction } from '@reduxjs/toolkit';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { ONS_ARCHIVE_STATUS } from '../../../domain/ons';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  generateArchive,
  getProcessingStatus,
} from '../../../store/ons/ons.thunks';
import {
  setLoadingUuids,
  removeLoadingUuid,
} from '../../../store/ons/ons.slice';
import { PERCENTAGE_INTERVAL } from './helpers';
import classes from '../../styles/ons.module.css';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 4,
  width: '50%',
  borderRadius: 5,
  marginBottom: '0.5rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(144, 202, 249, 0.5)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'rgba(144, 202, 249, 1)',
  },
}));

interface IProps {
  uuid: string;
  status: ONS_ARCHIVE_STATUS;
  percent_processed: number;
}

const StatusCell: FC<IProps> = memo(
  ({ uuid, status, percent_processed }): ReactElement => {
    const dispatch = useAppDispatch();
    const loadingUuids = useAppSelector((state) => state.ons.loadingUuids);

    const [visible, setVisible] = useState<boolean>(false);
    const { t } = useTranslation();
    let percentageInterval: NodeJS.Timeout;

    useEffect(() => {
      if (
        status === ONS_ARCHIVE_STATUS.LOADING ||
        status === ONS_ARCHIVE_STATUS.BUILDING ||
        status === ONS_ARCHIVE_STATUS.NEW
      ) {
        percentageInterval = setInterval(() => {
          dispatch(getProcessingStatus(uuid) as unknown as UnknownAction);
        }, PERCENTAGE_INTERVAL);
        dispatch(setLoadingUuids(uuid));
      } else {
        clearInterval(percentageInterval);
      }

      return () => {
        clearInterval(percentageInterval);
      };
    }, [status]);

    useEffect(() => {
      if (status === ONS_ARCHIVE_STATUS.COMPLETED) {
        if (loadingUuids.includes(uuid)) {
          dispatch(removeLoadingUuid(uuid));
          setVisible(true);

          setTimeout(() => {
            setVisible(false);
          }, 4000);
        }
      }
    }, [loadingUuids.length]);

    const startRebuild = (event: any): void => {
      event.stopPropagation();
      dispatch(
        generateArchive({
          uuid,
          onSuccess: () => {
            dispatch(getProcessingStatus(uuid) as unknown as UnknownAction);
          },
        }) as unknown as UnknownAction
      );
    };

    if (status === ONS_ARCHIVE_STATUS.ERROR) {
      return (
        <Chip
          label={t(`ons.${status}Status`)}
          color='error'
          size='medium'
          variant='filled'
        />
      );
    }

    if (status === ONS_ARCHIVE_STATUS.NEW) {
      return (
        <Chip
          label={t(`ons.${status}Status`)}
          color='primary'
          size='medium'
          variant='filled'
          sx={{
            backgroundColor: 'rgba(144, 202, 249, 1)',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        />
      );
    }

    if (status === ONS_ARCHIVE_STATUS.REBUILD) {
      return (
        <Box className={classes.restoreBlock}>
          <Chip
            label={t(`ons.${status}Status`)}
            color='primary'
            size='medium'
            variant='filled'
            sx={{
              backgroundColor: 'rgba(144, 202, 249, 1)',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          />
          <Tooltip title={t('ons.startRebuild')} arrow placement='top'>
            <IconButton color='default' size='medium' onClick={startRebuild}>
              <RestoreIcon color='primary' className={classes.tableIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }

    if (
      status === ONS_ARCHIVE_STATUS.LOADING ||
      status === ONS_ARCHIVE_STATUS.BUILDING
    ) {
      return (
        <Box style={{ gap: '1rem' }}>
          <BorderLinearProgress
            variant='determinate'
            value={percent_processed}
          />
          <Typography variant='body2' color='rgba(255, 255, 255, 1)'>
            {t(`ons.${status}Status`)}
          </Typography>
        </Box>
      );
    }

    return (
      <>
        <Chip
          label={t(`ons.${status}Status`)}
          color='primary'
          size='medium'
          variant='filled'
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
            color: 'rgba(255, 255, 255, 1)',
          }}
        />
        {visible ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open
            autoHideDuration={4000}
            key='topright'
          >
            <Alert variant='filled' severity='success'>
              <Typography variant='body1'>
                {t('ons.archiveGeneratedText', { uuid })}
              </Typography>
            </Alert>
          </Snackbar>
        ) : null}
      </>
    );
  }
);

export default StatusCell;

import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import {
  ACCEPT_LANGUAGE_HEADER,
  ACCESS_TOKEN_HEADER,
  LOCAL_STORAGE_AUTH_KEY,
  REFRESH_TOKEN,
  REFRESH_TOKEN_HEADER,
} from './constants';
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from '../../../view/common/router/helpers';
import { IAuthData } from '../../../view/common/router/types';

const http = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
});

http.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const authData: IAuthData | null = getDataFromLocalStorage(
      LOCAL_STORAGE_AUTH_KEY
    );

    if (authData) {
      config.headers[ACCESS_TOKEN_HEADER] = authData.accessToken;
    }

    config.headers[ACCEPT_LANGUAGE_HEADER] =
      localStorage.getItem('i18nextLng') || 'uk';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const refreshAccessToken = async () => {
  const authData = getDataFromLocalStorage(LOCAL_STORAGE_AUTH_KEY);
  const response = await http.get(REFRESH_TOKEN, {
    headers: {
      [REFRESH_TOKEN_HEADER]: (authData as IAuthData).refreshToken,
    },
  });

  const accessToken = response.headers[ACCESS_TOKEN_HEADER];
  const refreshToken = response.headers[REFRESH_TOKEN_HEADER];
  setDataToLocalStorage(LOCAL_STORAGE_AUTH_KEY, { accessToken, refreshToken });

  return accessToken;
};

http.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 403 &&
      !originalRequest._retry &&
      originalRequest.url !== REFRESH_TOKEN
    ) {
      originalRequest._retry = true;
      axios.defaults.headers.common[ACCESS_TOKEN_HEADER] =
        await refreshAccessToken();
      return http(originalRequest);
    }

    if (
      error.response.status === 403 &&
      originalRequest.url === REFRESH_TOKEN
    ) {
      removeDataFromLocalStorage(LOCAL_STORAGE_AUTH_KEY);
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default http;

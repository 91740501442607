import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWayPointRequest } from '../../domain/ons';
import { UserError } from '../../domain/user';
import {
  SUCCESS_STATUS,
  WARNING_STATUS,
} from '../../infra/common/httpService/constants';
import {
  cleatNotificationsData,
  setNonDeletedUuids,
  setDeselectedAllUuids,
} from './ons.slice';

export const getMapWayPoint = createAsyncThunk(
  'ons/getMapWayPoint',
  async (
    payload: {
      title: string;
      list_waypoints: IWayPointRequest[];
      onSuccess: () => void;
    },
    { extra: container }
  ) => {
    try {
      const response = await (container as any)
        // @ts-ignore
        .getMapWayPoint(payload);

      if (response.status === SUCCESS_STATUS) {
        payload.onSuccess();
        return response;
      }

      return null;
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getOnsHistory = createAsyncThunk(
  'ons/getOnsHistory',
  async (payload: string, { extra: container }) => {
    try {
      return await (container as any)
        // @ts-ignore
        .getOnsHistory(payload);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const getProcessingStatus = createAsyncThunk(
  'ons/getProcessingStatus',
  async (payload: string, { extra: container }) => {
    try {
      return await (container as any)
        // @ts-ignore
        .getProcessingStatus(payload);
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const generateArchive = createAsyncThunk(
  'ons/generateArchive',
  async (
    { uuid, onSuccess }: { uuid: string; onSuccess: () => void },
    { extra: container }
  ) => {
    try {
      const response = await (container as any)
        // @ts-ignore
        .generateArchive(uuid);

      if (response.status === SUCCESS_STATUS) {
        onSuccess();
      }
    } catch (error) {
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

export const deleteRequests = createAsyncThunk(
  'ons/deleteRequests',
  async (
    {
      requests,
      onSuccess,
      onFailure,
      options,
    }: {
      requests: string[];
      onSuccess: (status: 'success' | 'warning', message: string) => void;
      onFailure: (status: 'error', message: string) => void;
      options: string;
    },
    { extra: container, dispatch }
  ) => {
    try {
      dispatch(cleatNotificationsData());
      const response = await (container as any)
        // @ts-ignore
        .deleteRequests(requests);

      if (response.status === WARNING_STATUS) {
        onSuccess(response.status, response.data.msg);
        dispatch(setDeselectedAllUuids());
        dispatch(setNonDeletedUuids(response.data.details));
        dispatch(getOnsHistory(decodeURIComponent(options)));

        return requests;
      }

      if (response.status === SUCCESS_STATUS) {
        onSuccess(
          response.status,
          response.data.msg === 'success'
            ? 'This file deleted successfully'
            : response.data.msg
        );

        dispatch(getOnsHistory(decodeURIComponent(options)));

        return requests;
      }

      return null;
    } catch (error) {
      onFailure(
        'error',
        (error as Error).message || (error as UserError).detail
      );
      throw (error as Error).message || (error as UserError).detail;
    }
  }
);

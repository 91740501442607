import { FC, ReactElement, memo } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const StartManualIcon: FC = memo(
  (): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <g clipPath='url(#clip0_2321_6968)'>
          <path
            d='M20.7647 17.9348C20.9147 17.3303 20.996 16.6986 20.9999 16.0484V5.73091C20.9896 5.0497 20.4336 4.5 19.75 4.5C19.06 4.5 18.5 5.06 18.5 5.75V11.5C18.5 11.78 18.28 12 18 12C17.72 12 17.5 11.78 17.5 11.5V2.75C17.5 2.06 16.94 1.5 16.25 1.5C15.56 1.5 15 2.06 15 2.75V11.5C15 11.6844 14.9045 11.8429 14.7593 11.9295L20.7647 17.9348Z'
            fill='white'
          />
          <path
            d='M14 1.25V11.1701L11.5 8.67014V1.25C11.5 0.56 12.06 0 12.75 0C13.44 0 14 0.56 14 1.25Z'
            fill='white'
          />
          <path
            d='M10.5 3.25V7.67014L8 5.17014V3.25C8 2.56 8.56 2 9.25 2C9.94 2 10.5 2.56 10.5 3.25Z'
            fill='white'
          />
          <path
            d='M2.80989 2.81008C2.41989 2.42008 1.78989 2.42008 1.39989 2.81008C0.999895 3.20008 0.999895 3.83008 1.38989 4.22008L7.99998 10.8302V15H7.57998C7.37998 15 7.19998 14.88 7.11998 14.68L5.83998 11.47C5.61998 10.92 5.15998 10.49 4.59998 10.31L3.80998 10.05C2.99998 9.79002 2.25998 10.58 2.56998 11.37L5.59998 18.98C6.80998 22.01 9.73998 24 13 24C15.2532 24 17.2881 23.0697 18.7418 21.572L19.7799 22.6101C20.1699 23.0001 20.7999 23.0001 21.1899 22.6101C21.5799 22.2201 21.5799 21.5801 21.1899 21.1901L2.80989 2.81008Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_2321_6968'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
);

export default StartManualIcon;

import { FC, ReactElement, memo } from 'react';

const CalendarIcon: FC = memo(
  (): ReactElement => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.409 19C16.633 16.601 15.132 15.115 13.143 13.398C15.0894 11.842 17.5081 10.9961 20 11V3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H6V1H8V5H4V12C9.22 12 13.662 14.462 15.313 19H17.409ZM18 1V5H10V3H16V1H18ZM16.5 10C16.1022 10 15.7206 9.84196 15.4393 9.56066C15.158 9.27936 15 8.89782 15 8.5C15 8.10218 15.158 7.72064 15.4393 7.43934C15.7206 7.15804 16.1022 7 16.5 7C16.8978 7 17.2794 7.15804 17.5607 7.43934C17.842 7.72064 18 8.10218 18 8.5C18 8.89782 17.842 9.27936 17.5607 9.56066C17.2794 9.84196 16.8978 10 16.5 10Z'
        fill='#fff'
      />
    </svg>
  )
);

export default CalendarIcon;

import { VehicleRepository } from '../../domain/vehicle';

type Dependencies = {
  vehicleRepository: VehicleRepository;
};

export default ({ vehicleRepository }: Dependencies) =>
  async (uuid: string, list_ids: string[], photo_type: 'photo' | 'slide') => {
    try {
      return await vehicleRepository.deleteStreamingPhotosRequest(
        uuid,
        list_ids,
        photo_type
      );
    } catch (error) {
      throw error;
    }
  };

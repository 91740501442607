export const transformBrowserLanguage = (): string => {
  return localStorage.getItem('i18nextLng') || 'uk';
};

export const showHelperText = (error: string, text: string): string => {
  if (!error) {
    return '';
  }
  if (error.includes(text)) {
    return error;
  }

  return '';
};

export const showErrorStatus = (error: string, text: string): boolean => {
  if (!error) {
    return false;
  }

  return error.includes(text);
};

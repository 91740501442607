import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import classes from '../../styles/index.module.css';

interface IProps {
  setVisibleSettings: (visibility: boolean) => void;
}

const Header: FC<IProps> = ({ setVisibleSettings }): ReactElement => {
  const { t } = useTranslation();
  return (
    <Box className={classes.settingsWidgetHeader}>
      <Typography variant='subtitle2'>
        {t('vehiclesList.mapSettingsTitle')}
      </Typography>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={() => setVisibleSettings(false)}
      >
        <path
          d='M6.99974 5.58574L11.9497 0.635742L13.3637 2.04974L8.41374 6.99974L13.3637 11.9497L11.9497 13.3637L6.99974 8.41374L2.04974 13.3637L0.635742 11.9497L5.58574 6.99974L0.635742 2.04974L2.04974 0.635742L6.99974 5.58574Z'
          fill='#14151A'
        />
      </svg>
    </Box>
  );
};

export default Header;

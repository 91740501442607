import { FC, ReactElement, memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { socketSend } from '../../../../infra/common/socketService';
import {
  takeOffCommand,
  // sendMissionCommand,
  droneCommand,
} from '../../../../infra/common/socketService/commands/droneCommands';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  VEHICLE_TYPES,
  vehicleActionsByType,
} from '../../../../domain/vehicle';
import {
  setController,
  setEngineRunning,
} from '../../../../store/vehicle/vehicle.slice';
import {
  disarmCommand,
  startEngineCommand,
} from '../../../../infra/common/socketService/commands/commonCommands';
import ArmIcon from '../../../assets/ArmIcon';
import TakeOffIcon from '../../../assets/TakeOffIcon';
import LandIcon from '../../../assets/LandIcon';
import classes from '../../../styles/vehicle.module.css';

const DroneElementsControls: FC = memo((): ReactElement => {
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const controller = useAppSelector((state) => state.vehicles.controller);

  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const dispatch = useAppDispatch();
  const engineRunning = useAppSelector((state) => state.vehicles.engineRunning);

  // const [missionValue, setMissionValue] = useState<string>('');
  const [land, setLand] = useState<boolean>(true);

  const { t } = useTranslation();

  const startArm = async (): Promise<void> => {
    await socketSend(
      startEngineCommand(
        vehicleActionsByType[vehicleType as VEHICLE_TYPES].armCommand
      ),
      timeProcessing
    );
    await dispatch(setEngineRunning(!engineRunning));
  };

  const disarm = async (): Promise<void> => {
    if (vehicleType === VEHICLE_TYPES.DRONE) {
      await socketSend(disarmCommand('drone_disarm'), timeProcessing);
    }
    if (vehicleType === VEHICLE_TYPES.PLANE) {
      await socketSend(disarmCommand('plane_disarm'), timeProcessing);
    }
  };

  const startTakeOffControl = async (): Promise<void> => {
    if (vehicleType === VEHICLE_TYPES.DRONE) {
      await socketSend(takeOffCommand('drone_take_off'), timeProcessing);
    }
    if (vehicleType === VEHICLE_TYPES.PLANE) {
      await socketSend(takeOffCommand('plane_take_off'), timeProcessing);
    }
  };

  const startLandControl = async (): Promise<void> => {
    if (vehicleType === VEHICLE_TYPES.DRONE) {
      await socketSend(droneCommand('drone_to_land'), timeProcessing);
    }
    if (vehicleType === VEHICLE_TYPES.PLANE) {
      await socketSend(droneCommand('plane_to_land'), timeProcessing);
    }
  };

  // const startMission = async (): Promise<void> => {
  //   await socketSend(droneCommand('arm_auto'), timeProcessing);
  // };
  //
  // const setPause = async (): Promise<void> => {
  //   await socketSend(droneCommand('set_mode_guided'), timeProcessing);
  // };
  //
  // const setResume = async (): Promise<void> => {
  //   await socketSend(droneCommand('set_mode_auto'), timeProcessing);
  // };

  // const setRestart = async (): Promise<void> => {
  //   await socketSend(droneCommand('ardupilot_mission_reset'), timeProcessing);
  // };
  //
  // const sendMission = async (): Promise<void> => {
  //   await socketSend(sendMissionCommand(missionValue), timeProcessing);
  //   setMissionValue('');
  // };
  //
  // const downloadMission = async (): Promise<void> => {
  //   await socketSend(
  //     droneCommand('ardupilot_mission_download'),
  //     timeProcessing
  //   );
  // };
  // const calibrateVehicleLevel = async (): Promise<void> => {
  //   await socketSend(droneCommand('calibrate_vehicle_level'), timeProcessing);
  // };
  // const calibrateAccelerometer = async (): Promise<void> => {
  //   await socketSend(droneCommand('calibrate_accelerometer'), timeProcessing);
  // };
  // const calibrateMagnetometer = async (): Promise<void> => {
  //   await socketSend(droneCommand('calibrate_magnetometer'), timeProcessing);
  // };
  // const calibrateGyro = async (): Promise<void> => {
  //   await socketSend(droneCommand('calibrate_gyro'), timeProcessing);
  // };
  // const calibrateBarometer = async (): Promise<void> => {
  //   await socketSend(droneCommand('calibrate_barometer'), timeProcessing);
  // };
  // const calibrateDevice = async (): Promise<void> => {
  //   await socketSend(droneCommand('calibrate_device'), timeProcessing);
  // };

  return (
    <>
      <Box className={classes.controlItemContainer}>
        <IconButton
          size='large'
          className={
            !engineRunning ? classes.startEngineStart : classes.startEngineStop
          }
          onClick={startArm}
        >
          <ArmIcon />
        </IconButton>
        <Typography variant='caption' className={classes.commandText}>
          {!engineRunning
            ? t('vehicle.startEngineLabel')
            : t('vehicle.stopEngineLabel')}
        </Typography>
      </Box>
      <Box className={classes.controlItemContainer}>
        <IconButton
          size='large'
          className={classes.startEngineStop}
          onClick={land ? startTakeOffControl : startLandControl}
        >
          {land ? <TakeOffIcon /> : <LandIcon />}
        </IconButton>
        <Typography variant='caption' className={classes.commandText}>
          {land ? t('vehicle.takeOffLabel') : t('vehicle.landLabel')}
        </Typography>
      </Box>

      {/* <textarea */}
      {/*  className={classes.textArea} */}
      {/*  value={missionValue} */}
      {/*  placeholder='Write mission...' */}
      {/*  onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => */}
      {/*    setMissionValue(event.target.value) */}
      {/*  } */}
      {/* /> */}
      {/* <Box> */}
      {/*  <Typography variant='body1'>Mission commands</Typography> */}
      {/*  <Button onClick={startMission}>Start mission</Button> */}
      {/* <Button onClick={disarm}>Disarm</Button> */}
      {/*  <Button onClick={setPause}>Pause mission</Button> */}
      {/*  <Button onClick={setResume}>Resume mission</Button> */}
      {/*  <Button onClick={setRestart}>Restart mission</Button> */}
      {/*  <Button onClick={sendMission}>Send mission</Button> */}
      {/*  <Button onClick={downloadMission}>Download mission</Button> */}
      {/* </Box> */}
      {/* <Box> */}
      {/*  <Typography variant='body1'>Calibration commands</Typography> */}
      {/*  <Button onClick={calibrateVehicleLevel}>Calibrate vehicle level</Button> */}
      {/*  <Button onClick={calibrateAccelerometer}> */}
      {/*    Calibrate accelerometer */}
      {/*  </Button> */}
      {/*  <Button onClick={calibrateMagnetometer}>Calibrate magnetometer</Button> */}
      {/*  <Button onClick={calibrateGyro}>Calibrate gyro </Button> */}
      {/*  <Button onClick={calibrateBarometer}>Calibrate barometer </Button> */}
      {/*  <Button onClick={calibrateDevice}>Calibrate device</Button> */}
      {/* </Box> */}
    </>
  );
});

export default DroneElementsControls;

import { FC, ReactElement, Suspense, useEffect } from 'react';
import { useLoaderData, useOutlet, Await } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import Alert from '@mui/material/Alert';
import { AuthProvider } from './AuthProvider';
import { IAuthData } from './types';
import Fallback from '../Fallback';
import baseTheme from '../../styles/themes/baseTheme';
import useWindowSize from '../hooks/useWindowSize';

const AuthLayout: FC = (): ReactElement => {
  const outlet = useOutlet();
  const { authDataPromise }: any = useLoaderData();
  const { windowWidth } = useWindowSize();

  const { t } = useTranslation();

  useEffect(() => {
    const htmlElement: HTMLElement | null = document.querySelector('html');

    if (htmlElement) {
      if (windowWidth <= 850) {
        htmlElement.style.fontSize = '8px';
        return;
      }

      if (windowWidth > 850 && windowWidth <= 1024) {
        htmlElement.style.fontSize = '10px';
        return;
      }

      if (windowWidth > 1024 && windowWidth <= 1600) {
        htmlElement.style.fontSize = '12px';
        return;
      }

      if (windowWidth > 1600 && windowWidth < 1900) {
        htmlElement.style.fontSize = '14px';
        return;
      }

      htmlElement.style.fontSize = '16px';
    }
  }, [windowWidth]);

  return (
    <Suspense fallback={<Fallback />}>
      <Await
        resolve={authDataPromise}
        errorElement={<Alert severity='error'>{t('init.error')}</Alert>}
        children={(authData: IAuthData | null): ReactElement => (
          <ThemeProvider theme={baseTheme}>
            <AuthProvider authData={authData}>{outlet}</AuthProvider>
          </ThemeProvider>
        )}
      />
    </Suspense>
  );
};

export default AuthLayout;

import { FC, memo, ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import displayImageName from '../../helpers/displayImageName';
import { setSelectedSlides } from '../../../../store/vehicle/vehicle.slice';
import { useAppDispatch } from '../../../../store';
import SingleImageDelete from '../Actions/SingleImageDelete';
import SingleImageFullscreen from '../Actions/SingleImageFullscreen';
import SingleImageDownload from '../Actions/SingleImageDownload';
import SingleImageSelected from '../Actions/SingleImageSelected';
import classes from '../index.module.css';

interface IProps {
  imageID: number;
  imageURL: string;
  selected: boolean;
  index: number;
  createdAt: string;
}

const SingleSlide: FC<IProps> = memo(
  ({ imageID, imageURL, selected, index, createdAt }): ReactElement => {
    const dispatch = useAppDispatch();
    const [hover, setHover] = useState<boolean>(false);

    return (
      <Box
        className={classes.singlePhotoContainer}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img
          src={imageURL}
          alt={imageURL}
          width='100%'
          height='100%'
          style={{ opacity: selected ? 0.8 : 1 }}
          className={classes.mediaListSingleImage}
          onClick={() =>
            dispatch(
              setSelectedSlides({
                id: imageID,
                image: imageURL,
                created_at: createdAt,
              })
            )
          }
        />
        {selected ? (
          <SingleImageSelected
            imageURL={imageURL}
            imageID={imageID}
            createdAt={createdAt}
          />
        ) : null}
        {hover ? (
          <Box className={classes.singlePhotoHoverActions}>
            <SingleImageFullscreen index={index} />
            <SingleImageDownload imageURL={imageURL} />
            <SingleImageDelete
              imageURL={imageURL}
              imageID={imageID}
              type='slide'
            />
          </Box>
        ) : null}
        <Box className={classes.nonVideoDescription}>
          <Typography variant='caption'>
            {displayImageName(imageURL)}
          </Typography>
        </Box>
      </Box>
    );
  }
);

export default SingleSlide;

import { FC, memo, ReactElement, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { UnknownAction } from '@reduxjs/toolkit';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { listStreamingSlides } from '../../../../store/vehicle/vehicle.thunks';
import { ISelectedPhoto, IStreamingPhoto } from '../../../../domain/vehicle';
import SingleSlide from './SingleSlide';
import getLocalISO from '../../helpers/getLocalISO';
import classes from '../index.module.css';

const Slides: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
  const listSlidesLoading = useAppSelector(
    (state) => state.vehicles.listSlidesLoading
  );
  const listSlides = useAppSelector((state) => state.vehicles.listSlides);
  const slidesStartDate = useAppSelector(
    (state) => state.vehicles.mediaList.slidesStartDate
  );
  const slidesEndDate = useAppSelector(
    (state) => state.vehicles.mediaList.slidesEndDate
  );
  const slidesLimit = useAppSelector(
    (state) => state.vehicles.mediaList.slidesLimit
  );
  const slidesPage = useAppSelector(
    (state) => state.vehicles.mediaList.slidesPage
  );
  const selectedSlides = useAppSelector(
    (state) => state.vehicles.mediaList.selectedSlides
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (uuid) {
      dispatch(
        listStreamingSlides({
          uuid,
          from: slidesStartDate || '2011-04-11T11:51:00',
          to: slidesEndDate || getLocalISO(),
          limit: slidesLimit,
          page: slidesPage,
        }) as unknown as UnknownAction
      );
    }
  }, [uuid, slidesStartDate, slidesEndDate, slidesLimit, slidesPage]);

  if (listSlidesLoading) {
    return (
      <Box className={classes.mediaListLoader}>
        <CircularProgress color='inherit' />
        <Typography variant='caption'>
          {t('vehicle.loadingSlidesLabel')}
        </Typography>
      </Box>
    );
  }

  if (!listSlides.length) {
    return (
      <Box className={classes.mediaListNoData}>
        <Typography variant='caption'>{t('vehicle.noSlidesLabel')}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.videoListContainer}>
      {listSlides.map(
        (slide: IStreamingPhoto, index: number): ReactElement => (
          <SingleSlide
            key={slide.id}
            imageURL={slide.image}
            index={index}
            imageID={slide.id}
            createdAt={slide.created_at}
            selected={
              !!selectedSlides.find(
                (selectedSlide: ISelectedPhoto): boolean =>
                  selectedSlide.id === slide.id
              )
            }
          />
        )
      )}
    </Box>
  );
});

export default Slides;

export interface CarElementControlCommand {
  type_task: string;
  task_uuid: null;
  step: null;
  is_check: boolean;
  command: {
    command_to_drone: string;
    is_online: boolean;
    move_to: null;
    take_off: null;
    ned_velocity: null;
    mission: null;
  };
  telemetry: null;
  is_online: boolean;
  is_force: boolean;
  is_controller: boolean;
  time_processing: { frontend_sent: number };
}

// переключение передач
export const carChangeGearCommand = (gear: string): any => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'car_change_gear_main',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    car_change_gear: gear,
  },
  telemetry: null,
  is_online: true,
  is_force: false,
  is_controller: true,
  time_processing: { frontend_sent: 0 },
});

// управление элементами машины (дворники ...)
export const carElementControlCommand = (
  carElement: string
): CarElementControlCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: carElement,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: false,
  is_controller: true,
  time_processing: { frontend_sent: 0 },
});

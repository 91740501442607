/**
 * Take off to height of 0.1 meter.
 * This command should be run ~0.5 sec after start_manual command is sent,
 * before starting to send the joystick axes values.
 */

export interface CommonDroneCommand {
  type_task: string;
  task_uuid: null;
  step: null;
  is_check: boolean;
  command: {
    command_to_drone:
      | 'calibrate_vehicle_level'
      | 'calibrate_accelerometer'
      | 'calibrate_magnetometer'
      | 'calibrate_gyro'
      | 'calibrate_barometer'
      | 'calibrate_device'
      | 'drone_to_land'
      | 'plane_to_land'
      | 'arm_auto'
      | 'set_mode_guided'
      | 'set_mode_auto'
      | 'ardupilot_mission_reset'
      | 'ardupilot_mission_download'
      | 'drone_take_off'
      | 'plane_take_off';
    is_online: boolean;
    move_to: null;
    take_off: null | number;
    ned_velocity: null;
    mission: null;
  };
  telemetry: null;
  is_online: boolean;
  is_force: boolean;
  is_controller: boolean;
  time_processing: { frontend_sent: number };
}

export const droneCommand = (
  command_to_drone:
    | 'calibrate_vehicle_level'
    | 'calibrate_accelerometer'
    | 'calibrate_magnetometer'
    | 'calibrate_gyro'
    | 'calibrate_barometer'
    | 'calibrate_device'
    | 'drone_to_land'
    | 'plane_to_land'
    | 'arm_auto'
    | 'set_mode_guided'
    | 'set_mode_auto'
    | 'ardupilot_mission_reset'
    | 'ardupilot_mission_download'
): CommonDroneCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const takeOffCommand = (
  commandToDrone: 'drone_take_off' | 'plane_take_off'
): CommonDroneCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: commandToDrone,
    is_online: true,
    move_to: null,
    take_off: 0.5,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const nedVelocityCommand = (drone_controls: any) => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'drone_controls',
    is_online: true,
    move_to: null,
    take_off: null,
    drone_controls,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: false,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const sendMissionCommand = (input: string): any => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'ardupilot_mission_upload',
    ardupilot_mission: input,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

import { FC, ReactElement, memo } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const StopManualIcon: FC = memo(
  (): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          d='M21 16C21 20.42 17.42 24 13 24C9.73998 24 6.80998 22.01 5.59998 18.98L2.56998 11.37C2.25998 10.58 2.99998 9.79 3.80998 10.05L4.59998 10.31C5.15998 10.49 5.61998 10.92 5.83998 11.47L7.11998 14.68C7.19998 14.88 7.37998 15 7.57998 15H7.99998V3.25C7.99998 2.56 8.55998 2 9.24998 2C9.93998 2 10.5 2.56 10.5 3.25V11.5C10.5 11.78 10.72 12 11 12C11.28 12 11.5 11.78 11.5 11.5V1.25C11.5 0.56 12.06 0 12.75 0C13.44 0 14 0.56 14 1.25V11.5C14 11.78 14.22 12 14.5 12C14.78 12 15 11.78 15 11.5V2.75C15 2.06 15.56 1.5 16.25 1.5C16.94 1.5 17.5 2.06 17.5 2.75V11.5C17.5 11.78 17.72 12 18 12C18.28 12 18.5 11.78 18.5 11.5V5.75C18.5 5.06 19.06 4.5 19.75 4.5C20.44 4.5 21 5.06 21 5.75V16Z'
          fill='white'
        />
      </svg>
    </Box>
  )
);

export default StopManualIcon;

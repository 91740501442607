import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { UnknownAction } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  clearOnsData,
  IWayPoint,
  setTitleInProcessing,
} from '../../../store/ons/ons.slice';
import { getMapWayPoint } from '../../../store/ons/ons.thunks';
import { IWayPointRequest } from '../../../domain/ons';
import classes from '../../styles/ons.module.css';

interface IProps {
  disabledForClear: boolean;
  disabledForGenerate: boolean;
  wayPoints: IWayPoint[];
}

const RouteActions: FC<IProps> = memo(
  ({ disabledForClear, disabledForGenerate, wayPoints }): ReactElement => {
    const dispatch = useAppDispatch();
    const title = useAppSelector((state) => state.ons.title);
    const getMapWayPointLoading = useAppSelector(
      (state) => state.ons.getMapWayPointLoading
    );

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
      <Box className={classes.routeActionsContainer}>
        <Button
          variant='text'
          color='primary'
          size='medium'
          disabled={!disabledForClear}
          onClick={() => dispatch(clearOnsData())}
          className={
            !disabledForClear
              ? classes.clearAllButtonDisabled
              : classes.clearAllButton
          }
        >
          {t('ons.clearAllButtonText')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='medium'
          disabled={!disabledForGenerate || getMapWayPointLoading}
          className={
            !disabledForGenerate
              ? classes.generateFileButtonDisabled
              : classes.generateFileButton
          }
          onClick={() =>
            dispatch(
              getMapWayPoint({
                title,
                list_waypoints: wayPoints.map(
                  (point: IWayPoint): IWayPointRequest => ({
                    latitude: point.lat,
                    longitude: point.lng,
                  })
                ),
                onSuccess: () => {
                  dispatch(setTitleInProcessing(title));
                  navigate('/ons/history');
                },
              }) as unknown as UnknownAction
            )
          }
        >
          {getMapWayPointLoading
            ? t('ons.generatingFileButtonText')
            : t('ons.generateFileButtonText')}
        </Button>
      </Box>
    );
  }
);

export default RouteActions;

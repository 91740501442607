import { VideoStreamingCommand } from '../infra/common/socketService/commands/streamingCommands';
import { CommonCommand } from '../infra/common/socketService/commands/commonCommands';
import { TractorCommand } from '../infra/common/socketService/commands/tractorControlCommands';
import { CarElementControlCommand } from '../infra/common/socketService/commands/carControlCommands';
import { CommonDroneCommand } from '../infra/common/socketService/commands/droneCommands';

export enum SOCKET_STATES {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

export type CommandType =
  | VideoStreamingCommand
  | CommonCommand
  | TractorCommand
  | CarElementControlCommand
  | CommonDroneCommand;

import { FC, ReactElement, useEffect, useState } from 'react';
import { Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  addWayPoints,
  setWayPointsLength,
  clearOnsData,
  IWayPoint,
  setElevationService,
  setEditable,
} from '../../../store/ons/ons.slice';
import { polyLineStyle, mapConfig, setElevation } from './mapConfig';
import classes from '../../styles/ons.module.css';
import GoogleMapMarker from './GoogleMapMarker';

let flightPath: google.maps.Polyline;
const {
  gestureHandling,
  style,
  mapTypeControlOptions,
  mapTypeId,
  defaultCenter,
  defaultZoom,
} = mapConfig;

const GoogleMap: FC = (): ReactElement | null => {
  const dispatch = useAppDispatch();
  const wayPoints = useAppSelector((state) => state.ons.wayPoints);
  const focusOnLastPoint = useAppSelector(
    (state) => state.ons.focusOnLastPoint
  );
  const rowHoverIndex = useAppSelector((state) => state.ons.rowHoverIndex);
  const editable = useAppSelector((state) => state.ons.editable);
  const [dragging, setDragging] = useState<boolean>(false);

  const map = useMap();
  const maps = useMapsLibrary('maps');
  const geometry = useMapsLibrary('geometry');
  const elevation = useMapsLibrary('elevation');

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(clearOnsData());
    };
  }, []);

  useEffect(() => {
    if (elevation) {
      dispatch(setElevationService(elevation));
    }
  }, [elevation]);

  useEffect(() => {
    if (!maps || !geometry) {
      return;
    }

    if (map) {
      if (wayPoints.length) {
        const bounds = new window.google.maps.LatLngBounds();

        if (bounds) {
          if (focusOnLastPoint) {
            const { lat, lng } = wayPoints[wayPoints.length - 1];
            bounds.extend({ lat, lng });
          } else {
            wayPoints.forEach(({ lat, lng }: { lat: number; lng: number }) =>
              bounds.extend({ lat, lng })
            );
          }
        }
        map.fitBounds(bounds);
      } else {
        map.setCenter(defaultCenter);
        map.setZoom(defaultZoom);
      }
    }

    if (flightPath) {
      flightPath.setPath(wayPoints);
    } else {
      flightPath = new maps.Polyline(polyLineStyle(wayPoints));
    }
    flightPath.setMap(map);
    dispatch(
      setWayPointsLength(geometry.spherical.computeLength(flightPath.getPath()))
    );
  }, [wayPoints, map, focusOnLastPoint]);

  useEffect(() => {
    if (!rowHoverIndex) {
      dispatch(setEditable(false));
      return;
    }

    if (rowHoverIndex && editable) {
      if (map) {
        if (wayPoints.length) {
          const bounds = new window.google.maps.LatLngBounds();

          if (bounds) {
            const { lat, lng } = wayPoints[rowHoverIndex - 1];
            bounds.extend({ lat, lng });
          }
          map.fitBounds(bounds);
        }
      }
    }
  }, [rowHoverIndex, editable]);

  const setWayPointHandler = async ({
    detail: { latLng },
  }: any): Promise<void> => {
    if (dragging) {
      return;
    }

    if (latLng) {
      const { lat, lng } = latLng;

      dispatch(
        addWayPoints({
          lat,
          lng,
          elevation: await setElevation(elevation, latLng),
        })
      );
    }
  };

  const centerMapByBounds = () => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      wayPoints.forEach(({ lat, lng }: { lat: number; lng: number }) =>
        bounds.extend({ lat, lng })
      );
      map.fitBounds(bounds);
    }
  };

  return (
    <Box className={classes.mapContainer}>
      <Map
        className={classes.map}
        style={style}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        mapId={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        gestureHandling={gestureHandling}
        mapTypeControlOptions={mapTypeControlOptions}
        mapTypeId={mapTypeId}
        zoomControl
        onClick={setWayPointHandler}
      >
        {wayPoints.map(
          (marker: IWayPoint, index: number): ReactElement => (
            <GoogleMapMarker
              key={index}
              index={index}
              setDragging={setDragging}
              lng={marker.lng}
              lat={marker.lat}
              elevation={elevation}
              hover
            />
          )
        )}
      </Map>
      {!wayPoints.length ? (
        <Box className={classes.noWayPoints}>
          <Typography variant='body1' color='rgba(255, 255, 255, 1)'>
            {t('ons.noPointsMessage')}
          </Typography>
        </Box>
      ) : null}
      <Box className={classes.centeredWidget} onClick={centerMapByBounds}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_231_4627)'>
            <path
              d='M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM20.94 11C20.48 6.83 17.17 3.52 13 3.06V2C13 1.45 12.55 1 12 1C11.45 1 11 1.45 11 2V3.06C6.83 3.52 3.52 6.83 3.06 11H2C1.45 11 1 11.45 1 12C1 12.55 1.45 13 2 13H3.06C3.52 17.17 6.83 20.48 11 20.94V22C11 22.55 11.45 23 12 23C12.55 23 13 22.55 13 22V20.94C17.17 20.48 20.48 17.17 20.94 13H22C22.55 13 23 12.55 23 12C23 11.45 22.55 11 22 11H20.94ZM12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19Z'
              fill='black'
            />
          </g>
          <defs>
            <clipPath id='clip0_231_4627'>
              <rect width='24' height='24' fill='white' />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </Box>
  );
};

export default GoogleMap;

import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ROUTES from '../common/router/constants';
import Header from '../common/Header';
import classes from '../styles/noaccess.module.css';

const NoAccess: FC = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Box className={classes.noAccessContainer}>
        <Typography variant='body1'>{t('noAccess.noAccessMessage')}</Typography>
        <Button
          variant='contained'
          className={classes.backToLogin}
          onClick={() => navigate(ROUTES.AUTH)}
        >
          {t('noAccess.backToLogin')}
        </Button>
      </Box>
    </>
  );
};

export default NoAccess;

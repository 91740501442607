import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { normalizeDeg } from '../../../../common/helpers/gamepadControls';

interface WheelIndicatorProps {
  angleOfRotation: number;
}

const WheelIndicator: React.FC<WheelIndicatorProps> = ({ angleOfRotation }) => {
  const [currentDegree, setCurrentDegree] = useState<number | null>(
    normalizeDeg(angleOfRotation)
  );

  useEffect(() => {
    if (angleOfRotation) {
      setCurrentDegree(normalizeDeg(angleOfRotation));
    } else {
      setCurrentDegree(0);
    }
  }, [angleOfRotation]);

  return (
    <Box width='100%' textAlign='center'>
      <Slider
        value={!currentDegree ? 0 : currentDegree}
        min={-450}
        max={450}
        valueLabelDisplay='auto'
        disabled
      />
    </Box>
  );
};

export default WheelIndicator;

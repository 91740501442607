import { FC, memo, ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import { setSelectedPhotos } from '../../../../store/vehicle/vehicle.slice';
import { useAppDispatch } from '../../../../store';
import classes from '../index.module.css';

interface IProps {
  imageID: number;
  imageURL: string;
  createdAt: string;
}

const SingleImageSelected: FC<IProps> = memo(
  ({ imageID, imageURL, createdAt }): ReactElement => {
    const dispatch = useAppDispatch();
    return (
      <IconButton
        className={classes.singlePhotoCheckbox}
        size='small'
        onClick={() =>
          dispatch(
            setSelectedPhotos({
              id: imageID,
              image: imageURL,
              created_at: createdAt,
            })
          )
        }
      >
        <CheckIcon className={classes.singlePhotoIcon} />
      </IconButton>
    );
  }
);

export default SingleImageSelected;

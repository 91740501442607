import { useEffect, useCallback } from 'react';

const useEscapeKey = (handleClose: () => void): void => {
  const handleEscKey = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect((): (() => void) => {
    document.addEventListener('keyup', handleEscKey, false);

    return () => {
      document.removeEventListener('keyup', handleEscKey, false);
    };
  }, [handleEscKey]);
};

export default useEscapeKey;

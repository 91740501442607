import { OnsRepository } from '../../domain/ons';

type Dependencies = {
  onsRepository: OnsRepository;
};

export default ({ onsRepository }: Dependencies) =>
  async (options: string) => {
    try {
      return await onsRepository.getOnsHistoryRequest(options);
    } catch (error) {
      throw error;
    }
  };

import { useEffect } from 'react';

const useOutsideClick = (
  ref: { current: HTMLElement | null },
  callback: () => void
): void => {
  const handleClick = (event: any): void => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;

import { FC, ReactElement, memo } from 'react';

const CarIcon: FC = memo(
  (): ReactElement => (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 9.5999V10.7999C16 11.2499 15.625 11.5999 15.2 11.5999H14.35C14.175 12.7499 13.175 13.5999 12 13.5999C10.8 13.5999 9.8 12.7499 9.625 11.5999H6.35C6.175 12.7499 5.175 13.5999 4 13.5999C2.8 13.5999 1.8 12.7499 1.625 11.5999H0.8C0.35 11.5999 0 11.2499 0 10.7999V7.9999C0 7.3249 0.4 6.7499 1 6.5249L2.05 3.9249C2.4 2.9999 3.3 2.3999 4.275 2.3999H8.825C9.55 2.3999 10.225 2.7499 10.7 3.3249L13.2 6.4249C14.775 6.6249 16 7.9749 16 9.5999ZM4.275 3.9999C3.95 3.9999 3.65 4.1999 3.525 4.5249L2.775 6.3999H5.6V3.9999H4.275ZM6.8 6.3999H11.125L9.45 4.2999C9.3 4.1249 9.05 3.9999 8.825 3.9999H6.8V6.3999ZM13.125 11.5999C13.175 11.4749 13.2 11.3499 13.2 11.1999C13.2 10.5499 12.65 9.9999 12 9.9999C11.325 9.9999 10.8 10.5499 10.8 11.1999C10.8 11.3499 10.8 11.4749 10.85 11.5999C11.025 12.0749 11.475 12.3999 12 12.3999C12.5 12.3999 12.95 12.0749 13.125 11.5999ZM5.125 11.5999C5.175 11.4749 5.2 11.3499 5.2 11.1999C5.2 10.5499 4.65 9.9999 4 9.9999C3.325 9.9999 2.8 10.5499 2.8 11.1999C2.8 11.3499 2.8 11.4749 2.85 11.5999C3.025 12.0749 3.475 12.3999 4 12.3999C4.5 12.3999 4.95 12.0749 5.125 11.5999Z'
        fill='white'
      />
    </svg>
  )
);

export default CarIcon;

import { FC, memo, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classes from '../../../styles/stream.module.css';

const recordingTimeouts: { [key: string]: NodeJS.Timeout } = {};

interface IProps {
  camera: number;
  videoSaved: boolean;
  fullScreen: boolean;
}

const VideoRecordingInfo: FC<IProps> = memo(
  ({ camera, videoSaved, fullScreen }): ReactElement => {
    const [videoRecordingCount, setVideoRecordingCount] = useState<number>(0);

    const { t } = useTranslation();

    useEffect(() => {
      return () => {
        clearInterval(recordingTimeouts[camera]);
      };
    }, []);

    useEffect(() => {
      if (!videoSaved) {
        recordingTimeouts[camera] = setInterval(() => {
          setVideoRecordingCount(videoRecordingCount + 1);
        }, 1000);
      }

      return () => {
        clearInterval(recordingTimeouts[camera]);
      };
    }, [videoRecordingCount, videoSaved]);

    const showTime = (videoRecordingCount: number): string => {
      const counter: number = videoRecordingCount % 3600;
      let minutes: string | number = Math.floor(counter / 60);
      let seconds: string | number = counter % 60;

      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      return `${minutes}:${seconds}`;
    };

    return (
      <Box
        className={classes.videoRecordingInfo}
        style={{
          backgroundColor: videoSaved ? '#44b700' : '#0F132499',
          top: fullScreen ? '3.8rem' : '0.8rem',
        }}
      >
        {videoSaved ? (
          <Typography
            variant='caption'
            sx={{ color: '#fff', fontSize: '13px', fontWeight: 400 }}
          >
            {t('vehicle.recordSavedLabel')}
          </Typography>
        ) : (
          <>
            <span className={classes.redDot} />
            <Typography
              variant='caption'
              sx={{ color: '#fff', fontSize: '13px', fontWeight: 400 }}
            >
              {t('vehicle.recordingLabel')}
            </Typography>
            <Typography
              variant='caption'
              sx={{ color: '#fff', fontSize: '13px', fontWeight: 400 }}
            >
              {showTime(videoRecordingCount)}
            </Typography>
          </>
        )}
      </Box>
    );
  }
);

export default VideoRecordingInfo;

import { FC, ReactElement, useState, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel } from '@mui/material';
import classes from '../../styles/vehicles.module.css';

interface IProps {
  vehicle_types: string;
  handleQueryParams: (key: string, value: string) => void;
  removeVehiclesQueryParam: (key: string) => void;
}

const ChooseVehicleType: FC<IProps> = memo(
  ({
    vehicle_types,
    removeVehiclesQueryParam,
    handleQueryParams,
  }): ReactElement => {
    const [vehicleTypes, setVehicleTypes] = useState<string[]>(
      vehicle_types?.split(',') || []
    );

    const { t, i18n } = useTranslation();

    const vehicleTypesConstants: { label: string; value: string }[] =
      useMemo((): { label: string; value: string }[] => {
        return [
          { label: t('vehiclesList.listDeviceTractorLabel'), value: 'tractor' },
          { label: t('vehiclesList.listDeviceCarLabel'), value: 'car' },
          { label: t('vehiclesList.listDeviceBoatLabel'), value: 'boat' },
          { label: t('vehiclesList.listDeviceDroneLabel'), value: 'drone' },
          { label: t('vehiclesList.listDevicePlaneLabel'), value: 'plane' },
          {
            label: t('vehiclesList.listDeviceTractorGeminiLabel'),
            value: 'tractor_gemini',
          },
          {
            label: t('vehiclesList.listDeviceTractorHTILabel'),
            value: 'tractor_khti',
          },
        ];
      }, [i18n.language]);

    const chooseVehicleType = (
      event: SelectChangeEvent<typeof vehicleTypes>
    ): void => {
      const values: string[] | string = event.target.value;

      setVehicleTypes(values as string[]);

      if (!values.length) {
        removeVehiclesQueryParam('vehicle_types');
      } else {
        handleQueryParams('vehicle_types', (values as string[]).join(','));
      }
    };

    return (
      <FormControl variant='standard' size='small' fullWidth sx={{ m: 1 }}>
        <InputLabel
          sx={{
            '&.MuiInputLabel-root': {
              color: '#fafafa',
              fontSize: '16px',
              fontWeight: '400',
            },
            '&.MuiInputLabel-root.Mui-focused': {
              color: '#2196F3',
            },
          }}
          id='demo-multiple-name-label'
        >
          {t('vehiclesList.listDeviceTypeLabel')}
        </InputLabel>
        <Select
          MenuProps={{
            sx: {
              '& .MuiList-root': {
                padding: '0',
                backgroundColor: '#424242',
              },
            },
          }}
          sx={{
            '&:hover': {
              '&:before': {
                borderBottom: '1px solid #757575 !important',
              },
            },
            '&:before': {
              borderColor: '#757575',
            },
            '& .MuiSelect-icon': {
              fill: '#757575',
            },
            '& .MuiSelect-icon.MuiSelect-iconOpen': {
              fill: '#2196F3',
            },
            '& .MuiInput-input': {
              color: '#fafafa',
            },
          }}
          labelId='demo-multiple-name-label'
          id='demo-multiple-name'
          multiple
          renderValue={(selected): string => selected.join(', ')}
          value={vehicleTypes}
          onChange={chooseVehicleType}
        >
          {vehicleTypesConstants.map(
            (vehicleType: { label: string; value: string }): ReactElement => (
              <MenuItem
                key={vehicleType.value}
                value={vehicleType.value}
                sx={{
                  '&:hover': {
                    backgroundColor: '#2196F30A',
                  },
                }}
              >
                <Checkbox
                  checked={vehicleTypes.includes(vehicleType.value)}
                  sx={{ padding: '0 8px 0 0', color: '#fafafa' }}
                />
                <ListItemText
                  className={classes.vehiclesListTextColor}
                  primary={
                    <Typography variant='caption'>
                      {vehicleType.label}
                    </Typography>
                  }
                />
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    );
  }
);

export default ChooseVehicleType;

import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../store';
import classes from '../../../common/map/styles/index.module.css';
import fromDegreesToDMS from '../../../common/helpers/fromDegreesToDMS';
import useCopyToClipboard from '../../../common/hooks/useCopyToClipboard';

interface IProps {
  containerClassName: string;
  latitude: number;
  longitude: number;
}
const MiniCoordinates: FC<IProps> = ({
  containerClassName,
  latitude,
  longitude,
}): ReactElement => {
  const [copiedText, copy] = useCopyToClipboard();
  const { t } = useTranslation();

  return (
    <Tooltip
      arrow
      title={copiedText ? t('vehicle.copiedLabel') : t('vehicle.copyLabel')}
    >
      <Box
        className={containerClassName}
        onClick={() =>
          copy(
            `${fromDegreesToDMS(latitude || 0, 'lat')} ${fromDegreesToDMS(longitude || 0, 'long')}`
          )
        }
      >
        <Typography
          variant='caption'
          noWrap
          className={classes.miniMapCoordinatesValue}
        >
          {fromDegreesToDMS(latitude || 0, 'lat')}
        </Typography>
        <Typography
          variant='caption'
          noWrap
          className={classes.miniMapCoordinatesValue}
        >
          {fromDegreesToDMS(longitude || 0, 'long')}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default MiniCoordinates;

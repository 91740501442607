import { FC, ReactElement, memo } from 'react';

const GamepadIcon: FC = memo(
  (): ReactElement => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 4C18.5913 4 20.1174 4.63214 21.2426 5.75736C22.3679 6.88258 23 8.4087 23 10V14C23 15.5913 22.3679 17.1174 21.2426 18.2426C20.1174 19.3679 18.5913 20 17 20H7C5.4087 20 3.88258 19.3679 2.75736 18.2426C1.63214 17.1174 1 15.5913 1 14V10C1 8.4087 1.63214 6.88258 2.75736 5.75736C3.88258 4.63214 5.4087 4 7 4H17ZM17 6H7C5.97376 6 4.98677 6.39444 4.24319 7.10172C3.4996 7.80901 3.05631 8.77504 3.005 9.8L3 10V14C3 15.0262 3.39444 16.0132 4.10172 16.7568C4.80901 17.5004 5.77504 17.9437 6.8 17.995L7 18H17C18.0262 18 19.0132 17.6056 19.7568 16.8983C20.5004 16.191 20.9437 15.225 20.995 14.2L21 14V10C21 8.97376 20.6056 7.98677 19.8983 7.24319C19.191 6.4996 18.225 6.05631 17.2 6.005L17 6ZM10 9V11H12V13H9.999L10 15H8L7.999 13H6V11H8V9H10ZM18 13V15H16V13H18ZM16 9V11H14V9H16Z'
        fill='#fff'
      />
    </svg>
  )
);

export default GamepadIcon;

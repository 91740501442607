import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const LandIcon: FC = memo(
  (): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          d='M20.5 18.9999H3.5C2.95 18.9999 2.5 19.4499 2.5 19.9999C2.5 20.5499 2.95 20.9999 3.5 20.9999H20.5C21.05 20.9999 21.5 20.5499 21.5 19.9999C21.5 19.4499 21.05 18.9999 20.5 18.9999ZM3.51 11.6099L19.34 15.8499C20.14 16.0599 20.96 15.5899 21.18 14.7899C21.39 13.9899 20.92 13.1699 20.12 12.9499L14.81 11.5299L12.23 3.07986C12.12 2.71986 11.84 2.44986 11.48 2.34986C10.8 2.16986 10.13 2.67986 10.13 3.38986V10.2699L5.15 8.94986L4.4 7.08986C4.28 6.79986 4.04 6.57986 3.73 6.49986L3.4 6.40986C3.08 6.31986 2.77 6.55986 2.77 6.88986V10.6399C2.77 11.0999 3.07 11.4899 3.51 11.6099Z'
          fill='white'
        />
      </svg>
    </Box>
  )
);

export default LandIcon;

import { FC, ReactElement, useState, FormEvent, ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { UnknownAction } from '@reduxjs/toolkit';
import Typography from '@mui/material/Typography';
import ROUTES from '../common/router/constants';
import { confirmPasswordRecovery } from '../../store/user/user.thunks';
import { useAppDispatch, useAppSelector } from '../../store';
import { showErrorStatus, showHelperText } from '../common/helpers/utils';
import Checked from '../assets/Checked';
import classes from '../styles/auth.module.css';

const ResetPasswordConfirmPage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const {
    createPasswordConfirmLoading,
    createPasswordConfirmSuccess,
    createPasswordConfirmError,
  } = useAppSelector((state) => state.user);

  const { search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [newPasswordVisibility, setNewPasswordVisibility] =
    useState<boolean>(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const searchParams: URLSearchParams = new URLSearchParams(search);

    await dispatch(
      confirmPasswordRecovery({
        uidb64: searchParams.get('uidb64'),
        token: searchParams.get('token'),
        newPassword: password,
        confirmedPassword: confirmPassword,
      }) as unknown as UnknownAction
    );
  };

  const navigateToLogin = (): void => navigate(ROUTES.AUTH);

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[а-яА-ЯёЁіІ]/g, '');
    setPassword(filteredValue);
  };

  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[а-яА-ЯёЁіІ]/g, '');
    setConfirmPassword(filteredValue);
  };

  if (createPasswordConfirmSuccess) {
    return (
      <>
        <Box className={classes.loginHeaders}>
          <Checked />
          <Typography variant='body1' sx={{ mt: 3 }}>
            {t('resetPasswordConfirm.congratulationTitle')}
          </Typography>
          <Typography variant='caption'>
            {t('resetPasswordConfirm.congratulationSubTitle')}
          </Typography>
        </Box>
        <Button
          fullWidth
          variant='contained'
          sx={{ mt: '2.5rem' }}
          onClick={navigateToLogin}
        >
          {t('resetPasswordConfirm.congratulationButtonText')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Box className={classes.loginHeaders}>
        <Typography variant='body1'>
          {t('resetPasswordConfirm.title')}
        </Typography>
        <Typography variant='caption'>
          {t('resetPasswordConfirm.subTitle')}
        </Typography>
      </Box>
      <Box
        component='form'
        onSubmit={handleSubmit}
        className={classes.inputsContainer}
      >
        <TextField
          error={showErrorStatus(createPasswordConfirmError, 'new password')}
          helperText={showHelperText(
            createPasswordConfirmError,
            'new password'
          )}
          placeholder={t('resetPasswordConfirm.newPasswordPlaceholder')}
          fullWidth
          name='password'
          type={newPasswordVisibility ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() =>
                    setNewPasswordVisibility(!newPasswordVisibility)
                  }
                >
                  {newPasswordVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          error={
            showErrorStatus(createPasswordConfirmError, 'confirmed password') ||
            showErrorStatus(createPasswordConfirmError, 'match') ||
            showErrorStatus(createPasswordConfirmError, 'stronger') ||
            showErrorStatus(createPasswordConfirmError, 'новий') ||
            showErrorStatus(createPasswordConfirmError, 'надійнішим')
          }
          helperText={
            showHelperText(createPasswordConfirmError, 'confirmed password') ||
            showHelperText(createPasswordConfirmError, 'match') ||
            showHelperText(createPasswordConfirmError, 'stronger') ||
            showHelperText(createPasswordConfirmError, 'новий') ||
            showHelperText(createPasswordConfirmError, 'надійнішим')
          }
          placeholder={t('resetPasswordConfirm.confirmPasswordPlaceholder')}
          fullWidth
          name='confirmPassword'
          type={confirmPasswordVisibility ? 'text' : 'password'}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() =>
                    setConfirmPasswordVisibility(!confirmPasswordVisibility)
                  }
                >
                  {confirmPasswordVisibility ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          disabled={createPasswordConfirmLoading}
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: '2.5rem' }}
        >
          {t('resetPasswordConfirm.resetPasswordConfirmButtonText')}
        </Button>
      </Box>
    </>
  );
};

export default ResetPasswordConfirmPage;

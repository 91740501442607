import { ChangeEvent, FC, memo, ReactElement, useState } from 'react';
import TextField from '@mui/material/TextField';
import { setEditable, updateWayPoints } from '../../../store/ons/ons.slice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setElevation, validateCoordinate } from './mapConfig';
import classes from '../../styles/ons.module.css';

interface IProps {
  index: number;
  lat: number;
  lng: number;
}

const RouteListItemLatitude: FC<IProps> = memo(
  ({ index, lat, lng }): ReactElement => {
    const dispatch = useAppDispatch();
    const elevation = useAppSelector((state) => state.ons.elevation);
    const rowHoverIndex = useAppSelector((state) => state.ons.rowHoverIndex);

    const [value, setValue] = useState<number | string>(lat);

    return (
      <TextField
        size='small'
        variant='outlined'
        autoComplete='off'
        value={value}
        className={lat ? classes.position : classes.positionNullable}
        onFocus={() => dispatch(setEditable(true))}
        onBlur={async ({ target: { value } }) => {
          if (validateCoordinate(value)) {
            return;
          }

          const numberValue = Number(value);
          dispatch(
            updateWayPoints({
              index,
              position: {
                lat: numberValue,
                lng,
                elevation: await setElevation(elevation, {
                  lat: numberValue,
                  lng,
                }),
              },
            })
          );
          if (index !== rowHoverIndex + 1) {
            dispatch(setEditable(false));
          }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setValue(event.target.value)
        }
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
            focused: classes.positionFocused,
          },
          className: classes.inputValue,
        }}
      />
    );
  }
);

export default RouteListItemLatitude;

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  createPasswordRecovery,
  confirmPasswordRecovery,
  getLicense,
  acceptLicenseStatus,
  getUserProfile,
  loginUser,
} from './user.thunks';
import { User } from '../../domain/user';

export interface UserState {
  getUserProfileLoading: boolean;
  userData: null | User;
  getUserProfileError: string | null;
  createPasswordRecoveryLoading: boolean;
  createPasswordRecoverySuccess: boolean;
  createPasswordRecoveryError: null | string;
  createPasswordConfirmLoading: boolean;
  createPasswordConfirmSuccess: boolean;
  createPasswordConfirmError: null | string;
  getLicenseLoading: boolean;
  getLicenseFileURL: string | null;
  getLicenseError: null | string;
}

const initialState: UserState = {
  getUserProfileLoading: false,
  userData: null,
  getUserProfileError: null,
  createPasswordRecoveryLoading: false,
  createPasswordRecoverySuccess: false,
  createPasswordRecoveryError: null,
  createPasswordConfirmLoading: false,
  createPasswordConfirmSuccess: false,
  createPasswordConfirmError: null,
  getLicenseLoading: false,
  getLicenseFileURL: null,
  getLicenseError: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserData: (state: UserState) => {
      state.userData = null;
    },
    clearPasswordRecoveryError: (state: UserState, { payload }) => {
      state.createPasswordRecoveryError = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
    builder.addCase(loginUser.pending, (state: UserState) => {
      state.getUserProfileLoading = true;
      state.getUserProfileError = null;
    });
    builder.addCase(loginUser.fulfilled, (state: UserState, { payload }) => {
      state.getUserProfileLoading = false;
      state.userData = payload;
    });
    builder.addCase(loginUser.rejected, (state: UserState, { error }) => {
      state.getUserProfileError = error.message as string;
      state.getUserProfileLoading = false;
    });
    builder.addCase(getUserProfile.pending, (state: UserState) => {
      state.getUserProfileLoading = true;
      state.getUserProfileError = null;
    });
    builder.addCase(
      getUserProfile.fulfilled,
      (state: UserState, { payload }) => {
        state.getUserProfileLoading = false;
        state.userData = payload;
      }
    );
    builder.addCase(getUserProfile.rejected, (state: UserState, { error }) => {
      state.getUserProfileError = error.message as string;
      state.getUserProfileLoading = false;
    });
    builder.addCase(createPasswordRecovery.pending, (state: UserState) => {
      state.createPasswordRecoveryError = null;
      state.createPasswordRecoveryLoading = true;
    });
    builder.addCase(createPasswordRecovery.fulfilled, (state: UserState) => {
      state.createPasswordRecoveryLoading = false;
      state.createPasswordRecoverySuccess = true;
    });
    builder.addCase(
      createPasswordRecovery.rejected,
      (state: UserState, { error }) => {
        state.createPasswordRecoveryError = error.message as string;
        state.createPasswordRecoveryLoading = false;
        state.createPasswordRecoverySuccess = false;
      }
    );
    builder.addCase(confirmPasswordRecovery.pending, (state: UserState) => {
      state.createPasswordConfirmError = null;
      state.createPasswordConfirmLoading = true;
    });
    builder.addCase(confirmPasswordRecovery.fulfilled, (state: UserState) => {
      state.createPasswordConfirmLoading = false;
      state.createPasswordConfirmSuccess = true;
    });
    builder.addCase(
      confirmPasswordRecovery.rejected,
      (state: UserState, { error }) => {
        state.createPasswordConfirmError = error.message as string;
        state.createPasswordConfirmLoading = false;
        state.createPasswordRecoverySuccess = false;
      }
    );
    builder.addCase(getLicense.pending, (state: UserState) => {
      state.getLicenseLoading = true;
      state.getLicenseError = null;
    });
    builder.addCase(getLicense.fulfilled, (state: UserState, { payload }) => {
      state.getLicenseLoading = false;
      state.getLicenseFileURL = payload;
    });
    builder.addCase(getLicense.rejected, (state: UserState, { error }) => {
      state.getLicenseError = error.message as string;
      state.getLicenseLoading = false;
    });
    builder.addCase(acceptLicenseStatus.pending, (state: UserState) => {
      state.getLicenseLoading = true;
      state.getLicenseError = null;
    });
    builder.addCase(
      acceptLicenseStatus.fulfilled,
      (state: UserState, { payload }) => {
        state.getLicenseLoading = false;

        if (state.userData) {
          state.userData.is_signed = payload;
        }
      }
    );
    builder.addCase(
      acceptLicenseStatus.rejected,
      (state: UserState, { error }) => {
        state.getLicenseError = error.message as string;
        state.getLicenseLoading = false;
      }
    );
  },
});

const userSliceReducer = userSlice.reducer;
export const { clearUserData, clearPasswordRecoveryError } = userSlice.actions;

export default userSliceReducer;

import { FC, memo, ReactElement, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import CommonControls from './CommonControls';
import Mission from './Mission';
import { VEHICLE_TYPES } from '../../../../domain/vehicle';
import classes from '../../../styles/vehicle.module.css';

interface IProps {
  vehicleType: VEHICLE_TYPES;
}

const Controls: FC<IProps> = memo(({ vehicleType }): ReactElement | null => {
  const location = useLocation();

  const viewType: string | null = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('role');
  }, [location.search]);

  if (viewType === 'viewer') {
    return null;
  }

  return (
    <Box className={classes.commonControlsContainer}>
      <Box className={classes.commonControls}>
        <CommonControls />
      </Box>
      {vehicleType === VEHICLE_TYPES.BOAT ||
      vehicleType === VEHICLE_TYPES.DRONE ||
      vehicleType === VEHICLE_TYPES.PLANE ? (
        <Box className={classes.commonControls}>
          <Mission />
        </Box>
      ) : null}
    </Box>
  );
});

export default Controls;

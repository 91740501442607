import { FC, memo, ReactElement } from 'react';

const StopCrop: FC = memo(
  (): ReactElement => (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.7029 17.8731C21.4608 17.5886 22.0002 16.8573 22.0002 16V4C22.0002 2.89543 21.1047 2 20.0002 2H8.00015C7.14285 2 6.41154 2.53939 6.12708 3.29732L8.00015 5.17039V5C8.00015 4.44772 8.44787 4 9.00015 4L19.0002 4C19.5524 4 20.0002 4.44772 20.0002 5V15C20.0002 15.5523 19.5524 16 19.0001 16H18.8298L20.7029 17.8731Z'
        fill='#F7F7F8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.10012 3.51027C2.49012 3.12027 3.12012 3.12027 3.51012 3.51027L20.4801 20.4903C20.8701 20.8803 20.8701 21.5103 20.4801 21.9003C20.0901 22.2903 19.4601 22.2903 19.0701 21.9003L15.172 17.9998H8.00012C6.89555 17.9998 6.00012 17.1044 6.00012 15.9998V8.82258L2.10012 4.92027C1.71012 4.53027 1.71012 3.90027 2.10012 3.51027ZM8.00012 10.8238V14.9998C8.00012 15.5521 8.44784 15.9998 9.00012 15.9998H13.1731L8.00012 10.8238Z'
        fill='#F7F7F8'
      />
      <path
        d='M4.00012 8C4.00012 7.44772 3.55241 7 3.00012 7C2.44784 7 2.00012 7.44772 2.00012 8V20C2.00012 21.1046 2.89555 22 4.00012 22H16.0001C16.5524 22 17.0001 21.5523 17.0001 21C17.0001 20.4477 16.5524 20 16.0001 20H5.00012C4.44784 20 4.00012 19.5523 4.00012 19V8Z'
        fill='#F7F7F8'
      />
    </svg>
  )
);

export default StopCrop;

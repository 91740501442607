import { FC, ReactElement } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import router from './router';
import i18n from './i18n/index';

const App: FC = (): ReactElement => (
  <ThemeProvider theme={{}}>
    <I18nextProvider i18n={i18n} defaultNS='translation'>
      <RouterProvider router={router} />
    </I18nextProvider>
  </ThemeProvider>
);

export default App;

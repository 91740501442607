import { FC, ReactElement, memo } from 'react';

const TextIcon: FC = memo(
  (): ReactElement => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.71875 0.78125C0.71875 0.34375 1.0625 0.03125 1.46875 0.03125H2.46875C2.90625 0.03125 3.21875 0.34375 3.21875 0.78125V4.5H3.71875C4.15625 4.5 4.46875 4.84375 4.46875 5.25C4.46875 5.6875 4.15625 6 3.71875 6H1.21875C0.8125 6 0.46875 5.6875 0.46875 5.25C0.46875 4.84375 0.8125 4.5 1.21875 4.5H1.71875V1.53125H1.46875C1.0625 1.53125 0.71875 1.1875 0.71875 0.78125ZM2.6875 9.6875C2.5 9.4375 2.125 9.46875 1.9375 9.71875L1.59375 10.1875C1.34375 10.5312 0.875 10.625 0.5625 10.375C0.21875 10.125 0.125 9.65625 0.375 9.34375L0.71875 8.84375C1.46875 7.8125 2.96875 7.71875 3.8125 8.6875C4.5 9.4375 4.46875 10.5938 3.78125 11.3438L2.6875 12.5H3.75C4.15625 12.5 4.5 12.8438 4.5 13.25C4.5 13.6875 4.15625 14 3.75 14H1C0.6875 14 0.40625 13.8438 0.3125 13.5625C0.1875 13.2812 0.21875 12.9688 0.4375 12.75L2.6875 10.3125C2.84375 10.125 2.84375 9.875 2.6875 9.6875ZM15.25 1.25C15.6562 1.25 16 1.59375 16 2C16 2.4375 15.6562 2.75 15.25 2.75H6.75C6.3125 2.75 6 2.4375 6 2C6 1.59375 6.3125 1.25 6.75 1.25H15.25ZM15.25 6.25C15.6562 6.25 16 6.59375 16 7C16 7.4375 15.6562 7.75 15.25 7.75H6.75C6.3125 7.75 6 7.4375 6 7C6 6.59375 6.3125 6.25 6.75 6.25H15.25ZM15.25 11.25C15.6562 11.25 16 11.5938 16 12C16 12.4375 15.6562 12.75 15.25 12.75H6.75C6.3125 12.75 6 12.4375 6 12C6 11.5938 6.3125 11.25 6.75 11.25H15.25Z'
        fill='#fff'
      />
    </svg>
  )
);

export default TextIcon;

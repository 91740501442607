import { FC, ReactElement, memo } from 'react';

const MiniMapIcon: FC = memo(
  (): ReactElement => (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 7H12C11.45 7 11 7.45 11 8V12C11 12.55 11.45 13 12 13H18C18.55 13 19 12.55 19 12V8C19 7.45 18.55 7 18 7ZM21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 20.98 3 20.98H21C22.1 20.98 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM20 19.01H4C3.45 19.01 3 18.56 3 18.01V5.98C3 5.43 3.45 4.98 4 4.98H20C20.55 4.98 21 5.43 21 5.98V18.01C21 18.56 20.55 19.01 20 19.01Z'
        fill='black'
        fillOpacity='0.87'
      />
    </svg>
  )
);
export default MiniMapIcon;

import { FC, memo, ReactElement } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import classes from '../../styles/ons.module.css';

interface IProps {
  index: number;
  isVisibleReorderIcon: boolean;
  isVisibleCounter: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const RouteListItemOrder: FC<IProps> = memo(
  ({
    index,
    dragHandleProps,
    isVisibleReorderIcon,
    isVisibleCounter,
  }): ReactElement => (
    <Box className={classes.listItemOrder} {...dragHandleProps}>
      <DragIndicatorRoundedIcon
        className={classes.dragIcon}
        style={{ visibility: isVisibleReorderIcon ? 'visible' : 'hidden' }}
      />
      <LocationOnRoundedIcon
        className={classes.locationIcon}
        style={{ visibility: isVisibleCounter ? 'visible' : 'hidden' }}
      />
      <Typography
        variant='caption'
        color='rgba(255, 255, 255, 0.7)'
        style={{ visibility: isVisibleCounter ? 'visible' : 'hidden' }}
      >
        {index + 1}
      </Typography>
    </Box>
  )
);

export default RouteListItemOrder;

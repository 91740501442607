import { FC, memo, ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useTranslation } from 'react-i18next';
import {
  setFullScreenImage,
  setFullScreenImageIndex,
} from '../../../../../store/vehicle/vehicle.slice';
import { useAppDispatch } from '../../../../../store';
import classes from './index.module.css';

const CloseFullScreen: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <Tooltip title={t('vehicle.collapseLabel')} arrow placement='top'>
      <IconButton
        className={classes.closeFullScreen}
        size='small'
        onClick={() => {
          dispatch(setFullScreenImage());
          dispatch(setFullScreenImageIndex(0));
        }}
      >
        <CloseFullscreenIcon className={classes.fullScreenMode} />
      </IconButton>
    </Tooltip>
  );
});

export default CloseFullScreen;

import {
  FC,
  forwardRef,
  memo,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import { UnknownAction } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  setSelectedPhotos,
  setSelectedSlides,
  clearArchive,
} from '../../../../store/vehicle/vehicle.slice';
import downloadImage from '../../helpers/downloadImage';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createArchive,
  getArchive,
  deleteStreamingPhotos,
  listStreamingPhotos,
  listStreamingSlides,
} from '../../../../store/vehicle/vehicle.thunks';
import { ISelectedPhoto, IStreamingSlide } from '../../../../domain/vehicle';
import { MediaListTabsEnum } from '../index';
import getLocalISO from '../../helpers/getLocalISO';
import classes from '../index.module.css';

interface IProps {
  open: boolean;
  handleClose: () => void;
  activeTab: MediaListTabsEnum;
  type: 'photo' | 'slide';
}

let archiveInterval: NodeJS.Timeout;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ImagesActionsModal: FC<IProps> = memo(
  ({ open, handleClose, type, activeTab }): ReactElement => {
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
    const deletePhotosLoading = useAppSelector(
      (state) => state.vehicles.deletePhotosLoading
    );
    const selectedPhotos = useAppSelector(
      (state) => state.vehicles.mediaList.selectedPhotos
    );
    const selectedSlides = useAppSelector(
      (state) => state.vehicles.mediaList.selectedSlides
    );
    const slidesStartDate = useAppSelector(
      (state) => state.vehicles.mediaList.slidesStartDate
    );
    const slidesEndDate = useAppSelector(
      (state) => state.vehicles.mediaList.slidesEndDate
    );
    const slidesLimit = useAppSelector(
      (state) => state.vehicles.mediaList.slidesLimit
    );
    const slidesPage = useAppSelector(
      (state) => state.vehicles.mediaList.slidesPage
    );
    const photosStartDate = useAppSelector(
      (state) => state.vehicles.mediaList.photosStartDate
    );
    const photosEndDate = useAppSelector(
      (state) => state.vehicles.mediaList.photosEndDate
    );
    const photosLimit = useAppSelector(
      (state) => state.vehicles.mediaList.photosLimit
    );
    const photosPage = useAppSelector(
      (state) => state.vehicles.mediaList.photosPage
    );
    const archive = useAppSelector((state) => state.vehicles.archive);

    const { t } = useTranslation();

    const selectedImages: ISelectedPhoto[] | IStreamingSlide[] = useMemo(
      (): ISelectedPhoto[] | IStreamingSlide[] =>
        activeTab === MediaListTabsEnum.PhotosTab
          ? selectedPhotos
          : selectedSlides,
      [activeTab, selectedPhotos, selectedSlides]
    );

    const [selectedLocalImages, setsSelectedLocalImages] = useState<
      ISelectedPhoto[] | IStreamingSlide[]
    >([]);

    const [archiveLinkLoading, setArchiveLinkLoading] =
      useState<boolean>(false);

    useEffect(() => {
      setsSelectedLocalImages(selectedImages);

      return () => {
        dispatch(clearArchive());
        clearInterval(archiveInterval);
      };
    }, []);

    useEffect(() => {
      if (!selectedImages.length) {
        handleClose();
      }
    }, [selectedImages.length]);

    useEffect(() => {
      if (archive) {
        if (archive.status === 'new') {
          archiveInterval = setInterval(() => {
            dispatch(getArchive(archive.uuid) as unknown as UnknownAction);
          }, 1000);
        } else {
          clearInterval(archiveInterval);
          downloadImage(archive?.path_to_archive);
          setArchiveLinkLoading(false);
        }
      }
    }, [archive?.status]);

    const downloadSelectedImages = () => {
      selectedImages.forEach((photo: ISelectedPhoto | IStreamingSlide) =>
        downloadImage(photo.image)
      );
    };

    const deleteSelectedImages = (): void => {
      if (!deletePhotosLoading) {
        dispatch(
          deleteStreamingPhotos({
            uuid,
            list_ids: selectedLocalImages.map(
              (selectedLocalImage: ISelectedPhoto | IStreamingSlide): number =>
                selectedLocalImage.id
            ),
            photo_type: type,
            onSuccess: () => {
              if (type === 'photo') {
                dispatch(
                  listStreamingPhotos({
                    uuid,
                    from: photosStartDate || '2011-04-11T11:51:00',
                    to: photosEndDate || getLocalISO(),
                    limit: photosLimit,
                    page: photosPage,
                  }) as unknown as UnknownAction
                );
              } else {
                dispatch(
                  listStreamingSlides({
                    uuid,
                    from: slidesStartDate || '2011-04-11T11:51:00',
                    to: slidesEndDate || getLocalISO(),
                    limit: slidesLimit,
                    page: slidesPage,
                  }) as unknown as UnknownAction
                );
              }
              handleClose();
            },
          }) as unknown as UnknownAction
        );
      }
    };

    const createImagesArchive = (): void => {
      setArchiveLinkLoading(true);
      dispatch(
        createArchive({
          archive_type: type,
          list_ids: selectedImages.map(
            (selectedLocalImage: ISelectedPhoto | IStreamingSlide): number =>
              selectedLocalImage.id
          ),
        }) as unknown as UnknownAction
      );
    };

    const setSelectedImagesHandler = (
      id: number,
      image: string,
      created_at: string
    ): void => {
      if (activeTab === MediaListTabsEnum.PhotosTab) {
        dispatch(setSelectedPhotos({ id, image, created_at }));
      } else {
        dispatch(setSelectedSlides({ id, image, created_at }));
      }
    };

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent className={classes.photosListForAction}>
          {selectedLocalImages.map(
            ({
              id,
              image,
              created_at,
            }: ISelectedPhoto | IStreamingSlide): ReactElement => {
              const selected = !!selectedImages.find(
                (selectedImage: ISelectedPhoto | IStreamingSlide): boolean =>
                  selectedImage.id === id
              );
              return (
                <Box className={classes.selectedLocalPhotoContainer} key={id}>
                  <img
                    src={image}
                    alt={image}
                    width='100%'
                    height='100%'
                    style={{
                      opacity: selected ? 0.8 : 1,
                    }}
                    className={classes.mediaListSingleImage}
                    onClick={() =>
                      setSelectedImagesHandler(id, image, created_at)
                    }
                  />
                  {selected ? (
                    <IconButton
                      className={classes.singlePhotoLocalSelected}
                      size='small'
                      onClick={() =>
                        setSelectedImagesHandler(id, image, created_at)
                      }
                    >
                      <CheckIcon className={classes.singlePhotoIcon} />
                    </IconButton>
                  ) : null}
                </Box>
              );
            }
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={createImagesArchive}
            disabled={archiveLinkLoading}
            variant='contained'
          >
            {!archiveLinkLoading
              ? t('vehicle.downloadAsArchive')
              : t('vehicle.downloading')}
          </Button>
          <Button onClick={downloadSelectedImages} variant='contained'>
            {t('vehicle.downloadAsFiles')}
          </Button>
          <Button
            onClick={deleteSelectedImages}
            variant='contained'
            color='error'
          >
            {t('ons.deleteLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ImagesActionsModal;

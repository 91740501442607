import { FC, memo, ReactElement, useState, SyntheticEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from '@mui/material/Snackbar';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { UnknownAction } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setVisibility } from '../helpers';
import { ONS_ARCHIVE_STATUS } from '../../../../domain/ons';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { deleteRequests } from '../../../../store/ons/ons.thunks';
import { setAlertMessage, setSeverity } from '../../../../store/ons/ons.slice';
import classes from '../../../styles/ons.module.css';

const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxWidth: '24rem',
  },
}));

interface IProps {
  status: ONS_ARCHIVE_STATUS;
  title: string;
  uuid: string;
}

const Delete: FC<IProps> = memo(({ status, title, uuid }): ReactElement => {
  const dispatch = useAppDispatch();
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);

  const deleteRequestsLoading = useAppSelector(
    (state) => state.ons.deleteRequestsLoading
  );

  const alertMessage = useAppSelector((state) => state.ons.alertMessage);
  const severity = useAppSelector((state) => state.ons.severity);

  const location = useLocation();
  const { t } = useTranslation();

  const handleCloseDelete = (event: any) => {
    event.stopPropagation();
    setVisibleDelete(false);
  };

  const deleteFileHandler = (event: any) => {
    event.stopPropagation();
    dispatch(
      deleteRequests({
        requests: [uuid],
        onSuccess: (status: 'success' | 'warning', message: string) => {
          setVisibleDelete(false);
          dispatch(setSeverity(status));
          dispatch(setAlertMessage(message));
        },
        onFailure: (status: 'error', error: string) => {
          dispatch(setSeverity(status));
          dispatch(setAlertMessage(error));
        },
        options: location.search,
      }) as unknown as UnknownAction
    );
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setAlertMessage(''));
    dispatch(setSeverity(undefined));
  };

  return (
    <>
      <Tooltip title={t('ons.deleteLabel')} arrow placement='top'>
        <IconButton
          color='default'
          size='medium'
          style={{
            visibility: setVisibility(
              status === ONS_ARCHIVE_STATUS.COMPLETED ||
                status === ONS_ARCHIVE_STATUS.NEW ||
                status === ONS_ARCHIVE_STATUS.REBUILD
            ),
          }}
          onClick={(event) => {
            event.stopPropagation();
            setVisibleDelete(true);
          }}
        >
          <DeleteRoundedIcon className={classes.tableIcon} />
        </IconButton>
      </Tooltip>
      <DialogWrapper
        open={visibleDelete}
        onClose={handleCloseDelete}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {t('ons.deleteSingleLabel', { title })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('ons.deleteSingleSubTitle')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDelete}
            className={classes.clearAllButton}
          >
            {t('ons.cancel')}
          </Button>
          <Button
            onClick={deleteFileHandler}
            autoFocus
            variant='contained'
            color='error'
            disabled={deleteRequestsLoading}
          >
            {deleteRequestsLoading ? t('ons.deleting') : t('ons.deleteLabel')}
          </Button>
        </DialogActions>
      </DialogWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!alertMessage}
        onClose={handleClose}
        autoHideDuration={4000}
        key='topright'
      >
        <Alert variant='filled' severity={severity}>
          <Typography variant='body1'>{alertMessage}</Typography>
        </Alert>
      </Snackbar>
    </>
  );
});

export default Delete;

const truncate = (n: number): number => {
  return n > 0 ? Math.floor(n) : Math.ceil(n);
};

const fromDegreesToDMS = (dd: number, longOrLat: 'lat' | 'long'): string => {
  // eslint-disable-next-line no-nested-ternary
  const hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
    ? dd < 0
      ? 'W'
      : 'E'
    : dd < 0
      ? 'S'
      : 'N';

  const absDD: number = Math.abs(dd);
  const degrees: number = truncate(absDD);
  const minutes: number = truncate((absDD - degrees) * 60);
  const seconds: string = ((absDD - degrees - minutes / 60) * 60 ** 2).toFixed(
    2
  );

  const dmsArray = [degrees, minutes, seconds, hemisphere];
  return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
};

export default fromDegreesToDMS;

import { FC, memo, ReactElement, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setTitle } from '../../../store/ons/ons.slice';
import classes from '../../styles/ons.module.css';

const RouteEditableTitle: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const title = useAppSelector((state) => state.ons.title);

  const [editable, setEditable] = useState<boolean>(false);
  const [value, setValue] = useState<string>(title);

  const { t } = useTranslation();
  const saveTitleHandler = (): void => {
    setEditable(false);

    if (value) {
      dispatch(setTitle(value));
    } else {
      setValue(title);
    }
  };

  return (
    <Box className={classes.editableTitleContainer}>
      {editable ? (
        <TextField
          size='small'
          value={value}
          onChange={(event) => setValue(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='save'
                  onClick={saveTitleHandler}
                  edge='end'
                  size='small'
                >
                  <SaveRoundedIcon
                    className={classes.deleteIcon}
                    fontSize='small'
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <>
          <Tooltip arrow placement='top' title={value}>
            <Typography
              variant='h6'
              color='#fff'
              className={classes.title}
              noWrap
            >
              {value}
            </Typography>
          </Tooltip>
          <Tooltip arrow placement='top' title={t('ons.editTitle')}>
            <IconButton size='small' onClick={() => setEditable(true)}>
              <EditRoundedIcon
                className={classes.deleteIcon}
                fontSize='small'
              />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
});

export default RouteEditableTitle;

import { IWayPoint } from '../../../store/ons/ons.slice';

export const mapConfig = {
  defaultZoom: 12,
  defaultCenter: { lat: 50.184255, lng: 36.617714 },
  style: { borderRadius: '20px' },
  gestureHandling: 'greedy',
  mapTypeControlOptions: {
    mapTypeIds: ['hybrid', 'satellite'],
    streetViewControl: false,
  },
  mapTypeId: 'hybrid',
};

export const previewMapConfig = {
  defaultZoom: 12,
  defaultCenter: { lat: 50.184255, lng: 36.617714 },
  style: { borderRadius: '20px' },
  gestureHandling: 'greedy',
  mapTypeControlOptions: {
    mapTypeIds: [],
  },
  mapTypeId: 'satellite',
  streetViewControl: false,
  fullscreenControl: false,
};

export const polyLineStyle = (
  wayPoints: IWayPoint[] | { lat: number; lng: number }[]
) => ({
  path: wayPoints,
  strokeColor: 'rgba(255, 238, 88, 1)',
  strokeOpacity: 1.0,
  strokeWeight: 2,
});

export const setElevation = async (
  elevation: any,
  latLng: any
): Promise<number> => {
  if (elevation) {
    const elevationService = new elevation.ElevationService();
    const { results } = await elevationService.getElevationForLocations({
      locations: [latLng],
    });

    if (results[0]) {
      return Math.round(results[0].elevation);
    }
  }

  return 0;
};

export const validateCoordinate = (value: string): boolean => {
  // if (value.length < 8 || value.length > 10) {
  //   return true;
  // }
  return !value.match('(?<=^| )\\d+\\.\\d+(?=$| )');
};

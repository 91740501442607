import { combineReducers, configureStore, Store } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { useSelector, useDispatch } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import userSlice from './user/user.slice';
import vehiclesSlice from './vehicle/vehicle.slice';
import socketSlice from './socket/socket.slice';
import appSlice from './app/app.slice';
import container from '../app/container';
import onsSlice from './ons/ons.slice';

const logger = createLogger({
  collapsed: true,
});

const rootReducer = combineReducers({
  app: appSlice,
  user: userSlice,
  vehicles: vehiclesSlice,
  socket: socketSlice,
  ons: onsSlice,
});

export const store: Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: container,
      },
    }).concat(logger),
});

type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

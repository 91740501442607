import { FC, memo, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import useBytesToSize from '../../common/hooks/useBytesToSize';

interface IProps {
  archive_size: number | null;
}

const SizeCell: FC<IProps> = memo(({ archive_size }): ReactElement => {
  const [getSize] = useBytesToSize();

  return (
    <Typography variant='body2' color='#fff'>
      {getSize(archive_size) || '-'}
    </Typography>
  );
});

export default SizeCell;

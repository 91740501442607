import { AxiosInstance, AxiosResponse } from 'axios';
import {
  Vehicle,
  VehicleRepository,
  VehiclesListMetricsResponse,
  VehiclesListResponse,
} from '../../domain/vehicle';
import {
  ACCEPT_LANGUAGE_HEADER,
  CONNECT_TO_VEHICLE,
  DISCONNECT_FROM_VEHICLE,
  VEHICLE_IS_CONNECT,
  VEHICLE_STATUS,
  VEHICLES_LIST,
  VEHICLES_LIST_METRICS,
} from '../common/httpService/constants';
import {
  RECORD_LIST,
  RECORD_START,
  RECORD_STOP,
  PHOTO_LIST,
  SLIDE_LIST,
  LOCAL_STORAGE_AUTH_KEY,
  REACT_APP_STREAM_BASE_URL,
  ARCHIVE_LIST,
  ARCHIVE,
  ACCESS_TOKEN_STREAM,
} from '../common/streamService/constants';
import { IAuthData } from '../../view/common/router/types';
import { getDataFromLocalStorage } from '../../view/common/router/helpers';

type Dependencies = {
  http: AxiosInstance;
};

export default ({ http }: Dependencies): VehicleRepository => ({
  async getVehicleListRequest(
    options: string
  ): Promise<VehiclesListResponse | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${VEHICLES_LIST}${options}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getVehicleListMetricsRequest(
    options: string
  ): Promise<VehiclesListMetricsResponse | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${VEHICLES_LIST_METRICS}${options}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async connectToVehicleRequest(
    uuid: string
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${CONNECT_TO_VEHICLE}${uuid}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async isVehicleCanConnect(
    uuid: string
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${VEHICLE_IS_CONNECT}${uuid}`
      );
      return response.data.is_can_connect;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getVehicleStatusRequest(uuid: string): Promise<Vehicle | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${VEHICLE_STATUS}${uuid}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async disconnectFromVehicleRequest(
    uuid: string
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.delete(
        `${DISCONNECT_FROM_VEHICLE}${uuid}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async startStreamingRecordingRequest(
    uuid: string,
    num_camera: number
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const authData: IAuthData | null = getDataFromLocalStorage(
        LOCAL_STORAGE_AUTH_KEY
      );
      const response: any = await fetch(
        `${REACT_APP_STREAM_BASE_URL}${RECORD_START}?uuid=${uuid}&num_camera=${num_camera}`,
        {
          headers: {
            [ACCESS_TOKEN_STREAM]: `${'Bearer '}${authData?.accessToken}`,
            [ACCEPT_LANGUAGE_HEADER]:
              localStorage.getItem('i18nextLng') || 'uk',
          },
        }
      );

      return response.json();
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async stopStreamingRecordingRequest(
    uuid: string,
    num_camera: number
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const authData: IAuthData | null = getDataFromLocalStorage(
        LOCAL_STORAGE_AUTH_KEY
      );
      const response: any = await fetch(
        `${REACT_APP_STREAM_BASE_URL}${RECORD_STOP}?uuid=${uuid}&num_camera=${num_camera}`,
        {
          headers: {
            [ACCESS_TOKEN_STREAM]: `${'Bearer '}${authData?.accessToken}`,
            [ACCEPT_LANGUAGE_HEADER]:
              localStorage.getItem('i18nextLng') || 'uk',
          },
        }
      );

      return response.json();
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async listStreamingRecordingsRequest(
    uuid: string,
    limit: number,
    page: number
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const authData: IAuthData | null = getDataFromLocalStorage(
        LOCAL_STORAGE_AUTH_KEY
      );
      const response: any = await fetch(
        `${REACT_APP_STREAM_BASE_URL}${RECORD_LIST}?uuid=${uuid}&limit=${limit}&page=${page}`,
        {
          headers: {
            [ACCESS_TOKEN_STREAM]: `${'Bearer '}${authData?.accessToken}`,
            [ACCEPT_LANGUAGE_HEADER]:
              localStorage.getItem('i18nextLng') || 'uk',
          },
        }
      );

      return response.json();
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async deleteStreamingRecordingRequest(
    uuid: string,
    names: string
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const authData: IAuthData | null = getDataFromLocalStorage(
        LOCAL_STORAGE_AUTH_KEY
      );
      const response: any = await fetch(
        `${REACT_APP_STREAM_BASE_URL}${RECORD_LIST}?uuid=${uuid}&names=${names}`,
        {
          headers: {
            [ACCESS_TOKEN_STREAM]: `${'Bearer '}${authData?.accessToken}`,
            [ACCEPT_LANGUAGE_HEADER]:
              localStorage.getItem('i18nextLng') || 'uk',
          },
          method: 'DELETE',
        }
      );

      return response.json();
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async listStreamingPhotosRequest(
    uuid: string,
    from: any,
    to: any,
    limit: number,
    page: number
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${PHOTO_LIST}?vehicle=${uuid}&from=${from}&to=${to}&limit=${limit}&page=${page}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async deleteStreamingPhotosRequest(
    uuid: string,
    list_ids: string[],
    photo_type: 'photo' | 'slide'
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.delete(
        `${PHOTO_LIST}?vehicle_uuid=${uuid}&list_ids=${list_ids.join(',')}&photo_type=${photo_type}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async listStreamingSlidesRequest(
    uuid: string,
    from: string,
    to: string,
    limit: number,
    page: number
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${SLIDE_LIST}?vehicle=${uuid}&from=${from}&to=${to}&limit=${limit}&page=${page}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getArchiveRequest(
    uuid: string
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.get(`${ARCHIVE}?uuid=${uuid}`);
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async createArchiveRequest(
    archive_type: 'photo' | 'slide',
    list_ids: number[]
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.post(ARCHIVE, {
        archive_type,
        list_ids: list_ids.join(','),
      });
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
  async getArchivesListRequest(
    uuid: string,
    limit: number,
    page: number
  ): Promise<{ status: 'success' } | unknown> {
    try {
      const response: AxiosResponse = await http.get(
        `${ARCHIVE_LIST}?uuid=${uuid}&limit=${limit}&page=${page}`
      );
      return response.data;
    } catch (error: unknown) {
      throw (error as any).response.data.data;
    }
  },
});

import { FC, ReactElement } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import GoogleMap from '../components/OnsCreate/GoogleMap';
import BreadcrumbsContainer from '../components/OnsCreate/Breadcrumbs';
import RouteList from '../components/OnsCreate/RouteList';
import classes from '../styles/ons.module.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const OnsCreate: FC = (): ReactElement => {
  const { i18n } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.onsCreateContainer}>
        <BreadcrumbsContainer />
        <Box className={classes.onsCreateInner}>
          <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
            language={i18n.language}
          >
            <GoogleMap />
          </APIProvider>
          <RouteList />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default OnsCreate;

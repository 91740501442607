import { VideoStreamingCommand } from './streamingCommands';

export const startSingleFrameModeSchema = (
  camera: number
): VideoStreamingCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'start_slide_streaming',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
    camera: { stream: camera, save: [0, 1, 2] },
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const stopSingleFrameModeSchema = (
  camera: number
): VideoStreamingCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'stop_slide_streaming',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
    camera: { stream: camera, save: [0, 1, 2] },
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

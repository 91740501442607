import { FC, memo, ReactElement, useMemo, useState } from 'react';
import {
  MapContainer,
  ScaleControl,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import { useAppSelector } from '../../../../store';
import { pinnedMiniMapConfig } from '../common/config';
import PinMapWidget from '../Widgets/PinMapWidget';
import RotatedMarker from '../SlidesMap/RotatedMarker';
import classes from '../styles/index.module.css';
import { mapStyles } from '../styles/mapStyles';
import CenteringWidget from '../SlidesMap/CenteringWidget';

const {
  zoom: {
    initialZoom,
    zoomControl,
    zoomControlPosition,
    zoomSnap,
    minZoom,
    maxZoom,
    maxNativeZoom,
    minZoomTile,
    maxZoomTile,
  },
  bounds: { maxBoundsViscosity },
  layerControls: { satelliteTileLayerUrl, subdomainsForSatelliteTile },
  scale,
  dragging,
} = pinnedMiniMapConfig;

const PinnedMiniMap: FC = memo((): ReactElement | null => {
  const [mapHovered, setMapHovered] = useState<boolean>(false);
  const collapseAdditionalInfoSection = useAppSelector(
    (state) => state.app.collapseAdditionalInfoSection
  );
  const pinnedMap = useAppSelector((state) => state.vehicles.pinnedMap);
  const vehicleMetrics = useAppSelector((state) => state.socket.vehicleMetrics);
  const yaw = useAppSelector(
    (state) => state.socket.vehicleMetrics?.attitude.yaw
  );

  const coordinates: LatLngExpression = useMemo((): LatLngExpression => {
    if (!vehicleMetrics) {
      return [0, 0];
    }

    return [
      vehicleMetrics.position ? vehicleMetrics.position.latitude : 0,
      vehicleMetrics.position ? vehicleMetrics.position.longitude : 0,
    ];
  }, [vehicleMetrics]);

  return (
    <section
      className={classes.wrapperForListPage}
      style={mapStyles(!!pinnedMap, !!collapseAdditionalInfoSection)}
      onMouseEnter={() => setMapHovered(true)}
      onMouseLeave={() => setMapHovered(false)}
    >
      <MapContainer
        className='miniMapContainer'
        minZoom={minZoom}
        zoom={initialZoom}
        maxZoom={maxZoom}
        zoomSnap={zoomSnap}
        maxBoundsViscosity={maxBoundsViscosity}
        zoomControl={zoomControl}
        center={coordinates}
        dragging={dragging}
      >
        <TileLayer
          url={satelliteTileLayerUrl}
          subdomains={subdomainsForSatelliteTile}
          minZoom={minZoomTile}
          maxZoom={maxZoomTile}
          maxNativeZoom={maxNativeZoom}
          detectRetina
        />
        {mapHovered ? (
          <>
            <ScaleControl position={scale.position} />
            <ZoomControl position={zoomControlPosition} />
            <CenteringWidget coordinates={coordinates} />
            <PinMapWidget
              mapHovered={mapHovered}
              isDragging={false}
              pinnedMap={pinnedMap}
            />
          </>
        ) : null}
        <RotatedMarker
          position={coordinates}
          yaw={yaw}
          reducePin={false}
          centered={false}
        />
      </MapContainer>
    </section>
  );
});

export default PinnedMiniMap;

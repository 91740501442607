import { ChangeEvent, FC, memo, MouseEvent, ReactElement } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { MediaListTabsEnum } from '../index';
import { useAppDispatch } from '../../../../store';
import {
  setMediaListPhotosLimit,
  setMediaListPhotosPage,
  setMediaListRecordingsLimit,
  setMediaListRecordingsPage,
  setMediaListSlidesLimit,
  setMediaListSlidesPage,
} from '../../../../store/vehicle/vehicle.slice';
import setPaginationPage from '../../helpers/setPaginationPage';

interface IProps {
  activeTab: MediaListTabsEnum;
  limit: number;
  page: number;
  count: number;
}

const Pagination: FC<IProps> = memo(
  ({ activeTab, limit, page, count }): ReactElement => {
    const dispatch = useAppDispatch();

    const handleChangePage = (
      _: MouseEvent<HTMLButtonElement> | null,
      page: number
    ): void => {
      if (activeTab === MediaListTabsEnum.PhotosTab) {
        dispatch(setMediaListPhotosPage(page + 1));
      }

      if (activeTab === MediaListTabsEnum.SlidesTab) {
        dispatch(setMediaListSlidesPage(page + 1));
      }

      if (activeTab === MediaListTabsEnum.VideosTab) {
        dispatch(setMediaListRecordingsPage(page + 1));
      }
    };

    const handleChangeRowsPerPage = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (activeTab === MediaListTabsEnum.PhotosTab) {
        dispatch(setMediaListPhotosLimit(parseInt(event.target.value, 10)));
      }

      if (activeTab === MediaListTabsEnum.SlidesTab) {
        dispatch(setMediaListSlidesLimit(parseInt(event.target.value, 10)));
      }

      if (activeTab === MediaListTabsEnum.VideosTab) {
        dispatch(setMediaListRecordingsLimit(parseInt(event.target.value, 10)));
      }
    };

    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50]}
        count={count}
        page={setPaginationPage(page)}
        rowsPerPage={Number(limit)}
        labelRowsPerPage={null}
        showFirstButton
        showLastButton
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component='div'
        sx={{
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
          height: '2.625rem',
          backgroundColor: 'rgba(222,224,227,0.7)',
          borderRadius: '50px',
          '.MuiTablePagination-toolbar': {
            padding: 0,
          },
        }}
      />
    );
  }
);

export default Pagination;

import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const Error: FC = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className='section'>
      <div className='error-page'>
        <p className='error-page__text text-1'>404</p>
        <p className='error-page__text'>{t('error.pageNotFound')}</p>
      </div>
    </div>
  );
};

export default Error;

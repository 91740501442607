import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import { APIProvider } from '@vis.gl/react-google-maps';
import { ONS_ARCHIVE_STATUS } from '../../../../domain/ons';
import Preview from './Preview';
import Delete from './Delete';
import Download from './Download';
import classes from '../../../styles/ons.module.css';

interface IProps {
  status: ONS_ARCHIVE_STATUS;
  title: string;
  uuid: string;
  archive: string | null;
  index: number;
}

const ActionsCell: FC<IProps> = memo(
  ({ status, title, uuid, archive, index }): ReactElement => (
    <Box className={classes.cellActions}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
        <Preview index={index} />
      </APIProvider>
      <Download status={status} archive={archive} />
      <Delete status={status} title={title} uuid={uuid} />
    </Box>
  )
);

export default ActionsCell;

import { ChangeEvent, FC, memo, ReactElement } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  count: number;
}

const Pagination: FC<IProps> = memo(({ count }): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams: any = Object.fromEntries([...searchParams]);

  const { t } = useTranslation();

  const handleChangePage = (_: unknown, newPage: number): void => {
    const updatedParams: URLSearchParamsInit = {
      ...currentParams,
      page: newPage + 1,
    };
    setSearchParams(updatedParams);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const updatedParams: URLSearchParamsInit = {
      ...currentParams,
      limit: parseInt(event.target.value, 10),
      page: 1,
    };
    setSearchParams(updatedParams);
  };

  const setPage = (currentParams: any): number => {
    if (currentParams && currentParams.page) {
      const currentPage = Number(currentParams.page);
      return currentPage - 1 === 0 ? 0 : currentPage - 1;
    }

    return 0;
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component='div'
      count={count}
      rowsPerPage={
        currentParams && currentParams.limit ? Number(currentParams.limit) : 0
      }
      labelRowsPerPage={t('ons.labelRowsPerPage')}
      showLastButton
      showFirstButton
      page={setPage(currentParams)}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        overflow: 'hidden',
        color: '#fafafa',
        '& .Mui-disabled': {
          color: '#ffffff78 !important',
        },
        '& .MuiSelect-icon': {
          color: '#fafafa',
        },
      }}
    />
  );
});

export default Pagination;

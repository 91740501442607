import { FC, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import classes from '../../styles/vehicles.module.css';

interface IProps {
  internetStatus: 'true' | 'false';
  handleQueryParams: (key: string, value: boolean) => void;
  removeVehiclesQueryParam: (key: string) => void;
}

const FilterByInternetStatus: FC<IProps> = memo(
  ({
    internetStatus,
    handleQueryParams,
    removeVehiclesQueryParam,
  }): ReactElement => {
    const { t, i18n } = useTranslation();

    const [value, setValue] = useState(
      internetStatus !== 'true' ? 'offline' : 'online'
    );

    const statuses: { label: string; value: string }[] = useMemo((): {
      label: string;
      value: string;
    }[] => {
      return [
        { label: t('vehiclesList.listDeviceStatusAll'), value: 'all' },
        { label: t('vehiclesList.listDeviceStatusOnline'), value: 'online' },
        { label: t('vehiclesList.listDeviceStatusOffline'), value: 'offline' },
        {
          label: t('vehiclesList.listDeviceStatusOnMission'),
          value: 'on_mission',
        },
      ];
    }, [i18n.language]);

    useEffect(() => {
      if (!internetStatus) {
        setValue('all');
        return;
      }
      setValue(internetStatus !== 'true' ? 'offline' : 'online');
    }, []);

    const handleVehiclesQueryParams = (event: any): void => {
      setValue(event.target.value);

      if (event.target.value === 'all') {
        removeVehiclesQueryParam('is_online');
        removeVehiclesQueryParam('is_real_online');
      } else if (event.target.value === 'on_mission') {
        removeVehiclesQueryParam('is_real_online');
        handleQueryParams('is_online', true);
      } else if (event.target.value === 'online') {
        removeVehiclesQueryParam('is_online');
        handleQueryParams('is_real_online', true);
      } else {
        removeVehiclesQueryParam('is_online');
        removeVehiclesQueryParam('is_real_online');
      }
    };

    // console.log(value, 'value');

    return (
      <FormControl variant='standard' size='medium' fullWidth sx={{ m: 1 }}>
        <InputLabel
          sx={{
            borderColor: 'rgba(255, 255, 255, 0.23)',
            '&.MuiInputLabel-root': {
              color: '#fafafa',
              fontSize: '16px',
              fontWeight: '400',
            },
            '&.MuiInputLabel-root.Mui-focused': {
              color: '#2196F3',
            },
          }}
          id='demo-multiple-name-label'
        >
          {t('vehiclesList.listDeviceStatusLabel')}
        </InputLabel>
        <Select
          MenuProps={{
            sx: {
              '& .MuiList-root': {
                padding: '0',
                backgroundColor: '#424242',
              },
            },
          }}
          sx={{
            '&:hover': {
              '&:before': {
                borderBottom: '1px solid #757575 !important',
              },
            },
            '&:before': {
              borderColor: '#757575',
            },
            '& .MuiSelect-icon': {
              fill: '#757575',
            },
            '& .MuiSelect-icon.MuiSelect-iconOpen': {
              fill: '#2196F3',
            },
            '& .MuiInput-input': {
              color: '#fafafa',
            },
            '& .MuiListItemText-root': {
              margin: '0',
            },
          }}
          labelId='demo-multiple-name-label'
          id='demo-multiple-name'
          value={value}
          onChange={handleVehiclesQueryParams}
        >
          {statuses.map(
            (status: { label: string; value: string }): ReactElement => (
              <MenuItem
                key={status.label}
                value={status.value}
                sx={{
                  '&:hover': {
                    backgroundColor: '#2196F30A',
                  },
                }}
              >
                <ListItemText
                  className={classes.vehiclesListTextColor}
                  primary={
                    <Typography variant='caption'>{status.label}</Typography>
                  }
                />
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    );
  }
);

export default FilterByInternetStatus;

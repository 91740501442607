import http from '../infra/common/httpService';
import makeVehicleRepository from '../infra/vehicle/vehicleRepository';
import makeUserRepository from '../infra/user/userRepository';
import makeAppRepository from '../infra/app/appRepository';
import makeOnsRepository from '../infra/ons/onsRepository';
import makeGetServerTime from './_app/getServerTime';
import makeGetPing from './_app/getPing';
import makeStartStreamingRecord from './vehicle/startStreamingRecord';
import makeStopStreamingRecord from './vehicle/stopStreamingRecord';
import makeListStreamingRecordings from './vehicle/listStreamingRecordings';
import makeGetArchive from './vehicle/getArchive';
import makeGetArchiveList from './vehicle/getArchiveList';
import makeCreateArchive from './vehicle/createArchive';
import makeDeleteStreamingRecording from './vehicle/deleteStreamingRecord';
import makeDeleteStreamingPhotos from './vehicle/deleteStreamingPhotos';
import makeListStreamingPhotos from './vehicle/listStreamingPhotos';
import makeListStreamingSlides from './vehicle/listStreamingSlides';
import makeGetVehicleList from './vehicle/getVehicleList';
import makeGetVehicleStatus from './vehicle/getVehicleStatus';
import makeConnectionToVehicle from './vehicle/connectToVehicle';
import makeVehicleIsCanConnect from './vehicle/isVehicleCanConnect';
import makeDisconnectFromVehicle from './vehicle/disconnectFromVehicle';
import makeGetVehicleListMetrics from './vehicle/getVehiclesListMetrics';
import makeLogin from './user/createLogin';
import makeCreatePasswordRecovery from './user/createPasswordRecovery';
import makeCreatePasswordConfirm from './user/createPasswordConfirm';
import makeGetLicense from './user/getLicense';
import makeAcceptLicense from './user/acceptLicense';
import makeGetAcceptLicenseStatus from './user/getAcceptLicenseStatus';
import makeGetUserProfile from './user/getUserProfile';
import makeGetMapWayPoint from './ons/getMapWaypoint';
import makeGetOnsHistory from './ons/getOnsHistory';
import makeGetProcessingStatus from './ons/getProcessingStatus';
import makeGenerateArchive from './ons/generateArchive';
import makeDeleteRequests from './ons/deleteRequests';
import { validateUserEmail, validateUserPassword } from '../domain/user';

// INFRA
const vehicleRepository = makeVehicleRepository({
  http,
});

const userRepository = makeUserRepository({
  http,
});

const appRepository = makeAppRepository({
  http,
});

const onsRepository = makeOnsRepository({
  http,
});

// APP
const getMapWayPoint = makeGetMapWayPoint({ onsRepository });
const getOnsHistory = makeGetOnsHistory({ onsRepository });
const getProcessingStatus = makeGetProcessingStatus({ onsRepository });
const generateArchive = makeGenerateArchive({
  onsRepository,
});
const deleteRequests = makeDeleteRequests({ onsRepository });

const getServerTime = makeGetServerTime({
  appRepository,
});

const getPing = makeGetPing({
  appRepository,
});

const getVehicleList = makeGetVehicleList({
  vehicleRepository,
});

const getVehicleListMetrics = makeGetVehicleListMetrics({
  vehicleRepository,
});

const connectToVehicle = makeConnectionToVehicle({
  vehicleRepository,
});

const vehicleIsCanConnect = makeVehicleIsCanConnect({
  vehicleRepository,
});

const getVehicleStatus = makeGetVehicleStatus({
  vehicleRepository,
});

const startStreamingRecord = makeStartStreamingRecord({
  vehicleRepository,
});

const listStreamingRecordings = makeListStreamingRecordings({
  vehicleRepository,
});

const listStreamingPhotos = makeListStreamingPhotos({
  vehicleRepository,
});

const listStreamingSlides = makeListStreamingSlides({
  vehicleRepository,
});

const deleteStreamingRecording = makeDeleteStreamingRecording({
  vehicleRepository,
});

const deleteStreamingPhotos = makeDeleteStreamingPhotos({
  vehicleRepository,
});

const stopStreamingRecord = makeStopStreamingRecord({ vehicleRepository });

const disconnectFromVehicle = makeDisconnectFromVehicle({
  vehicleRepository,
});

const getArchive = makeGetArchive({
  vehicleRepository,
});

const getArchiveList = makeGetArchiveList({
  vehicleRepository,
});

const createArchive = makeCreateArchive({ vehicleRepository });

const createLogin = makeLogin({
  userRepository,
  validateUserEmail,
  validateUserPassword,
});

const createPasswordRecovery = makeCreatePasswordRecovery({
  userRepository,
  validateUserEmail,
});

const createPasswordConfirm = makeCreatePasswordConfirm({
  userRepository,
  validateUserPassword,
});

const createGetLicense = makeGetLicense({
  userRepository,
});

const createAcceptLicense = makeAcceptLicense({
  userRepository,
});

const getAcceptLicenseStatus = makeGetAcceptLicenseStatus({
  userRepository,
});

const getUserProfile = makeGetUserProfile({
  userRepository,
});

const container = {
  getServerTime,
  getPing,
  createPasswordRecovery,
  createPasswordConfirm,
  getVehicleList,
  getVehicleListMetrics,
  connectToVehicle,
  disconnectFromVehicle,
  getVehicleStatus,
  createGetLicense,
  createAcceptLicense,
  getAcceptLicenseStatus,
  getUserProfile,
  createLogin,
  startStreamingRecord,
  stopStreamingRecord,
  listStreamingRecordings,
  deleteStreamingRecording,
  listStreamingPhotos,
  listStreamingSlides,
  deleteStreamingPhotos,
  getArchive,
  getArchiveList,
  createArchive,
  vehicleIsCanConnect,
  getMapWayPoint,
  getOnsHistory,
  getProcessingStatus,
  generateArchive,
  deleteRequests,
};

export default container;

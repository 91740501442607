export type OnsRepository = {
  getMapWaypointRequest: (wayPoints: {
    title: string;
    list_waypoints: IWayPointRequest[];
  }) => Promise<IWayPointResponse | unknown>;
  getOnsHistoryRequest: (
    options: string
  ) => Promise<IWayPointResponse | unknown>;
  getProcessingStatusRequest: (
    request_uuid: string
  ) => Promise<IWayPointResponse | unknown>;
  generateArchiveRequest: (
    request_uuid: string
  ) => Promise<IWayPointResponse | unknown>;
  deleteRequestsRequest: (
    requests: string[]
  ) => Promise<IWayPointResponse | unknown>;
};

export interface IWayPointRequest {
  latitude: number;
  longitude: number;
}

export type IWayPointResponse = {
  status: string;
  data: {
    list_waypoints: {
      latitude: number;
      longitude: number;
    }[];
    uuid: string;
    percent_processed: number;
    status: string;
    created_at: string;
    updated_at: string;
    archive: string;
  };
};

export interface IOnsHistoryItem {
  title: string;
  list_waypoints: {
    latitude: number;
    longitude: number;
  }[];
  uuid: string;
  percent_processed: number;
  status: ONS_ARCHIVE_STATUS;
  created_at: string;
  updated_at: string;
  archive: string | null;
  archive_size: number | null;
  archive_price: number | null;
}

export type OnsHistoryT = {
  count: number;
  items: IOnsHistoryItem[];
};

export enum ONS_ARCHIVE_STATUS {
  NEW = 'new',
  LOADING = 'load_map_image',
  BUILDING = 'build_archive',
  COMPLETED = 'completed',
  REBUILD = 'need_to_build_archive',
  ERROR = 'error',
}

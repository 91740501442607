import { FC, memo, ReactElement, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import Layers from './Layers';
import MarkerContainer from './Markers';
import AllDevicesWidget from '../Widgets/AllDevicesWidget';
import SettingsWidget from '../Widgets/SettingsWidget';
import { listMapConfig } from '../common/config';
import ZoomControlWidget from '../Widgets/ZoomControlWidget';
import { useAppSelector } from '../../../../store';
import DevicePopup from '../../../components/VehicleList/selectedDevicePopup';
import classes from '../styles/index.module.css';

const {
  zoom: { initialZoom, zoomControl, zoomSnap, minZoom, maxZoom },
  bounds: { maxBoundsViscosity },
} = listMapConfig;

const ListMap: FC = memo((): ReactElement => {
  const [hover, setHover] = useState<boolean>(true);
  const [clustersActive, setClustersActive] = useState<boolean>(false);
  const selectedUuidDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.uuid
  );

  return (
    <section
      className={classes.wrapperForListPage}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <MapContainer
        className={classes.mapContainer}
        minZoom={minZoom}
        zoom={initialZoom}
        zoomSnap={zoomSnap}
        maxZoom={maxZoom}
        maxBoundsViscosity={maxBoundsViscosity}
        zoomControl={zoomControl}
        center={[0, 0]}
      >
        <Layers />
        {hover ? (
          <>
            <ZoomControlWidget pinnedMap collapseAdditionalInfoSection />
            <SettingsWidget />
            <AllDevicesWidget setClustersActive={setClustersActive} />
          </>
        ) : null}
        <MarkerContainer
          selectedUuidDevice={selectedUuidDevice}
          clustersActive={clustersActive}
          setClustersActive={setClustersActive}
        />
        {selectedUuidDevice ? <DevicePopup /> : null}
      </MapContainer>
    </section>
  );
});

export default ListMap;

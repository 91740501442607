const typography = {
  fontFamily: `"Inter", sans-serif`,
  // h6: {
  //   fontSize: '1.5rem',
  //   fontWeight: 600,
  //   color: '#14151A',
  //   lineHeight: '28px',
  // },
  // body1: {
  //   fontSize: '1.25rem',
  //   fontWeight: 700,
  //   color: '#14151A',
  //   lineHeight: '28px',
  // },
  // body2: {
  //   fontSize: '1.25rem',
  //   fontWeight: 500,
  //   color: '#14151A',
  //   lineHeight: '28px',
  // },
  // subtitle1: {
  //   fontSize: '1.25rem',
  //   fontWeight: 600,
  //   color: '#14151A',
  //   lineHeight: '28px',
  // },
  // subtitle2: {
  //   fontSize: '1rem',
  //   fontWeight: 600,
  //   color: '#14151A',
  //   lineHeight: '24px',
  // },
  // caption: {
  //   fontSize: '0.875rem',
  //   fontWeight: 500,
  //   color: '#0F132499',
  //   lineHeight: '20px',
  // },
  // button: {
  //   textTransform: undefined,
  // },
};

export default typography;

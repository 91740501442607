import { useEffect } from 'react';
import {
  setEngineRunning,
  setGamepadData,
  setManualControlRunning,
} from '../../../store/vehicle/vehicle.slice';
import { socketSend } from '../../../infra/common/socketService';
import {
  disarmCommand,
  manualControlCommand,
  setControlCommand,
  startEngineCommand,
  stopEngineCommand,
} from '../../../infra/common/socketService/commands/commonCommands';
import { VEHICLE_TYPES, vehicleActionsByType } from '../../../domain/vehicle';
import {
  setDataCommandByGamepad,
  setKhtiTractorMove,
} from '../helpers/gamepadControls';
import { useAppDispatch, useAppSelector } from '../../../store';

let gamepadInterval: NodeJS.Timeout;
let isArmPressed = false;
let forcedArm = false;
let armTimeOut: NodeJS.Timeout;
let isBlurActive = false;
let isDisarmPressed = false;
let resetControl = false;

const useVehicleHTIControls = (): void => {
  const dispatch = useAppDispatch();
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const manualControlRunning = useAppSelector(
    (state) => state.vehicles.manualControlRunning
  );
  const isDeviceOnline = useAppSelector(
    (state) => state.vehicles.activeVehicle?.is_online
  );
  const engineRunning = useAppSelector((state) => state.vehicles.engineRunning);
  const gamepadRunning = useAppSelector(
    (state) => state.vehicles.gamepadRunning
  );
  const isUserOnline = useAppSelector((state) => state.app.isUserOnline);
  const getPingSuccess = useAppSelector((state) => state.app.getPingSuccess);
  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );

  const isOperatorOnline = getPingSuccess || isUserOnline;

  useEffect(() => {
    if (!vehicleType) {
      return;
    }

    if (vehicleType !== VEHICLE_TYPES.TRACTOR_KHTI) {
      return;
    }

    if (!isDeviceOnline || !isOperatorOnline) {
      // dispatch(setManualControlRunning(false));
      clearInterval(gamepadInterval);
    } else {
      gamepadInterval = setInterval(async () => {
        if (gamepadRunning) {
          const gamepad: Gamepad | null = navigator.getGamepads()[0];
          await dispatch(setGamepadData(gamepad));

          if (
            gamepad?.buttons[9].pressed &&
            manualControlMetric &&
            !isArmPressed &&
            !forcedArm
          ) {
            isArmPressed = true;
            await dispatch(setEngineRunning(true));
            if (!engineRunning) {
              await socketSend(
                startEngineCommand(
                  vehicleActionsByType[vehicleType as VEHICLE_TYPES].armCommand
                ),
                timeProcessing
              );
              armTimeOut = setTimeout(async () => {
                await socketSend(
                  stopEngineCommand(
                    vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                      ?.stopArmCommand
                  ),
                  timeProcessing
                );
                forcedArm = true;
                isArmPressed = false;
                await dispatch(setEngineRunning(false));
              }, 7000);
            }
          }

          if (
            !gamepad?.buttons[9].pressed &&
            manualControlMetric &&
            isArmPressed &&
            !forcedArm
          ) {
            clearTimeout(armTimeOut);
            await socketSend(
              stopEngineCommand(
                vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                  ?.stopArmCommand
              ),
              timeProcessing
            );
            isArmPressed = false;
            await dispatch(setEngineRunning(false));
          }
          if (!gamepad?.buttons[9].pressed) {
            forcedArm = false;
          }
          if (!manualControlMetric) {
            resetControl = true;
          }
          if (gamepad && manualControlMetric) {
            const commands: any = await setDataCommandByGamepad(
              vehicleType as VEHICLE_TYPES,
              gamepad.buttons,
              gamepad.axes,
              isBlurActive,
              resetControl
            );

            if (!gamepad.buttons[6].pressed && !gamepad.buttons[7].pressed) {
              await socketSend(
                setControlCommand(
                  vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                    .controlCommand,
                  commands
                ),
                timeProcessing
              );
            }

            if (
              gamepad.buttons[12].pressed ||
              gamepad.buttons[13].pressed ||
              gamepad.buttons[6].pressed ||
              gamepad.buttons[7].pressed
            ) {
              await socketSend(
                setControlCommand(
                  'tractor_khti_move',
                  setKhtiTractorMove(
                    gamepad.buttons[12].pressed,
                    gamepad.buttons[13].pressed,
                    gamepad.buttons[6].pressed,
                    gamepad.buttons[7].pressed
                  )
                ),
                timeProcessing
              );
            }
            isBlurActive = false;
          }
          if (manualControlMetric) {
            resetControl = false;
          }

          if (gamepad?.buttons[2].pressed && !isDisarmPressed) {
            await socketSend(
              disarmCommand('tractor_khti_disarm'),
              timeProcessing
            );
            await socketSend(
              manualControlCommand('stop_manual'),
              timeProcessing
            );
            await dispatch(setManualControlRunning(false));
            isDisarmPressed = true;
          }
          if (!gamepad?.buttons[2].pressed && isDisarmPressed) {
            isDisarmPressed = false;
          }
        }
      }, 100);
    }
    // eslint-disable-next-line consistent-return
    return () => clearInterval(gamepadInterval);
  }, [manualControlMetric, gamepadRunning, isDeviceOnline, isOperatorOnline]);

  const blurStopControl = async () => {
    // if (vehicleType !== VEHICLE_TYPES.TRACTOR_KHTI) {
    //   return;
    // }
    if (
      manualControlMetric &&
      (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI ||
        vehicleType === VEHICLE_TYPES.CAR)
    ) {
      // dispatch(setManualControlRunning(false));
      await socketSend(manualControlCommand('stop_manual'), timeProcessing);
      isBlurActive = true;
    }
  };

  useEffect(() => {
    window.addEventListener('blur', blurStopControl);
    return () => {
      window.removeEventListener('blur', blurStopControl);
    };
  }, [manualControlMetric, vehicleType]);
};

export default useVehicleHTIControls;

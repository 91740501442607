import { FC, ReactElement, memo } from 'react';
import { Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useAppSelector } from '../../../../../store';

const GamepadR1Buttons: FC = memo((): ReactElement => {
  const gamepadButtonR1 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[5].pressed
  );
  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );
  const changeColorButton = () => {
    if (manualControlMetric) {
      if (gamepadButtonR1) {
        return '1';
      }
    }
    return '0.2';
  };
  return (
    <Typography variant='caption'>
      <ArrowForwardIosIcon
        sx={{ opacity: () => changeColorButton(), color: '#fff' }}
      />
    </Typography>
  );
});

export default GamepadR1Buttons;

import { FC, ReactElement, memo } from 'react';
import { TileLayer } from 'react-leaflet';
import { LayersEnum, listMapConfig } from '../common/config';
import { useAppSelector } from '../../../../store';

const {
  layerControls: {
    defaultTileLayerUrl,
    satelliteTileLayerUrl,
    subdomainsForSatelliteTile,
  },
} = listMapConfig;

const Layers: FC = memo((): ReactElement => {
  const mapLayerType = useAppSelector((state) => state.vehicles.mapLayerType);

  if (mapLayerType === LayersEnum.SATELLITE) {
    return (
      <TileLayer
        key={mapLayerType}
        url={satelliteTileLayerUrl}
        subdomains={subdomainsForSatelliteTile}
        maxZoom={100}
      />
    );
  }
  return (
    <TileLayer
      key={mapLayerType}
      url={defaultTileLayerUrl as string}
      maxZoom={100}
    />
  );
});

export default Layers;

import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import { MediaListTabsEnum } from '../index';
import DateTimePickers from './DateTimePickers';
import Pagination from './Pagination';
import { useAppSelector } from '../../../../store';
import PhotosActions from './ImagesActions';
import classes from '../index.module.css';

interface IProps {
  activeTab: MediaListTabsEnum;
}

const Actions: FC<IProps> = ({ activeTab }): ReactElement => {
  const photosStartDate = useAppSelector(
    (state) => state.vehicles.mediaList.photosStartDate
  );
  const photosEndDate = useAppSelector(
    (state) => state.vehicles.mediaList.photosEndDate
  );
  const slidesStartDate = useAppSelector(
    (state) => state.vehicles.mediaList.slidesStartDate
  );
  const slidesEndDate = useAppSelector(
    (state) => state.vehicles.mediaList.slidesEndDate
  );
  const photosLimit = useAppSelector(
    (state) => state.vehicles.mediaList.photosLimit
  );
  const photosPage = useAppSelector(
    (state) => state.vehicles.mediaList.photosPage
  );
  const slidesLimit = useAppSelector(
    (state) => state.vehicles.mediaList.slidesLimit
  );
  const slidesPage = useAppSelector(
    (state) => state.vehicles.mediaList.slidesPage
  );
  const recordingsLimit = useAppSelector(
    (state) => state.vehicles.mediaList.recordingsLimit
  );
  const recordingsPage = useAppSelector(
    (state) => state.vehicles.mediaList.recordingsPage
  );
  const listPhotosTotal = useAppSelector(
    (state) => state.vehicles.listPhotosTotal
  );
  const listSlidesTotal = useAppSelector(
    (state) => state.vehicles.listSlidesTotal
  );
  const listRecordingsTotal = useAppSelector(
    (state) => state.vehicles.listRecordingsTotal
  );

  const displayDatePicker = (
    activeTab: MediaListTabsEnum
  ): ReactElement | null => {
    if (activeTab === MediaListTabsEnum.PhotosTab) {
      return (
        <DateTimePickers
          activeTab={activeTab}
          startDate={photosStartDate}
          endDate={photosEndDate}
        />
      );
    }

    if (activeTab === MediaListTabsEnum.SlidesTab) {
      return (
        <DateTimePickers
          activeTab={activeTab}
          startDate={slidesStartDate}
          endDate={slidesEndDate}
        />
      );
    }

    return null;
  };

  const displayPagination = (activeTab: MediaListTabsEnum): ReactElement => {
    if (activeTab === MediaListTabsEnum.PhotosTab) {
      return (
        <Pagination
          activeTab={activeTab}
          limit={photosLimit}
          page={photosPage}
          count={listPhotosTotal}
        />
      );
    }

    if (activeTab === MediaListTabsEnum.SlidesTab) {
      return (
        <Pagination
          activeTab={activeTab}
          limit={slidesLimit}
          page={slidesPage}
          count={listSlidesTotal}
        />
      );
    }

    return (
      <Pagination
        activeTab={activeTab}
        limit={recordingsLimit}
        page={recordingsPage}
        count={listRecordingsTotal}
      />
    );
  };

  return (
    <Box className={classes.mediaActions}>
      {displayDatePicker(activeTab)}
      {displayPagination(activeTab)}
      {activeTab !== MediaListTabsEnum.VideosTab ? (
        <PhotosActions activeTab={activeTab} />
      ) : null}
    </Box>
  );
};

export default Actions;

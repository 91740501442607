import { FC, ReactElement, memo } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/index.module.css';
import MiniMapIcon from '../../../assets/MiniMapIcon';

interface IProps {
  setMiniMapMode: (status: boolean) => void;
  setMiniMapHovered: (status: boolean) => void;
}

const ReduceMapWidget: FC<IProps> = memo(
  ({ setMiniMapHovered, setMiniMapMode }): ReactElement => (
    <Box
      className={classes.slidesMiniMapContainer}
      onClick={() => {
        setMiniMapMode(true);
        setMiniMapHovered(false);
      }}
    >
      <MiniMapIcon />
    </Box>
  )
);

export default ReduceMapWidget;

import { FC, ReactElement, memo } from 'react';

const TractorIcon: FC = memo(
  (): ReactElement => (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.4 2.2001C2.4 1.3251 3.1 0.600098 4 0.600098H6.65C7.3 0.600098 7.9 1.0001 8.125 1.6251L9.325 4.6001H12V3.7751C12 3.1501 12.125 2.5251 12.4 1.9751L12.475 1.8501C12.675 1.4501 13.15 1.3001 13.55 1.5001C13.95 1.7001 14.1 2.1751 13.9 2.5751L13.85 2.7001C13.675 3.0251 13.6 3.4001 13.6 3.7751V4.6001H15C15.55 4.6001 16 5.0501 16 5.6001V6.7501C16 7.1501 15.775 7.5501 15.425 7.7751L14.35 8.4501C13.975 8.3001 13.6 8.2001 13.2 8.2001C12.2 8.2001 11.325 8.6751 10.775 9.4001H8.8C8.8 9.8501 8.425 10.2001 8 10.2001H7.775C7.75 10.3251 7.7 10.4501 7.625 10.5751L7.775 10.7001C8.1 11.0251 8.1 11.5251 7.775 11.8501L7.225 12.4001C6.9 12.7251 6.4 12.7251 6.075 12.4001L5.95 12.2501C5.825 12.3251 5.7 12.3751 5.6 12.4001V12.6001C5.6 13.0501 5.225 13.4001 4.8 13.4001H4C3.55 13.4001 3.2 13.0501 3.2 12.6001V12.4001C3.075 12.3751 2.95 12.3251 2.825 12.2501L2.7 12.4001C2.375 12.7251 1.875 12.7251 1.55 12.4001L1 11.8501C0.675 11.5251 0.675 11.0251 1 10.7001L1.15 10.5751C1.075 10.4501 1.025 10.3251 1 10.2001H0.8C0.35 10.2001 0 9.8501 0 9.4001V8.6001C0 8.1751 0.35 7.8001 0.8 7.8001H1C1.025 7.7001 1.075 7.5751 1.15 7.4501L1 7.3251C0.675 7.0001 0.675 6.5001 1 6.1751L1.55 5.6251C1.775 5.4001 2.1 5.3251 2.4 5.4251V2.2001ZM4 2.2001V4.6001H7.6L6.65 2.2001H4ZM4.4 7.0001C3.275 7.0001 2.4 7.9001 2.4 9.0001C2.4 10.1251 3.275 11.0001 4.4 11.0001C5.5 11.0001 6.4 10.1251 6.4 9.0001C6.4 7.9001 5.5 7.0001 4.4 7.0001ZM11 11.2001C11 10.4751 11.35 9.8001 11.925 9.4001C12.275 9.1501 12.725 9.0001 13.2 9.0001C13.25 9.0001 13.325 9.0251 13.375 9.0251C14.5 9.1251 15.4 10.0751 15.4 11.2001C15.4 12.4251 14.4 13.4001 13.2 13.4001C11.975 13.4001 11 12.4251 11 11.2001ZM13.2 11.8001C13.525 11.8001 13.8 11.5501 13.8 11.2001C13.8 10.8751 13.525 10.6001 13.2 10.6001C12.85 10.6001 12.6 10.8751 12.6 11.2001C12.6 11.5501 12.85 11.8001 13.2 11.8001Z'
        fill='white'
      />
    </svg>
  )
);

export default TractorIcon;

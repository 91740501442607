import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box/Box';
import VehiclesList from '../components/VehicleList/List';
import ListMap from '../common/map/ListMap';
import classes from '../styles/vehicles.module.css';

const VehicleListPage: FC = (): ReactElement => (
  <Box className={classes.vehiclesContainer}>
    <VehiclesList />
    <ListMap />
  </Box>
);

export default VehicleListPage;

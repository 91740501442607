import { FC, memo, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

interface IProps {
  created_at: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const CreatedAtCell: FC<IProps> = memo(
  ({ created_at }): ReactElement => (
    <Typography variant='body2' color='#fff'>
      {dayjs(created_at)
        .tz(dayjs.tz.guess(), true)
        .add(3, 'hours')
        .format('DD/MM/YY HH:mm')}
    </Typography>
  )
);

export default CreatedAtCell;

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getServerTime, getPing } from './app.thunks';

export interface AppState {
  timeProcessingLoading: boolean;
  timeProcessing: number;
  timeProcessingError: string | null;
  getPingSuccess: boolean;
  isUserOnline: boolean;
  pingError: string | null;
  collapseAdditionalInfoSection: boolean;
}

const initialState: AppState = {
  timeProcessingLoading: false,
  timeProcessing: 0,
  timeProcessingError: null,
  getPingSuccess: true,
  isUserOnline: true,
  pingError: null,
  collapseAdditionalInfoSection: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCollapseAdditionalInfoSection: (
      state: AppState,
      { payload }: { payload: boolean }
    ) => {
      state.collapseAdditionalInfoSection = payload;
    },
    setTimeProcessing: (state: AppState, { payload }: { payload: number }) => {
      state.timeProcessing = payload;
      state.timeProcessingLoading = false;
    },
    setIsUserOnline: (state: AppState, { payload }: { payload: boolean }) => {
      state.isUserOnline = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AppState>) => {
    builder.addCase(getServerTime.pending, (state: AppState) => {
      state.timeProcessingLoading = true;
    });
    builder.addCase(getServerTime.rejected, (state: AppState, { error }) => {
      state.timeProcessingError = error.message as string;
      state.timeProcessingLoading = false;
    });
    builder.addCase(getPing.fulfilled, (state: AppState) => {
      state.getPingSuccess = true;
    });
    builder.addCase(getPing.rejected, (state: AppState, { error }) => {
      state.getPingSuccess = false;
      state.pingError = error.message as string;
    });
  },
});

const appSliceReducer = appSlice.reducer;
export const {
  setTimeProcessing,
  setIsUserOnline,
  setCollapseAdditionalInfoSection,
} = appSlice.actions;
export default appSliceReducer;

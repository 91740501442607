import { AppRepository } from '../../domain/app';

type Dependencies = {
  appRepository: AppRepository;
};

export default ({ appRepository }: Dependencies) =>
  async () => {
    try {
      return await appRepository.getServerTimeRequest();
    } catch (error) {
      throw error;
    }
  };

import { FC, ReactElement, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import classes from '../../styles/ons.module.css';

const BreadcrumbsContainer: FC = memo((): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={
        <NavigateNextIcon fontSize='small' className={classes.separator} />
      }
    >
      <Typography className={classes.myRoutesLink} onClick={() => navigate(-1)}>
        {t('ons.historyPageTitle')}
      </Typography>
      <Typography color='#fff'>{t('ons.newRouteTitle')}</Typography>
    </Breadcrumbs>
  );
});

export default BreadcrumbsContainer;

import { VehicleRepository } from '../../domain/vehicle';

type Dependencies = {
  vehicleRepository: VehicleRepository;
};

export default ({ vehicleRepository }: Dependencies) =>
  async (archive_type: 'photo' | 'slide', list_ids: number[]) => {
    try {
      return await vehicleRepository.createArchiveRequest(
        archive_type,
        list_ids
      );
    } catch (error) {
      throw error;
    }
  };

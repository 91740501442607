import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const ArmIcon: FC = memo(
  (): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          d='M12.73 9.51444C13.48 9.74444 14.03 10.2944 14.3 10.9744L18.57 3.86444C19.22 2.78444 18.87 1.38444 17.79 0.734441C16.92 0.214441 15.8 0.324441 15.06 1.02444L11.63 4.23444C11.23 4.60444 11 5.13444 11 5.68444V9.61444C11.36 9.46444 11.98 9.28444 12.73 9.51444Z'
          fill='white'
        />
        <path
          d='M9.61 11.1744C9.77 10.6544 10.09 10.2144 10.5 9.90442H2.28C1.02 9.90442 0 10.9244 0 12.1844C0 13.2044 0.67 14.0944 1.65 14.3744L6.16 15.6644C6.69 15.8144 7.26 15.7444 7.74 15.4544L10.43 13.8444C9.66 13.2244 9.3 12.1744 9.61 11.1744Z'
          fill='white'
        />
        <path
          d='M13.4299 12.3344C13.1899 13.1344 12.3499 13.5744 11.5599 13.3344C10.7699 13.0944 10.3199 12.2544 10.5599 11.4644C10.7999 10.6644 11.6399 10.2244 12.4299 10.4644C13.2299 10.7044 13.6699 11.5444 13.4299 12.3344Z'
          fill='white'
        />
        <path
          d='M11.6379 14.3592C12.3618 14.4654 13.1131 14.264 13.6873 13.7295C14.2615 13.195 14.4156 12.5681 14.4657 12.1813L17.8692 14.1463C18.3455 14.4213 18.6895 14.8855 18.8099 15.4169L19.8748 19.9924C20.1111 20.9832 19.6463 22.0082 18.761 22.5016C17.6581 23.1119 16.2706 22.715 15.6603 21.6121L11.6379 14.3592Z'
          fill='white'
        />
      </svg>
    </Box>
  )
);

export default ArmIcon;

import { FC, ReactElement, memo } from 'react';
import Box from '@mui/material/Box';
import classes from '../styles/vehicle.module.css';

const WipersDryIcon: FC<{ on: boolean }> = memo(
  ({ on }): ReactElement => (
    <Box className={classes.startEngineIconWrapper}>
      <svg
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.fullWidthHeight}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 6.2002C8.34879 6.2002 4.76195 7.16129 1.59995 8.98687C1.41621 9.09295 1.28213 9.26769 1.22721 9.47263C1.1723 9.67757 1.20105 9.89594 1.30713 10.0797L5.30713 17.0079C5.41322 17.1916 5.58795 17.3257 5.7929 17.3806C5.99784 17.4355 6.2162 17.4068 6.39995 17.3007C7.3712 16.74 8.41688 16.3307 9.50097 16.0825L11.2615 20.3078C11.4314 20.7156 11.8998 20.9085 12.3076 20.7385C12.7155 20.5686 12.9083 20.1002 12.7384 19.6924L11.1307 15.834C11.4193 15.8115 11.7092 15.8002 12 15.8002C13.966 15.8002 15.8973 16.3177 17.5999 17.3007C17.7837 17.4068 18.0021 17.4355 18.207 17.3806C18.4119 17.3257 18.5867 17.1916 18.6928 17.0079L22.6928 10.0797C22.9137 9.69705 22.7826 9.20778 22.3999 8.98687C19.2379 7.16129 15.6511 6.2002 12 6.2002ZM10.4874 14.2899C10.9878 14.2303 11.4929 14.2002 12 14.2002C13.9795 14.2002 15.9283 14.6593 17.6946 15.5367L20.8988 9.98694C18.156 8.55231 15.1032 7.8002 12 7.8002C10.637 7.8002 9.28375 7.94528 7.96222 8.22956L10.4874 14.2899ZM6.39807 8.63559C5.26289 8.98185 4.15893 9.43364 3.10111 9.98694L6.30528 15.5367C7.12828 15.1279 7.99088 14.8099 8.87775 14.5868L6.39807 8.63559Z'
          fill={on ? '#66BB6A' : '#F7F7F8'}
        />
      </svg>
    </Box>
  )
);

export default WipersDryIcon;

// переключение передач
const boatChangeGearCommand = (gear: string): any => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone: 'boat_gear_change_main',
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    boat_change_gear: gear,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: { frontend_sent: 0 },
});

export default boatChangeGearCommand;

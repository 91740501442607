import { FC, ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import classes from '../../../styles/stream.module.css';

interface IProps {
  camera: number;
  totalCameras: number;
  setVideoFullScreenMode: (status: boolean) => void;
  setVideoFullScreenCamera: (camera: number) => void;
}

const FullScreenCameras: FC<IProps> = memo(
  ({
    camera,
    totalCameras,
    setVideoFullScreenMode,
    setVideoFullScreenCamera,
  }): ReactElement => {
    const { t } = useTranslation();
    return (
      <Box className={classes.otherCameras}>
        {Array.from({ length: totalCameras }).map(
          (_, index: number): ReactElement => (
            <Button
              variant='contained'
              color={index === camera ? 'primary' : 'inherit'}
              size='medium'
              key={index}
              onClick={(): void => {
                setVideoFullScreenMode(true);
                setVideoFullScreenCamera(index);
              }}
            >
              {t('vehicle.cameraLabel')} {index + 1}
            </Button>
          )
        )}
      </Box>
    );
  }
);

export default FullScreenCameras;

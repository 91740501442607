import { FC, ReactElement, memo } from 'react';
import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useAppSelector } from '../../../../../store';

const GamepadL1Button: FC = memo((): ReactElement => {
  const gamepadButtonL1 = useAppSelector(
    (state) => state.vehicles.gamepad?.buttons[4].pressed
  );
  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );
  const changeColorButton = () => {
    if (manualControlMetric) {
      if (gamepadButtonL1) {
        return '1';
      }
    }
    return '0.2';
  };
  return (
    <Typography variant='caption'>
      <ArrowBackIosIcon
        sx={{ opacity: () => changeColorButton(), color: '#fff' }}
      />
    </Typography>
  );
});

export default GamepadL1Button;

import { FC, memo, ReactElement, useEffect } from 'react';
import Box from '@mui/material/Box';
import { UnknownAction } from '@reduxjs/toolkit';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { listStreamingRecordings } from '../../../../store/vehicle/vehicle.thunks';
import SingleVideo from './SingleVideo';
import classes from '../index.module.css';

const Videos: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
  const listRecordings = useAppSelector(
    (state) => state.vehicles.listRecordings
  );
  const listRecordingsLoading = useAppSelector(
    (state) => state.vehicles.listRecordingsLoading
  );
  const recordingsLimit = useAppSelector(
    (state) => state.vehicles.mediaList.recordingsLimit
  );
  const recordingsPage = useAppSelector(
    (state) => state.vehicles.mediaList.recordingsPage
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (uuid) {
      dispatch(
        listStreamingRecordings({
          uuid,
          limit: recordingsLimit,
          page: recordingsPage,
        }) as unknown as UnknownAction
      );
    }
  }, [uuid, recordingsLimit, recordingsPage]);

  if (listRecordingsLoading) {
    return (
      <Box className={classes.mediaListLoader}>
        <CircularProgress color='inherit' />
        <Typography variant='caption'>
          {t('vehicle.loadingVideosLabel')}
        </Typography>
      </Box>
    );
  }

  if (!listRecordings.length) {
    return (
      <Box className={classes.mediaListNoData}>
        <Typography variant='caption'>{t('vehicle.noVideosLabel')}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.videoListContainer}>
      {listRecordings.map(
        (recordingURL: string): ReactElement => (
          <SingleVideo key={recordingURL} recordingURL={recordingURL} />
        )
      )}
    </Box>
  );
});

export default Videos;

import { FC, ReactElement, memo } from 'react';
import { useAppSelector } from '../../../../../store';

const GamepadStickRight: FC = memo((): ReactElement => {
  const horizontalAxis = useAppSelector(
    (state) => state.vehicles.gamepad?.axes[2]
  );
  const verticalAxis = useAppSelector(
    (state) => state.vehicles.gamepad?.axes[3]
  );

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 110 110'
      style={{
        width: '90px',
        height: '90px',
        fill: 'rgba(255, 255, 255, 0.12)',
      }}
    >
      <g transform='rotate(-22.5 55 55)'>
        <path id='s1' d='M 55 55 L 105 55 A 50 50 0 0 1 90.35 90.35 Z' />
        <path id='s2' d='M 55 55 L 90.35 90.35 A 50 50 0 0 1 55 105 Z' />
        <path id='s3' d='M 55 55 L 55 105 A 50 50 0 0 1 19.6447 90.35 Z' />
        <path id='s4' d='M 55 55 L 19.64 90.353 A 50 50 0 0 1 5 55 Z' />
        <path id='s5' d='M 55 55 L 5 55 A 50 50 0 0 1 19.64 19.64 Z' />
        <path id='s6' d='M 55 55 L 19.64 19.64 A 50 50 0 0 1 55 5 Z' />
        <path id='s7' d='M 55 55 L 55 5 A 50 50 0 0 1 90.35 19.64 Z' />
        <path id='s8' d='M 55 55 L 90.35 19.64 A 50 50 0 0 1 105 55 Z' />
      </g>
      <circle
        id='center'
        cx='55'
        cy='55'
        r='10'
        fill='wheat'
        style={{
          transform: `translate(${horizontalAxis * (50 - 10)}px, ${verticalAxis * (50 - 10)}px)`,
        }}
      />
    </svg>
  );
});

export default GamepadStickRight;

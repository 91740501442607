import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.translation.json';
import uk from './locales/uk.translation.json';

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'uk');
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['uk', 'en'],
    fallbackLng: 'uk',
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      uk: {
        translation: uk,
      },
    },
  });

export default i18n;

import { useState, useEffect, useCallback } from 'react';

export interface IWindowSize {
  isSmartPhone: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  windowWidth: number;
}

const useWindowSize = (): IWindowSize => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleResize = useCallback((): void => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const isSmartPhone: boolean = windowWidth <= 768;
  const isTablet: boolean = windowWidth > 768 && windowWidth < 1200;
  const isLaptop: boolean = windowWidth > 1200 && windowWidth < 2200;

  return {
    windowWidth,
    isSmartPhone,
    isTablet,
    isLaptop,
  };
};

export default useWindowSize;

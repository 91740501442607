import { VehicleRepository } from '../../domain/vehicle';

type Dependencies = {
  vehicleRepository: VehicleRepository;
};

export default ({ vehicleRepository }: Dependencies) =>
  async (uuid: string, from: any, to: any, limit: number, page: number) => {
    try {
      return await vehicleRepository.listStreamingPhotosRequest(
        uuid,
        from,
        to,
        limit,
        page
      );
    } catch (error) {
      throw error;
    }
  };

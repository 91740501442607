import { FC, memo, ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import downloadImage from '../../helpers/downloadImage';
import classes from '../index.module.css';

interface IProps {
  imageURL: string;
}

const SingleImageDownload: FC<IProps> = memo(({ imageURL }): ReactElement => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('ons.downloadLabel')} arrow placement='right'>
      <IconButton
        className={classes.singlePhotoDownload}
        size='small'
        onClick={() => downloadImage(imageURL)}
      >
        <DownloadIcon className={classes.singlePhotoIcon} />
      </IconButton>
    </Tooltip>
  );
});

export default SingleImageDownload;

import { FC, memo, ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import classes from './index.module.css';

interface IProps {
  zoomIn: () => void;
  zoomOut: () => void;
  resetTransform: () => void;
}

const Zoom: FC<IProps> = memo(
  ({ zoomIn, zoomOut, resetTransform }): ReactElement => {
    const { t } = useTranslation();
    return (
      <Box className={classes.singlePhotoZoomContainer}>
        <Tooltip title={t('vehicle.zoomInLabel')} arrow placement='top'>
          <IconButton size='small' onClick={() => zoomIn()}>
            <ZoomInIcon className={classes.fullScreenMode} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('vehicle.zoomOutLabel')} arrow placement='top'>
          <IconButton size='small' onClick={() => zoomOut()}>
            <ZoomOutIcon className={classes.fullScreenMode} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('vehicle.resetLabel')} arrow placement='top'>
          <IconButton size='small' onClick={() => resetTransform()}>
            <RestartAltIcon className={classes.fullScreenMode} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
);

export default Zoom;

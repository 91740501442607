import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import FilterStatus from './FilterStatus';
import FilterRangePicker from './FilterRangePicker';
import Delete from './Delete';
import Download from './Download';
import Search from './Search';
import { setDeselectedAllUuids } from '../../../../store/ons/ons.slice';
import { useAppDispatch } from '../../../../store';
import classes from '../../../styles/ons.module.css';

interface IProps {
  numSelected: string[];
  refreshIcon: ReactElement;
}

const EnhancedTableToolbar: FC<IProps> = ({
  numSelected,
  refreshIcon,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToOnsCreatePage = (): void => navigate('/ons/create');

  return (
    <Toolbar
      sx={{
        mt: 0,
        mb: 2,
        pl: 0,
        pr: 0,
        pt: 0,
        borderRadius: '0.25rem',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '56px',
        ...(numSelected.length > 0 && {
          bgcolor: 'rgba(144, 202, 249, 1)',
        }),
      }}
    >
      {numSelected.length > 0 ? (
        <Box className={classes.toolbarRightSelected}>
          <Typography color='rgba(0, 0, 0, 0.87)' variant='body1'>
            {t('ons.selected')}: {numSelected.length}
          </Typography>
          <Download numSelected={numSelected} />
          <Delete numSelected={numSelected} />
        </Box>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant='h4'
          id='tableTitle'
          component='div'
          color='#fff'
        >
          {t('ons.historyPageTitle')}
        </Typography>
      )}
      {numSelected.length > 0 ? (
        <IconButton onClick={() => dispatch(setDeselectedAllUuids())}>
          <CloseRoundedIcon className={classes.toolbarRightButtons} />
        </IconButton>
      ) : (
        <Box className={classes.toolbarRight}>
          {refreshIcon}
          <Search />
          <FilterStatus />
          <FilterRangePicker />
          <Button
            variant='contained'
            color='primary'
            size='large'
            fullWidth
            startIcon={<AddIcon />}
            className={classes.createRouteButton}
            onClick={navigateToOnsCreatePage}
          >
            {t('ons.historyPageCreateRouteButtonText')}
          </Button>
        </Box>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;

import { FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classes from './index.module.css';

interface IProps {
  created_at: string;
}

const Timestamp: FC<IProps> = memo(
  ({ created_at }): ReactElement => (
    <Box className={classes.timestampFullScreen}>
      <Typography variant='caption' className={classes.fullScreenMode}>
        {created_at}
      </Typography>
    </Box>
  )
);

export default Timestamp;

import { CSSProperties, FC, memo, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { setMetricsInterval } from '../../../../../store/vehicle/vehicle.slice';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import classes from '../../styles/index.module.css';

const metricsIntervals: number[] = [5000, 10000, 15000];

const MetricsIntervalSettings: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const metricsInterval = useAppSelector(
    (state) => state.vehicles.metricsInterval
  );

  const { t } = useTranslation();

  const setActiveBackgroundColor = (isActive: boolean): CSSProperties => ({
    backgroundColor: isActive ? '#F0F4FE' : '#fff',
  });

  const setActiveTextColor = (isActive: boolean): CSSProperties => ({
    color: isActive ? '#4778F5' : '#14151A',
  });

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Typography variant='caption'>
        {t('vehiclesList.mapMetricsUpdateFrequencyLabel')}
      </Typography>
      <Box className={classes.metrics}>
        {metricsIntervals.map(
          (interval: number): ReactElement => (
            <Box
              key={interval}
              className={classes.metric}
              style={setActiveBackgroundColor(interval === metricsInterval)}
              onClick={() => dispatch(setMetricsInterval(interval))}
            >
              <Typography
                variant='caption'
                style={setActiveTextColor(interval === metricsInterval)}
              >
                {interval / 1000}
                {t('vehiclesList.seconds')}
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
});

export default MetricsIntervalSettings;

import { FC, ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../../store';
import { VEHICLE_TYPES } from '../../../../domain/vehicle';
import { fromRadiansToDegrees } from '../../../common/map/common/config';
import Compass from './Compass';
import classes from '../../../styles/vehicle.module.css';

const Metrics: FC = memo((): ReactElement => {
  const metrics = useAppSelector((state) => state.socket.vehicleMetrics);
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const { t } = useTranslation();

  return (
    <Box className={classes.metricsBlockMain}>
      <Box className={classes.metricsBlock}>
        <Box>
          {/* <Box className={classes.secondaryMetricContainer}>
            <Typography variant='caption' className={classes.secondaryMetric}>
              {t('vehicle.aslLabel')}
            </Typography>
            <Box className={classes.secondaryMetricContainer1}>
              <Typography variant='caption' className={classes.secondaryMetric}>
                {metrics && metrics.groundspeed
                  ? `${(metrics.groundspeed * 3.6).toFixed(3)} `
                  : '0 '}
              </Typography>
              <Typography
                variant='caption'
                className={classes.secondaryMetric1}
              >
                {t('ons.meters')}
              </Typography>
            </Box>
          </Box> */}
          {vehicleType === VEHICLE_TYPES.DRONE ? (
            <Box className={classes.primaryMetricContainer}>
              <Typography variant='h4' className={classes.primaryMetricValue}>
                {metrics && metrics.position
                  ? `${metrics.position.altitude} ${t('ons.meters')}`
                  : 0}
              </Typography>
              <Box className={classes.primaryMetricContainer1}>
                <Typography variant='caption' className={classes.primaryMetric}>
                  {t('vehicle.altLabel')}
                </Typography>
                <Typography
                  variant='caption'
                  className={classes.primaryMetric1}
                >
                  {t('ons.meters')}
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
        {/* <Box className={classes.metric}> */}
        {/*  <Typography variant='caption'>Battery</Typography> */}
        {/*  <Typography variant='caption'> */}
        {/*    {metrics && metrics.metrics && metrics.metrics.battery_charge */}
        {/*      ? `${metrics.metrics.battery_charge}%` */}
        {/*      : '0%'} */}
        {/*  </Typography> */}
        {/* </Box> */}
        <Box>
          <Box className={classes.secondaryMetricContainer}>
            <Typography variant='caption' className={classes.secondaryMetric}>
              {t('vehicle.asLabel')}
            </Typography>
            <Box className={classes.secondaryMetricContainer1}>
              <Typography variant='caption' className={classes.secondaryMetric}>
                {metrics && metrics.airspeed
                  ? `${(metrics.airspeed * 3.6).toFixed(3)} `
                  : '0 '}
              </Typography>
              <Typography
                variant='caption'
                className={classes.secondaryMetric1}
              >
                {t('vehicle.kmH')}
              </Typography>
            </Box>
          </Box>
          {/* {vehicleType === VEHICLE_TYPES.DRONE ? ( */}
          <Box className={classes.primaryMetricContainer}>
            <Typography variant='h4' className={classes.primaryMetricValue}>
              {metrics && metrics.groundspeed
                ? `${(metrics.groundspeed * 3.6).toFixed(3)}`
                : '0 '}
            </Typography>
            <Box className={classes.primaryMetricContainer1}>
              <Typography variant='caption' className={classes.primaryMetric}>
                {t('vehicle.gsLabel')}
              </Typography>
              <Typography variant='caption' className={classes.primaryMetric1}>
                {t('vehicle.kmH')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rollPitchContainer}>
          {vehicleType === VEHICLE_TYPES.DRONE ||
          vehicleType === VEHICLE_TYPES.BOAT ? (
            <Box className={classes.rollPitchContainerInner}>
              <Typography variant='caption' className={classes.secondaryMetric}>
                {t('vehicle.rollLabel')} (X)
              </Typography>
              <Typography variant='caption' className={classes.rollValue}>
                {metrics && metrics.attitude
                  ? `${fromRadiansToDegrees(metrics.attitude.pitch).toFixed(2)} Y${fromRadiansToDegrees(metrics.attitude.roll).toFixed(2)}`
                  : 0}
              </Typography>
            </Box>
          ) : null}
          {vehicleType === VEHICLE_TYPES.DRONE ||
          vehicleType === VEHICLE_TYPES.BOAT ? (
            <Box className={classes.rollPitchContainerInner}>
              <Typography variant='caption' className={classes.secondaryMetric}>
                {t('vehicle.pitchLabel')} (Y)
              </Typography>
              <Typography variant='caption' className={classes.pitchValue}>
                {metrics && metrics.attitude
                  ? `${fromRadiansToDegrees(metrics.attitude.pitch).toFixed(2)} Y${fromRadiansToDegrees(metrics.attitude.roll).toFixed(2)}`
                  : 0}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Compass />
    </Box>
  );
});

export default Metrics;

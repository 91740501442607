import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  setController,
  setGamepadIndex,
  setShowSelectController,
} from '../../../../store/vehicle/vehicle.slice';
import { socketSend } from '../../../../infra/common/socketService';
import { manualControlCommand } from '../../../../infra/common/socketService/commands/commonCommands';
import classes from './controls.module.css';

const SelectController: React.FC = () => {
  const dispatch = useAppDispatch();
  const controller = useAppSelector((state) => state.vehicles.controller);
  const gamepadIndex = useAppSelector((state) => state.vehicles.gamepadIndex);
  const showSelectController = useAppSelector(
    (state) => state.vehicles.showSelectController
  );

  const controllerData = localStorage.getItem('controller');
  const gamepadIndexData = localStorage.getItem('gamepad-index');
  const parsedGamepadIndexData = gamepadIndexData
    ? JSON.parse(gamepadIndexData)
    : null;
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );
  const [availableGamepads, setAvailableGamepads] = useState<
    (Gamepad | null)[]
  >([]);
  const [selectedGamepadIndex, setSelectedGamepadIndex] = useState<
    number | null
  >(parsedGamepadIndexData);

  const { t } = useTranslation();

  useEffect(() => {
    const updateGamepads = () => {
      const gamepadsArray = navigator.getGamepads();
      setAvailableGamepads(
        Array.from(gamepadsArray).filter((gp) => gp !== null)
      );
    };

    window.addEventListener('gamepadconnected', updateGamepads);
    window.addEventListener('gamepaddisconnected', updateGamepads);

    // Initial check
    updateGamepads();

    return () => {
      window.removeEventListener('gamepadconnected', updateGamepads);
      window.removeEventListener('gamepaddisconnected', updateGamepads);
    };
  }, [showSelectController]);

  useEffect(() => {
    if (!controllerData && !gamepadIndexData) {
      dispatch(setShowSelectController(true));
    } else {
      dispatch(setShowSelectController(false));
    }
  }, []);

  useEffect(() => {
    if (controllerData) {
      dispatch(setController(controllerData));
    }
  }, [controllerData, controller]);

  useEffect(() => {
    dispatch(setGamepadIndex(selectedGamepadIndex));
  }, [selectedGamepadIndex]);

  const handleSelectController = (index: number) => {
    setSelectedGamepadIndex(index);
  };

  const handleCloseModal = () => {
    dispatch(setShowSelectController(false));
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={showSelectController}
      onClose={handleCloseModal}
      aria-labelledby='media-list-title'
      aria-describedby='media-list-description'
    >
      <Box className={classes.modalContainer}>
        <IconButton
          onClick={handleCloseModal}
          className={classes.modalCloseIcon}
        >
          <CloseIcon />
        </IconButton>
        {availableGamepads && availableGamepads.length > 0 ? (
          <Typography variant='h4' sx={{ marginBottom: '50px' }}>
            {t('vehicle.connectedControllersLabel')}:
          </Typography>
        ) : null}
        {availableGamepads && availableGamepads.length > 0 ? (
          <>
            {availableGamepads.map((gamepad: any) => (
              <Box key={gamepad.index} className={classes.gamepadCardContainer}>
                <Box className={classes.gamepadCard}>
                  <Typography variant='body1'>{gamepad.id}</Typography>
                  <Button
                    onClick={() => {
                      localStorage.setItem('gamepad-index', gamepad.index);
                      handleSelectController(gamepad.index);
                    }}
                    variant='contained'
                  >
                    {t('vehicle.selectControllerLabel')}
                  </Button>
                </Box>
              </Box>
            ))}
            <Typography variant='body1'>
              {t('vehicle.selectedControllerLabel')}:{' '}
              {availableGamepads.map((gamepad: any) =>
                gamepad.index === gamepadIndex ? gamepad.id : null
              )}
            </Typography>
          </>
        ) : (
          <Box className={classes.connectGamepadContainer}>
            <CircularProgress color='inherit' />
            <Typography className={classes.modalHeader} variant='h5'>
              {t('vehicle.gpStartMessageText')}
            </Typography>
          </Box>
        )}
        {availableGamepads && availableGamepads.length > 0 ? (
          <>
            <Typography variant='h4' sx={{ marginBottom: '50px' }}>
              {t('vehicle.selectTypeControlLabel')}:
            </Typography>
            <Box className={classes.controlTypeContainer}>
              <Button
                variant={controller === 'gamepad' ? 'contained' : 'outlined'}
                sx={{ maxWidth: '250px', width: '100%' }}
                className={classes.controlType}
                onClick={async () => {
                  dispatch(setController('gamepad'));
                  localStorage.setItem('controller', 'gamepad');
                  if (manualControlMetric) {
                    await socketSend(
                      manualControlCommand('stop_manual'),
                      timeProcessing
                    );
                  }
                }}
              >
                <SportsEsportsIcon />
                {t('vehicle.gp')}
              </Button>
              <Button
                variant={controller === 'wheel' ? 'contained' : 'outlined'}
                sx={{ maxWidth: '250px', width: '100%' }}
                className={classes.controlType}
                onClick={async () => {
                  dispatch(setController('wheel'));
                  localStorage.setItem('controller', 'wheel');
                  if (manualControlMetric) {
                    await socketSend(
                      manualControlCommand('stop_manual'),
                      timeProcessing
                    );
                  }
                }}
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 70 70'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ transform: `rotate(350deg)` }}
                >
                  <g clipPath='url(#clip0_2499_2994)'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M59.2121 37.3131L43.5029 32.938L43.3409 32.4183C42.3976 29.3909 39.8406 26.9837 36.5171 26.3977C33.1935 25.8117 29.9674 27.1992 28.0456 29.7213L27.7157 30.1543L11.4574 28.8927L11.8351 27.6928C15.4378 16.245 27.0856 8.94025 39.2181 11.0795C51.3505 13.2188 59.7975 24.0668 59.2676 36.0565L59.2121 37.3131ZM10.795 32.6421L26.4072 36.6666L26.5578 37.2315C27.052 39.0861 28.1484 40.7348 29.6604 41.9082L30.1025 42.2513L28.3253 58.3884L27.145 57.9858C16.9769 54.5174 10.2277 44.6503 10.737 33.8702L10.795 32.6421ZM33.2735 59.2609L37.1228 43.4891L37.6557 43.3179C39.4776 42.7325 41.0717 41.5582 42.1706 39.9845L42.5053 39.5052L58.5524 41.0631L58.1869 42.2369C54.9783 52.541 45.2614 59.5047 34.5204 59.2862L33.2735 59.2609ZM58.7557 18.3663C54.3116 12.0194 47.6664 7.78602 40.036 6.44058C32.4057 5.09514 24.7134 6.80042 18.3665 11.2446C12.0196 15.6887 7.78626 22.3339 6.44083 29.9642C5.09539 37.5946 6.80066 45.2868 11.2448 51.6337C15.689 57.9807 22.3341 62.214 29.9645 63.5594C37.5948 64.9049 45.2871 63.1996 51.6341 58.7554C57.9809 54.3114 62.2142 47.6662 63.5597 40.0358C64.9051 32.4054 63.1998 24.7132 58.7557 18.3663ZM34.3014 38.9634C32.115 38.5778 30.6514 36.4874 31.0369 34.3012C31.4224 32.1149 33.513 30.6512 35.6991 31.0367C37.8852 31.4221 39.3491 33.5127 38.9636 35.6989C38.5781 37.8849 36.4878 39.3489 34.3014 38.9634Z'
                      fill={controller === 'wheel' ? '#fff' : '#1976d2'}
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_2499_2994'>
                      <rect
                        width='60'
                        height='60'
                        fill='white'
                        transform='translate(10.6655 0.246338) rotate(10)'
                      />
                    </clipPath>
                  </defs>
                </svg>
                {t('vehicle.steeringWheelLabel')}
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
    </Modal>
  );
};

export default SelectController;

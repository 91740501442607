import {
  ChangeEvent,
  FC,
  forwardRef,
  Fragment,
  memo,
  MouseEvent,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { UnknownAction } from '@reduxjs/toolkit';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadIcon from '@mui/icons-material/Download';
import TablePagination from '@mui/material/TablePagination';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { getArchiveList } from '../../../../store/vehicle/vehicle.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { IArchive } from '../../../../domain/vehicle';
import downloadImage from '../../helpers/downloadImage';
import setPaginationPage from '../../helpers/setPaginationPage';
import classes from '../index.module.css';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ArchivesModal: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
  const listArchives = useAppSelector((state) => state.vehicles.listArchives);
  const listArchivesTotal = useAppSelector(
    (state) => state.vehicles.listArchivesTotal
  );

  const [archivesModalVisible, setArchivesModalVisible] =
    useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const { t } = useTranslation();

  useEffect(() => {
    if (uuid) {
      dispatch(
        getArchiveList({
          uuid,
          limit,
          page,
        }) as unknown as UnknownAction
      );
    }
  }, [uuid, limit, page]);

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number
  ): void => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <Chip
        className={classes.chipAction}
        disabled={!listArchives.length}
        label={t('vehicle.archivesLabel')}
        onClick={() => setArchivesModalVisible(true)}
      />
      <Dialog
        open={archivesModalVisible}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setArchivesModalVisible(false)}
        fullWidth
      >
        <DialogContent>
          <List
            sx={{
              width: '100%',
            }}
          >
            {listArchives.map(
              (archive: IArchive): ReactElement => (
                <Fragment key={archive.uuid}>
                  <ListItem
                    secondaryAction={
                      archive.status === 'success' ? (
                        <Tooltip
                          arrow
                          placement='top'
                          title={t('ons.downloadLabel')}
                        >
                          <IconButton
                            edge='end'
                            aria-label='download'
                            onClick={() =>
                              downloadImage(archive.path_to_archive)
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip arrow placement='top' title={archive.reason}>
                          <IconButton edge='end' aria-label='info'>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  >
                    <ListItemAvatar style={{ minWidth: '34px' }}>
                      {archive.status === 'success' ? (
                        <CheckCircleIcon color='success' />
                      ) : (
                        <ErrorIcon color='error' />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        archive.archive_type === 'slide'
                          ? t('vehicle.slidesLabel')
                          : t('vehicle.photosLabel')
                      }
                      secondary={archive.created_at}
                    />
                  </ListItem>
                  <Divider component='li' />
                </Fragment>
              )
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              20,
              50,
              {
                label: t('vehiclesList.paginationAllText'),
                value: listArchivesTotal || 100,
              },
            ]}
            count={listArchivesTotal}
            page={setPaginationPage(page)}
            rowsPerPage={Number(limit)}
            labelRowsPerPage={null}
            showFirstButton
            showLastButton
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component='div'
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '42px',
              backgroundColor: 'rgba(222,224,227,0.7)',
              borderRadius: '50px',
              '.MuiTablePagination-toolbar': {
                padding: 0,
              },
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
});

export default ArchivesModal;

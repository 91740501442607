import Supercluster, { AnyProps } from 'supercluster';
import { IMapConfig } from './mapTypes';

export enum LayersEnum {
  SATELLITE = 'satellite',
  TERRAIN = 'terrain',
}

export const listMapConfig: IMapConfig = {
  layerControls: {
    defaultTileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    satelliteTileLayerUrl: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomainsForSatelliteTile: ['mt0', 'mt1', 'mt2', 'mt3'],
  },
  zoom: {
    initialZoom: 5,
    minZoom: 3,
    zoomSnap: 0.5,
    maxZoom: 20,
    zoomControlPosition: 'bottomright',
    zoomControl: false,
    activePinZoom: 12,
    minZoomTile: 0,
    maxZoomTile: 20,
    maxNativeZoom: 20,
  },
  bounds: {
    maxBoundsViscosity: 1.0,
    padding: [20, 20],
    maxZoom: 100,
    duration: 1,
  },
  marker: {
    rotationOrigin: 'bottom center',
  },
  scale: {
    position: 'bottomleft',
  },
  dragging: true,
};

export const slidesMiniMapConfig: IMapConfig = {
  layerControls: {
    defaultTileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    satelliteTileLayerUrl: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomainsForSatelliteTile: ['mt0', 'mt1', 'mt2', 'mt3'],
  },
  zoom: {
    initialZoom: 16,
    minZoom: 3,
    zoomSnap: 0.5,
    maxZoom: 19,
    zoomControlPosition: 'bottomright',
    zoomControl: false,
    activePinZoom: 12,
    minZoomTile: 0,
    maxZoomTile: 20,
    maxNativeZoom: 20,
  },
  bounds: {
    maxBoundsViscosity: 1.0,
    padding: [10, 10],
    maxZoom: 100,
    duration: 1,
  },
  marker: {
    rotationOrigin: 'bottom center',
  },
  scale: {
    position: 'bottomleft',
  },
  dragging: true,
};

export const miniMapConfig: IMapConfig = {
  layerControls: {
    defaultTileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    satelliteTileLayerUrl: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomainsForSatelliteTile: ['mt0', 'mt1', 'mt2', 'mt3'],
  },
  zoom: {
    initialZoom: 17,
    minZoom: 3,
    zoomSnap: 0.5,
    maxZoom: 19,
    zoomControlPosition: 'bottomright',
    zoomControl: false,
    activePinZoom: 12,
    minZoomTile: 0,
    maxZoomTile: 20,
    maxNativeZoom: 20,
  },
  bounds: {
    maxBoundsViscosity: 1.0,
    padding: [10, 10],
    maxZoom: 100,
    duration: 1,
  },
  marker: {
    rotationOrigin: 'bottom center',
  },
  scale: {
    position: 'bottomleft',
  },
  dragging: false,
};

export const pinnedMiniMapConfig: IMapConfig = {
  layerControls: {
    defaultTileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    satelliteTileLayerUrl: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomainsForSatelliteTile: ['mt0', 'mt1', 'mt2', 'mt3'],
  },
  zoom: {
    initialZoom: 17,
    minZoom: 3,
    zoomSnap: 0.5,
    maxZoom: 19,
    zoomControlPosition: 'bottomright',
    zoomControl: false,
    activePinZoom: 12,
    minZoomTile: 0,
    maxZoomTile: 20,
    maxNativeZoom: 20,
  },
  bounds: {
    maxBoundsViscosity: 1.0,
    padding: [10, 10],
    maxZoom: 100,
    duration: 1,
  },
  marker: {
    rotationOrigin: 'bottom center',
  },
  scale: {
    position: 'bottomleft',
  },
  dragging: true,
};

export const superCluster: Supercluster<AnyProps> = new Supercluster({
  radius: 40,
  maxZoom: 10,
  minPoints: 1,
  extent: 256,
});

export const fromRadiansToDegrees = (radians: number): number => {
  if (radians) {
    return radians * (Math.PI / 180);
  }

  return 0;
};
// 50.184255, 36.617714

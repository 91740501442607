import { FC, memo, MouseEvent, ReactElement, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { UnknownAction } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  deleteStreamingPhotos,
  listStreamingPhotos,
  listStreamingSlides,
} from '../../../../store/vehicle/vehicle.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store';
import classes from '../index.module.css';
import getLocalISO from '../../helpers/getLocalISO';

interface IProps {
  imageID: number;
  imageURL: string;
  type: 'photo' | 'slide';
}

const SingleImageDelete: FC<IProps> = memo(
  ({ imageID, imageURL, type }): ReactElement => {
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
    const deletePhotosLoading = useAppSelector(
      (state) => state.vehicles.deletePhotosLoading
    );
    const photosStartDate = useAppSelector(
      (state) => state.vehicles.mediaList.photosStartDate
    );
    const photosEndDate = useAppSelector(
      (state) => state.vehicles.mediaList.photosEndDate
    );
    const photosLimit = useAppSelector(
      (state) => state.vehicles.mediaList.photosLimit
    );
    const photosPage = useAppSelector(
      (state) => state.vehicles.mediaList.photosPage
    );
    const slidesStartDate = useAppSelector(
      (state) => state.vehicles.mediaList.slidesStartDate
    );
    const slidesEndDate = useAppSelector(
      (state) => state.vehicles.mediaList.slidesEndDate
    );
    const slidesLimit = useAppSelector(
      (state) => state.vehicles.mediaList.slidesLimit
    );
    const slidesPage = useAppSelector(
      (state) => state.vehicles.mediaList.slidesPage
    );
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { t } = useTranslation();
    const openPopover = (event: MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    };

    const closePopover = (): void => {
      setAnchorEl(null);
    };

    return (
      <>
        <Tooltip title={t('ons.deleteLabel')} arrow placement='bottom'>
          <IconButton
            className={classes.singlePhotoDelete}
            size='small'
            onClick={openPopover}
          >
            <DeleteIcon className={classes.singlePhotoIcon} />
          </IconButton>
        </Tooltip>
        <Popover
          id={imageURL}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className={classes.deletePopover}>
            <Typography sx={{ p: 1 }} variant='caption'>
              {t('vehicle.mediaDeleteTitle')}?
            </Typography>
            <Box className={classes.deletePopoverButtons}>
              <Button variant='text' onClick={closePopover}>
                {t('ons.cancel')}
              </Button>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  if (!deletePhotosLoading) {
                    dispatch(
                      deleteStreamingPhotos({
                        uuid,
                        list_ids: [imageID],
                        photo_type: type,
                        onSuccess: () => {
                          if (type === 'photo') {
                            dispatch(
                              listStreamingPhotos({
                                uuid,
                                from: photosStartDate || '2011-04-11T11:51:00',
                                to: photosEndDate || getLocalISO(),
                                limit: photosLimit,
                                page: photosPage,
                              }) as unknown as UnknownAction
                            );
                          } else {
                            dispatch(
                              listStreamingSlides({
                                uuid,
                                from: slidesStartDate || '2011-04-11T11:51:00',
                                to: slidesEndDate || getLocalISO(),
                                limit: slidesLimit,
                                page: slidesPage,
                              }) as unknown as UnknownAction
                            );
                          }
                          closePopover();
                        },
                      }) as unknown as UnknownAction
                    );
                  }
                }}
              >
                {!deletePhotosLoading
                  ? t('ons.deleteLabel')
                  : t('ons.deleting')}
              </Button>
            </Box>
          </Box>
        </Popover>
      </>
    );
  }
);

export default SingleImageDelete;

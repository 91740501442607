import { VehicleRepository } from '../../domain/vehicle';

type Dependencies = {
  vehicleRepository: VehicleRepository;
};

export default ({ vehicleRepository }: Dependencies) =>
  async (uuid: string) => {
    try {
      return await vehicleRepository.getArchiveRequest(uuid);
    } catch (error) {
      throw error;
    }
  };

import { FC, ReactElement } from 'react';
import { useOutlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SelectLanguage from '../Language/SelectLanguage';
import classes from './index.module.css';

const LoginLayout: FC = (): ReactElement => {
  const outlet = useOutlet();
  return (
    <Box className={classes.container}>
      <SelectLanguage />
      <Paper elevation={3} className={classes.paper}>
        {outlet}
      </Paper>
    </Box>
  );
};

export default LoginLayout;

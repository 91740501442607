import { VehicleRepository } from '../../domain/vehicle';

type Dependencies = {
  vehicleRepository: VehicleRepository;
};

export default ({ vehicleRepository }: Dependencies) =>
  async (vehicleUuid: string, num_camera: number) => {
    try {
      return await vehicleRepository.startStreamingRecordingRequest(
        vehicleUuid,
        num_camera
      );
    } catch (error) {
      throw error;
    }
  };

import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { useMap } from 'react-leaflet';
import { zoomControlStyles } from '../styles/mapStyles';
import classes from '../styles/index.module.css';
import { useAppSelector } from '../../../../store';

interface IProps {
  pinnedMap: boolean;
  collapseAdditionalInfoSection: boolean;
}

const ZoomControlWidget: FC<IProps> = ({
  pinnedMap,
  collapseAdditionalInfoSection,
}): ReactElement => {
  const map = useMap();
  const selectedUuidDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.uuid
  );
  return (
    <Box
      className={
        selectedUuidDevice
          ? classes.zoomControlContainerPopupActive
          : classes.zoomControlContainer
      }
      style={zoomControlStyles(pinnedMap, collapseAdditionalInfoSection)}
    >
      <Box
        className={classes.zoomControlItem}
        onClick={() => map.setZoom(map.getZoom() + 1)}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z'
            fill='black'
            fillOpacity='0.87'
          />
        </svg>
      </Box>
      <Box
        className={classes.zoomControlItem}
        onClick={() => map.setZoom(map.getZoom() - 1)}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M19 13H5V11H19V13Z' fill='black' fillOpacity='0.87' />
        </svg>
      </Box>
    </Box>
  );
};

export default ZoomControlWidget;

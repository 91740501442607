import { FC, memo, ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert/Alert';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import classes from '../../styles/vehicle.module.css';

interface IProps {
  gamepadRunning: boolean;
  isGamepadDisconnected: boolean;
  open: boolean;
  setOpen: (status: boolean) => void;
}

const Notifications: FC<IProps> = memo(
  ({ gamepadRunning, isGamepadDisconnected, open, setOpen }): ReactElement => {
    const { t } = useTranslation();
    const handleClose = (
      event: SyntheticEvent | Event,
      reason?: string
    ): void => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };

    const action = (
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    );
    const displayGamepadNotification = (): ReactElement | null => {
      if (gamepadRunning) {
        return (
          <Snackbar
            sx={{
              top: '68px',
              bottom: 'auto !important',
              left: 'auto !important',
              right: '5px !important',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={t('vehicle.gpConnection')}
            action={action}
          />
        );
      }
      return null;
    };

    return (
      <>
        <Box className={classes.additionalInfoBlock}>
          {isGamepadDisconnected ? (
            <Alert
              className={classes.alertContainer}
              variant='filled'
              severity='error'
              onClose={handleClose}
            >
              {t('vehicle.gpDisconnected')}
            </Alert>
          ) : null}
        </Box>
        {displayGamepadNotification()}
      </>
    );
  }
);

export default Notifications;

import { FC, ReactElement, memo } from 'react';

const BoatIcon: FC = memo(
  (): ReactElement => (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.62037 1.4001C5.62037 0.975098 5.97037 0.600098 6.42037 0.600098H9.62037C10.0454 0.600098 10.4204 0.975098 10.4204 1.4001V2.2001H11.6204C12.2704 2.2001 12.8204 2.7501 12.8204 3.4001V6.6001L13.9204 6.9751C14.4954 7.1751 14.6454 7.9251 14.1954 8.3251L11.6704 10.6501C11.2704 10.8751 10.8204 11.0251 10.4204 11.0251C9.92037 11.0251 9.39537 10.8251 8.92037 10.5251C8.37037 10.1251 7.64537 10.1251 7.09537 10.5251C6.67037 10.8001 6.14537 11.0251 5.62037 11.0251C5.19537 11.0251 4.74537 10.8751 4.34537 10.6501L1.82037 8.3251C1.37037 7.9251 1.52037 7.1751 2.09537 6.9751L3.22037 6.6001V3.4001C3.22037 2.7501 3.74537 2.2001 4.42037 2.2001H5.62037V1.4001ZM4.82037 6.0751L7.49537 5.1751C7.82037 5.0751 8.19537 5.0751 8.52037 5.1751L11.2204 6.0751V3.8001H4.82037V6.0751ZM10.4204 11.8001C11.0704 11.8001 11.7954 11.5501 12.3454 11.1501C12.6454 10.9501 13.0454 10.9751 13.3204 11.2001C13.6954 11.5001 14.1454 11.7251 14.5954 11.8251C15.0204 11.9251 15.2954 12.3751 15.1954 12.8001C15.0954 13.2251 14.6454 13.5001 14.2204 13.4001C13.6204 13.2501 13.0954 12.9751 12.7704 12.7751C12.0454 13.1501 11.2454 13.4001 10.4204 13.4001C9.62037 13.4001 8.89537 13.1751 8.39537 12.9501C8.24537 12.8751 8.12037 12.8001 8.02037 12.7501C7.89537 12.8001 7.77037 12.8751 7.62037 12.9501C7.12037 13.1751 6.39537 13.4001 5.62037 13.4001C4.77037 13.4001 3.97037 13.1501 3.24537 12.7751C2.92037 12.9751 2.39537 13.2501 1.79537 13.4001C1.37037 13.5001 0.920373 13.2251 0.820373 12.8001C0.720373 12.3751 0.995373 11.9251 1.42037 11.8251C1.87037 11.7251 2.32037 11.5001 2.69537 11.2001C2.97037 10.9751 3.37037 10.9501 3.67037 11.1501C4.22037 11.5501 4.94537 11.8001 5.62037 11.8001C6.29537 11.8001 6.99537 11.5501 7.54537 11.1501C7.82037 10.9501 8.19537 10.9501 8.47037 11.1501C9.02037 11.5501 9.72037 11.8001 10.4204 11.8001Z'
        fill='white'
      />
    </svg>
  )
);

export default BoatIcon;

import { FC, ReactElement, memo } from 'react';

interface IProps {
  disabled?: boolean;
}

const VisibilityIcon: FC<IProps> = memo(
  ({ disabled }): ReactElement => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <path
        d='M10.0002 3.75C5.8335 3.75 2.27516 6.34167 0.833496 10C2.27516 13.6583 5.8335 16.25 10.0002 16.25C14.1668 16.25 17.7252 13.6583 19.1668 10C17.7252 6.34167 14.1668 3.75 10.0002 3.75ZM10.0002 14.1667C7.70016 14.1667 5.8335 12.3 5.8335 10C5.8335 7.7 7.70016 5.83333 10.0002 5.83333C12.3002 5.83333 14.1668 7.7 14.1668 10C14.1668 12.3 12.3002 14.1667 10.0002 14.1667ZM10.0002 7.5C8.61683 7.5 7.50016 8.61667 7.50016 10C7.50016 11.3833 8.61683 12.5 10.0002 12.5C11.3835 12.5 12.5002 11.3833 12.5002 10C12.5002 8.61667 11.3835 7.5 10.0002 7.5Z'
        fill='#fff'
      />
    </svg>
  )
);

export default VisibilityIcon;

import { ReactElement, FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../common/router/AuthProvider';
import ROUTES from '../common/router/constants';
import { useAppSelector } from '../../store';
import { showErrorStatus, showHelperText } from '../common/helpers/utils';
import Logo from '../assets/Logo';
import classes from '../styles/auth.module.css';

const Login: FC = (): ReactElement => {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [isNavigateAvailable, setIsNavigateAvailable] = useState(true);

  const getUserProfileLoading = useAppSelector(
    (state) => state.user.getUserProfileLoading
  );
  const userData = useAppSelector((state) => state.user.userData);
  const getUserProfileError = useAppSelector(
    (state) => state.user.getUserProfileError
  );

  const { t } = useTranslation();

  // @ts-ignore
  const { login } = useAuth();
  const navigate = useNavigate();

  const isUserData = () => {
    setIsNavigateAvailable(false);
    if (userData) {
      if (userData.is_signed) {
        if (
          userData.permissions.use_remote_control &&
          userData.permissions.use_visual_navigation
        ) {
          navigate('/vehicles/list');
          return;
        }

        if (
          userData.permissions.use_remote_control &&
          !userData.permissions.use_visual_navigation
        ) {
          navigate('/vehicles/list');
          return;
        }

        if (
          !userData.permissions.use_remote_control &&
          userData.permissions.use_visual_navigation
        ) {
          navigate('/ons/history');
          return;
        }

        navigate('/no-access');
      } else {
        navigate('/license');
      }
    }
  };

  useEffect(() => {
    if (!isNavigateAvailable) {
      isUserData();
    }
    return () => {
      isUserData();
    };
  }, [userData, isNavigateAvailable]);

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    await login(
      formData.get('email') as string,
      formData.get('password') as string
    );
  };

  const navigateToPasswordReset = (): void => {
    navigate(ROUTES.RESTORE);
  };

  const handleClickShowPassword = (): void =>
    setPasswordVisibility(!passwordVisibility);

  return (
    <>
      <Box className={classes.loginHeaders}>
        <Logo orientation='vertical' />
        <Typography sx={{ paddingTop: '50px' }} variant='body1'>
          {t('login.title')}
        </Typography>
        <Typography variant='caption'>{t('login.subTitle')}</Typography>
      </Box>
      <Box
        className={classes.inputsContainer}
        component='form'
        onSubmit={handleSubmit}
      >
        <TextField
          error={showErrorStatus(getUserProfileError, 'email')}
          helperText={showHelperText(getUserProfileError, 'email')}
          placeholder={t('login.emailPlaceholder')}
          fullWidth
          name='email'
          inputProps={{
            maxLength: 101,
          }}
        />
        <TextField
          error={showErrorStatus(getUserProfileError, 'password')}
          helperText={showHelperText(getUserProfileError, 'password')}
          placeholder={t('login.passwordPlaceholder')}
          fullWidth
          name='password'
          type={passwordVisibility ? 'text' : 'password'}
          inputProps={{
            maxLength: 40,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: '2.5rem', mb: 1 }}
          disabled={getUserProfileLoading}
        >
          {t('login.signInButtonText')}
        </Button>
        <Button
          fullWidth
          onClick={navigateToPasswordReset}
          variant='text'
          disabled={getUserProfileLoading}
        >
          {t('login.restorePasswordText')}
        </Button>
      </Box>
    </>
  );
};

export default Login;

import { FC, ReactElement, memo } from 'react';

const Checked: FC = memo(
  (): ReactElement => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='84'
      height='84'
      viewBox='0 0 84 84'
      fill='none'
    >
      <path
        d='M41.9997 83.6663C18.9872 83.6663 0.333008 65.0122 0.333008 41.9997C0.333008 18.9872 18.9872 0.333008 41.9997 0.333008C65.0122 0.333008 83.6663 18.9872 83.6663 41.9997C83.6663 65.0122 65.0122 83.6663 41.9997 83.6663ZM41.9997 75.333C50.8402 75.333 59.3187 71.8211 65.5699 65.5699C71.8211 59.3187 75.333 50.8402 75.333 41.9997C75.333 33.1591 71.8211 24.6807 65.5699 18.4294C59.3187 12.1782 50.8402 8.66634 41.9997 8.66634C33.1591 8.66634 24.6807 12.1782 18.4294 18.4294C12.1782 24.6807 8.66634 33.1591 8.66634 41.9997C8.66634 50.8402 12.1782 59.3187 18.4294 65.5699C24.6807 71.8211 33.1591 75.333 41.9997 75.333V75.333ZM37.8455 58.6663L20.1663 40.9872L26.058 35.0955L37.8455 46.883L61.4122 23.3122L67.308 29.2038L37.8455 58.6663Z'
        fill='#26BD6C'
      />
    </svg>
  )
);

export default Checked;

import { useTranslation } from 'react-i18next';

const useBytesToSize = () => {
  const { t } = useTranslation();
  const sizes: string[] = [
    t('ons.bytes'),
    t('ons.kb'),
    t('ons.mb'),
    t('ons.gb'),
  ];

  const getSize = (bytes: number | null) => {
    if (!bytes) return 0;

    const i = parseInt(
      String(Math.floor(Math.log(bytes) / Math.log(1024))),
      10
    );

    return `${(bytes / 1024 ** i).toFixed(1)}${sizes[i]}`;
  };

  return [getSize];
};

export default useBytesToSize;

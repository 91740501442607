import { CSSProperties } from 'react';

export const setHeightForVehicleContainer = (
  collapseAdditionalInfoSection: boolean
): CSSProperties => {
  return {
    height: collapseAdditionalInfoSection ? '100vh' : '94vh',
  };
};

export const setWidthForStreamingContainer = (
  collapseAdditionalInfoSection: boolean,
  pinnedMap: boolean
): CSSProperties => {
  return {
    display: pinnedMap ? 'flex' : 'grid',
    width: collapseAdditionalInfoSection ? '100%' : '88.4%',
  };
};

export const setStylesForAdditionalInfoContainer = (
  collapseAdditionalInfoSection: boolean
): CSSProperties => {
  return {
    width: collapseAdditionalInfoSection ? 0 : '13rem', // 11.6%
    padding: collapseAdditionalInfoSection ? 0 : '1rem',
  };
};

export const setContainerHeight = (
  totalCamerasLength: number,
  collapseAdditionalInfoSection: boolean
): string => {
  if (totalCamerasLength === 1) {
    return collapseAdditionalInfoSection ? '100vh' : '83vh';
  }

  return collapseAdditionalInfoSection ? '94vh' : '80vh';
};

export const setGridTemplateColumns = (
  pinnedCamera: number | null,
  totalCameras: number
): string => {
  if (pinnedCamera !== null) {
    return '75% 1fr';
  }

  if (totalCameras === 1) {
    return 'repeat(1, 1fr)';
  }

  return 'repeat(2, 1fr)';
};
export const setStylesForVideoStreamContainer = (
  totalCameras: number[],
  collapseAdditionalInfoSection: boolean,
  pinnedCamera: number | null,
  pinnedMap: boolean,
  videoFullScreenCamera: number | null
): CSSProperties => {
  if (videoFullScreenCamera !== null && collapseAdditionalInfoSection) {
    return {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
    };
  }
  if (pinnedCamera !== null) {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '2rem 2rem 0 2rem',
      gap: '0.5rem',
      height: '80vh',
      width: '15%',
    };
  }
  if (pinnedMap) {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '2rem',
      gap: '0.5rem',
      height: '80vh',
      width: '15%',
    };
  }

  return {
    gridTemplateColumns: setGridTemplateColumns(
      pinnedCamera,
      totalCameras.length
    ),
    alignItems: pinnedCamera !== null ? 'inherit' : 'center',
    height: setContainerHeight(
      totalCameras.length,
      collapseAdditionalInfoSection
    ),
    rowGap: '0.5rem',
    columnGap:
      pinnedCamera !== null && totalCameras.length > 1 ? '2rem' : '0.5rem',
    padding: totalCameras.length === 1 ? 0 : '2rem 2rem 0 2rem',
  };
};

export const setStylesForSingleVideoStreamContainer = (
  fulledScreen: boolean,
  pinned: number | null,
  pinnedCamera: boolean,
  collapseAdditionalInfoSection: boolean,
  totalCameras: number,
  pinnedMap: boolean
): CSSProperties => {
  if (pinnedMap) {
    return {
      width: '100%',
      height: '10rem',
    };
  }
  if (fulledScreen) {
    return {
      position: 'absolute',
      top: collapseAdditionalInfoSection ? 0 : '4rem',
      left: 0,
      zIndex: 998,
      width: collapseAdditionalInfoSection ? '100%' : '86.8%',
      height: collapseAdditionalInfoSection ? '100vh' : '84%',
      boxShadow: 'none',
      borderRadius: 0,
    };
  }

  if (pinned !== null) {
    if (pinnedCamera) {
      return {
        height: collapseAdditionalInfoSection ? '100%' : '80%',
        width: collapseAdditionalInfoSection ? '82%' : '69%',
        position: 'absolute',
        left: collapseAdditionalInfoSection ? '18%' : '16%',
        bottom: collapseAdditionalInfoSection ? 0 : 'initial',
      };
    }

    if (!pinnedCamera) {
      return {
        height: '10rem',
        width: '100%',
        marginRight: '1rem',
      };
    }
  }

  return {
    position: 'relative',
    top: 'initial',
    left: 'initial',
    zIndex: 0,
    height: totalCameras === 2 ? '50vh' : '100%',
  };
};

export const setSingleVideoStreamVisibility = (
  videoStarted: boolean
): CSSProperties => ({
  display: videoStarted ? 'block' : 'none',
});

export const setSingleVideoStreamOutline = (
  videoHovered: boolean,
  totalCameras: number,
  fullScreen: boolean
): CSSProperties => ({
  outline:
    videoHovered && totalCameras > 1 && fullScreen
      ? '2px solid #FFFFFF'
      : 'none',
});

const setStylesForVehiclePageMapOutline = (
  mapHovered: boolean,
  collapseAdditionalInfoSection: boolean
): string => {
  if (collapseAdditionalInfoSection) {
    if (mapHovered) {
      return '2px solid rgba(232, 234, 246, 1)';
    }
    return '2px solid rgba(176, 190, 197, 1)';
  }

  return mapHovered ? '2px solid rgba(255, 255, 255, 1)' : 'none';
};

export const setStylesForVehiclePageMap = (
  mapHovered: boolean,
  collapseAdditionalInfoSection: boolean
): CSSProperties => {
  return {
    width: collapseAdditionalInfoSection ? '13rem' : '100%',
    outline: setStylesForVehiclePageMapOutline(
      mapHovered,
      collapseAdditionalInfoSection
    ),
    position: collapseAdditionalInfoSection ? 'absolute' : 'initial',
  };
};

export const setIconControlsStyles = (reduceButton: boolean): CSSProperties => {
  return {
    padding: reduceButton ? '0 5px' : '0 18px',
    gap: reduceButton ? 0 : '0.8rem',
  };
};

export const setLayoutOptionsStyles = (
  reduceButton: boolean,
  fullScreen: boolean
): CSSProperties => {
  if (fullScreen) {
    return {
      top: '0.8rem',
      right: '0.8rem',
    };
  }

  return {
    top: reduceButton ? 0 : '0.8rem',
    right: reduceButton ? 0 : '0.8rem',
  };
};
